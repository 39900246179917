//#region DO_NOT_EDIT
/*
  USED BY BUILD SCRIPT, DO NOT CHANGE THESE VALUES!!!
*/
const MAJOR = "1";
const MINOR = "8";
const POINT = "0";
const REVISION = "5221";
const HASH = "a6c3e092089ccb56dba28279c6cb10626e7aa39e";
//#endregion DO_NOT_EDIT

export class VERSION {
  public static SIMPLE = MAJOR + "." + MINOR;
  public static DEFAULT = MAJOR + "." + MINOR + "." + POINT + "." + REVISION;
  public static FULL =
    MAJOR + "." + MINOR + "." + POINT + "." + REVISION + "-" + HASH;
}
