import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { HIANotificationService } from "src/app/services/notification/notification.service";

export interface DismissResult {
  ok: boolean;
  neverShow: boolean;
}

export interface AlertModalOptions {
  mainText: string;
  description?: string;
  allowCancel?: boolean;
  showIcon?: boolean;
  customClass?: string;
  allowNeverShow?: boolean;
  allowBackdropDismiss?: boolean;
  neverShowText?: string;
  customButtons?: {
    label: string;
    color?: string;
    callback?: () => void;
    close?: boolean;
  }[];
  customInputs?: CustomAlertInput[];
}

export interface CustomAlertInput {
  label: string;
  type: string;
  value: string;
  onChange?: (value: string) => void;
}

@Component({
  selector: "app-alert-modal",
  templateUrl: "./alert-modal.component.html",
  styleUrls: ["./alert-modal.component.scss"],
  standalone: false,
})
export class AlertModalComponent implements OnInit {
  @ViewChild("AlertModalContent", { read: ViewContainerRef, static: false })
  m_AlertModalContent: ViewContainerRef | undefined;

  @Input("main-text") m_MainText: string = "";
  @Input("show-icon") m_ShowIcon: boolean = false;
  @Input("custom-class") m_CustomClass: string = "";
  @Input("description") m_Description: string | undefined = "";
  @Input("allow-cancel") m_AllowCancel: boolean = true;
  @Input("allow-never-show") m_AllowNeverShow: boolean = false;
  @Input("never-show-text") m_NeverShowText: string = "";
  @Input("allow-backdrop-dismiss") m_AllowBackdropDismiss: boolean = true;
  @Input("custom-buttons") m_CustomButtons?:
    | {
        label: string;
        color?: string;
        callback?: () => void;
        close?: boolean;
      }[]
    | null = null;
  @Input("custom-inputs") m_CustomInputs?:
    | {
        label: string;
        type: string;
        value: string;
        onChange?: (value: string) => void;
      }[]
    | null = null;

  @Input() externalOkFunction?: (value?: string) => Promise<void>;
  @Input() externalCancelFunction?: () => void;
  @Input() externalInputChangeFunction?: (value: string) => void;

  public m_IsOpen: boolean = false;
  public m_NeverShow: boolean = false;
  public m_Loading: boolean = false;
  public m_Error: boolean = false;
  public m_DisableOkButton: boolean = false;
  public m_InputText?: string = "";

  private m_Resolve?: (
    value: DismissResult | PromiseLike<DismissResult>
  ) => void;
  public m_Ok: boolean = false;

  constructor(private m_NotificationService: HIANotificationService) {}

  ngOnInit() {}

  show(
    okFunction?: (value?: string) => Promise<void>,
    cancelFunction?: () => void,
    inputChangeFunction?: (value: string) => void
  ): Promise<DismissResult> {
    this.externalOkFunction = okFunction;
    this.externalCancelFunction = cancelFunction;
    this.externalInputChangeFunction = inputChangeFunction;

    this.m_IsOpen = true;

    return new Promise<DismissResult>((resolve) => {
      this.m_Resolve = resolve;
    });
  }

  setButtonLoadingStatus(value: boolean) {
    this.m_Loading = value;
  }

  setButtonDisableStatus(value: boolean) {
    this.m_DisableOkButton = value;
  }

  async onOkClicked() {
    if (this.externalOkFunction) {
      await this.externalOkFunction(this.m_InputText);
      this.m_Ok = true;
    } else {
      this.m_Ok = true;
      this.m_IsOpen = false;
    }
  }

  async onCancelClicked() {
    if (this.externalCancelFunction) {
      this.externalCancelFunction();
    }
    this.m_Ok = false;
    this.m_IsOpen = false;
    this.clearData();
  }

  clearData() {
    this.m_Description = "";
    this.m_MainText = "";
  }

  onCustomButtonClick(button: any) {
    if (button.callback != null) {
      button.callback();
    }
    if (button.close) {
      this.m_IsOpen = false;
    }
  }

  onWillPresent() {
    this.m_IsOpen = true;
    this.m_Ok = false;
    this.m_NeverShow = false;
  }

  onWillDismiss() {
    this.m_IsOpen = false;
    if (this.m_Resolve != null) {
      this.m_Resolve({ ok: this.m_Ok, neverShow: this.m_NeverShow });
    }
    this.clearData();
  }

  onNeverShowChanged(target: any) {
    this.m_NeverShow = target?.checked;
  }

  onCustomInputChanged(input: any) {
    if (input.onChange != null) {
      input.onChange(input.value);
      this.m_InputText = input.value;
    }
  }

  onFocusOut() {
    if (this.externalInputChangeFunction && this.m_InputText)
      this.externalInputChangeFunction(this.m_InputText);
  }

  shouldDisplayLoading() {
    return this.m_Loading;
  }

  showNotification(text: string) {
    this.m_NotificationService.showError(text, 4000, "top");
  }

  shouldShowButtons(): boolean {
    return (
      (this.m_CustomButtons != null && this.m_CustomButtons.length > 0) ||
      this.m_AllowCancel
    );
  }
}
