import { NgModule } from "@angular/core";
import {
  PreloadAllModules,
  RouterModule,
  Routes,
  mapToCanActivate,
  mapToCanDeactivate,
} from "@angular/router";
import { SignedInGuardService } from "./services/route-guards/auth/signed-in-guard.service";
import { SignedOutGuardService } from "./services/route-guards/auth/signed-out-guard.service";
import { canDeactivateGuard } from "./services/route-guards/auth/can-leave.service";
import { EditPage } from "./pages/edit/edit.page";
import { LocalizeGuardService } from "./services/route-guards/initialization/localize-guard.service";
import { EditProfilePage } from "./pages/edit-profile/edit-profile.page";
import { ContentPageGuardService } from "./services/route-guards/initialization/content-page-guard.service";
import { IsAdminGuardService } from "./services/route-guards/auth/is-admin-guard.service";
import { LoggedInGuardService } from "./services/route-guards/auth/logged-in-guard.service";

const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
    canActivate: mapToCanActivate([LocalizeGuardService, SignedInGuardService]),
  },
  {
    //Home page dashboard
    path: "home",
    loadChildren: () =>
      import("./pages/home-page/home-page.module").then(
        (m) => m.HomePagePageModule
      ),
    canActivate: mapToCanActivate([LocalizeGuardService, SignedInGuardService]),
  },
  {
    path: "library",
    loadChildren: () => {
      return import("./pages/library/library.module").then(
        (m) => m.LibraryPageModule
      );
    },
    canActivate: mapToCanActivate([LocalizeGuardService, SignedInGuardService]),
  },
  {
    path: "playlists",
    loadChildren: () => {
      return import("./pages/playlist/playlist.module").then(
        (m) => m.PlaylistPageModule
      );
    },
    canActivate: mapToCanActivate([LocalizeGuardService]),
  },
  {
    path: "playlists/manage",
    loadChildren: () => {
      return import("./pages/playlist-manage/playlist-manage.module").then(
        (m) => m.PlaylistManagePageModule
      );
    },
    canActivate: mapToCanActivate([LocalizeGuardService, SignedInGuardService]),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
    canActivate: mapToCanActivate([LocalizeGuardService, LoggedInGuardService]),
  },
  {
    path: "watch",
    loadChildren: () =>
      import("./pages/watch/watch.module").then((m) => m.WatchPageModule),
    runGuardsAndResolvers: "always",
    canActivate: mapToCanActivate([LocalizeGuardService]),
  },
  {
    path: "edit",
    component: EditPage,
    loadChildren: () =>
      import("./pages/edit/edit.module").then((m) => m.EditPageModule),
    canActivate: mapToCanActivate([LocalizeGuardService, SignedInGuardService]),
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: "",
    loadChildren: () => {
      return import("./pages/home/home.module").then((m) => m.HomePageModule);
    },
    canActivate: mapToCanActivate([
      LocalizeGuardService,
      ContentPageGuardService,
    ]),
  },
  {
    path: "verify",
    loadChildren: () =>
      import("./pages/verify-email/verify-email.module").then(
        (m) => m.VerifyEmailPageModule
      ),
    canActivate: mapToCanActivate([LocalizeGuardService]),
  },
  {
    path: "edit-profile",
    component: EditProfilePage,
    loadChildren: () =>
      import("./pages/edit-profile/edit-profile.module").then(
        (m) => m.EditProfilePageModule
      ),
    canActivate: mapToCanActivate([LocalizeGuardService, SignedInGuardService]),
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: "pricing",
    loadChildren: () =>
      import("./pages/pricing/pricing.module").then((m) => m.PricingPageModule),
    canActivate: mapToCanActivate([LocalizeGuardService]),
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactPageModule),
    canActivate: mapToCanActivate([LocalizeGuardService]),
  },
  {
    path: "analytics",
    loadChildren: () =>
      import("./pages/analytics/dashboard-analytics/analytics.module").then(
        (m) => m.AnalyticsPageModule
      ),
    canActivate: mapToCanActivate([LocalizeGuardService, SignedInGuardService]),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./pages/admin/admin.module").then((m) => m.AdminPageModule),
    canActivate: mapToCanActivate([LocalizeGuardService, IsAdminGuardService]),
  },
  {
    path: "embed",
    loadChildren: () =>
      import("./pages/embed/embed.module").then((m) => m.EmbedPageModule),
  },
  {
    path: "embedqna",
    loadChildren: () =>
      import("./pages/embedQnA/embedQnA.module").then(
        (m) => m.EmbedQnAPageModule
      ),
  },
  {
    path: "u/:username",
    loadChildren: () =>
      import("./pages/public-profile/public-profile.module").then(
        (m) => m.PublicProfilePageModule
      ),
    canActivate: mapToCanActivate([LocalizeGuardService]),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
