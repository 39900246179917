import {
  ANALYTICS_AGGREGATED_QUERIES_DTO,
  ANALYTICS_DTO,
  ANALYTICS_QUERY_DTO,
} from "@shared/models/analytics/analytics";
import { VIDEO } from "../video/video";
import { MEDIA_TYPE } from "@shared/models/media/media";

export enum TRACK_EVENT {
  VIDEO_QUERY = "video_query",
  CLICK = "click",
  VIDEO_START = "video_start",
  VIDEO_PAUSE = "video_pause",
  VIDEO_END = "video_end",
  VIDEO_SKIP = "video_skip",
  VIDEO_RESUME = "video_resume",
  VIDEO_WATCH_CHECKPOINT = "video_watch_checkpoint",
  VIDEO_FEEDBACK = "video_query_feedback",
  VIDEO_OVERLAY_SHOWN = "video_overlay_shown",
  VIDEO_OVERLAY_CLICKED = "video_overlay_clicked",
  PAGE_VIEW = "page_view",
}

export interface TRACK_QUERY_DTO {
  sessionId: string;
  videoId: string;
  question: string;
  questionTyped: boolean;
  response_type: "record" | "fallback" | "insight" | "none";
  record_score?: number;
  record_id?: string;
  answer?: string;
  insight_key?: string;
  insight_name?: string;
  thumbsUpDown?: boolean;
}

export class RawAnalytics {
  video_id: string;
  views: number;
  total_watch_time: number;
  date_time: string;

  constructor(analyticsDTO: ANALYTICS_DTO) {
    this.video_id = analyticsDTO.video_id;
    this.views = parseInt(analyticsDTO.views as any);
    this.total_watch_time = parseInt(analyticsDTO.total_watch_time as any);
    this.date_time = analyticsDTO.date_time || "";
  }
}

export class RawAggregratedQueryData {
  video_id: string;
  questions_asked: number;
  most_frequently_asked: string;
  most_frequently_asked_count: number;
  correct_percentage: number;
  fallback_percentage: number;
  date_time: string;

  constructor(videoId: string, analyticsDTO: ANALYTICS_AGGREGATED_QUERIES_DTO) {
    this.video_id = videoId;
    this.questions_asked = parseInt(analyticsDTO.questions_asked as any);
    this.most_frequently_asked = analyticsDTO.most_frequently_asked;
    this.most_frequently_asked_count = parseInt(
      analyticsDTO.most_frequently_asked_count as any
    );
    this.correct_percentage = parseFloat(
      analyticsDTO.correct_percentage as any
    );
    this.fallback_percentage = parseFloat(
      analyticsDTO.fallback_percentage as any
    );
    this.date_time = analyticsDTO.date_time || "";
  }
}

export interface ParsedViewAnalytics {
  totalViews: number;
  totalWatchTime: number;
  totalWatchTimeFormatted: string;
  rawAnalytics: RawAnalytics[][];
}

export interface ParsedQueryAnalytics {
  totalQueries: number;
  totalQueryCorrectPercentage: number;
  totalQueryFallbackPercentage: number;
  rawAggregatedQueries: RawAggregratedQueryData[][];
}

export class ANALYTICS_QUERY implements ANALYTICS_QUERY_DTO {
  id: string;
  question: string;
  response_type: "record" | "fallback" | "insight" | "none";
  answer?: string;
  record_id?: string;
  insight_key?: string;
  insight_name?: string;
  media_id?: string;
  media_type?: MEDIA_TYPE;
  media_url?: string;
  reviewed?: Date;
  timestamp: Date;
  web_url?: string;
  end_time?: number;
  start_time?: number;

  video_id?: string;

  constructor(analyticsDTO: ANALYTICS_QUERY_DTO, videoId?: string) {
    this.id = analyticsDTO.id;
    this.question = analyticsDTO.question;
    this.response_type = analyticsDTO.response_type;
    this.answer = analyticsDTO.answer;
    this.record_id = analyticsDTO.record_id;
    this.insight_key = analyticsDTO.insight_key;
    this.insight_name = analyticsDTO.insight_name;
    this.media_id = analyticsDTO.media_id;
    this.media_type = analyticsDTO.media_type;
    this.media_url = analyticsDTO.media_url;
    this.reviewed = analyticsDTO.reviewed;
    this.timestamp = analyticsDTO.timestamp;
    this.web_url = analyticsDTO.web_url;
    this.end_time = analyticsDTO.end_time;
    this.start_time = analyticsDTO.start_time;

    this.video_id = videoId;
  }
}

export interface ParsedIndividualQueryAnalytics {
  data: ANALYTICS_QUERY[];
  total: number;
  page: number;
  pageSize: number;
  totalPages: number;
}

export interface DashboardAnalytics {
  video_id?: string;
  totalViews: number;
  totalWatchTime: number;
  totalWatchTimeFormatted: string;
  totalSessions?: number;
  queryDashboardAnalytics?: QueryDashboardAnalytics;
}

export interface TopAivioAnalytics {
  id: number;
  video_id: string;
  totalViews: number;
  totalWatchTime: number;
  totalWatchTimeFormatted: string;
  totalQuestionsAsked: number;
  totalQueriesCorrect: number;
  totalQueriesFallback: number;
  video?: VIDEO;
}

export interface QueryDashboardAnalytics {
  video_id?: string;
  totalQueries: number;
  totalQueryCorrectPercentage: number;
  totalQueryFallbackPercentage: number;
}

export interface QueryDonutChartData {
  category: string;
  value: number;
}

export interface AnalyticsCardData {
  value: number | string;
  title: string;
  tooltip?: string;
}
