import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { USER_DTO, USER_DTO_UPDATE_REQ } from "@shared/models/user/user";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { UserService } from "src/app/services/user/user.service";
import { AlertModalComponent } from "../../modals/alert-modal/alert-modal.component";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { SessionService } from "src/app/services/session/session.service";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
  standalone: false,
})
export class EditProfileComponent implements OnInit {
  @ViewChild("imgUpload", { static: false }) m_ImgUpload!: ElementRef;
  @ViewChild(AlertModalComponent) m_AlertModalComponent:
    | AlertModalComponent
    | undefined;

  public $t = T.translate;
  public m_UserInfo: USER_DTO;
  public m_Saving: boolean = false;
  public m_UserInfoCloned: USER_DTO;
  public m_BannerPreview: string = "";
  public m_BannerError: boolean = false;
  private m_ImageFile?: File;
  private m_MAX_FILE_SIZE: number = 6291456;
  private m_MAX_WIDTH: number = 2048;
  private m_MAX_HEIGHT: number = 1152;

  constructor(
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_NotificationService: HIANotificationService,
    private m_Events: Events
  ) {
    this.m_UserInfo = {
      id: this.m_AuthService.UserId,
      first_name: "",
      last_name: "",
      description: "",
      headline: "",
      profile_banner_url: "",
    };
    this.m_UserInfoCloned = JSON.parse(JSON.stringify(this.m_UserInfo));
  }

  ngOnInit() {
    this.fetchUserInfo();
  }

  async fetchUserInfo() {
    let userInfo = await this.m_UserService.ActiveUserInfo;
    if (userInfo == null) {
      userInfo = await this.m_UserService.getUserInfo(this.m_UserInfo.id);
    }
    this.m_UserInfo = {
      id: userInfo.id,
      first_name: userInfo.first_name || "",
      last_name: userInfo.last_name || "",
      headline: userInfo.headline || "",
      description: userInfo.description || "",
      profile_banner_url: userInfo.profile_banner_url || "",
    };
    if (this.m_UserInfo.profile_banner_url) {
      this.m_BannerPreview = this.m_UserInfo.profile_banner_url;
    } else this.m_BannerPreview = "assets/icon/video/upload-file.svg";
  }

  async saveChanges() {
    this.m_Saving = true;

    let userInfo: USER_DTO_UPDATE_REQ = {
      id: this.m_UserInfo.id || "",
      first_name: this.m_UserInfo.first_name || "",
      last_name: this.m_UserInfo.last_name || "",
      headline: this.m_UserInfo.headline || "",
      description: this.m_UserInfo.description || "",
    };

    try {
      await this.m_UserService.setUserInfo(userInfo, null, this.m_ImageFile);
      this.m_Events.publish(EVENTS.PROFILE_UPDATED);
      this.m_NotificationService.showSuccess(
        this.$t("pages.editProfile.profileUpdated"),
        1000
      );
    } catch (e) {
      console.log(e);
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.profileUpdateFailed"),
        1000
      );

      this.m_UserInfoCloned = JSON.parse(JSON.stringify(this.m_UserInfo));
    } finally {
      this.m_Saving = false;
    }
  }

  async onSelectPicture(event: any) {
    if (event?.target?.files != null && event?.target?.files[0] != null) {
      let file = event.target.files[0];
      this.m_ImageFile = file;
      if (this.m_ImageFile && this.m_ImageFile?.size > this.m_MAX_FILE_SIZE) {
        this.m_BannerError = true;
      } else {
        this.m_BannerError = false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        if (reader.result != null) {
          let img = new Image();
          img.src = reader.result.toString();

          img.onload = () => {
            let width = img.width;
            let height = img.height;

            if (width >= this.m_MAX_WIDTH || height >= this.m_MAX_HEIGHT) {
              this.m_BannerError = true;
            } else {
              this.m_BannerError = false;
            }
          };

          img.onerror = () => {
            console.error("Error while loading image.");
            this.m_BannerError = true;
          };
          this.m_BannerPreview = reader.result.toString();
        }
      };
      reader.readAsDataURL(file);
    }
  }

  handleFirstnameChange(value: any) {
    if (value) this.m_UserInfo.first_name = value;
  }

  handleLastnameChange(value: any) {
    if (value) this.m_UserInfo.last_name = value;
  }

  handleHeadlineChange(value: any) {
    if (value) this.m_UserInfo.headline = value;
  }

  handleDescriptionChange(value: any) {
    if (value) this.m_UserInfo.description = value;
  }

  onChangeClicked() {
    this.m_ImgUpload.nativeElement.click();
  }
}
