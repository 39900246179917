import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { VIDEO_STATUS } from "@shared/models/video/video";
import {
  PlayerEvent,
  SegmentEvent,
  VideoPlayerComponent,
} from "../video-player/video-player.component";
import {
  PollResults,
  VideoService,
} from "src/app/services/video/video.service";
import { PreloadType } from "theoplayer";
import { Subscription } from "rxjs";
import { QvioViewMode, VIDEO } from "src/app/models/video/video";
import { MediaProgressComponent } from "src/app/components/shared/media-progress/media-progress.component";
import { T } from "src/app/services/localization/localization.service";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { TRACK_EVENT } from "src/app/models/analytics/analytics";
import { ORIENTATION, UiService } from "src/app/services/ui/ui.service";
import { UserService } from "src/app/services/user/user.service";
import { Router } from "@angular/router";
import { HIA_MSG } from "src/app/services/chat/chat.service";
import { QnaAttachmentViewerComponent } from "../../qna/qna-attachment-viewer/qna-attachment-viewer.component";
import { TextToSpeechService } from "src/app/services/text-to-speech/text-to-speech.service";
import { QnaQuestionUiComponent } from "../../qna/qna-question-ui/qna-question-ui.component";
import { VideoInsightsComponent } from "../video-insights/video-insights.component";
import { AdminService } from "src/app/services/admin/admin.service";
import { parseTimestampsFromString } from "src/app/utility/video-utils";
import {
  UploadInfo,
  UploadService,
} from "src/app/services/upload/upload.service";
import { QnaModalComponent } from "../../modals/qna-modal/qna-modal.component";
import { checkIfPointIsInRect } from "src/app/utility/utils";
import { OVERLAY } from "src/app/models/overlays/overlay";
import { VideoOverlayComponent } from "../video-overlay/video-overlay.component";
import { ConfigService } from "src/app/services/config/config.service";
import { OVERLAY_DTO } from "@shared/models/overlays/overlay";

enum VIDEO_WIDGET_STATE {
  LOADING,
  VIDEO_READY,
  ERROR,
}

export type VideoErrors =
  | "videoNotAvailable"
  | "videoPermissionDenied"
  | "videoFailedProcess"
  | "videoDeleted"
  | "videoFailed";

@Component({
  selector: "app-video-widget",
  templateUrl: "./video-widget.component.html",
  styleUrls: ["./video-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class VideoWidgetComponent implements OnInit {
  @Input() m_QvioViewMode: QvioViewMode = QvioViewMode.WATCH;
  @Input() m_TrackSession: boolean = true;
  @Input() m_UseCachedTime: boolean = true;
  @Input() m_VisibleOverlaysIds: string[] = [];
  @Input() m_ShowSegment: boolean = false;
  @Input() m_SegmentStartTime: number = 0;
  @Input() m_SegmentEndTime: number = 0;
  @Input() m_SegmentWholeVideo: boolean = true;

  @Input() m_OverridePreload: PreloadType | undefined = undefined;
  @Output() VideoLoaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() SegmentChanged = new EventEmitter<SegmentEvent>();

  @ViewChild("m_PlayerRoot") m_PlayerRoot: ElementRef | undefined;
  @ViewChild(VideoPlayerComponent) private m_VideoPlayerComponent:
    | VideoPlayerComponent
    | undefined;
  @ViewChild(MediaProgressComponent) m_VideoProgressComponent:
    | MediaProgressComponent
    | undefined;
  @ViewChild("qnaOverlay") m_QnAOverlay: ElementRef | undefined;
  @ViewChild("m_VideoOverlay") m_VideoOverlay: ElementRef | undefined;
  @ViewChild(QnaAttachmentViewerComponent, { static: false })
  m_QnaAttachmentViewerComponent: QnaAttachmentViewerComponent | undefined;
  @ViewChild(QnaQuestionUiComponent, { static: false }) m_QnaUI:
    | QnaQuestionUiComponent
    | undefined;
  @ViewChild("insightsBtnOverlay") m_InsightsBtnOverlay: ElementRef | undefined;
  @ViewChild(VideoInsightsComponent, { static: false })
  m_VideoInsightsComponent: VideoInsightsComponent | undefined;
  @ViewChild("insightsOverlay") m_InsightsOverlay: ElementRef | undefined;
  @ViewChild("qnaModal") m_QnaModal?: QnaModalComponent;
  @ViewChild(VideoOverlayComponent) m_VideoOverlayComponent:
    | VideoOverlayComponent
    | undefined;
  @ViewChild("m_OverlayContainer") m_OverlayContainer: ElementRef | undefined;

  get Loaded() {
    return this.m_VideoWidgetState != VIDEO_WIDGET_STATE.LOADING;
  }
  get Progress() {
    return this.m_VideoProgressComponent?.Progress;
  }

  get Errored() {
    return this.m_VideoWidgetState == VIDEO_WIDGET_STATE.ERROR;
  }

  get Size() {
    return this.m_Size;
  }

  get VideoAspectRatio() {
    let width = this.m_VideoPlayerComponent?.Player?.videoWidth ?? 0;
    let height = this.m_VideoPlayerComponent?.Player?.videoHeight ?? 0;
    return height > 0 ? width / height : 0;
  }

  get ContainerAspectRatio() {
    return this.m_Size.width / this.m_Size.height;
  }

  get Paused() {
    return this.m_VideoPlayerComponent?.Paused;
  }
  get IsFullscreen() {
    return this.m_VideoPlayerComponent?.IsFullscreen;
  }

  get IsViewPage() {
    return this.m_QvioViewMode == QvioViewMode.WATCH;
  }

  get IsAdminPage() {
    return this.m_QvioViewMode == QvioViewMode.ADMIN;
  }

  get VideoStarted() {
    return this.m_VideoPlayerComponent?.m_VideoStarted;
  }

  private readonly CHECKPOINT_INTERVAL = 5.0;

  //Template helpers
  public $t = T.translate;
  public m_TimedOut: boolean = false;
  public m_IsActive: boolean = false;
  public m_ErrorMsg: string | null = null;
  //Polling events
  private m_QnaShowSub: Subscription | null = null;
  private m_Player360Sub: Subscription | null = null;
  private m_OnVideoReadySub: Subscription | null = null;
  private m_OnIndexReadySub: Subscription | null = null;
  private m_OnVideoProgressSub: Subscription | null = null;
  private m_OnVideoTimeoutSub: Subscription | null = null;
  private m_PollID: string | null = null;
  //Event Emitters/Listners
  private m_WindowResizeEvent: EventListener | null = null;
  private m_BackdropClickHandler: EventListener | null = null;

  //Overlay event subscription
  private m_OverlayEventSub: Subscription | undefined;

  //Data
  private m_VideoData: VIDEO | null = null;
  private m_VideoWidgetState: VIDEO_WIDGET_STATE = VIDEO_WIDGET_STATE.LOADING;
  private m_VideoCurrentTime: number = 0.0;
  private m_VideoSeekStartTime: number = 0.0;
  private m_VideoSeekEndTime: number = 0.0;
  private m_VideoCumulativeWatchTime: number = 0.0;
  private m_CurrentCheckpoint: number = 1;
  private m_VideoSeeking: boolean = false;
  private m_Size: { width: number; height: number } = { width: 0, height: 0 };
  private m_VideoStarted: boolean = false;
  private m_VideoEnded: boolean = false;
  private m_QnAOverlayOpen: boolean = false;
  private m_StartTime?: number;
  private m_UploadInfo?: UploadInfo;
  private m_OverlaysEnabled?: boolean = false;
  private m_OverlayPauseOnPlay: boolean = false;
  private m_OverlayBlock: boolean = false;
  private m_OnVideoEnded?: () => void;

  //--------------------------------------------------------------------
  get Title(): string {
    return this.m_VideoData?.video_name || "";
  }

  get VideoData(): VIDEO | null {
    return this.m_VideoData;
  }

  get VideoId(): string | null {
    return this.m_VideoData?.video_id ? this.m_VideoData.video_id : null;
  }

  get CanAskChatGPT(): boolean {
    return this.m_VideoData?.can_ask_chatgpt ?? false;
  }

  get CurrentVideoTime() {
    return this.m_VideoPlayerComponent?.CurrentTime ?? 0;
  }

  get InternalQnAOverlayOpen() {
    return this.m_QnAOverlayOpen;
  }
  //--------------------------------------------------------------------
  //Send events on queue on close app
  @HostListener("window:beforeunload", ["$event"])
  async beforeUnloadHandler() {
    this.trackLastEvent();
  }
  //--------------------------------------------------------------------
  constructor(
    private m_VideoService: VideoService,
    private m_Events: Events,
    private m_Analytics: AnalyticsService,
    private m_UiService: UiService,
    private m_TextToSpeechService: TextToSpeechService,
    private m_Router: Router,
    private m_UploadService: UploadService,
    private m_ConfigService: ConfigService
  ) {}

  //--------------------------------------------------------------------
  async ngOnInit() {
    this.m_QnaShowSub = this.m_Events.subscribe(EVENTS.QNA_MODAL_SHOW, () => {
      this.pausePlayer(true);
    });

    this.m_Player360Sub = this.m_Events.subscribe(
      EVENTS.PLAYER_360,
      (video: VIDEO) => {
        if (!this.VideoId) return;
        this.reset();
        this.m_VideoData = video;
        this.initializeVideo(video, video.video_id);
      }
    );

    try {
      let overlayConfig = JSON.parse(
        await this.m_ConfigService.get("OVERLAY_CONFIG")
      );
      this.m_OverlaysEnabled = overlayConfig?.enabled;
    } catch (err) {
      this.m_OverlaysEnabled = false;
    }
  }

  initialize() {
    this.m_OverlayPauseOnPlay = false;
    this.m_OverlayEventSub =
      this.m_VideoOverlayComponent?.overlayEvent.subscribe((eventData) => {
        this.handleOverlayEvent(eventData);
      });
  }

  //--------------------------------------------------------------------
  ngOnDestroy() {
    this.dispose();
    this.m_OverlayEventSub?.unsubscribe();
  }
  //#region Public Methods
  //--------------------------------------------------------------------
  dispose() {
    //console.log("Disposing video widget");
    this.m_IsActive = false;
    this.m_VideoStarted = false;
    if (this.m_WindowResizeEvent != null)
      window.removeEventListener("resize", this.m_WindowResizeEvent);

    this.m_QnaShowSub?.unsubscribe();
    this.m_Player360Sub?.unsubscribe();
    this.unsubscribeFromVideoStatusPolling();
    this.toggleQnABackdropEvent(false);
    this.m_VideoData = null;
    this.m_VideoWidgetState = VIDEO_WIDGET_STATE.LOADING;
    this.m_QnaUI?.clearSuggestions();
    this.m_VideoPlayerComponent?.ngOnDestroy();
  }
  //--------------------------------------------------------------------
  resumeVideo() {
    this.m_VideoPlayerComponent?.resumeVideo();
  }
  //--------------------------------------------------------------------
  addInsightEvents() {
    this.m_VideoPlayerComponent?.addInsightMessages();
  }
  //--------------------------------------------------------------------
  removeInsightEvents() {
    this.m_VideoPlayerComponent?.removeInsightMessages();
    this.m_Events.destroy(EVENTS.INSIGHT_LOADED);
    this.m_Events.publish(EVENTS.INSIGHT_DESTROY);
  }
  //--------------------------------------------------------------------
  reset() {
    this.unsubscribeFromVideoStatusPolling();
    this.toggleQnABackdropEvent(false);
    this.m_VideoPlayerComponent?.reset();
    this.m_VideoData = null;
    this.m_VideoWidgetState = VIDEO_WIDGET_STATE.LOADING;
    this.m_QnaUI?.clearSuggestions();
  }
  //--------------------------------------------------------------------
  initializeVideo(video: VIDEO, id: string) {
    try {
      this.m_VideoPlayerComponent?.buildVideoPlayer(
        video,
        null,
        null,
        null,
        this.m_StartTime,
        this.m_UseCachedTime,
        () => {
          this.m_VideoWidgetState = VIDEO_WIDGET_STATE.VIDEO_READY;
          //Dispatch resize event to force video to resize
          setTimeout(() => {
            this.resizeWidget();
          }, 100);

          this.VideoLoaded.emit();

          if (this.m_ShowSegment) {
            this.createMarkers();
          }
        }
      );
    } catch (e) {
      //Show Error icon on video
      console.log(e);
    } finally {
      this.m_IsActive = true;
    }
  }
  //--------------------------------------------------------------------
  //Fetch video data
  async initVideo(
    videoData: VIDEO,
    startTime?: number,
    onVideoEnded?: () => void
  ): Promise<boolean | string> {
    this.m_OnVideoEnded = onVideoEnded;
    this.m_StartTime = startTime;

    this.m_VideoData = videoData;

    if (this.VideoId != null) {
      this.m_UploadInfo = this.m_UploadService.getVideoUploadInfo(this.VideoId);
    }

    if (
      this.m_VideoData != null &&
      this.m_VideoData.video_status == VIDEO_STATUS.READY &&
      this.m_VideoData.video_url != null
    ) {
      let id = this.m_VideoData.video_id + Date.now().toString();
      this.initializeVideo(this.m_VideoData, id);
      this.m_Events.publish(EVENTS.VIDEO_UPDATED, this.m_VideoData);
    } else {
      //Begin polling for video status
      this.onVideoEncodingProgress(1);
      this.m_PollID = await this.m_VideoService.startVideoStatusPolling(
        this.m_VideoData.video_id
      );
      this.hookUpVideoStatusPolling();
      return true;
    }

    if (this.m_VideoData.video_index_status == VIDEO_STATUS.READY) {
      //Emit insight ready event
    } else if (this.m_VideoData.video_index_status != VIDEO_STATUS.ERROR) {
      //Begin polling for index status
      this.onVideoIndexingProgress(1);
      this.m_PollID = await this.m_VideoService.startVideoStatusPolling(
        this.m_VideoData.video_id
      );
      this.hookUpVideoStatusPolling();
    }
    return true;
  }
  //--------------------------------------------------------------------
  createMarkers() {
    this.m_VideoPlayerComponent?.createRangeMarkers(
      this.m_SegmentStartTime ?? 0,
      this.m_SegmentEndTime ?? 2,
      (startTime: number, endTime: number) => {
        const segment: SegmentEvent = {
          startTime: startTime,
          endTime: endTime,
        };
        this.SegmentChanged.emit(segment);
      }
    );
  }
  //--------------------------------------------------------------------
  updateMarkers(startTime: number, endTime: number) {
    this.m_VideoPlayerComponent?.updateRangeMarkers(startTime, endTime);
  }
  //--------------------------------------------------------------------
  updateOverlayData(overlayUpdated: OVERLAY) {
    if (this.m_VideoOverlayComponent) {
      this.m_VideoOverlayComponent.updateOverlay(overlayUpdated);
    }
  }
  //--------------------------------------------------------------------
  createOverlay(overlayUpdated: OVERLAY) {
    if (this.m_VideoOverlayComponent) {
      this.m_VideoOverlayComponent.createOverlay(overlayUpdated);
    }
    if (this.m_VideoData && this.m_VideoData.overlays == undefined)
      this.m_VideoData.overlays = [];

    this.m_VideoData?.overlays?.push(overlayUpdated);
  }
  //--------------------------------------------------------------------
  removeOverlay(overlayId: string) {
    if (this.m_VideoOverlayComponent) {
      this.m_VideoOverlayComponent.removeOverlay(overlayId);
    }
    if (this.m_VideoData?.overlays) {
      this.m_VideoData.overlays = this.m_VideoData?.overlays?.filter(
        (overlay) => overlay.id !== overlayId
      );
    }
    if (this.m_VideoData && this.m_VideoData?.overlays == undefined)
      this.m_VideoData.overlays = [];
  }

  //--------------------------------------------------------------------
  public resizeWidget(overrideSize?: { width: number; height: number }) {
    const aspectRatioContainer = this.m_PlayerRoot?.nativeElement;
    const parentContainer = aspectRatioContainer.parentElement;

    let parentWidth = parentContainer.offsetWidth;
    let parentHeight = parentContainer.offsetHeight;

    if (overrideSize) {
      parentWidth = overrideSize.width;
      parentHeight = overrideSize.height;
    }

    const aspectRatio = 16 / 9;

    // Calculate the maximum width and height while maintaining aspect ratio
    let maxWidth = parentHeight * aspectRatio;
    let maxHeight = parentWidth / aspectRatio;

    if (maxWidth > parentWidth) {
      // If the calculated width is more than the parent's width, adjust the height instead
      maxWidth = parentWidth;
      maxHeight = maxWidth / aspectRatio;
    }

    // Check if maxHeight is more than the parent's height
    if (maxHeight > parentHeight) {
      // If the calculated height is more than the parent's height, adjust the width instead
      maxHeight = parentHeight;
      maxWidth = maxHeight * aspectRatio;
    }

    aspectRatioContainer.style.width = maxWidth + "px";
    aspectRatioContainer.style.height = maxHeight + "px";
    this.m_Size = { width: maxWidth, height: maxHeight };
  }
  //--------------------------------------------------------------------
  public resetWidgetSize() {
    const aspectRatioContainer = this.m_PlayerRoot?.nativeElement;

    aspectRatioContainer.style.width = "100%";
    aspectRatioContainer.style.height = "100%";
  }
  //--------------------------------------------------------------------
  onVideoEncodingProgress(progress: number) {
    this.m_VideoProgressComponent?.setProgress(progress);
    this.m_VideoProgressComponent?.setRenderProgress(true);

    let text =
      this.VideoData?.video_status == VIDEO_STATUS.DUBBING
        ? "shared.messages.processingDub"
        : "shared.messages.processingVideo";

    this.m_VideoProgressComponent?.setProgressText(this.$t(text));
  }
  //--------------------------------------------------------------------
  onVideoIndexingProgress(progress: number) {
    this.m_VideoProgressComponent?.setProgress(progress);
    this.m_VideoProgressComponent?.setRenderProgress(true);
    this.m_VideoProgressComponent?.setProgressText(
      this.$t("shared.messages.processingAI")
    );
  }
  //--------------------------------------------------------------------
  pausePlayer(fadeOutAudio: boolean = false) {
    let interrupted = !this.m_VideoPlayerComponent?.Paused;
    this.m_VideoPlayerComponent?.pause(fadeOutAudio, interrupted);
  }
  //--------------------------------------------------------------------
  resumePlayer() {
    this.m_VideoPlayerComponent?.play();
  }
  //--------------------------------------------------------------------
  setDisabled(disabled: boolean) {
    if (disabled) {
      this.m_IsActive = false;
      this.m_VideoPlayerComponent?.pause();
    }
  }
  //--------------------------------------------------------------------
  setAutoPlay(status: boolean) {
    this.m_VideoPlayerComponent?.setAutoPlay(status);
  }
  //--------------------------------------------------------------------
  isUploading(): boolean {
    return this.m_UploadInfo?.uploading || false;
  }
  //--------------------------------------------------------------------
  uploadProgress(): number {
    return this.m_UploadInfo?.uploadProgress || 0;
  }
  //--------------------------------------------------------------------
  endTrackingSession() {
    this.onVideoEnded();
  }
  subscribeToQnaModalEvents() {
    this.m_QnaModal?.subscribeToEvents();
  }
  //--------------------------------------------------------------------
  unsubscribeQnaModalEvents() {
    this.m_QnaModal?.unsubscribeEvents();
  }
  //--------------------------------------------------------------------
  seekVideoTime(timeInSeconds: number) {
    this.m_VideoPlayerComponent?.seekVideoTime(timeInSeconds);
  }
  //--------------------------------------------------------------------
  onSegmentTimeChanged(timeEvent: SegmentEvent) {
    this.SegmentChanged.emit(timeEvent);
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region HTML Handlers
  onPlayerEvent(event: PlayerEvent) {
    switch (event.event) {
      case EVENTS.PLAYER_TIME_UPDATE:
        this.onPlayerTimeUpdate(event);
        break;
      case EVENTS.PLAYER_PLAY:
        this.onPlayerPlay(event);
        break;
      case EVENTS.PLAYER_PAUSE:
        this.onPlayerPause(event);
        break;
      case EVENTS.PLAYER_SEEK_START:
        this.onPlayerSeekStart(event);
        break;
      case EVENTS.PLAYER_SEEK_END:
        this.onPlayerSeekEnd(event);
        break;
      case EVENTS.PLAYER_ENDED:
        this.onVideoEnded();
        break;
      case EVENTS.PLAYER_FULLSCREEN:
        this.onVideoFullscreen(event.data ?? false);
        break;
      case EVENTS.VIDEO_ERROR:
        this.handleVideoError(event);
        break;
    }
  }

  onQnaClicked() {
    if (
      this.m_VideoPlayerComponent?.IsFullscreen ||
      !this.m_UiService.isMobileSize()
    ) {
      this.toggleQnAOverlay();
    } else {
      this.m_Events.publish(EVENTS.QNA_MODAL_SHOW, this.m_QvioViewMode);
    }
  }

  onRefreshClicked() {
    if (this.m_VideoData == null) return;
    this.m_TimedOut = false;
    this.m_VideoWidgetState = VIDEO_WIDGET_STATE.LOADING;
    this.initVideo(this.m_VideoData);
  }

  onShowAttachment(msg: HIA_MSG) {
    if (!this.m_QnAOverlayOpen) return;
    this.m_QnaAttachmentViewerComponent?.onShowAttachment(msg);
  }

  //Called when the user clicks the close attachment button
  onCloseAttachmentClicked() {
    this.m_QnaAttachmentViewerComponent?.onCloseAttachmentClicked();
  }

  //Called when the user clicks the close qna button
  onCloseQnAClicked() {
    this.onCloseAttachmentClicked();
    this.toggleQnAOverlay();
  }

  //Event handler for when the Qvio chapter list is modified
  handleNewChapterList() {
    let chapters = parseTimestampsFromString(
      this.m_VideoData?.video_description ?? ""
    );

    this.m_VideoPlayerComponent?.setChapters(chapters);
  }
  //#endregion
  //#region Render Methods
  getButtonName(name: string) {
    return name + "_" + (this.m_VideoData?.video_id ?? "");
  }
  //--------------------------------------------------------------------
  getPlayerRootBorderEmbedClass() {
    return this.m_UiService.VideoEmbed ? "player-root-border-embed" : "";
  }
  //--------------------------------------------------------------------
  getAttachmentType() {
    return this.m_QnaAttachmentViewerComponent?.getAttachmentType();
  }
  //--------------------------------------------------------------------
  isMobileHorizontal() {
    return (
      this.m_UiService.isMobile() &&
      this.m_UiService.currentOrientation() == "landscape"
    );
  }
  //--------------------------------------------------------------------

  getOverlays(): OVERLAY[] | undefined {
    if (this.m_OverlaysEnabled) {
      if (this.m_VisibleOverlaysIds && this.m_VisibleOverlaysIds.length) {
        return this.m_VideoData?.overlays?.filter((o) =>
          this.m_VisibleOverlaysIds.includes(o.id)
        );
      }

      return this.m_VideoData?.overlays;
    } else {
      return;
    }
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  private async handleVideoReady(results: PollResults) {
    if (results.video_id == this.m_VideoData?.video_id) {
      //Unsubscribe from the event
      this.m_OnVideoReadySub?.unsubscribe();

      if (results.success) {
        try {
          this.m_VideoData = await this.m_VideoService.fetchVideo(
            results.video_id
          );
        } catch (error) {
          //Display error icon on video
          this.handleVideoError(error);
          return;
        }

        if (this.m_VideoData.video_url == null) return;

        let id = this.m_VideoData.video_id + Date.now().toString();
        this.initializeVideo(this.m_VideoData, id);
      } else {
        this.handleVideoError("videoFailedProcess");
      }
    }
  }
  //--------------------------------------------------------------------
  private async handleIndexReady(results: PollResults) {
    if (results.video_id == this.m_VideoData?.video_id) {
      //Unsubscribe from the event
      this.m_OnIndexReadySub?.unsubscribe();

      if (results.success) console.log("Video indexed");
      else console.error("Failed to index video");
    }
  }
  //--------------------------------------------------------------------
  /**
   * Toggles the qna overlay
   * @param forceHide
   * @returns
   */
  private toggleQnAOverlay(forceHide?: boolean) {
    let container = this.m_QnAOverlay?.nativeElement;
    let stateToSet = !this.m_QnAOverlayOpen;
    if (container == null) return;
    if (forceHide != null) {
      stateToSet = !forceHide;
      container?.classList.toggle("disabled", forceHide);
    } else {
      container?.classList.toggle("disabled");
    }

    if (this.m_QnAOverlayOpen && stateToSet == true) {
      this.onCloseAttachmentClicked();
    } else if (stateToSet) {
      let interrupted = !this.m_VideoPlayerComponent?.Paused;
      this.m_VideoPlayerComponent?.pause(true, interrupted);
      this.m_TextToSpeechService.connectToSocket();
      this.m_QnaUI?.intialize();
      //Add event listener to close the modal on click outside
      this.toggleQnABackdropEvent(true);
    } else if (!stateToSet) {
      this.m_TextToSpeechService.disconnectFromSocket();
      this.m_QnaUI?.unhookEvents();
      this.m_QnaUI?.onAnswerClose();
      this.m_QnaUI?.clearSuggestions();
      this.onCloseAttachmentClicked();
      this.toggleQnABackdropEvent(false);
      //If the video is playing, resume it
      this.m_VideoPlayerComponent?.resumeVideo();
    }
    this.m_QnAOverlayOpen = stateToSet;
  }

  /**
   * Toggles the backdrop click event to close the internal qna overlay (Not the modal)
   * @param enabled
   */
  private toggleQnABackdropEvent(enabled: boolean) {
    if (enabled) {
      let container = this.m_QnAOverlay?.nativeElement.querySelector(
        ".player-qna-container"
      );
      if (container == null) return;

      //Call the function again to remove the event listener if it is already set
      this.toggleQnABackdropEvent(false);

      let firstTime = true;
      this.m_BackdropClickHandler = (event: Event) => {
        //Ignore the first click event as it is the one that opens the overlay
        if (firstTime) {
          firstTime = false;
          return;
        }

        //Check if click is outside the container rect
        let rect = container?.getBoundingClientRect();
        if (rect == null) return;
        if (!checkIfPointIsInRect(event as MouseEvent, rect)) {
          this.toggleQnAOverlay(true);
        }
      };
      document.body.addEventListener("click", this.m_BackdropClickHandler);
    } else {
      if (this.m_BackdropClickHandler != null) {
        document.body.removeEventListener("click", this.m_BackdropClickHandler);
        this.m_BackdropClickHandler = null;
      }
    }
  }

  /**
   * Toggles the insights overlay
   * @param forceHide
   * @returns
   */
  private toggleInsightsOverlay(forceHide?: boolean) {
    if (this.m_UiService.isMobile()) forceHide = true;

    let container = this.m_InsightsBtnOverlay?.nativeElement;
    if (container == null) return;
    if (forceHide != null) {
      container?.classList.toggle("hidden", forceHide);
    } else {
      container?.classList.toggle("hidden");
    }
  }

  /**
   * Toggles the overlays inside fullscreen mode
   * @param hide
   * @returns
   */
  private toggleMoveOverlay(hide: boolean) {
    let overlays = this.m_OverlayContainer?.nativeElement;
    let container =
      this.m_VideoPlayerComponent?.m_VideoContainer?.nativeElement;

    if (!container || !overlays) return;
    if (hide) {
      container?.appendChild(overlays);
    } else {
      this.m_PlayerRoot?.nativeElement.appendChild(overlays);
    }
  }

  //Called when the user clicks the show player insights button
  toggleInsightsPanel(forceHide?: boolean) {
    let container = this.m_InsightsOverlay?.nativeElement;
    if (container == null) return;
    if (forceHide != null) {
      container.classList.toggle("hidden", forceHide);
    } else {
      container.classList.toggle("hidden");
    }
  }

  shouldStackView() {
    return this.m_UiService.currentOrientation() == ORIENTATION.PORTRAIT;
  }

  //--------------------------------------------------------------------
  //Helper function to hook up to video status polling events
  private hookUpVideoStatusPolling() {
    this.m_OnVideoReadySub = this.m_VideoService.OnVideoReady.subscribe(
      (results: PollResults) => {
        this.handleVideoReady(results);
      }
    );

    this.m_OnIndexReadySub = this.m_VideoService.OnIndexReady.subscribe(
      (results: PollResults) => {
        this.handleIndexReady(results);
      }
    );

    this.m_OnVideoTimeoutSub = this.m_VideoService.OnVideoTimeout.subscribe(
      (results: PollResults) => {
        if (results.video_id == this.m_VideoData?.video_id) {
          console.log("Video timed out");

          //Unsubscribe from the event
          this.m_OnVideoTimeoutSub?.unsubscribe();
          this.m_TimedOut = true;
          this.m_VideoProgressComponent?.setProgressText("Timeout done");
        }
      }
    );

    this.m_OnVideoProgressSub = this.m_VideoService.OnVideoProgress.subscribe(
      (results: PollResults) => {
        if (results.video_id == this.m_VideoData?.video_id) {
          let progress = results.progress || 0;
          console.log("Video progress: " + results.progress);
          if (this.m_VideoWidgetState == VIDEO_WIDGET_STATE.LOADING) {
            this.onVideoEncodingProgress(progress);
            return;
          }
          if (this.m_VideoWidgetState == VIDEO_WIDGET_STATE.VIDEO_READY)
            this.onVideoIndexingProgress(progress);
        }
      }
    );
  }
  //--------------------------------------------------------------------
  handleVideoError(error: any) {
    this.m_VideoWidgetState = VIDEO_WIDGET_STATE.ERROR;
    this.m_VideoProgressComponent?.setProgress(100);
    this.unsubscribeFromVideoStatusPolling();
    const errorValue: VideoErrors = error.toString().split(" ")[1];

    switch (errorValue) {
      case "videoDeleted":
        this.m_ErrorMsg = this.$t("shared.messages.videoDeleted");
        return;
      case "videoNotAvailable":
        this.m_ErrorMsg = this.$t("shared.messages.videoNotAvailable");
        return;
      case "videoFailedProcess":
        this.m_ErrorMsg = this.$t("shared.messages.videoFailedProcess");
        return;
      case "videoPermissionDenied":
        this.m_ErrorMsg = this.$t("shared.messages.videoPermissionDenied");
        return;
      default:
        this.m_ErrorMsg = this.$t("shared.messages.videoFailed");
        return;
    }
  }
  //--------------------------------------------------------------------
  private unsubscribeFromVideoStatusPolling() {
    this.m_OnVideoReadySub?.unsubscribe();
    this.m_OnIndexReadySub?.unsubscribe();
    this.m_OnVideoProgressSub?.unsubscribe();
    this.m_OnVideoTimeoutSub?.unsubscribe();

    if (this.m_PollID)
      this.m_VideoService.stopVideoStatusPolling(this.m_PollID);
  }
  //--------------------------------------------------------------------
  // Handler for overlay events
  private handleOverlayEvent(eventData: { event: string; data: any }) {
    switch (eventData.event) {
      case "VIDEO_OVERLAY_SHOWN":
        this.trackSessionEvent(TRACK_EVENT.VIDEO_OVERLAY_SHOWN, {
          sessionId: this.m_Analytics.CurrWatchSessionId,
          trackId: this.m_Analytics.CurrWatchTrackId,
          videoId: this.m_VideoData?.video_id,
          overlayId: eventData.data.id,
          time: this.m_VideoCurrentTime,
        });
        break;
      case "VIDEO_OVERLAY_CLICKED":
        this.trackSessionEvent(TRACK_EVENT.VIDEO_OVERLAY_CLICKED, {
          sessionId: this.m_Analytics.CurrWatchSessionId,
          trackId: this.m_Analytics.CurrWatchTrackId,
          videoId: this.m_VideoData?.video_id,
          overlayId: eventData.data.id,
          time: this.m_VideoCurrentTime,
        });
        break;
      case "VIDEO_OVERLAY_PAUSE":
        this.onVideoOverlayPause();
        break;
      case "VIDEO_OVERLAY_RESUME":
        this.onVideoOverlayResume();
        break;
    }
  }
  //--------------------------------------------------------------------
  //Tracks a player event, if tracking is enabled
  private trackSessionEvent(
    eventName: TRACK_EVENT,
    metadata: any,
    forceSend: boolean = false
  ) {
    if (this.shouldTrackSession() && metadata != null) {
      this.m_Analytics.trackEvent(eventName, metadata, forceSend);
    }
  }

  //#endregion
  //#region Video Player Event Handlers
  private onVideoFullscreen(isFullscreen: boolean) {
    this.m_Events.publish(EVENTS.PLAYER_FULLSCREEN, isFullscreen);

    if (!isFullscreen) {
      //If we are exiting fullscreen, hide the the overlays
      this.toggleQnAOverlay(true);
      this.toggleInsightsOverlay(true);
      this.toggleInsightsPanel(true);
      this.toggleMoveOverlay(false);

      setTimeout(() => {
        this.resizeWidget();
      }, 100);
    } else {
      this.toggleInsightsOverlay(false);
      this.toggleMoveOverlay(true);
    }
  }

  private onVideoOverlayPause() {
    if (this.Paused && !this.m_OverlayBlock) {
      this.m_OverlayPauseOnPlay = true;
    } else {
      this.m_OverlayBlock = true;
      this.m_VideoPlayerComponent?.pause();
      this.m_VideoPlayerComponent?.disableControls();
    }
  }

  private onVideoOverlayResume() {
    this.m_OverlayBlock = false;
    this.m_VideoPlayerComponent?.enableControls();
  }

  //These methods are called specifically when this component instance's video player emits an event
  private onVideoEnded() {
    if (!this.shouldTrackSession() || this.m_VideoEnded) return;
    let watchTime = this.m_VideoCurrentTime - this.m_VideoSeekEndTime;
    let finalWatchTime = this.m_VideoCumulativeWatchTime + watchTime;

    this.m_VideoEnded = true;

    if (this.m_OnVideoEnded != null) this.m_OnVideoEnded();

    this.trackSessionEvent(TRACK_EVENT.VIDEO_END, {
      sessionId: this.m_Analytics.CurrWatchSessionId,
      trackId: this.m_Analytics.CurrWatchTrackId,
      videoId: this.m_VideoData?.video_id,
      watch_duration: finalWatchTime,
    });
  }

  private onPlayerTimeUpdate(event: PlayerEvent) {
    //Publish time update event from widget to components listening
    this.m_Events.publish(EVENTS.PLAYER_TIME_UPDATE, event.data);

    let time = event.data?.time;
    if (!this.shouldTrackSession() || time == null) return;

    this.m_VideoCurrentTime = time;

    if (!this.m_VideoSeeking) {
      let watchTime = this.m_VideoCurrentTime - this.m_VideoSeekEndTime;
      let checkpointWatchTime = this.m_VideoCumulativeWatchTime + watchTime;

      if (
        checkpointWatchTime >
        this.m_CurrentCheckpoint * this.CHECKPOINT_INTERVAL
      ) {
        this.m_VideoPlayerComponent?.addCurrentTimeStorage();
        this.m_CurrentCheckpoint++;

        if (this.m_TrackSession && this.m_VideoData?.video_id != null) {
          this.m_Analytics.reportWatchTime(this.m_VideoData?.video_id, time);
        }

        this.trackSessionEvent(TRACK_EVENT.VIDEO_WATCH_CHECKPOINT, {
          sessionId: this.m_Analytics.CurrWatchSessionId,
          trackId: this.m_Analytics.CurrWatchTrackId,
          videoId: this.m_VideoData?.video_id,
          time: time,
          cumulative_watch_time: checkpointWatchTime,
        });
      }

      this.m_VideoSeekStartTime = time;
    }
  }

  private trackLastEvent() {
    if (this.shouldTrackSession() && this.m_VideoData?.video_id != null) {
      let watchTime = this.m_VideoCurrentTime - this.m_VideoSeekEndTime;
      let checkpointWatchTime = this.m_VideoCumulativeWatchTime + watchTime;

      this.m_Analytics.reportWatchTime(
        this.m_VideoData?.video_id,
        this.m_VideoCurrentTime
      );

      this.trackSessionEvent(
        TRACK_EVENT.VIDEO_WATCH_CHECKPOINT,
        {
          sessionId: this.m_Analytics.CurrWatchSessionId,
          trackId: this.m_Analytics.CurrWatchTrackId,
          videoId: this.m_VideoData?.video_id,
          time: this.m_VideoCurrentTime,
          cumulative_watch_time: checkpointWatchTime,
        },
        true
      );
    }
  }

  private onPlayerPlay(event: PlayerEvent) {
    if (this.m_OverlayPauseOnPlay) {
      this.m_OverlayPauseOnPlay = false;
      this.onVideoOverlayPause();
      return;
    }

    let time = event.data?.time;
    if (!this.shouldTrackSession() || time == null) return;

    this.m_VideoEnded = false;
    this.m_VideoStarted = true;

    let eventName =
      time > 0.0001 ? TRACK_EVENT.VIDEO_RESUME : TRACK_EVENT.VIDEO_START;

    this.trackSessionEvent(eventName, {
      sessionId: this.m_Analytics.CurrWatchSessionId,
      trackId: this.m_Analytics.CurrWatchTrackId,
      videoId: this.m_VideoData?.video_id,
      time: time,
    });
  }

  private onPlayerPause(event: PlayerEvent) {
    let time = event.data?.time;
    if (!this.shouldTrackSession() || time == null) return;

    setTimeout(() => {
      if (!this.m_VideoEnded) {
        this.trackSessionEvent(TRACK_EVENT.VIDEO_PAUSE, {
          sessionId: this.m_Analytics.CurrWatchSessionId,
          trackId: this.m_Analytics.CurrWatchTrackId,
          videoId: this.m_VideoData?.video_id,
          time: time,
        });
      }
    }, 1);
  }

  private onPlayerSeekStart(event: PlayerEvent) {
    if (!this.shouldTrackSession()) return;

    if (!this.m_VideoSeeking) {
      let watchTime = this.m_VideoCurrentTime - this.m_VideoSeekEndTime;
      this.m_VideoCumulativeWatchTime += watchTime;
    }

    this.m_VideoSeeking = true;
  }

  private onPlayerSeekEnd(event: PlayerEvent) {
    let time = event.data?.time;
    if (!this.shouldTrackSession() || time == null) return;

    this.m_VideoSeekEndTime = time;
    this.m_VideoSeeking = false;
    if (!this.m_VideoStarted) return;

    this.trackSessionEvent(TRACK_EVENT.VIDEO_SKIP, {
      sessionId: this.m_Analytics.CurrWatchSessionId,
      trackId: this.m_Analytics.CurrWatchTrackId,
      videoId: this.m_VideoData?.video_id,
      from: this.m_VideoSeekStartTime,
      to: time,
    });
  }

  shouldDisplayAskmeButton() {
    return this.m_Size.width > 560;
  }

  hideAskMeButton() {
    return !this.m_VideoPlayerComponent?.m_VideoStarted;
  }

  private shouldTrackSession() {
    return this.m_TrackSession;
  }
  //#endregion
}
