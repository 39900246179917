import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { OVERLAY_TYPE } from "@shared/models/overlays/overlay";
import { ContentChange, QuillEditorComponent } from "ngx-quill";
import Quill from "quill";
import { T } from "src/app/services/localization/localization.service";

@Component({
  selector: "app-html-input",
  templateUrl: "./html-input.component.html",
  styleUrls: ["./html-input.component.scss"],
  standalone: false,
})
export class HTMLInputsComponent {
  @Output() InputsChanged = new EventEmitter<{
    property: string;
    value: string;
  }>();

  @Input() m_OverlayType: OVERLAY_TYPE = 0;
  @Input() m_EmbedUrl: string = "";
  @Input() m_UrlField: string = "";

  public $t = T.translate;

  private m_Quill?: Quill;

  get Modules() {
    return {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [
          {
            color: [
              "#000000",
              "#e60000",
              "#ff9900",
              "#ffff00",
              "#008a00",
              "#0066cc",
              "#9933ff",
              "#ffffff",
              "#facccc",
              "#ffebcc",
              "#ffffcc",
              "#cce8cc",
              "#cce0f5",
              "#ebd6ff",
              "#bbbbbb",
              "#f06666",
              "#ffc266",
              "#ffff66",
              "#66b966",
              "#66a3e0",
              "#c285ff",
              "#888888",
              "#a10000",
              "#b26b00",
              "#b2b200",
              "#006100",
              "#0047b2",
              "#6b24b2",
              "#444444",
              "#5c0000",
              "#663d00",
              "#666600",
              "#003700",
              "#002966",
              "#3d1466",
            ],
          },
          { background: [] },
        ], // dropdown with defaults from theme
        [{ font: [] }],

        ["clean"], // remove formatting button
      ],
    };
  }

  constructor(private m_Element: ElementRef) {}

  setRTFText(value: string) {
    this.m_Quill?.clipboard.dangerouslyPasteHTML(value);
  }

  handleEditorCreated(quill: Quill) {
    this.m_Quill = quill;
  }

  handleInputChange(
    property: "m_TextField" | "m_EmbedUrl" | "m_UrlField",
    event: Event | ContentChange
  ) {
    let value: string;
    if (property == "m_TextField") {
      value = (event as ContentChange).html ?? "";
    } else {
      value = ((event as Event).target as HTMLInputElement).value;
    }

    this.InputsChanged.emit({ property, value });
  }

  resetClicked() {
    this.m_EmbedUrl = "";
    this.m_UrlField = "";
    this.setRTFText("");
  }

  setBgColor(color: string) {
    let query =
      this.m_Element.nativeElement.getElementsByClassName("ql-editor");

    if (query != null && query.length > 0) {
      let editor = query[0];
      (editor as HTMLElement).style.backgroundColor = color;
    }
  }
}
