import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ConfigService } from "src/app/services/config/config.service";
import { InspectletService } from "src/app/services/inspectlet/inspectlet.service";
import { T } from "src/app/services/localization/localization.service";
import { SessionService } from "src/app/services/session/session.service";
import { UserService } from "src/app/services/user/user.service";

interface Plan {
  monthly: PlanInfo;
  annual?: PlanInfo;
}

interface PlanInfo {
  planName: string;
  title: () => string;
  price: () => string;
  videoLength: () => string;
  numberOfRecords: () => string;
  integratedAI: () => string;
  numberOfUserViews: () => string;
  analyticsPackage: () => string;
  button: () => string;
  action: () => void;
  trialLength?: number;
}

interface BillingConfig {
  freemiumPlan: { monthly: PlanConfig; annual: PlanConfig };
  starterPlan: { monthly: PlanConfig; annual: PlanConfig };
  proPlan: { monthly: PlanConfig; annual: PlanConfig };
  premiumPlan: { monthly: PlanConfig; annual: PlanConfig };
}

interface PlanConfig {
  planName: string;
  title: "starter" | "pro" | "premium";
  billingURL: string;
  priceValue: number | "custom";
  pricePeriod?: "monthly" | "annual";
  videoLengthValue: number | "unlimited";
  videoLengthTimeUnit?: "minutes" | "hours";
  videoLengthPeriod?: "monthly" | "annual";
  numberOfRecordsValue: number | "unlimited";
  integratedAIValue: "no" | "chatgpt" | "custom";
  numberOfViewsValue: number | "unlimited";
  analyticsPackage: "standard" | "premium";
  button: "default" | "trial" | "contact";
  trialLength?: number;
}

type Period = "monthly" | "annual";

@Component({
  selector: "app-pricing-component",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
  standalone: false,
})
export class PricingComponent implements OnInit {
  private m_Plans?: Plan[];
  private m_CurrentPeriod: Period = "monthly";
  private m_BillingConfigObj?: BillingConfig;
  private m_BillingManageSubscription?: string;
  private m_Loading = true;
  private m_Redirecting = false;

  private m_QueryParamsSub: Subscription | null = null;

  public $t = T.translate;

  get Redirecting() {
    return this.m_Redirecting;
  }

  constructor(
    private m_Router: Router,
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_ConfigService: ConfigService,
    private m_Route: ActivatedRoute,
    private m_InspectletService: InspectletService
  ) {}

  async ngOnInit() {
    this.m_Loading = true;
    await this.initializePlans();
    this.m_Loading = false;

    //Check if a plan was queued in the URL
    this.m_QueryParamsSub = this.m_Route.queryParams.subscribe(
      (params: Params) => {
        this.checkForQueuedPlan(params);
      }
    );
  }

  ngOnDestroy() {
    this.m_QueryParamsSub?.unsubscribe();
    this.m_QueryParamsSub = null;
  }

  //#region Render Helpers
  getPlans() {
    return this.m_CurrentPeriod == "monthly"
      ? this.m_Plans?.map((plan) => plan.monthly)
      : this.m_Plans
          ?.filter((plan) => plan.annual !== undefined) // Remove plans that don't have plan.annual
          .map((plan) => plan.annual);
  }

  getPeriodButtonClass(period: Period) {
    return this.m_CurrentPeriod == period
      ? "hia-primary-btn"
      : "period-button-unselected";
  }

  getBorderClass(planInfo: PlanInfo | undefined) {
    if (!planInfo) return "";
    return this.isCurrentPlan(planInfo) ? "currentPlanBorder" : "";
  }

  getPlanButtonLabel(plan: PlanInfo) {
    if (this.isCurrentPlan(plan)) {
      return this.$t("components.pricing.manageButton");
    } else {
      switch (plan.planName) {
        case "premium":
          return this.$t("components.pricing.plan.button.contact");
        case "freemium_monthly":
          return this.$t("components.pricing.plan.button.signUp");
        default:
          return plan.button();
      }
    }
  }

  isCurrentPlan(plan: PlanInfo) {
    if (!plan) return false;
    let currentPlanName = this.m_UserService?.ActiveUserLimits?.tierName || "";
    return plan.planName == currentPlanName;
  }

  isLoading() {
    return this.m_Loading || this.m_Redirecting;
  }

  getLoadingMsg() {
    if (this.m_Loading) {
      return this.$t("components.pricing.loading");
    } else if (this.m_Redirecting) {
      return this.$t("components.pricing.redirecting");
    } else {
      return "";
    }
  }

  onPeriodSelect(period: Period) {
    this.m_CurrentPeriod = period;
  }
  //#endregion
  //#region HTML Handlers
  async onBillingClick(config: PlanConfig) {
    if (this.m_AuthService?.LoggedIn) {
      let id = this.m_UserService.ActiveUserInfo?.id;
      let email = this.m_UserService.ActiveUserInfo?.email;

      if (id == null || email == null) {
        return;
      }

      let billingUrl = config.billingURL.replace("{{ID}}", id);
      billingUrl = config.billingURL.replace("{{EMAIL}}", email);

      let currentPlanName =
        this.m_UserService?.ActiveUserLimits?.tierName || "";

      this.m_Redirecting = true;
      let url: string | undefined;

      if (currentPlanName == config.planName) {
        url = this.m_BillingManageSubscription;
      } else {
        url = await this.m_UserService.createCheckoutSession(billingUrl);
      }

      if (url) {
        this.m_InspectletService.tagSessionMetadata("viewed checkout");
        this.openBillingPage(url);
      } else {
        this.m_Loading = false;
        this.m_Redirecting = false;
      }
    } else {
      this.m_Router.navigate(["/login"], {
        queryParams: {
          signup: true,
          plan: config.planName,
        },
      });
    }
  }
  //#endregion

  async initializePlans() {
    let billingConfig = await this.m_ConfigService.get("BILLING_CONFIG");
    if (billingConfig == null) {
      return;
    }
    this.m_BillingConfigObj = JSON.parse(billingConfig);
    if (this.m_BillingConfigObj == null) {
      return;
    }

    if (this.m_AuthService?.LoggedIn) {
      try {
        this.m_BillingManageSubscription =
          await this.m_UserService.createPortalLink();
      } catch (e) {
        console.error(e);
      }
    }

    this.m_Plans = [
      this.generatePlan(this.m_BillingConfigObj.freemiumPlan),
      this.generatePlan(this.m_BillingConfigObj.starterPlan),
      this.generatePlan(this.m_BillingConfigObj.proPlan),
      this.generatePlan(this.m_BillingConfigObj.premiumPlan),
    ];
  }

  private generatePlan(config: {
    monthly: PlanConfig;
    annual: PlanConfig | undefined;
  }): Plan {
    if (config.annual)
      return {
        monthly: this.generatePlanInfo(config.monthly),
        annual: this.generatePlanInfo(config.annual),
      };
    else {
      return {
        monthly: this.generatePlanInfo(config.monthly),
      };
    }
  }

  private generatePlanInfo(config: PlanConfig): PlanInfo {
    let canUseFreeTrial = this.m_UserService.CanUseFreeTrial;
    let button = "";
    if (
      config.button == "trial" &&
      canUseFreeTrial &&
      config.trialLength != null
    ) {
      button = this.$t(
        "components.pricing.plan.button." + config.button
      ).replace("{0}", config.trialLength || 7);
    } else {
      config.button = "default";
      button = this.$t("components.pricing.plan.button." + config.button);
    }

    return {
      planName: config.planName,
      title: () => this.$t("components.pricing.plan.title." + config.title),
      price: () => this.generatePrice(config),
      videoLength: () => this.generateVideoLength(config),
      numberOfRecords: () => this.generateNumberOfRecords(config),
      integratedAI: () =>
        this.$t(
          "components.pricing.plan.integratedAI." + config.integratedAIValue
        ),
      numberOfUserViews: () =>
        config.numberOfViewsValue == "unlimited"
          ? this.$t("components.pricing.plan.unlimited")
          : config.numberOfViewsValue.toString(),
      analyticsPackage: () =>
        this.$t(
          "components.pricing.plan.analyticsPackage." + config.analyticsPackage
        ),
      button: () => button,
      action: () => {
        if (config.planName == "premium") {
          this.m_Router.navigate(["/contact"], {
            queryParams: {},
          });
        } else {
          this.onBillingClick(config);
        }
      },
    };
  }

  //#region Private Helpers
  private generatePrice(config: PlanConfig): string {
    if (config.priceValue == "custom") {
      return this.$t("components.pricing.plan.priceCustom");
    } else {
      let pricePeriod = this.$t(
        "components.pricing.plan.pricePeriod." + config.pricePeriod
      );

      return this.$t("components.pricing.plan.price")
        .replace("{{PRICE}}", config.priceValue)
        .replace("{{PERIOD}}", pricePeriod);
    }
  }

  private generateVideoLength(config: PlanConfig): string {
    if (config.videoLengthValue == "unlimited") {
      return this.$t("components.pricing.plan.anyOrUnlimited");
    } else {
      let videoLengthTimeUnit = this.$t(
        "components.pricing.plan.videoLengthTimeUnit." +
          config.videoLengthTimeUnit
      );

      let videoLengthPeriod = this.$t(
        "components.pricing.plan.videoLengthPeriod." + config.videoLengthPeriod
      );

      return this.$t("components.pricing.plan.videoLength")
        .replace("{{VALUE}}", config.videoLengthValue)
        .replace("{{UNIT}}", videoLengthTimeUnit)
        .replace("{{PERIOD}}", videoLengthPeriod);
    }
  }

  private generateNumberOfRecords(config: PlanConfig): string {
    if (config.numberOfRecordsValue == "unlimited") {
      return this.$t("components.pricing.plan.anyOrUnlimited");
    } else {
      return this.$t("components.pricing.plan.numberOfRecords").replace(
        "{{VALUE}}",
        config.numberOfRecordsValue
      );
    }
  }

  private openBillingPage(url: string) {
    //Open managemnt page in new tab
    if (url == this.m_BillingManageSubscription) {
      window.open(url, "_blank");
      this.m_Redirecting = false;
    } else {
      //Redirect to billing page
      window.location.href = url;
    }
  }

  private checkForQueuedPlan(params: Params) {
    let plan = params["plan"];
    if (plan && this.m_BillingConfigObj != null) {
      this.m_CurrentPeriod = plan.includes("monthly") ? "monthly" : "annual";

      if (plan.includes("starter")) {
        this.onBillingClick(
          this.m_BillingConfigObj.starterPlan[this.m_CurrentPeriod]
        );
      } else if (plan.includes("pro")) {
        this.onBillingClick(
          this.m_BillingConfigObj.proPlan[this.m_CurrentPeriod]
        );
      } else if (plan.includes("freemium")) {
        this.onBillingClick(this.m_BillingConfigObj.freemiumPlan["monthly"]);
      }
    }
  }
  //#endregion
}
