import { Component, OnInit } from "@angular/core";
import { EVENTS } from "src/app/constants/events";
import { RECORD } from "src/app/models/record/record";
import { Events } from "src/app/services/events/events.service";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { RecordService } from "src/app/services/record/record.service";
import { VideoService } from "src/app/services/video/video.service";
import { v4 as uuidv4 } from "uuid";
import { ConfigService } from "src/app/services/config/config.service";
import { RecordEditorConfig } from "../../record-management/record-editor/record-editor/record-editor.component";
import { Subscription } from "rxjs";

export type GENERATE_TYPES = "prompt" | "transcript" | null;

@Component({
  selector: "app-generate-questions-modal",
  templateUrl: "./generate-questions-modal.component.html",
  styleUrls: ["./generate-questions-modal.component.scss"],
  standalone: false,
})
export class GenerateQuestionsModalComponent implements OnInit {
  public $t = T.translate;
  public m_IsOpen: boolean = false;
  public m_videoId: string = "";
  public m_Records: RECORD[] = [];
  public m_Loading: boolean = false;
  public m_PromptInput: string = "";
  public m_NotificationText: string = "";
  public m_IsTranscriptMode: boolean = false;

  private m_MediaId?: string | undefined = undefined;
  private m_RecordConfig: RecordEditorConfig | null = null;
  private m_OnRecordDelete: Subscription | null = null;

  get VideoId() {
    return this.m_videoId;
  }

  get MediaId() {
    return this.m_MediaId;
  }

  get Records() {
    return this.m_Records;
  }

  hasRecords() {
    if (this.m_IsOpen)
      return this.m_Records != null && this.m_Records.length > 0;
    else return false;
  }
  constructor(
    private m_RecordService: RecordService,
    private m_Events: Events,
    private m_NotificationService: HIANotificationService,
    private m_VideoService: VideoService,
    private m_ConfigService: ConfigService
  ) {
    this.m_ConfigService.get("RECORD_CONFIG").then((config) => {
      let recordConfig: RecordEditorConfig = JSON.parse(config);
      if (recordConfig == null) return;
      this.m_RecordConfig = recordConfig;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.m_OnRecordDelete) this.m_OnRecordDelete.unsubscribe();
  }

  openModal(
    videoId: string,
    mediaId: string | undefined,
    transcript?: boolean
  ) {
    this.m_IsOpen = true;
    this.m_videoId = videoId;
    this.m_MediaId = mediaId;
    if (transcript) this.generateByTranscript();

    this.m_OnRecordDelete = this.m_Events.subscribe(
      EVENTS.RECORD_DELETE,
      (data) => {
        this.deleteRecord(data.id);
      }
    );
  }

  closeModal() {
    this.m_IsOpen = false;
    this.resetInputs();

    if (this.m_OnRecordDelete) this.m_OnRecordDelete.unsubscribe();
  }

  resetInputs() {
    this.m_Records = [];
    this.m_videoId = "";
    this.m_MediaId = "";
    this.m_PromptInput = "";
    this.m_IsTranscriptMode = false;
  }

  onCancelClicked() {
    this.resetInputs();
    this.closeModal();
  }

  onAcceptClicked() {
    if (!this.m_Records) return;
    this.m_Loading = true;
    this.m_Events.publish(EVENTS.RECORD_GENERATED_CONFIRMED, {
      records: this.m_Records,
    });
    this.m_Loading = false;
    this.closeModal();
  }

  onGenerateClicked() {
    this.m_Records = [];
    if (this.m_IsTranscriptMode) {
      this.generateByTranscript();
    } else {
      this.generateByPrompt();
    }
  }

  onInputChanged(input: any) {
    if (input.target.value) this.m_PromptInput = input.target?.value;
  }

  //Generate questions by transcript
  async generateByTranscript() {
    this.m_IsTranscriptMode = true;
    this.m_Loading = true;

    try {
      //Make request to generate
      const response = await this.m_VideoService.generateQnAPairs(this.VideoId);
      if (response.qaPairs && response.qaPairs.length) {
        this.m_NotificationService.showSuccess(
          this.$t("shared.messages.generateQnASuccessfully"),
          5000
        );

        const enableAutoSegmentGeneration =
          this.m_RecordConfig?.enableAutoSegmentGeneration ?? false;

        response.qaPairs.forEach((qna) => {
          let newRecord = new RECORD();

          newRecord.record_id = "unsaved-" + uuidv4();
          newRecord.question = qna.question;
          newRecord.answer = qna.answer;
          newRecord.unsaved = true;
          newRecord.video_id = this.VideoId;
          if (enableAutoSegmentGeneration ?? false) {
            newRecord.video_start_time = qna.startTime ?? undefined;
            newRecord.video_end_time = qna.endTime ?? undefined;
          }
          this.m_Records?.push(newRecord);
        });
      }

      //Failed to get QA Pairs
      if (
        !response.qaPairs ||
        response.qaPairs.length === 0 ||
        response.status !== 200
      ) {
        this.m_NotificationService.showError(
          this.$t("shared.messages.generateQnAFailed"),
          5000
        );
      }
    } catch (err: any) {
      console.error(err);
      this.m_NotificationService.showError(err.message, 10000);
    } finally {
      this.m_Loading = false;
    }
  }

  async generateByPrompt() {
    //Generate questions by Prompt
    this.m_Loading = true;

    //Make request to generate
    try {
      const response = await this.m_RecordService.generateQnAPairsByPrompt(
        this.m_PromptInput
      );
      if (response.qaPairs && response.qaPairs.length) {
        this.m_NotificationService.showSuccess(
          this.$t("components.modals.generateQnA.generateSucess"),
          2000
        );
        response.qaPairs.forEach((qna) => {
          let newRecord = new RECORD();

          newRecord.record_id = "unsaved-" + uuidv4();
          newRecord.question = qna.question;
          newRecord.answer = qna.answer;
          newRecord.unsaved = true;
          newRecord.video_id = this.VideoId;
          this.m_Records?.push(newRecord);
        });
      }
      if (response.status != 200) {
        if (response.error) this.m_NotificationText = response.error;
        else
          this.m_NotificationText = this.$t(
            "components.modals.generateQnA.generateError"
          );
        this.m_NotificationService.showError(this.m_NotificationText, 5000);
      }
    } catch {
      this.m_NotificationService.showError(
        this.$t("components.modals.generateQnA.generateError")
      );
    } finally {
      this.m_Loading = false;
    }
  }

  public isTranscript() {
    return this.m_IsTranscriptMode;
  }

  deleteRecord(id: string) {
    if (this.m_Records == null) return;
    // Check if the record with the given id exists
    const recordExists = this.m_Records.some(
      (record) => record.record_id === id
    );

    // Proceed only if the record exists
    if (recordExists) {
      this.m_Records = this.m_Records.filter(
        (record) => record.record_id != id
      );
    }
  }
}
