import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { VIDEO_PUBLISH_STATE } from "@shared/models/video/video";
import { VIDEO } from "src/app/models/video/video";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { UserService } from "src/app/services/user/user.service";
import {
  LanguageData,
  VideoService,
} from "src/app/services/video/video.service";
import { uuidToShortId } from "@shared/utils/utils";
import { VOICE } from "src/app/models/voice/voice";
import { VoiceService } from "src/app/services/voice/voice.service";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { AdminService } from "src/app/services/admin/admin.service";
import { LANGUAGES } from "src/app/constants/languages";
import { getLanguageName } from "src/app/utility/video-utils";
import { Language } from "@azure/video-indexer-widgets";

interface PropertiesRadioOption {
  label: string;
  value: any;
  tooltip: string;
  disabled: boolean;
}

@Component({
  selector: "app-video-publish",
  templateUrl: "./video-publish.component.html",
  styleUrls: ["./video-publish.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class VideoPublishComponent implements OnInit {
  @ViewChild("m_NotificationContainer", {
    read: ViewContainerRef,
    static: false,
  })
  m_NotificationContainer: ViewContainerRef | undefined;

  private m_DisplayVoiceOpts: VOICE[] = [];

  @Input() set Video(value: VIDEO | null) {
    if (!value || this.m_Video == value) return;
    this.m_Video = value;
    this.m_ShortId = uuidToShortId(this.m_Video.video_id);
    this.initializeStates();
    this.getLanguageList();
  }

  get UnsavedChanges() {
    return (
      this.m_PublishStateChanged ||
      this.m_FiltersStateChanged ||
      this.m_ChatGPTStateChanged ||
      this.m_ReadOnlyChanged ||
      this.m_VoiceChanged
    );
  }

  get VoiceOptions() {
    return this.m_DisplayVoiceOpts;
  }

  get DubEnabled() {
    return this.m_VoiceService.DubEnabled;
  }

  get QvioThumbnail() {
    return this.m_Video?.video_thumb_url;
  }

  get VideoReadOnly() {
    return this.m_Video?.read_only ?? false;
  }

  get ActiveVideo() {
    return this.m_Video;
  }

  public $t = T.translate;
  public m_Saving: boolean = false;

  public publishStates: PropertiesRadioOption[] = [];
  public showFiltersState: PropertiesRadioOption[] = [];
  public chatGPTAvailabilityState: PropertiesRadioOption[] = [];
  public readOnlyState: PropertiesRadioOption[] = [];
  public m_LanguageList: LanguageData[] | undefined;
  public m_SelectedLanguageReindex: string | undefined;
  public m_IndexLoading: boolean = false;
  public m_IsReindexing: boolean = false;

  //--------------------------------------------------------------------
  private m_Video: VIDEO | null = null;
  private m_SelectedPublishState: VIDEO_PUBLISH_STATE = 0;
  private m_SelectedFiltersState: boolean = false;
  private m_SelectedChatGPTState: boolean = false;
  private m_SelectedReadOnlyState: boolean = false;
  private m_SelectedVoice: VOICE | null = null;

  private m_PublishStateChanged: boolean = false;
  private m_FiltersStateChanged: boolean = false;
  private m_ChatGPTStateChanged: boolean = false;
  private m_ReadOnlyChanged: boolean = false;
  private m_VoiceChanged: boolean = false;
  private m_ShortId: string = "";

  constructor(
    private m_VideoService: VideoService,
    private m_NotificationService: HIANotificationService,
    public m_UserService: UserService,
    public m_VoiceService: VoiceService,
    private m_Events: Events
  ) {}

  ngOnInit() {}

  //#region HTML Handlers
  onPublishOptionChanged(event: any) {
    this.m_SelectedPublishState = event.detail.value;
    this.m_PublishStateChanged =
      this.m_SelectedPublishState != this.m_Video?.video_publish_state;
  }

  onShowFiltersChanged(event: any) {
    this.m_SelectedFiltersState = event.detail.value;
    this.m_FiltersStateChanged =
      this.m_SelectedFiltersState != this.m_Video?.show_filters;
  }

  onChatGPTAvailabilityChanged(event: any) {
    this.m_SelectedChatGPTState = event.detail.value;
    this.m_ChatGPTStateChanged =
      this.m_SelectedChatGPTState != this.m_Video?.can_ask_chatgpt;
  }

  onReadOnlyChanged(event: any) {
    this.m_SelectedReadOnlyState = event.detail.value;
    this.m_ReadOnlyChanged =
      this.m_SelectedReadOnlyState != this.m_Video?.read_only;
  }

  onVoiceOptSelected(voiceOpt: VOICE) {
    this.m_SelectedVoice = voiceOpt;
    this.m_VoiceChanged = this.m_SelectedVoice?.id !== this.m_Video?.voice_id;
  }

  onLanguageOptSelected(event: any) {
    this.m_SelectedLanguageReindex = event.detail.value;
    this.m_IsReindexing = false;
  }

  async onReindexClicked() {
    if (!this.ActiveVideo?.video_id) return;
    try {
      this.m_IndexLoading = true;
      await this.m_VideoService.reindexVideo(
        this.ActiveVideo?.video_id,
        this.m_SelectedLanguageReindex
      );
      this.m_IsReindexing = true;
      this.m_NotificationService.showSuccess(
        this.$t("components.videoPublish.reIndexSuccess")
      );
      setTimeout(() => {
        //Waits a second before reloading the page
        window.location.reload();
      }, 1000);
    } catch (err: any) {
      console.error(err);
      this.m_NotificationService.showError(err.message, 10000);
    } finally {
      this.m_IndexLoading = false;
    }
  }

  async onSaveClicked() {
    if (!this.UnsavedChanges || this.m_Saving) return;

    if (this.m_PublishStateChanged)
      await this.changePublishStatus(this.m_SelectedPublishState);
    if (this.m_FiltersStateChanged)
      await this.changeFiltersState(this.m_SelectedFiltersState);
    if (this.m_ChatGPTStateChanged)
      await this.changeChatGPTState(this.m_SelectedChatGPTState);
    if (this.m_ReadOnlyChanged)
      await this.changeReadOnlyState(this.m_SelectedReadOnlyState);
    if (this.m_VoiceChanged)
      await this.changeVoice(this.m_SelectedVoice?.id ?? "");

    this.m_NotificationService.showSuccess(
      this.$t("shared.messages.saveSuccess"),
      2000
    );
    this.initializeStates();
  }

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.getShareableLink());
    this.m_NotificationService.showSuccess(
      this.$t("shared.messages.linkCopied"),
      2000
    );
  }
  //#endregion
  //#region Render Helpers
  getActivePublishState() {
    return this.m_Video?.video_publish_state;
  }

  shouldRenderChecked(index: number) {
    return index == this.getActivePublishState();
  }

  getActiveFiltersPublishState() {
    return this.m_Video?.show_filters;
  }

  shouldRenderCheckedFilters(state: boolean) {
    return state == this.getActiveFiltersPublishState();
  }

  canActivateChatGPT(): boolean {
    return this.m_UserService.ActiveUserLimits?.canActivateChatGPT ?? false;
  }

  getActiveChatGPTPublishState() {
    return this.m_Video?.can_ask_chatgpt;
  }

  getIsReadOnly() {
    return this.m_Video?.read_only ?? false;
  }

  getShareableLink() {
    if (this.m_Video == null) return "";
    return window.location.origin + "/watch?v=" + this.m_ShortId;
  }

  getButtonName(name: string) {
    return name + "_" + this.m_Video?.video_id;
  }

  isActiveVoice(voice: VOICE) {
    return this.m_SelectedVoice?.id === voice.id;
  }
  //#endregion
  //#region Private Methods
  private async changePublishStatus(status: VIDEO_PUBLISH_STATE) {
    if (!this.m_Video) return;

    try {
      this.m_Saving = true;
      let success = await this.m_VideoService.setVideoPublishState(
        this.m_Video.video_id,
        status
      );
      this.m_Saving = false;
      if (success) {
        this.m_Video.video_publish_state = status;
        this.m_PublishStateChanged = false;
        this.m_Events.publish(EVENTS.VIDEO_STATUS_UPDATED, status);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async changeFiltersState(state: boolean) {
    if (!this.m_Video) return;

    try {
      this.m_Saving = true;
      let success = await this.m_VideoService.setVideoFiltersState(
        this.m_Video.video_id,
        state
      );
      this.m_Saving = false;
      if (success) {
        this.m_Video.show_filters = state;
        this.m_FiltersStateChanged = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async changeChatGPTState(state: boolean) {
    if (!this.m_Video) return;

    try {
      this.m_Saving = true;
      let success = await this.m_VideoService.setVideoChatGPTState(
        this.m_Video.video_id,
        state
      );
      this.m_Saving = false;
      if (success) {
        this.m_Video.can_ask_chatgpt = state;
        this.m_ChatGPTStateChanged = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async changeReadOnlyState(state: boolean) {
    if (!this.m_Video) return;

    try {
      this.m_Saving = true;
      let success = await this.m_VideoService.setVideoReadOnlyState(
        this.m_Video.video_id,
        state
      );
      this.m_Saving = false;
      if (success) {
        this.m_Video.read_only = state;
        this.m_ReadOnlyChanged = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async changeVoice(voiceId: string) {
    if (!this.m_Video) return;

    try {
      this.m_Saving = true;
      let success = await this.m_VideoService.setVideoVoice(
        this.m_Video.video_id,
        voiceId
      );
      this.m_Saving = false;
      if (success) {
        this.m_VoiceChanged = false;
        this.m_Video.voice_id = voiceId;
        let newVoice = await this.m_VoiceService.getVoiceById(voiceId);
        this.m_VoiceService.setActiveVoice(newVoice);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async initializeStates() {
    let userPublishStates = this.m_UserService.ActiveUserLimits?.publishStates;

    const userPublishStatesSet = new Set(
      userPublishStates?.map((state) => state.enum_value)
    );

    this.publishStates = [
      {
        label: this.$t("components.videoPublish.private"),
        value: VIDEO_PUBLISH_STATE.PRIVATE,
        tooltip: this.getOptionTooltip(
          this.$t("components.videoPublish.privateTooltip")
        ),
        disabled:
          this.getIsReadOnly() ||
          !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PRIVATE),
      },
      {
        label: this.$t("components.videoPublish.unlisted"),
        value: VIDEO_PUBLISH_STATE.UNLISTED,
        tooltip: this.getOptionTooltip(
          this.$t("components.videoPublish.unlistedTooltip")
        ),
        disabled:
          this.getIsReadOnly() ||
          !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.UNLISTED),
      },
      {
        label: this.$t("components.videoPublish.public"),
        value: VIDEO_PUBLISH_STATE.PUBLIC,
        tooltip: this.getOptionTooltip(
          this.$t("components.videoPublish.publicTooltip")
        ),
        disabled:
          this.getIsReadOnly() ||
          !userPublishStatesSet.has(VIDEO_PUBLISH_STATE.PUBLIC),
      },
    ];

    this.showFiltersState = [
      {
        label: this.$t("components.videoPublish.showFilters"),
        value: true,
        tooltip: this.getOptionTooltip(
          this.$t("components.videoPublish.showFiltersTooltip")
        ),
        disabled: this.getIsReadOnly(),
      },
      {
        label: this.$t("components.videoPublish.hideFilters"),
        value: false,
        tooltip: this.getOptionTooltip(
          this.$t("components.videoPublish.hideFiltersTooltip")
        ),
        disabled: this.getIsReadOnly(),
      },
    ];

    this.chatGPTAvailabilityState = [
      {
        label: this.$t("components.videoPublish.chatGPTAvailable"),
        value: true,
        tooltip: this.getOptionTooltip(
          this.$t("components.videoPublish.chatGPTAvailableTooltip")
        ),
        disabled: this.getIsReadOnly(),
      },
      {
        label: this.$t("components.videoPublish.chatGPTNotAvailable"),
        value: false,
        tooltip: this.getOptionTooltip(
          this.$t("components.videoPublish.chatGPTNotAvailableTooltip")
        ),
        disabled: this.getIsReadOnly(),
      },
    ];

    this.readOnlyState = [
      {
        label: this.$t("components.videoPublish.readOnly"),
        value: true,
        tooltip: this.$t("components.videoPublish.readOnlyTooltip"),
        disabled: false,
      },
      {
        label: this.$t("components.videoPublish.readOnlyDisabled"),
        value: false,
        tooltip: this.$t("components.videoPublish.readOnlyDisabledTooltip"),
        disabled: false,
      },
    ];

    const activeUserId = this.m_UserService.ActiveUserInfo?.id;
    const videoOwnerId = this.m_Video?.video_user_id;
    if (videoOwnerId && activeUserId && videoOwnerId !== activeUserId) {
      try {
        const ownerVoicesList = await this.m_VoiceService.listVoices(
          undefined,
          undefined,
          videoOwnerId
        );
        this.m_DisplayVoiceOpts = ownerVoicesList.voices;
      } catch (error) {
        console.error("Error fetching owner voices", error);
        this.m_DisplayVoiceOpts = [];
      }
    } else {
      try {
        this.m_DisplayVoiceOpts = await this.m_VoiceService.getUserVoiceOpts(
          true
        );
      } catch (error) {
        console.error("Error fetching user voice options", error);
        this.m_DisplayVoiceOpts = [];
      }
    }

    // Set selected voice from video.
    const videoVoiceId = this.m_Video?.voice_id;
    if (videoVoiceId) {
      let voice = this.VoiceOptions.find((v) => v.id === videoVoiceId);
      if (voice) {
        this.m_SelectedVoice = voice;
      } else {
        try {
          const newVoice = await this.m_VoiceService.getVoiceById(videoVoiceId);
          this.m_SelectedVoice = newVoice;
        } catch (error) {
          console.error("Error fetching voice by id", error);
        }
      }
    }
  }

  private getOptionTooltip(defaultStr: string) {
    return this.getIsReadOnly()
      ? this.$t("shared.messages.readOnly")
      : defaultStr;
  }

  private getLanguageList() {
    this.m_IndexLoading = true;
    this.m_LanguageList = LANGUAGES.filter((languageCode, index, self) => {
      let languageCodeStr = languageCode.toLowerCase();

      if (
        languageCodeStr === "" ||
        languageCodeStr === "multi" ||
        languageCodeStr === "auto"
      )
        return false; // Remove empty strings

      const languageLabel = getLanguageName(languageCodeStr);
      return (
        self.findIndex((lang) => getLanguageName(lang) === languageLabel) ===
        index
      ); // Remove duplicates based on label
    })
      .map((languageCode) => {
        const languageCodeStr = languageCode.toLowerCase();
        const languageValue = languageCodeStr as Language;
        return {
          value: languageValue,
          label: getLanguageName(languageCodeStr),
          default: false,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    this.m_IndexLoading = false;
  }
  //#endregion
}
