import { BaseApiService } from "../base-api-service";
import { UserService } from "src/app/services/user/user.service";
import {
  CREATE_VOICE_REQ_DTO,
  GET_DUB_LANGUAGES_RES,
  LIST_VOICES_DTO_RES,
  VOICE_DTO_GET_RES,
} from "@shared/models/voice/voice";
import { Events } from "src/app/services/events/events.service";
import * as path from "path";

export class VoiceApi extends BaseApiService {
  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }

  private static readonly listVoicesEndpoint = {
    method: "GET",
    path: "/api/voice",
  };

  private static readonly listVoicesByUserEndpoint = {
    method: "GET",
    path: "/api/voice/users/{{userId}}",
  };

  private static readonly getVoiceEndpoint = {
    method: "GET",
    path: "/api/voice/{{voiceId}}",
  };

  private static readonly createVoiceEndpoint = {
    method: "POST",
    path: "/api/voice",
  };

  private static readonly getDubLanguagesEndpoint = {
    method: "GET",
    path: "/api/voice/languages",
  };

  async listVoices(pageSize?: number, pageNumber?: number, userId?: string) {
    let apiRequest = {
      method: userId
        ? VoiceApi.listVoicesByUserEndpoint.method
        : VoiceApi.listVoicesEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url = userId
      ? VoiceApi.listVoicesByUserEndpoint.path.replace("{{userId}}", userId)
      : VoiceApi.listVoicesEndpoint.path;
    if (pageNumber != null && pageSize != null) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    let response: LIST_VOICES_DTO_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Failed to list voices",
      3,
      200
    );

    return response;
  }

  async getVoice(voiceId: string) {
    let apiRequest = {
      method: VoiceApi.getVoiceEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url = VoiceApi.getVoiceEndpoint.path.replace("{{voiceId}}", voiceId);

    let response: VOICE_DTO_GET_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Failed to get voice",
      3,
      200
    );

    return response;
  }

  async createVoice(voice: CREATE_VOICE_REQ_DTO) {
    let apiRequest = {
      method: VoiceApi.createVoiceEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(voice),
    };

    let response = await this.makeRequestWithRetry(
      VoiceApi.createVoiceEndpoint.path,
      apiRequest,
      "Failed to create voice",
      3,
      200
    );

    return response;
  }

  //Gets the supported dubbing languages
  async getDubLanguages(): Promise<GET_DUB_LANGUAGES_RES> {
    let apiRequest = {
      method: VoiceApi.getDubLanguagesEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let response = await this.makeRequestWithRetry(
      VoiceApi.getDubLanguagesEndpoint.path,
      apiRequest,
      "Failed to get languages",
      3,
      200
    );

    return response;
  }
}
