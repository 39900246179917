import { Component, HostListener } from "@angular/core";
import { LocalizationService } from "./services/localization/localization.service";
import { ConfigService } from "./services/config/config.service";
import { AzureConfigSettings } from "src/environments/environment";
import { IntroService } from "./services/intro/intro.service";
import { AnalyticsService } from "./services/analytics/analytics.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  getPageViewMetadata,
  getPageViewMetadataFromURL,
} from "src/app/utility/utils";
import { Subscription } from "rxjs";
import { UiService } from "./services/ui/ui.service";
import { TRACK_EVENT } from "./models/analytics/analytics";
import { Events } from "./services/events/events.service";
import { SessionService } from "./services/session/session.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  standalone: false,
})
export class AppComponent {
  private m_OnClick?: any;
  private m_RouterEventsSubscription?: Subscription;
  private m_WatchPageHideHeaderLoggedOut: boolean = true;
  private m_AppLoaded: boolean = false;

  get HideNav() {
    return (
      this.m_UIService.playerFullscreen ||
      this.m_UIService.VideoEmbed ||
      (this.m_Router.url.startsWith("/watch") &&
        this.m_WatchPageHideHeaderLoggedOut &&
        !this.m_AuthService.LoggedIn) ||
      this.m_AppLoaded == false
    );
  }

  constructor(
    private m_ConfigService: ConfigService,
    private m_Translate: LocalizationService,
    private m_IntroService: IntroService,
    private m_Analytics: AnalyticsService,
    private m_Router: Router,
    private m_Route: ActivatedRoute,
    private m_UIService: UiService,
    private m_AuthService: SessionService,
    private m_Events: Events
  ) {
    //this.initialize();
  }

  //Send events on queue on close app
  @HostListener("window:beforeunload", ["$event"])
  async beforeUnloadHandler() {
    const events = this.m_Analytics.m_Events;
    if (events.length > 0) {
      await this.m_Analytics.trackEvents();
    }
  }

  async ngOnInit() {
    await this.initialize();
  }
  async initialize() {
    let promises = [
      new Promise((resolve) => {
        this.m_Translate.translationReady$.subscribe((isReady) => {
          if (isReady) {
            resolve(isReady);
          }
        });
      }),
      this.m_ConfigService.loadAzureConfig(
        AzureConfigSettings.connectionString,
        AzureConfigSettings.environment
      ),
      this.m_IntroService.init(),
    ];

    this.m_WatchPageHideHeaderLoggedOut =
      (await this.m_ConfigService.get("WATCH_PAGE_HIDE_HEADER_LOGGED_OUT")) ===
      "true";

    await Promise.all(promises);
    await this.intializeAnalytics();
    this.m_AppLoaded = true;
  }

  ngOnDestroy() {
    document.removeEventListener("click", this.m_OnClick);
    this.m_RouterEventsSubscription?.unsubscribe();
  }

  private async intializeAnalytics() {
    let maxTime = await this.m_ConfigService.get("ANALYTICS_MAX_TIME");
    let maxEvents = await this.m_ConfigService.get("ANALYTICS_MAX_EVENTS");
    this.m_Analytics.initialize(maxTime, maxEvents);

    let pageViewMetadata = getPageViewMetadataFromURL(window.location.href);
    this.m_Analytics.trackEvent(TRACK_EVENT.PAGE_VIEW, pageViewMetadata);

    this.m_RouterEventsSubscription = this.m_Router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          let pageViewMetadata = getPageViewMetadata(event);
          this.m_Analytics.trackEvent(TRACK_EVENT.PAGE_VIEW, pageViewMetadata);
        }
      }
    );

    this.m_OnClick = (event: any) => {
      let clickedElement = event.target as HTMLElement;
      let clickedElementId = clickedElement?.dataset["hiaId"];
      let hierarchy: { tagName: string; id: string; hiaId: string }[] = [];

      for (
        let element = clickedElement as HTMLElement;
        element != null;
        element = element.parentNode as HTMLElement
      ) {
        let properties = {
          tagName: element.tagName,
          id: element.id,
          hiaId: (element.dataset as any)?.hiaId,
        };

        hierarchy.push(properties);
      }

      let page = this.m_Router.url.split("?")[0];
      this.m_Analytics.trackEvent(TRACK_EVENT.CLICK, {
        button: clickedElementId,
        hierarchy: hierarchy,
        page: page,
      });
    };
    document.addEventListener("click", this.m_OnClick);
  }
}
