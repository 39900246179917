import { Injectable } from "@angular/core";
import { AdminApi } from "src/app/api/admin/admin-api";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { ConfigService } from "../config/config.service";

import { UserService } from "../user/user.service";
import { USER_ANALYTICS_LIST_ADMIN, USER_LIST } from "src/app/models/user/user";
import { PAGINATED_RECORDS } from "src/app/models/record/record";
import { CAPTIONS, VIDEO, VIDEO_LIST } from "src/app/models/video/video";
import {
  VIDEO_DTO_CAPTIONS_RES,
  VIDEO_PUBLISH_STATE,
} from "@shared/models/video/video";
import { Media } from "src/app/models/media/media";
import { Events } from "../events/events.service";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private m_AdminApi: AdminApi;

  constructor(
    eventsService: Events,
    private m_ConfigService: ConfigService,
    private m_UserService: UserService
  ) {
    this.m_AdminApi = new AdminApi(
      eventsService,
      m_UserService,
      this.m_ConfigService.get("SERVER_URL")
    );
  }

  //#region Public Methods

  //--------------------------------------------------------------------
  /**
   *  Gets User Subscriptions Tier List with Ids
   * @returns  The Tier List of all subscriptions for new users, null if failed
   */
  async getUserTierList() {
    // Fetch from the network
    const fetchPromise = this.m_AdminApi.getUserTierList().then((res) => {
      if (res.status !== 200) {
        throw ErrorFactory.error(res.status, "Failed to get tier list");
      }

      return res.tiers;
    });

    return fetchPromise;
  }
  //--------------------------------------------------------------------
  /**
   * Extend user subscription
   * @param userId Id of the user
   * @param end_date End date to expire subscription
   */
  async expandSubscription(userId: string, end_date: string) {
    const response = await this.m_AdminApi.extendSubscription(userId, end_date);
    if (response.status !== 204) {
      throw ErrorFactory.error(
        response.status,
        "Failed to extend user subscription"
      );
    }

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Creates a new user
   * @param email The email of the user
   * @param tierId The tier id of the user
   * @param end_date The end date of the user
   */
  async createUser(email: string, tierId: string, end_date: string) {
    const response = this.m_AdminApi
      .createUser(email, tierId, end_date)
      .then((res) => {
        if (res.status !== 200) {
          throw ErrorFactory.error(res.status, "Failed to get user info");
        }

        return res;
      });

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get all videos by user id, returned videos depend on the user id and caller's session id
   * @param userId
   * @returns
   */
  async getVideosByUserId(
    userId: string,
    page_number: number,
    page_size: number,
    visibility?: VIDEO_PUBLISH_STATE,
    searchTerm?: string,
    fields?: string,
    orderBy?: string,
    orderDirection?: string
  ): Promise<VIDEO_LIST> {
    let res = await this.m_AdminApi.getVideosByUserId(
      userId,
      page_number,
      page_size,
      visibility,
      searchTerm,
      fields,
      orderBy,
      orderDirection
    );

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to get videos");
    }

    let paginatedVideos = res.videos;
    let userVideos: VIDEO[] = [];

    if (paginatedVideos != null && paginatedVideos.videos.length > 0) {
      //loop over videos and convert them to VIDEO objects
      for (let i = 0; i < paginatedVideos.videos.length; i++) {
        userVideos[i] = new VIDEO(paginatedVideos.videos[i]);
      }
    }

    let responseList: VIDEO_LIST = {
      data: userVideos,
      total: res.videos?.totalItems ?? 0,
    };

    return responseList;
  }
  //--------------------------------------------------------------------
  /**
   * Get a users video
   * @param userID The email of the user
   * @param videoId The tier id of the user
   */
  async getVideo(userID: string, videoId: string): Promise<VIDEO> {
    let video_data = await this.m_AdminApi.getVideo(userID, videoId);

    if (video_data.status == 403) {
      throw ErrorFactory.error(video_data.status, "User does not own video");
    } else if (video_data.status != 200) {
      throw ErrorFactory.error(video_data.status, "Failed to get user's video");
    }

    return new VIDEO(video_data.video);
  }
  //--------------------------------------------------------------------
  /**
   * Get a users video records
   * @param videoId The tier id of the user
   */
  async getRecords(
    videoID: string,
    includeTrainingPhrases?: boolean,
    pageNumber?: number,
    pageSize?: number,
    searchTerm?: string
  ): Promise<PAGINATED_RECORDS | null> {
    let result = await this.m_AdminApi.getRecords(
      videoID,
      includeTrainingPhrases,
      pageNumber,
      pageSize,
      searchTerm
    );
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to get records");
    }

    return new PAGINATED_RECORDS(result);
  }
  //--------------------------------------------------------------------
  /**
   * Get a users video records
   * @param videoId The tier id of the user
   */
  async getSuggestions(
    videoID: string,
    pageNumber?: number,
    pageSize?: number,
    pageNumberSeed?: number
  ): Promise<PAGINATED_RECORDS> {
    let result = await this.m_AdminApi.getSuggestions(
      videoID,
      pageNumber,
      pageSize,
      pageNumberSeed
    );
    if (result.status != 200) {
      throw ErrorFactory.error(result.status, "Failed to get suggestions");
    }

    //Convert the records to RECORD objects via the PAGINATED_RECORDS object
    return new PAGINATED_RECORDS(result);
  }
  //--------------------------------------------------------------------
  /**
   * Gets a media DTO for a video
   * @param videoID
   * @param mediaID
   */
  async getAMedia(videoID: string, mediaID: string) {
    let response = await this.m_AdminApi.getAMedia(videoID, mediaID);

    if (response.status !== 200) {
      throw ErrorFactory.error(response.status, "Failed to get media");
    }

    return new Media(response.media);
  }
  //--------------------------------------------------------------------
  /**
   * Get captions for a video by video id and optional language
   * @param video_id
   * @param language
   */
  async getCaptions(video_id: string, language?: string): Promise<CAPTIONS> {
    if (video_id == null || video_id == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res: VIDEO_DTO_CAPTIONS_RES = await this.m_AdminApi.getCaptions(
      video_id,
      language
    );

    if (res.status != 200) {
      throw ErrorFactory.error(res.status, "Failed to get captions URL");
    }

    return new CAPTIONS(res, language ?? "en-US");
  }
  //--------------------------------------------------------------------
  /**
   * Gets all users
   */
  async getUserList(
    pageSize: number,
    pageNumber: number,
    searchTerm?: string,
    orderBy?: string,
    orderDirection?: string
  ): Promise<USER_LIST> {
    let response = await this.m_AdminApi.getUserList(
      pageSize,
      pageNumber,
      searchTerm,
      orderBy,
      orderDirection
    );

    if (response.status !== 200) {
      throw ErrorFactory.error(response.status, "Failed to get user list");
    }

    let paginatedUsers = response.paginatedUsers;
    let total = paginatedUsers.totalItems;

    return {
      users: paginatedUsers.users,
      total: total,
      totalPages: paginatedUsers.totalPages,
    };
  }
  //--------------------------------------------------------------------
  /**
   * Gets all users
   */
  async getUserAnalyticsList(
    pageSize: number,
    pageNumber: number,
    searchTerm?: string,
    orderBy?: string,
    orderDirection?: string
  ): Promise<USER_ANALYTICS_LIST_ADMIN> {
    let response = await this.m_AdminApi.getUserAnalyticsList(
      pageSize,
      pageNumber,
      searchTerm,
      orderBy,
      orderDirection
    );
    if (response.status !== 200) {
      throw ErrorFactory.error(response.status, "Failed to get user list");
    }

    let paginatedUsers = response.paginatedUsers;

    return {
      users: paginatedUsers.general_analytics,
      total: paginatedUsers.totalItems,
      totalPages: paginatedUsers.totalPages,
    };
  }

  async getVideoQueries(videoId: string, pageSize: number, pageNumber: number) {
    let response = await this.m_AdminApi.getVideoQueries(
      videoId,
      pageSize,
      pageNumber
    );
    if (response.status !== 200) {
      throw ErrorFactory.error(response.status, "Failed to get video queries");
    }

    return response.queries;
  }

  async patchFallbackThreshold(videoId: string, threshold: number) {
    let response = await this.m_AdminApi.patchFallbackThreshold(
      videoId,
      threshold
    );
    if (response.status !== 204) {
      throw ErrorFactory.error(
        response.status,
        "Failed to patch fallback threshold"
      );
    }

    return response;
  }

  async searchVideos(
    pageSize: number,
    pageNumber: number,
    searchTerm?: string
  ) {
    let response = await this.m_AdminApi.searchVideos(
      pageSize,
      pageNumber,
      searchTerm
    );
    if (response.status !== 200) {
      throw ErrorFactory.error(response.status, "Failed to search videos");
    }

    return response.videos;
  }
}
