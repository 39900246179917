import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IonPopover } from "@ionic/angular";

export interface ContextMenuOption {
  text: string;
  icon?: string;
  action?: (payload?: any) => void;
  disabled?: boolean | ((payload?: any) => boolean);
  tooltipText?: string;
  disabledTooltip?: string;
  hidden?: boolean | ((payload?: any) => boolean);
}

@Component({
  selector: "app-context-menu",
  templateUrl: "./context-menu.component.html",
  styleUrls: ["./context-menu.component.scss"],
  standalone: false,
})
export class ContextMenuComponent implements OnInit {
  @Input() m_Options: ContextMenuOption[] = [];
  @Input() m_CustomClass: string | null = null;
  @Input() m_CustomWidth: string | null = null;
  @Input() m_BorderRadius: string | null = "1rem";
  @Input() m_OffsetY: string | null = null;
  @Input() m_OffsetX: string | null = null;

  @Output() OnDidDismiss: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("contextMenu", { read: IonPopover, static: false }) popover:
    | IonPopover
    | undefined;

  public m_IsOpen: boolean = false;

  constructor() {}

  ngOnInit() {}

  //#region Public Methods

  open(event: any) {
    if (this.popover === undefined) {
      return;
    }

    this.popover.event = event;
    this.m_IsOpen = true;
  }
  //#endregion
  //#region Event Handlers
  onOptionClicked(e: ContextMenuOption) {
    e.action?.();
  }

  isDisabled(e: ContextMenuOption) {
    return e.disabled;
  }

  onContextMenuDismissed() {
    this.OnDidDismiss.emit();
    this.m_IsOpen = false;
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.onContextMenuDismissed();
  }
  //#endregion
  //#region Render Methods

  getItems() {
    return this.m_Options;
  }

  getTooltipText(e: ContextMenuOption) {
    return e.tooltipText;
  }
}
