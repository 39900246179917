import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  LIMITS_DTO,
  USER_DTO,
  USER_DTO_UPDATE_REQ,
} from "@shared/models/user/user";
import { UserService } from "src/app/services/user/user.service";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigService } from "src/app/services/config/config.service";
import { Title } from "@angular/platform-browser";
import { AlertModalComponent } from "src/app/components/modals/alert-modal/alert-modal.component";
import { EditProfileComponent } from "src/app/components/profile/edit-profile/edit-profile.component";
import { AccountSettingsComponent } from "src/app/components/profile/account-settings/account-settings.component";
import { MembershipPaymentsComponent } from "src/app/components/profile/membership-payments/membership-payments.component";
import { NotificationsComponent } from "src/app/components/profile/notifications/notifications.component";
import { Location } from "@angular/common";
import { SessionService } from "src/app/services/session/session.service";

interface MenuItem {
  text: string;
  value: string;
  component?: any;
  title?: string;
  description?: string;
  action?: any;
  disabled?: boolean;
}

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.page.html",
  styleUrls: ["./edit-profile.page.scss"],
  standalone: false,
})
export class EditProfilePage implements OnInit {
  @Input() component: any;
  @ViewChild("componentContainer", { read: ViewContainerRef })
  container: ViewContainerRef | undefined;
  @ViewChild("imgUpload", { static: false }) m_ImgUpload!: ElementRef;
  @ViewChild(AlertModalComponent) m_AlertModalComponent:
    | AlertModalComponent
    | undefined;

  public $t = T.translate;
  public m_UserInfo: USER_DTO;
  public m_UserLimits: LIMITS_DTO;
  public m_ImageSrc?: string | ArrayBuffer;
  public m_UsedLengthBarWidth: string;
  public m_NotificationsEnabled: boolean = false;
  public m_Saving: boolean = false;
  public m_ShowComponent: boolean = false;
  private m_NotificationsCloned: boolean = false;
  public m_UserInfoCloned: USER_DTO;
  private m_ImageFile?: File;
  private m_SavedUsername?: string;
  private m_BillingManageSubscription?: string;

  public m_CurrentMenuTabIndex: number | null = null;
  public m_CurrentComponent: any = EditProfileComponent;
  public m_CurrentTab: MenuItem | null = null;
  public m_ShowUploadMessage: Boolean = false;
  public m_Loading: Boolean = false;
  public m_LoadedTabIndex: number | null = null;

  public m_ProfileMenuItems: MenuItem[] = [
    {
      text: this.$t("pages.editProfile.pages.publicProfile"),
      value: "public-profile",
      action: () => {
        this.m_Router.navigate([
          `/u/${this.m_UserService.ActiveUserInfo?.username}`,
        ]);
      },
    },
    {
      text: this.$t("pages.editProfile.pages.editProfile"),
      value: "edit-profile",
      title: this.$t("pages.editProfile.edit.title"),
      description: this.$t("pages.editProfile.edit.description"),
      component: EditProfileComponent,
    },
    {
      text: this.$t("pages.editProfile.pages.accountSettings"),
      value: "account-settings",
      title: this.$t("pages.editProfile.pages.accountSettings"),
      component: AccountSettingsComponent,
    },
    {
      text: this.$t("pages.editProfile.pages.membershipPayments"),
      value: "membership-payments",
      title: this.$t("pages.editProfile.pages.membershipPayments"),
      component: MembershipPaymentsComponent,
    },
    {
      text: this.$t("pages.editProfile.pages.notifications"),
      value: "notifications",
      title: this.$t("pages.editProfile.pages.notifications"),
      description: this.$t("pages.editProfile.notifications.title"),
      component: NotificationsComponent,
    },
    // {
    //   text: this.$t("pages.editProfile.pages.closeAccount"),
    //   value: "close-account",
    //   title: this.$t("pages.editProfile.pages.closeAccount"),
    //   component: CloseAccountComponent,
    //   disabled: true,
    // },
  ];

  constructor(
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_ConfigService: ConfigService,
    private m_NotificationService: HIANotificationService,
    private m_Router: Router,
    private m_Route: ActivatedRoute,
    private m_TitleService: Title,
    private location: Location
  ) {
    this.m_UserInfo = {
      id: this.m_AuthService.UserId,
      username: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
    };
    this.m_UserInfoCloned = this.m_UserInfo;
    this.m_UserLimits = {
      tierName: "",
      tierDisplayName: "",
      currentUpload: 0,
      currentLength: 0,
      currentExtraLength: 0,
      currentRecordNumber: 0,
      maxUpload: -1,
      maxLength: -1,
      maxExtraLength: -1,
      maxResolution: "",
      maxRecords: -1,
      canActivateChatGPT: false,
      enableAIM: true,
      enableEmbedLinkSharing: true,
      enableURLEmbedding: true,
      enableMultilang: true,
      publishStates: [],
    };
    this.m_UsedLengthBarWidth = "width: 0%";
  }

  get UserInfo() {
    return this.m_UserInfo;
  }

  resetState() {
    this.m_UserInfo = {
      id: this.m_AuthService.UserId,
      username: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
    };
    this.m_UserInfoCloned = this.UserInfo;
    this.m_ImageFile = undefined;
  }

  async ngOnInit() {
    await this.fetchUserLimits();

    this.m_Route.queryParams.subscribe((params) => {
      this.m_LoadedTabIndex = params["tab"];
      if (!!this.m_LoadedTabIndex) return this.setCurrentTab(params["tab"]);
      return this.setCurrentTab("edit-profile");
    });
  }

  async ionViewWillEnter() {
    //Set window title
    this.m_TitleService.setTitle(this.$t("pages.tabTitles.editProfile"));
    this.resetState();
    await this.fetchUserInfo();
    await this.fetchUserLimits();
    await this.fetchUserSettings();
    await this.fetchBillingInfo();
  }

  async fetchUserInfo() {
    let userInfo = await this.m_UserService.ActiveUserInfo;
    if (userInfo == null) {
      userInfo = await this.m_UserService.getUserInfo(this.m_UserInfo.id);
    }
    this.m_UserInfo = userInfo;
    this.m_UserInfoCloned = JSON.parse(JSON.stringify(this.m_UserInfo));
    if (this.m_UserInfo.username) {
      this.m_SavedUsername = this.m_UserInfo.username;
    }
    if (this.m_UserInfo?.profile_picture_url != null) {
      this.m_ImageSrc = this.m_UserInfo.profile_picture_url;
    }
  }

  async fetchUserLimits() {
    try {
      let limits = await this.m_UserService.getUserLimits(this.m_UserInfo.id);
      this.m_UserLimits = limits.subInfo;
    } catch (e) {
      console.log(e);
    }

    if (this.m_UserLimits.maxLength > 0) {
      let percentage =
        (this.m_UserLimits.currentLength / this.m_UserLimits.maxLength) * 100;
      this.m_UsedLengthBarWidth = "width: " + percentage + "%";
    }
  }

  async fetchUserSettings() {
    let settings = await this.m_UserService.ActiveUserSettings;
    if (settings == null) {
      settings = await this.m_UserService.getSettings(this.m_UserInfo.id);
    }
    this.m_NotificationsEnabled =
      settings.user_settings.receive_unanswered_email_notification ?? false;
    this.m_NotificationsCloned = JSON.parse(
      JSON.stringify(this.m_NotificationsEnabled)
    );
  }

  async fetchBillingInfo() {
    this.m_BillingManageSubscription = await this.m_ConfigService.get(
      "BILLING_URL_MANAGE_SUBSCRIPTION"
    );
  }
  async setUserInfo(user: USER_DTO_UPDATE_REQ, picture?: File) {
    try {
      await this.m_UserService.setUserInfo(user, picture);
      this.m_NotificationService.showSuccess(
        this.$t("pages.editProfile.profileUpdated"),
        1000
      );
    } catch (e) {
      console.log(e);
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.profileUpdateFailed"),
        1000
      );
    }
  }

  hasUnsavedChanges(): boolean {
    let stringify = (value: any) => JSON.stringify(value);
    return (
      this.m_ImageFile != null ||
      stringify(this.m_UserInfo) !== stringify(this.m_UserInfoCloned) ||
      stringify(this.m_NotificationsEnabled) !==
        stringify(this.m_NotificationsCloned)
    );
  }

  isUnlimited() {
    return this.m_UserLimits.maxLength < 0;
  }

  isBasic() {
    return this.m_UserLimits.maxLength == 0;
  }

  getLengthUsedText() {
    if (this.m_UserLimits.maxLength > 0) {
      return (
        this.m_UserLimits.currentLength.toString() +
        " of " +
        this.m_UserLimits.maxLength +
        " " +
        this.$t("pages.editProfile.minsUsed")
      );
    } else {
      return (
        this.m_UserLimits.currentLength +
        " " +
        this.$t("pages.editProfile.minsUsed")
      );
    }
  }

  getMaxLengthText() {
    return this.m_UserLimits.maxLength > 0
      ? this.m_UserLimits.maxLength.toString() +
          " " +
          this.$t("pages.editProfile.mins")
      : this.$t("pages.editProfile.unlimited");
  }

  getMaxRecordsText() {
    return this.m_UserLimits.maxRecords > 0
      ? this.m_UserLimits.maxRecords.toString()
      : this.$t("pages.editProfile.unlimited");
  }

  onChangeClicked() {
    this.m_ImgUpload.nativeElement.click();
  }

  async onSelectPicture(event: any) {
    if (event?.target?.files != null && event?.target?.files[0] != null) {
      let file = event.target.files[0];
      this.m_ImageFile = file;
      let reader = new FileReader();
      reader.onload = (e) => {
        if (reader.result != null) {
          this.m_ImageSrc = reader.result;
        }
      };
      reader.readAsDataURL(file);
    }

    await this.onSaveClicked();
  }

  onRecieveNotificationsChanged() {
    this.m_NotificationsEnabled = !this.m_NotificationsEnabled;
  }

  onViewProfileClicked() {
    this.m_Router.navigate([`/u/${this.m_UserInfo.username}`]);
  }

  backDashboard() {
    this.location.back();
    this.resetState();
  }

  async onSaveClicked() {
    if (
      this.m_UserInfoCloned?.email == null ||
      this.m_UserInfoCloned?.email == ""
    ) {
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.emailRequired"),
        1000
      );
      return;
    }
    if (this.m_UserInfoCloned.username == "") {
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.usernameRequired"),
        1000
      );
      return;
    }

    this.m_Saving = true;
    let userInfo: USER_DTO_UPDATE_REQ = {
      id: this.m_UserInfoCloned.id,
      first_name: this.m_UserInfoCloned.first_name,
      last_name: this.m_UserInfoCloned.last_name,
      phone_number: this.m_UserInfoCloned.phone_number,
      receive_unanswered_email_notification: this.m_NotificationsEnabled,
    };

    if (
      this.m_SavedUsername != null &&
      this.m_SavedUsername != this.m_UserInfoCloned.username
    ) {
      userInfo.username = this.m_UserInfoCloned.username;
    }

    await this.setUserInfo(userInfo, this.m_ImageFile);

    this.resetState();
    await this.fetchUserInfo();
    await this.fetchUserSettings();
    this.m_Saving = false;
  }

  onUpgradeClicked() {
    this.m_Router.navigate(["/pricing"], {
      queryParams: {},
    });
  }

  onManageClicked() {
    window.open(this.m_BillingManageSubscription);
  }

  onBackClicked() {
    this.m_Router.navigate([".."]);
  }

  setCurrentTab(tab: string) {
    const tabIndex = this.m_ProfileMenuItems.findIndex((m) => m.value === tab);
    this.m_CurrentMenuTabIndex = tabIndex;
    this.m_CurrentTab = this.m_ProfileMenuItems[tabIndex];

    if (this.m_CurrentTab && this.m_CurrentTab.action) {
      return this.m_CurrentTab.action();
    }

    if (this.m_ProfileMenuItems[tabIndex]) {
      this.m_CurrentComponent = this.m_ProfileMenuItems[tabIndex].component;
    }

    if (this.m_CurrentTab) {
      this.m_Router.navigate([], {
        relativeTo: this.m_Route,
        queryParams: {
          tab: this.m_CurrentTab.value,
        },
        queryParamsHandling: "merge",
        skipLocationChange: true,
      });
    }
  }

  setPageLoaded() {
    this.m_Loading = false;
  }

  getProfileImage() {
    let user = this.m_UserService.ActiveUserInfo;
    if (
      user == null ||
      user.profile_picture_url == null ||
      user.profile_picture_url == ""
    )
      return "assets/icon/profile/user-avatar.svg";
    else return user.profile_picture_url;
  }

  getUsername() {
    let activeUser = this.m_UserService.ActiveUserInfo;

    if (activeUser == null) {
      return this.$t("pages.editProfile.user");
    }
    if (!activeUser.first_name && activeUser.username)
      return activeUser.username;

    const firstName = activeUser.first_name || "";
    const lastName = activeUser.last_name || "";

    let fullName = firstName + " " + lastName;

    return fullName || this.$t("pages.editProfile.user");
  }

  getProfileId() {
    let activeUser = this.m_UserService.ActiveUserInfo;
    return activeUser?.id;
  }

  getUserRole() {
    return this.m_UserInfo.headline || this.$t("pages.editProfile.user");
  }

  getCurrentTabTitle() {
    return this.m_CurrentTab?.title;
  }

  getCurrentTabDescription() {
    return this.m_CurrentTab?.description;
  }

  showTitleDescription() {
    if (!this.m_CurrentTab) return false;
    return !!this.m_CurrentTab.title || !!this.m_CurrentTab.description;
  }
}
