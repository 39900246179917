import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import {
  AlertController,
  IonContent,
  IonInput,
  NavController,
  Platform,
} from "@ionic/angular";
import { FOLDER_DTO, TransformedFolder } from "@shared/models/folders/folder";
import { VIDEO_PUBLISH_STATE, VIDEO_STATUS } from "@shared/models/video/video";
import {
  ContextMenuComponent,
  ContextMenuOption,
} from "src/app/components/shared/context-menu/context-menu.component";
import { VIDEO, VIDEO_LIST } from "src/app/models/video/video";
import { FoldersService } from "src/app/services/folders/folders.service";
import { T } from "src/app/services/localization/localization.service";
import { UserService } from "src/app/services/user/user.service";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { shortIdToUuid, uuidToShortId } from "@shared/utils/utils";
import {
  DragDropItem,
  DragDropCollection,
} from "src/app/utility/drag-drop-collection";
import {
  DragTargetDragEvent,
  DragTargetDragStartEvent,
  DropTargetEvent,
} from "@progress/kendo-angular-utils";
import { AlertModalOptions } from "src/app/components/modals/alert-modal/alert-modal.component";
import {
  HIANotificationService,
  HIAToastOpts,
} from "src/app/services/notification/notification.service";
import { UploadComponent } from "src/app/components/upload/upload.component";
import { Title } from "@angular/platform-browser";
import { VideoService } from "src/app/services/video/video.service";
import { VideoCardComponent } from "src/app/components/video/video-card/video-card.component";
import { FolderHierarchy } from "@shared/utils/folder-hierarchy";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { SessionService } from "src/app/services/session/session.service";
import { ShareModalComponent } from "src/app/components/modals/share-modal/share-modal.component";

export interface QVIOListFilter {
  display_name: string;
  value: string;
}
@Component({
  selector: "app-library",
  templateUrl: "./library.page.html",
  styleUrls: ["./library.page.scss"],
  standalone: false,
})
export class LibraryPage implements OnInit {
  @ViewChild("uploader") m_Uploader: UploadComponent | undefined;
  @ViewChild("m_DropZone", { static: false })
  m_DropZone: any;

  @ViewChild("foldersContainer", { read: ViewContainerRef, static: false })
  m_FoldersContainer: ViewContainerRef | undefined;
  @ViewChild("contextMenu", { read: ContextMenuComponent }) m_CtxMenu:
    | ContextMenuComponent
    | undefined;
  @ViewChild("m_TitleInput") m_TitleInput: IonInput | undefined;
  @ViewChildren(VideoCardComponent) m_VideoCards:
    | VideoCardComponent[]
    | undefined;

  @ViewChild(IonContent, { static: false }) content: IonContent | undefined;
  @ViewChild("videosContainer") m_VideosContainer?: ElementRef;
  @ViewChild("headerName") m_HeaderName?: ElementRef;
  @ViewChild(ShareModalComponent)
  m_ShareModal: ShareModalComponent | undefined;

  public readonly MAX_HEADER_FOLDER_LIMIT = 4;

  private m_ContextOptions: ContextMenuOption[] = [];
  private m_FolderContextMenuOptions: ContextMenuOption[] = [];
  private m_FilterContextMenuOptions: ContextMenuOption[] = [];
  private m_ParamsSub: Subscription | null = null;
  private m_UpdateFolders: Subscription | null = null;
  private m_DragDropManager: DragDropCollection = new DragDropCollection();
  private m_PageSize: number = 15;
  private m_OldTitle: string = "";
  private m_PageNumber: number = 0;
  private m_HoveredItemId?: string;
  private m_HoveredOnSelectIcon: boolean = false;
  private m_PressMode: boolean = true;
  private m_IsScrollingUp = false;
  private m_IsScrollingDown = false;
  private m_IntervalScrollingUp: any;
  private m_IntervalScrollingDown: any;
  private m_FolderHierarchy?: FolderHierarchy | null;
  private m_VideosMoved?: VIDEO[];

  public $t = T.translate;
  public m_Visibility: VIDEO_PUBLISH_STATE | null | undefined = null;
  public m_SelectedFolder?: FOLDER_DTO | null = null;
  public m_ParentFolder?: FOLDER_DTO | null = null;
  public m_ContextMenuFolder: FOLDER_DTO | null = null;
  public m_EditableFolder: FOLDER_DTO | null = null;
  public m_QuantityDisplay: number = 0;
  public m_CurrentFilter: string = "create_date";
  public m_CurrentFilterDisplay: string = "";
  public m_FilterMenuOpen: boolean = false;
  public m_FilterOrder: "ASC" | "DESC" = "DESC";
  public m_FolderExpand: boolean = false;
  public m_FlexFolders: boolean = false;
  public m_IsAllQvio: boolean = false;
  //Lists
  public m_VideoListPaginated: VIDEO_LIST = {
    total: 0,
    data: [],
    totalPages: 0,
  };

  public m_CurrentSubfolders: FOLDER_DTO[] = [];

  //Loaders
  public m_VideosLoading: boolean = false;
  public m_FoldersLoading: boolean = false;
  public m_TitleLoading: boolean = false;

  private m_VideoDeleted: Subscription | null = null;
  private m_VideoMoved: Subscription | null = null;
  private m_OnReuploadVideo: Subscription | null = null;
  private m_UpdateQvios: Subscription | null = null;
  private m_RouterEvents: Subscription | null = null;

  get CurrentVideos() {
    if (this.m_VideosMoved != null) {
      return this.m_VideoListPaginated.data.filter(
        (v) => !this.m_VideosMoved!.find((v2) => v2.video_id == v.video_id)
      );
    } else {
      return this.m_VideoListPaginated.data;
    }
  }

  get UserId() {
    return this.m_AuthService.UserId;
  }

  get UserPublishStates() {
    const userPublishStatesSet = new Set(
      this.m_UserService.ActiveUserLimits?.publishStates?.map(
        (state) => state.enum_value
      )
    );
    return userPublishStatesSet;
  }

  get CurrentFilterDisplay() {
    return (
      this.m_CurrentFilterDisplay || this.$t("shared.filters.dateModified")
    );
  }

  constructor(
    public navCtrl: NavController,
    public m_AuthService: SessionService,
    private m_UserService: UserService,
    private m_FoldersService: FoldersService,
    private m_Events: Events,
    private m_Route: ActivatedRoute,
    private m_Router: Router,
    private m_Renderer: Renderer2,
    private m_NotificationService: HIANotificationService,
    private m_TitleService: Title,
    private m_VideoService: VideoService,
    private m_AlertController: AlertController,
    private m_Platform: Platform,
    private m_ChangeDetectorRef: ChangeDetectorRef,
    private m_Analytics: AnalyticsService
  ) {}
  //--------------------------------------------------------------------
  async ngOnInit() {
    this.initContextMenus();

    //This event subscripion must be done in ngOnInit since the event is emitted before ionViewWillEnter
    if (this.m_RouterEvents == null) {
      this.m_RouterEvents = this.m_Router.events.subscribe(async (event) => {
        if (
          event instanceof NavigationEnd &&
          LibraryPage.isLibraryPage(event.url)
        ) {
          let folderId = "";
          if (LibraryPage.isAllQvio(event.url)) {
            this.m_IsAllQvio = true;
          } else {
            folderId = LibraryPage.getFolderIdFromRoute(event.url);
            this.m_IsAllQvio = false;
          }
          await this.initializeParams(folderId);
        }
      });
    }
  }
  //--------------------------------------------------------------------
  ionViewWillEnter() {
    this.subscribeToEvents();
    this.m_DragDropManager.reset();
    this.m_PressMode = false;
    this.m_TitleService.setTitle(this.$t("pages.tabTitles.library"));
    this.setCurrentFilterDisplay(this.m_CurrentFilter);

    //Loop over videos and find any that are set to ready to upload
    let readyToUpload = this.m_VideoListPaginated.data.filter(
      (v) => v.video_status === VIDEO_STATUS.PENDING
    );

    readyToUpload.forEach(async (v) => {
      //Get the latest status of the video
      let videoStatus = await this.m_VideoService.getVideoStatus(v.video_id);
      v.video_status = videoStatus.video_status;

      let card = this.m_VideoCards?.find((vc) => vc.VideoId === v.video_id);
      if (card) {
        card.VideoData = v;
        card.onRefreshClicked();
      }
    });

    //Fetch all folders initially
    this.getUsersFolders();
  }

  ionViewWillLeave() {
    if (this.m_UpdateFolders) {
      this.m_UpdateFolders.unsubscribe();
      this.m_UpdateFolders = null;
    }
    if (this.m_ParamsSub) {
      this.m_ParamsSub.unsubscribe();
      this.m_ParamsSub = null;
    }
    if (this.m_OnReuploadVideo) {
      this.m_OnReuploadVideo.unsubscribe();
      this.m_OnReuploadVideo = null;
    }
    if (this.m_UpdateQvios) {
      this.m_UpdateQvios.unsubscribe();
      this.m_UpdateQvios = null;
    }
  }

  ngOnDestroy() {
    if (this.m_RouterEvents) {
      this.m_RouterEvents?.unsubscribe();
      this.m_RouterEvents = null;
    }
  }

  //--------------------------------------------------------------------
  //#region Public Methods
  async initializeContent() {
    await this.calculateNumberOfVideos();
    this.clearPaginatedData();
    this.fetchData();
    if (!this.m_IsAllQvio) {
      this.getSubFolders();
    }
  }
  //--------------------------------------------------------------------
  async initializeParams(folderId: string) {
    this.m_DragDropManager.reset(); //Loading into a new view, reset the drag drop manager
    let folderID = folderId;
    if (folderID) {
      folderID = shortIdToUuid(folderID);
      await this.enterFolderbyId(folderID, true);
    } else {
      this.m_SelectedFolder = null;
    }

    this.initializeContent();
  }
  //--------------------------------------------------------------------
  async fetchData() {
    if (this.m_IsAllQvio) return this.getAllVideos();
    return this.getVideos();
  }
  //--------------------------------------------------------------------
  async calculateNumberOfVideos(): Promise<void> {
    //Wait for offsetWidth be set
    while (
      !this.m_VideosContainer ||
      !this.m_VideosContainer.nativeElement.offsetWidth
    ) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    //Get video card width and height
    const minPageSize = 12;
    const containerWidth = this.m_VideosContainer.nativeElement.offsetWidth;
    const containerRect =
      this.m_VideosContainer.nativeElement.getBoundingClientRect();
    const styles = getComputedStyle(document.documentElement);
    const videoCardWidth =
      parseFloat(styles.getPropertyValue("--video-card-library-width").trim()) *
      16;
    const videoCardMinHeight =
      parseFloat(
        styles.getPropertyValue("--video-card-library-height").trim()
      ) * 16;
    const cardSpacing = parseFloat(
      styles.getPropertyValue("--video-card-library-gap")
    );

    const ITEM_WIDTH = videoCardWidth + cardSpacing;
    const ITEM_HEIGHT = videoCardMinHeight + cardSpacing;

    const viewportHeight = window.innerHeight - containerRect.top;

    if (containerWidth <= 0 || viewportHeight <= 0) return;

    const numColumns = Math.floor(containerWidth / ITEM_WIDTH);
    const numRows = Math.floor(viewportHeight / ITEM_HEIGHT);

    let totalVisibleItems = numColumns * numRows + numColumns;

    if (totalVisibleItems < minPageSize) {
      const additionalRows = Math.ceil(
        (minPageSize - totalVisibleItems) / numColumns
      );
      totalVisibleItems += additionalRows * numColumns;
    }

    this.m_PageSize = totalVisibleItems;
  }

  //--------------------------------------------------------------------
  async getVideos() {
    if (this.m_PageNumber === 0) this.m_VideosLoading = true;

    try {
      const userId = this.m_AuthService.UserId;
      let visibility = this.m_Visibility;
      if (this.m_Visibility === null) {
        visibility = undefined;
      }
      if (visibility === null) return;
      const videos = await this.m_UserService.getVideosByUserId(
        userId,
        this.m_PageNumber,
        this.m_PageSize,
        visibility,
        "",
        "",
        this.m_CurrentFilter,
        this.m_FilterOrder,
        this.m_SelectedFolder?.id || undefined
      );
      this.onPaginatedDataUpdated(videos, this.m_PageNumber);
    } finally {
      this.m_VideosLoading = false;
    }
  }
  //--------------------------------------------------------------------
  async getAllVideos() {
    if (this.m_PageNumber === 0) this.m_VideosLoading = true;

    try {
      const userId = this.m_AuthService.UserId;
      let visibility = this.m_Visibility;
      if (this.m_Visibility === null) {
        visibility = undefined;
      }
      if (visibility === null) return;
      const videos = await this.m_UserService.getVideosByUserId(
        userId,
        this.m_PageNumber,
        this.m_PageSize,
        visibility,
        "",
        "",
        this.m_CurrentFilter,
        this.m_FilterOrder,
        "0"
      );
      this.onPaginatedDataUpdated(videos, this.m_PageNumber);
    } finally {
      this.m_VideosLoading = false;
    }
  }
  //--------------------------------------------------------------------
  async getSubFolders(getRoot?: boolean) {
    this.m_FoldersLoading = true;
    this.m_CurrentSubfolders = [];
    this.m_FolderExpand = false;
    try {
      const data = await this.m_FoldersService.getSubFolders(
        this.UserId,
        getRoot ? undefined : this.m_SelectedFolder?.id
      );
      if (data && data.subfolders) {
        this.m_CurrentSubfolders = data && data.subfolders;
      } else this.m_CurrentSubfolders = [];
    } finally {
      //Set flex folders because the folders have been rendered
      this.m_FlexFolders = true;
      setTimeout(() => {
        //Set the flex folders after the folders have been rendered
        this.setFlexFolders();
      }, 100);
      this.m_FoldersLoading = false;
    }

    this.m_ChangeDetectorRef.detectChanges();
  }
  //--------------------------------------------------------------------
  async getUsersFolders() {
    this.m_FoldersLoading = true;
    this.m_FolderExpand = false;
    try {
      const data = await this.m_FoldersService.getAllFolders(this.UserId);
      if (data && data.folders) {
        this.m_FolderHierarchy = this.m_FoldersService.FolderHierarchy;
      }
    } finally {
      this.m_FoldersLoading = false;
    }
  }
  //--------------------------------------------------------------------
  onDropFile(event: any) {
    event.preventDefault();
    this.resetDragZone();

    //Verify a file was selected
    if (event.dataTransfer == null || event.dataTransfer.files.length == 0)
      return;
    let file = event.dataTransfer.files[0];

    this.onFileSelected(file);
  }
  //--------------------------------------------------------------------
  onDragFileOver(event: any) {
    event.preventDefault();
    this.m_DropZone.nativeElement.classList.add("drag-over");
  }
  //--------------------------------------------------------------------
  onDragFileLeave(event: any) {
    event.preventDefault();
    this.resetDragZone();
  }
  //--------------------------------------------------------------------
  async onFileSelected(file: File) {
    this.m_Uploader?.onFileInputChanged(
      { target: { files: [file] } },
      this.m_SelectedFolder?.id
    );
  }
  //--------------------------------------------------------------------
  private resetDragZone() {
    if (!this.m_DropZone) return;
    this.m_DropZone.nativeElement.classList.remove("drag-over");
    this.m_DropZone.nativeElement.classList.remove("invalid-file");
  }

  //#endregion
  //--------------------------------------------------------------------
  //#region Render Helpers
  shouldShowIcon(video: VIDEO): boolean {
    return (
      this.m_PressMode ||
      video.video_id == this.m_HoveredItemId ||
      this.m_DragDropManager.isItemRegistered(video.video_id)
    );
  }
  //--------------------------------------------------------------------
  shouldShowMultiSelectBar() {
    return this.m_DragDropManager.NumberOfRegisteredItems > 0;
  }
  //--------------------------------------------------------------------
  shouldShowEmptyRecentVideos() {
    return this.m_VideoListPaginated.data.length === 0 && !this.m_VideosLoading;
  }
  //--------------------------------------------------------------------
  toggleFoldersListExpand() {
    this.m_FolderExpand = !this.m_FolderExpand;
  }
  //--------------------------------------------------------------------
  getheaderName() {
    if (this.m_IsAllQvio) return this.$t("components.navigation.allQvios");
    if (this.m_SelectedFolder?.name)
      return this.$t("pages.library.library") + " ";
    else return this.$t("pages.library.library");
  }
  //--------------------------------------------------------------------
  getHeaderFolderName(folder: FOLDER_DTO, index: number, count: number) {
    if (
      index > 0 ||
      count == 1 ||
      (index == 0 &&
        folder.parent_id == null &&
        count <= this.MAX_HEADER_FOLDER_LIMIT)
    ) {
      return folder.name;
    } else {
      return "...";
    }
  }
  //--------------------------------------------------------------------
  getPathArray() {
    if (this.m_FolderHierarchy != null && this.m_SelectedFolder?.id != null) {
      let pathArray = this.m_FolderHierarchy.getFolderLeafPathArray(
        this.m_SelectedFolder?.id,
        this.MAX_HEADER_FOLDER_LIMIT + 1
      );

      let availableWidth = this.m_HeaderName?.nativeElement?.clientWidth || 0;

      let length = pathArray.length;
      for (let i = length; i > 2; i--) {
        if (this.getPathWidth(pathArray) <= availableWidth) {
          break;
        } else {
          pathArray.shift();
        }
      }

      return pathArray;
    } else return [];
  }
  //--------------------------------------------------------------------
  getDragGhostHintText(videoData: VIDEO): string {
    if (this.m_DragDropManager.NumberOfRegisteredItems > 1) {
      return `${this.m_DragDropManager.NumberOfRegisteredItems} ${this.$t(
        "pages.library.multi-select-bar.videosSelected"
      )} `;
    } else return videoData.video_name;
  }
  //--------------------------------------------------------------------
  getFolderDragGhostHintText(folderData: any) {
    return folderData.name;
  }
  //--------------------------------------------------------------------
  canEditTitle() {
    return this.m_SelectedFolder?.name && !this.m_TitleLoading;
  }
  //--------------------------------------------------------------------
  videoCount(folderData: FOLDER_DTO) {
    let videoCount;
    if (this.m_Visibility == VIDEO_PUBLISH_STATE.PRIVATE)
      videoCount = folderData.private_video_count;
    else if (this.m_Visibility == VIDEO_PUBLISH_STATE.PUBLIC)
      videoCount = folderData.public_video_count;
    else if (this.m_Visibility == VIDEO_PUBLISH_STATE.UNLISTED)
      videoCount = folderData.unlisted_video_count;
    else videoCount = folderData.video_count;

    return videoCount;
  }
  //--------------------------------------------------------------------
  getContextMenuOpts() {
    return this.m_ContextOptions;
  }
  //--------------------------------------------------------------------
  getLibraryCounter() {
    return this.m_QuantityDisplay === 1
      ? this.m_QuantityDisplay + " " + this.$t("pages.library.video")
      : this.m_QuantityDisplay + " " + this.$t("pages.library.videos");
  }
  //--------------------------------------------------------------------
  getSelectState(video: VIDEO) {
    if (video) return this.m_DragDropManager.isItemRegistered(video.video_id);
    return;
  }
  //--------------------------------------------------------------------
  getSelectAllQviosState() {
    return (
      (this.m_VideoCards?.length ?? 0) > 0 &&
      this.m_DragDropManager.NumberOfRegisteredItems ==
        this.m_VideoCards?.length
    );
  }
  //--------------------------------------------------------------------
  shouldShowExpandButton() {
    if (!this.m_FoldersContainer?.element.nativeElement) return false;
    else if (this.m_FolderExpand) return true;
    else
      return (
        this.m_FoldersContainer?.element.nativeElement.scrollHeight >
        this.m_FoldersContainer?.element.nativeElement.clientHeight + 5
      );
  }
  //--------------------------------------------------------------------
  //#endregion
  //--------------------------------------------------------------------
  //#region Event Handlers
  onNewQvioClicked() {
    this.m_Uploader?.openFileDialog(undefined, this.m_SelectedFolder?.id);
  }
  //--------------------------------------------------------------------
  toggleOrder() {
    this.m_FilterOrder = this.m_FilterOrder === "ASC" ? "DESC" : "ASC";
    this.clearPaginatedData();
    this.fetchData();
  }
  //--------------------------------------------------------------------
  segmentChanged(event: any) {
    this.m_DragDropManager.reset();
    this.clearPaginatedData();
    this.fetchData();
  }
  //--------------------------------------------------------------------
  onDragEnterFolder(event: DropTargetEvent) {
    if (event.dragData == null) return;
    event.dropTarget.classList.add("drag-over");
  }
  //--------------------------------------------------------------------
  onDragLeaveFolder(event: DropTargetEvent) {
    event.dropTarget.classList.remove("drag-over");
  }
  //--------------------------------------------------------------------
  async onDragDropFolder(event: DropTargetEvent) {
    event.dropTarget.classList.remove("drag-over");
    let destinationFolderID =
      event.dropTarget.getAttribute("data-folder-id") || "";
    if (destinationFolderID == "") return;
    //Check if drop target is visible in the viewport
    let folderRect = event.dropTarget.getBoundingClientRect();
    let parentRect = event.dropTarget.parentElement?.getBoundingClientRect();
    if (
      parentRect != null &&
      (folderRect.top < parentRect?.top ||
        folderRect.bottom > parentRect?.bottom + 5)
    )
      return;

    let dragData: DragDropItem[] = event.dragData;

    let folderID = event.dragTarget.getAttribute("data-folder-id");
    if (folderID != null && folderID != "") {
      if (dragData == null) dragData = [];
      dragData.push({
        dragData: { id: folderID },
        id: folderID,
      });
    }

    if (dragData != null && dragData.length > 0) {
      let folder = dragData.find((item) => !(item.dragData instanceof VIDEO));
      // If a folder is being moved, selected videos WON'T be moved
      if (folder != null) {
        if (this.checkFolderMove(folder.dragData.id, destinationFolderID)) {
          await this.moveFolderToFolder(
            destinationFolderID,
            folder.dragData.id
          );
        }
      } else {
        let videos = dragData
          .filter((item) => item.dragData instanceof VIDEO)
          .map((item) => item.dragData as VIDEO);
        await this.moveVideosToFolder(videos, destinationFolderID);
      }
    }

    this.m_DragDropManager.reset();
    this.m_PressMode = false;
  }
  //--------------------------------------------------------------------
  onMouseEnterCard(video: VIDEO | null = null) {
    if (this.isHoverModeDevice()) {
      if (video) {
        this.m_HoveredItemId = video.video_id;
      }
    }
  }
  //--------------------------------------------------------------------
  onMouseLeaveCard() {
    this.m_HoveredItemId = undefined;
  }
  //--------------------------------------------------------------------
  onMouseEnterSelectIcon(video: VIDEO | null) {
    this.m_HoveredOnSelectIcon = true;

    if (video) {
      let card = this.m_VideoCards?.find((vc) => vc.VideoId === video.video_id);
      card?.onVideoCardMouseEnter();
    }
  }
  //--------------------------------------------------------------------
  onMouseLeaveSelectIcon(video: VIDEO | null) {
    this.m_HoveredOnSelectIcon = false;

    if (video) {
      let card = this.m_VideoCards?.find((vc) => vc.VideoId === video.video_id);
      card?.onVideoCardMouseLeave();
    }
  }
  //--------------------------------------------------------------------
  onClickQvio(event: any, video: VIDEO) {
    if (event.ctrlKey || event.metaKey) {
      let status = !this.m_DragDropManager.RegisteredItems.find(
        (item) => item.id == video.video_id
      );
      this.setQvioSelected(video, status);
      event.preventDefault();
      return;
    } else if (this.m_PressMode) {
      let status = !this.m_DragDropManager.RegisteredItems.find(
        (item) => item.id == video.video_id
      );

      this.setQvioSelected(video, status);
    } else {
      let page = this.m_Router.url.split("?")[0];
      this.m_Analytics.trackClickEvent(
        this.getButtonName(video?.video_id),
        page
      );

      this.m_Router.navigate(["/edit"], {
        queryParams: { v: uuidToShortId(video?.video_id || "") },
      });
    }
  }
  //--------------------------------------------------------------------
  getButtonName(name: string) {
    return "video-card-edit-_" + name;
  }
  //--------------------------------------------------------------------
  onPressQvio(event: any, video: VIDEO) {
    if (this.isHoverModeDevice()) return;

    this.m_PressMode = true;

    if (this.m_DragDropManager) {
      this.m_DragDropManager.registerItem({
        dragData: video,
        id: video.video_id,
      });
    }
  }
  //--------------------------------------------------------------------
  onDragQvioStart(event: DragTargetDragStartEvent, video: VIDEO) {
    if (this.m_HoveredOnSelectIcon) return;

    if (this.m_DragDropManager) {
      this.m_DragDropManager.registerDragItem({
        dragData: video,
        id: video.video_id,
      });
    }

    return true;
  }
  //--------------------------------------------------------------------
  onSelect(event: any, video: VIDEO) {
    event.preventDefault();
    if (video) this.setQvioSelected(video, event.detail.checked);
  }
  //--------------------------------------------------------------------
  onSelectAllQvios(event: any) {
    let checked = event.detail.checked;

    for (let videoCard of this.m_VideoCards ?? []) {
      if (videoCard.VideoId != null) {
        if (checked) {
          this.m_DragDropManager.registerItem({
            dragData: videoCard.VideoData,
            id: videoCard.VideoId,
          });
        } else {
          this.m_DragDropManager.unregisterItem({
            dragData: videoCard.VideoData,
            id: videoCard.VideoId,
          });
        }
      }
    }
  }
  //--------------------------------------------------------------------
  setQvioSelected(video: VIDEO, status: boolean) {
    if (this.m_DragDropManager) {
      if (status) {
        this.m_DragDropManager.registerItem({
          dragData: video,
          id: video.video_id,
        });
      } else {
        this.m_DragDropManager.unregisterItem({
          dragData: video,
          id: video.video_id,
        });

        if (this.m_DragDropManager.NumberOfRegisteredItems == 0) {
          this.m_PressMode = false;
        }
      }
    }
  }
  //--------------------------------------------------------------------
  private onDragItem(event: DragTargetDragEvent): void {
    //--------------------------------------------------------------------
    if (this.m_HoveredOnSelectIcon) return;

    //Fix provided by Kendo UI for custom drag and drop hints
    //https://www.telerik.com/kendo-angular-ui/components/knowledge-base/position-custom-drag-drop-hints/
    event.preventDefault();
    //Position the hint element where the center of the element is at the mouse position
    let hintWidth = event.hintElement?.offsetWidth ?? 0;
    let hintHeight = event.hintElement?.offsetHeight ?? 0;
    let topValue = `${event.dragEvent.clientY - hintHeight / 2}px`;
    let leftValue = `${event.dragEvent.clientX - hintWidth / 2}px`;

    if (event.dragEvent.clientY - hintHeight / 2 <= 50) {
      this.scrollPageUp(true);
      topValue = `${event.dragEvent.clientY - hintHeight / 2 + 30}px`;
    } else this.scrollPageUp(false);

    if (event.dragEvent.clientY - hintHeight >= window.outerHeight - 200) {
      this.scrollPageDown(true);
      topValue = `${event.dragEvent.clientY - hintHeight / 2 - 30}px`;
    } else this.scrollPageDown(false);

    this.m_Renderer.setStyle(event.hintElement, "top", topValue);
    this.m_Renderer.setStyle(event.hintElement, "left", leftValue);
    this.m_Renderer.setStyle(event.hintElement, "z-index", "1999");
    this.m_Renderer.setStyle(event.hintElement, "position", "absolute");
    this.m_Renderer.setStyle(event.hintElement, "opacity", "0.8");
  }
  //--------------------------------------------------------------------
  onDragQvio(event: DragTargetDragEvent): void {
    this.onDragItem(event);
  }
  //--------------------------------------------------------------------
  onDragFolder(event: DragTargetDragEvent) {
    this.onDragItem(event);
  }
  //--------------------------------------------------------------------
  onDragFolderStart(folderData: any, event: DragTargetDragStartEvent) {
    if (this.m_DragDropManager) {
      this.m_DragDropManager.registerDragItem({
        dragData: folderData,
        id: folderData.id,
      });
    }

    return true;
  }
  //--------------------------------------------------------------------
  scrollPageUp(shouldRun: boolean) {
    if (shouldRun && !this.m_IsScrollingUp) {
      //Toggle the scrooling up function on loop
      this.m_IsScrollingUp = true;
      //this.m_IntervalScrollingUp = setInterval(() => {
      //  this.content?.scrollByPoint(0, -20, 5);
      //}, 50);
      //Scroll up to the top of the page
      //NOTE: Until we change the folder layout, it makes more sense to scroll to the top of the page
      this.content?.scrollToTop(1000);
    } else if (!shouldRun && this.m_IsScrollingUp) {
      //Disable scrooling Up
      this.m_IsScrollingUp = false;
      //clearInterval(this.m_IntervalScrollingUp);
    }
  }
  //--------------------------------------------------------------------
  scrollPageDown(shouldRun: boolean) {
    if (shouldRun && !this.m_IsScrollingDown) {
      //Toggle the scrooling down function on loop
      this.m_IsScrollingDown = true;
      this.m_IntervalScrollingDown = setInterval(() => {
        this.content?.scrollByPoint(0, 20, 5);
      }, 10);
    } else if (!shouldRun && this.m_IsScrollingDown) {
      //Disable scrooling Down
      this.m_IsScrollingDown = false;
      clearInterval(this.m_IntervalScrollingDown);
    }
  }

  //--------------------------------------------------------------------
  onCancelClicked() {
    this.m_DragDropManager.reset();
    this.m_PressMode = false;
  }
  //--------------------------------------------------------------------
  getDragData() {
    return this.m_DragDropManager.getDragData();
  }
  //--------------------------------------------------------------------
  getNumberOfSelectedVideosText(): string {
    let numberOfVideos = this.m_DragDropManager.NumberOfRegisteredItems;

    if (numberOfVideos == 1)
      return "1 " + this.$t("pages.library.multi-select-bar.videoSelected");
    else if (numberOfVideos > 1)
      return (
        numberOfVideos +
        " " +
        this.$t("pages.library.multi-select-bar.videosSelected")
      );
    else return "";
  }

  //--------------------------------------------------------------------
  onAddPlaylistClicked() {
    let registeredItems = this.m_DragDropManager.RegisteredItems;
    let videoIds = registeredItems.map((item) => item.id);
    this.m_Events.publish(EVENTS.OPEN_PLAYLIST_MODAL, { videoIds: videoIds });
  }

  //--------------------------------------------------------------------
  onMultiMoveClicked() {
    let registeredItems = this.m_DragDropManager.RegisteredItems;
    let videoIds = registeredItems.map((item) => item.id);
    let videoData = registeredItems.map((item) => item.dragData);
    this.m_Events.publish(EVENTS.SHOW_FOLDERS_MODAL, {
      video_ids: videoIds,
      videos: videoData,
    });

    this.m_DragDropManager.reset();
    this.m_PressMode = false;
  }
  //--------------------------------------------------------------------
  async onMultiDeleteClicked() {
    let n = this.m_DragDropManager.NumberOfRegisteredItems;

    let header =
      n > 1
        ? this.$t("components.alert.multiDeleteAlert.title")
        : this.$t("components.alert.deleteAlert.title");
    let message =
      n > 1
        ? this.$t("components.alert.multiDeleteAlert.message")
        : this.$t("components.alert.deleteAlert.message");
    let button =
      n > 1
        ? this.$t("components.alert.multiDeleteAlert.button")
        : this.$t("components.alert.deleteAlert.button");

    // Show alert modal
    let alertConfig: AlertModalOptions = {
      mainText: header,
      description: message,
      allowCancel: true,
      showIcon: true,
      customClass: "delete-modal",
      allowNeverShow: false,
      customButtons: [
        {
          label: button,
          color: "#dd2222cc",
          close: true,
          callback: async () => {
            this.deleteVideos();
            this.m_DragDropManager.reset();
            this.m_PressMode = false;
          },
        },
      ],
    };

    this.m_Events.publish(EVENTS.ALERT, alertConfig);
  }
  //--------------------------------------------------------------------
  private deleteVideos() {
    let registeredItems = this.m_DragDropManager.RegisteredItems;
    for (let registeredItem of registeredItems) {
      let card = this.m_VideoCards?.find(
        (vc) => vc.VideoId === registeredItem.id
      );
      card?.deleteVideo();
    }
  }
  //--------------------------------------------------------------------
  onFolderClicked(folder: FOLDER_DTO) {
    if (this.m_EditableFolder == folder) return;
    this.m_ParentFolder = this.m_SelectedFolder;
    this.m_SelectedFolder = folder;

    this.m_Router.navigate(LibraryPage.getRouterPath(this.m_SelectedFolder.id));
  }
  //--------------------------------------------------------------------
  async onIonInfinite(event: any) {
    let totalPages = this.m_VideoListPaginated.totalPages ?? 1;

    if (this.m_PageNumber + 1 >= totalPages) {
      event.target.complete();
      return;
    }

    this.m_PageNumber++;
    await this.fetchData();
    event.target.complete();
  }
  //--------------------------------------------------------------------
  async enterFolderbyId(folderId: string, retry?: boolean) {
    if (
      this.m_FolderHierarchy?.isEmpty() &&
      this.m_FolderHierarchy?.isEmpty() != undefined
    ) {
      await this.getSubFolders(true);
    }

    let folder = this.m_FolderHierarchy?.findFolder(folderId);

    if (!folder && retry) {
      // Waits 4 seconds to FolderHierarchy to render properly
      this.m_FoldersLoading = true;
      this.m_VideosLoading = true;
      await new Promise((resolve) => setTimeout(resolve, 4000));
      folder = this.m_FolderHierarchy?.findFolder(folderId);
      this.m_FoldersLoading = false;
      this.m_VideosLoading = false;
      // We dont wait for FolderHierachy changes directly on the variables cause if the user doesent have nay folders it might result on errors.
    }

    if (folder) {
      this.m_SelectedFolder = folder.folderData;
      this.m_ParentFolder = folder.parent?.folderData;
    }
  }
  //--------------------------------------------------------------------
  backToLibrary() {
    if (!this.m_SelectedFolder) return;
    this.m_SelectedFolder = null;
    this.m_Router.navigate(LibraryPage.getRouterPath(""));
  }
  //--------------------------------------------------------------------
  async backtoParentFolder() {
    if (!this.m_SelectedFolder?.parent_id) {
      this.backToLibrary();
      return;
    }
    if (
      this.m_SelectedFolder.id == this.m_ParentFolder?.id ||
      this.m_SelectedFolder.parent_id !== this.m_ParentFolder?.id
    ) {
      await this.getUsersFolders();
      await this.enterFolderbyId(this.m_SelectedFolder.parent_id);

      return;
    }

    this.m_SelectedFolder = this.m_ParentFolder;
    //Update URL params
    this.m_Router.navigate(
      LibraryPage.getRouterPath(this.m_SelectedFolder.id || "")
    );
  }
  //--------------------------------------------------------------------
  onEditComplete(folderData: any, event: Event, isTitle?: boolean) {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
    }

    if (isTitle) {
      const newName = (event.target as HTMLElement).textContent;
      if (newName && newName != folderData.name) {
        if (folderData.id === "temp") {
          this.renameCreatedFolder(newName.trim());
        } else this.renameFolder(folderData, newName.trim());
      }
    } else {
      const newName = folderData.name;
      if (newName && newName != this.m_OldTitle) {
        if (folderData.id === "temp") {
          this.renameCreatedFolder(newName.trim());
        } else this.renameFolder(folderData, newName.trim());
      }
    }

    this.m_EditableFolder = null;
  }
  //--------------------------------------------------------------------
  async renameFolder(folderData: any, newName: string) {
    if (!newName || !folderData) return;

    folderData.name = newName;

    const folder = this.m_FolderHierarchy?.findFolder(folderData.id);

    if (folder) {
      folder.folderData.name = newName.trim();
    }

    if (newName != "") {
      try {
        this.m_TitleLoading = true;
        const data = await this.m_FoldersService.renameFolders(
          newName,
          folder?.folderData.id || folderData.id
        );
        if (data && folder) {
          folder.folderData.name = newName.trim();
          this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
        }
      } finally {
        this.m_TitleLoading = false;
      }
    }
  }
  //--------------------------------------------------------------------
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;

    if (!clipboardData) {
      return;
    }

    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        // Prevent the default paste behavior if an image is detected
        event.preventDefault();
        break;
      }
    }
  }

  //--------------------------------------------------------------------
  onQvioFilterClicked(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.m_ContextOptions = this.m_FilterContextMenuOptions;
    this.m_FilterMenuOpen = true;
    this.m_CtxMenu?.open(event);
  }
  //--------------------------------------------------------------------
  onContextMenuDismissed() {
    this.m_FilterMenuOpen = false;
    this.m_ContextOptions = [];
  }
  //--------------------------------------------------------------------
  onFolderOptionsClicked(folderData: FOLDER_DTO, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.m_ContextMenuFolder = folderData;
    this.m_ContextOptions = this.m_FolderContextMenuOptions;
    this.m_CtxMenu?.open(event);
  }
  //--------------------------------------------------------------------
  onRenameClicked() {
    if (!this.m_ContextMenuFolder) return;

    this.m_EditableFolder = this.m_ContextMenuFolder;
    this.m_OldTitle = this.m_ContextMenuFolder.name;
    setTimeout(async () => {
      this.m_TitleInput?.setFocus();
      let HTMLinput = await this.m_TitleInput?.getInputElement();

      HTMLinput?.setSelectionRange(0, HTMLinput.value.length);
    }, 600); // Wait for the rendering of the input
  }
  //--------------------------------------------------------------------
  onMoveClicked() {
    this.m_Events.publish(EVENTS.SHOW_FOLDERS_MODAL, this.m_ContextMenuFolder);
  }
  //--------------------------------------------------------------------
  async onCreateClicked() {
    this.m_FoldersLoading = true;
    try {
      // Check if we have folders with the default name and adds a number to it
      let newFolderName = this.$t("pages.library.untitled_folder");

      if (this.m_FolderHierarchy && this.m_CurrentSubfolders) {
        newFolderName = this.m_FolderHierarchy.getFolderNameForCreation(
          this.m_CurrentSubfolders
        );
      }

      // Adds a new temporary folder
      const tempFolder: FOLDER_DTO = {
        id: "temp",
        name: newFolderName,
        created_at: new Date(),
        updated_at: new Date(),
      };
      this.m_CurrentSubfolders.unshift(tempFolder);

      // Set the new folder as editable
      this.m_EditableFolder = tempFolder;
      this.m_OldTitle = "";

      setTimeout(() => {
        this.m_FoldersLoading = false;
      }, 600); // Gives some time for the rendering to apply the new folder to the list

      setTimeout(async () => {
        this.m_TitleInput?.setFocus();
        let HTMLinput = await this.m_TitleInput?.getInputElement();
        HTMLinput?.setSelectionRange(0, HTMLinput.value.length);
      }, 600); // Wait for the rendering of the input
    } finally {
      this.m_FoldersLoading = false;
    }
  }

  //--------------------------------------------------------------------
  async renameCreatedFolder(newName: string) {
    const tempFolderIndex = this.m_CurrentSubfolders.findIndex(
      (folder) => folder.id === "temp"
    );
    if (tempFolderIndex === -1) return;

    let data;
    if (this.m_SelectedFolder) {
      data = await this.m_FoldersService.createSubFolders(
        this.UserId,
        this.m_SelectedFolder.id,
        newName
      );
    } else {
      data = await this.m_FoldersService.createFolders(this.UserId, newName);
    }

    if (data) {
      // Removes the temporary folder
      this.m_CurrentSubfolders.splice(tempFolderIndex, 1);

      // Gets the new folders
      this.getSubFolders();
      this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
    }
  }

  //--------------------------------------------------------------------
  async onDeleteClicked() {
    if (!this.m_ContextMenuFolder) return;

    try {
      this.m_FoldersLoading = true;
      const data = await this.m_FoldersService.deleteFolders(
        this.m_ContextMenuFolder?.id,
        false
      );

      if (data.error) {
        // Show alert modal
        let alertConfig: AlertModalOptions = {
          mainText: this.$t("shared.messages.folderDeleteTitle"),
          description: this.$t("shared.messages.folderDelete"),
          allowCancel: true,
          showIcon: true,
          customClass: "delete-modal",
          allowNeverShow: false,
          customButtons: [
            {
              label: this.$t("shared.messages.folderDeleteButton"),
              color: "#dd2222cc",
              close: true,
              callback: async () => {
                if (this.m_ContextMenuFolder == null) return;

                const retryData = await this.m_FoldersService.deleteFolders(
                  this.m_ContextMenuFolder?.id,
                  true
                );

                if (!retryData.error) {
                  this.initializeContent();
                  this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
                }
              },
            },
          ],
        };

        this.m_Events.publish(EVENTS.ALERT, alertConfig);
      } else {
        if (data) this.initializeContent();
        this.m_Events.publish(EVENTS.FOLDERS_UPDATED);
      }
    } finally {
      this.m_FoldersLoading = false;
    }
  }

  onShareModalCliked(videoData: VIDEO) {
    this.m_ShareModal?.show(0, videoData);
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  private initContextMenus() {
    //Initialize context menu options
    this.m_FolderContextMenuOptions = [
      {
        text: this.$t("shared.button.rename"),
        icon: "assets/icon/card/edit.svg",
        action: this.onRenameClicked.bind(this),
      },
      {
        text: this.$t("shared.button.move") + "...",
        icon: "assets/icon/card/folder.svg",
        action: this.onMoveClicked.bind(this),
      },
      {
        text: this.$t("shared.button.delete"),
        icon: "assets/icon/card/delete.svg",
        action: this.onDeleteClicked.bind(this),
      },
    ];

    this.m_FilterContextMenuOptions = [
      {
        text: this.$t("shared.filters.dateModified"),
        action: () => {
          this.onQvioFilterChanged("last_modified");
        },
      },
      {
        text: this.$t("shared.filters.dateCreated"),
        action: () => {
          this.onQvioFilterChanged("create_date");
        },
      },
      {
        text: this.$t("shared.filters.duration"),
        action: () => {
          this.onQvioFilterChanged("duration");
        },
      },
      {
        text: this.$t("shared.filters.name"),
        action: () => {
          this.onQvioFilterChanged("video_name");
        },
      },
      {
        text: this.$t("shared.filters.views"),
        action: () => {
          this.onQvioFilterChanged("views");
        },
      },
    ];
  }
  //--------------------------------------------------------------------
  /**
   * Moves a video to a different folder
   * @param video
   * @param folderId
   * @param showUndo
   */
  private async moveVideosToFolder(
    videos: VIDEO[],
    folderId: string,
    showUndo: boolean = true
  ) {
    if (videos.length == 0) return;

    try {
      //this.m_VideosLoading = true;
      //this.m_FoldersLoading = true;
      let originalFolder = videos[0].folder_id || "0";
      await this.m_FoldersService.moveVideosToFolder(
        videos.map((video) => video.video_id),
        folderId
      );

      this.onVideosMoved(videos, originalFolder, folderId);

      let toastOpts: HIAToastOpts = {
        message: this.$t("pages.library.move_sucess"),
        duration: 10000,
        position: "bottom",
        buttons: showUndo
          ? [
              {
                text: "Undo",
                handler: () => {
                  this.moveVideosToFolder(videos, originalFolder, false);
                },
              },
            ]
          : [],
      };

      this.m_NotificationService.showToast(toastOpts);
    } catch (error) {
      console.error(error);
      this.m_NotificationService.showError(this.$t("pages.library.move_error"));
    } finally {
      this.m_VideosLoading = false;
      this.m_FoldersLoading = false;
    }
  }

  private checkFolderMove(
    sourceFolderId: string,
    destination: TransformedFolder | string
  ) {
    if (!this.m_FolderHierarchy) {
      return false;
    } else {
      return !this.m_FolderHierarchy.isAncestorInTree(
        destination,
        sourceFolderId
      );
    }
  }
  //--------------------------------------------------------------------
  private async moveFolderToFolder(
    parentID: string,
    folderID: string,
    showUndo: boolean = true
  ) {
    try {
      console.warn("Moving folder to folder");
      //this.m_FoldersLoading = true;
      await this.m_FoldersService.moveFolders(parentID, folderID);

      //Get object from current subfolders
      let folderIdx = this.m_CurrentSubfolders.findIndex(
        (f) => f.id === folderID
      );
      let folder = this.m_CurrentSubfolders[folderIdx];
      //Remove folder from current subfolders
      this.m_CurrentSubfolders.splice(folderIdx, 1);

      let originalParentFolderID = folder?.parent_id || "0";

      let toastOpts: HIAToastOpts = {
        message: this.$t("components.foldersModal.move_sucess"),
        duration: 10000,
        position: "bottom",
        buttons: showUndo
          ? [
              {
                text: "Undo",
                handler: async () => {
                  await this.moveFolderToFolder(
                    originalParentFolderID,
                    folderID,
                    false
                  );

                  if (folder != null) {
                    //Add folder back at the same index
                    this.m_CurrentSubfolders.splice(folderIdx, 0, folder);
                  }
                },
              },
            ]
          : [],
      };

      this.m_NotificationService.showToast(toastOpts);
    } catch (error) {
      console.error(error);
      this.m_NotificationService.showError(
        this.$t("components.foldersModal.move_error")
      );
    } finally {
      this.m_FoldersLoading = false;
    }
  }
  //--------------------------------------------------------------------
  /**
   * Handles the event when a video is moved to a different folder, updating the UI accordingly
   * @param video
   * @param folderId
   */
  private onVideosMoved(
    videos: VIDEO[],
    sourceFolderId: string,
    destFolderId: string
  ) {
    for (let video of videos) {
      //Remove video from current folder
      if (video.folder_id) {
        let currentFolder = this.m_CurrentSubfolders.find(
          (folder) => folder.id === video.folder_id
        );
        if (currentFolder) {
          currentFolder.video_count = (currentFolder.video_count ?? 1) - 1;
        }
      }

      //change video folder
      if (video) {
        let currentVideo = this.m_VideoListPaginated.data.find(
          (videoData) => videoData.video_id === video.video_id
        );
        if (currentVideo) {
          currentVideo.folder_id = destFolderId;
        }
      }
    }

    let currentFolderId = this.m_SelectedFolder?.id ?? "0";

    if (sourceFolderId == currentFolderId) {
      for (let video of videos) {
        if (!this.m_VideosMoved?.find((v) => v.video_id == video.video_id)) {
          this.m_VideosMoved?.push(video);
        }
      }
      this.m_QuantityDisplay -= videos.length;
    }

    if (destFolderId == currentFolderId) {
      this.m_VideosMoved = this.m_VideosMoved?.filter(
        (v) => !videos.find((v2) => v2.video_id == v.video_id)
      );
      this.m_QuantityDisplay += videos.length;
    }

    //update new folder video count
    for (let video of videos) {
      this.increamentFolderCount(destFolderId, video);
    }
  }
  //--------------------------------------------------------------------
  /**
   * Helper handler that updates the paginated data with the new data coming from the server
   * @param data
   * @param pageNumber
   */
  private onPaginatedDataUpdated(data: VIDEO_LIST, pageNumber: number = 0) {
    if (pageNumber === 0) {
      this.clearPaginatedData();
    }

    this.m_VideoListPaginated.data = this.m_VideoListPaginated.data.concat(
      data.data
    );
    this.m_VideoListPaginated.total = data.total;
    this.m_VideoListPaginated.totalPages = data.totalPages || 0;
    this.m_QuantityDisplay = this.m_VideoListPaginated.total;
  }
  //--------------------------------------------------------------------
  //Helper function to clear paginated data
  private clearPaginatedData() {
    this.m_VideoListPaginated = { total: 0, data: [], totalPages: 1 };
    this.m_VideosMoved = [];
    this.m_PageNumber = 0;
    this.m_QuantityDisplay = 0;
  }
  //--------------------------------------------------------------------
  /**
   * Increament the video count and visibility count of a folder
   * @param folderId
   * @param video
   */
  private increamentFolderCount(folderId: string, video: VIDEO) {
    let folder = this.m_CurrentSubfolders.find(
      (folder) => folder.id === folderId
    );
    if (folder) {
      folder.video_count = (folder.video_count || 0) + 1;

      switch (video.video_publish_state) {
        case VIDEO_PUBLISH_STATE.PRIVATE:
          folder.private_video_count = (folder.private_video_count || 0) + 1;
          break;
        case VIDEO_PUBLISH_STATE.PUBLIC:
          folder.public_video_count = (folder.public_video_count || 0) + 1;
          break;
        case VIDEO_PUBLISH_STATE.UNLISTED:
          folder.unlisted_video_count = (folder.unlisted_video_count || 0) + 1;
          break;
      }
    }
  }
  //--------------------------------------------------------------------
  private async onQvioFilterChanged(filter: string) {
    this.m_FilterMenuOpen = false;
    this.m_CurrentFilter = filter;
    this.setCurrentFilterDisplay(filter);
    this.clearPaginatedData();
    this.fetchData();
  }
  //--------------------------------------------------------------------
  private setCurrentFilterDisplay(filter: string) {
    switch (filter) {
      case "last_modified":
        this.m_CurrentFilterDisplay = this.$t("shared.filters.dateModified");
        break;
      case "create_date":
        this.m_CurrentFilterDisplay = this.$t("shared.filters.dateCreated");
        break;
      case "duration":
        this.m_CurrentFilterDisplay = this.$t("shared.filters.duration");
        break;
      case "video_name":
        this.m_CurrentFilterDisplay = this.$t("shared.filters.name");
        break;
      case "views":
        this.m_CurrentFilterDisplay = this.$t("shared.filters.views");
        break;
    }
  }
  //--------------------------------------------------------------------
  private isHoverModeDevice() {
    return !this.m_Platform.is("mobile") && !this.m_Platform.is("mobileweb");
  }
  //--------------------------------------------------------------------
  private setFlexFolders() {
    if (!this.m_FoldersContainer?.element.nativeElement)
      this.m_FlexFolders = false;
    else if (this.m_FoldersLoading) this.m_FlexFolders = true;
    else {
      let containerWidth =
        this.m_FoldersContainer?.element.nativeElement.clientWidth;
      let containerChildren =
        this.m_FoldersContainer?.element.nativeElement.children;
      let folderWidth = 0;
      for (let i = 0; i < containerChildren.length; i++) {
        folderWidth += containerChildren[i].clientWidth;
      }
      this.m_FlexFolders = folderWidth < containerWidth;
    }
  }
  //--------------------------------------------------------------------
  private subscribeToEvents() {
    if (this.m_UpdateFolders == null) {
      this.m_UpdateFolders = this.m_Events.subscribe(
        EVENTS.FOLDERS_UPDATED,
        () => {
          this.getUsersFolders();
          this.initializeContent();
        }
      );
    }

    if (this.m_VideoDeleted == null) {
      this.m_VideoDeleted = this.m_Events.subscribe(
        EVENTS.VIDEO_DELETED,
        (videoId: string) => {
          this.m_VideoListPaginated.data =
            this.m_VideoListPaginated.data.filter(
              (v) => v.video_id !== videoId
            );
        }
      );
    }

    if (this.m_VideoMoved == null) {
      this.m_VideoMoved = this.m_Events.subscribe(
        EVENTS.VIDEO_MOVED,
        (data: any) => {
          this.moveVideosToFolder(data.videos, data.folderId, true);
          this.m_DragDropManager.reset();
        }
      );
    }

    if (this.m_OnReuploadVideo == null) {
      this.m_OnReuploadVideo = this.m_Events.subscribe(
        EVENTS.REUPLOAD_VIDEO,
        (qvio: VIDEO) => {
          this.m_Uploader?.openFileDialog(qvio, this.m_SelectedFolder?.id);
        }
      );
    }

    if (this.m_UpdateQvios == null) {
      this.m_UpdateQvios = this.m_Events.subscribe(
        EVENTS.VIDEO_UPDATED,
        async (videoData: VIDEO | null) => {
          if (videoData == null) {
            this.getVideos();
          } else {
            let video = this.m_VideoListPaginated.data.find(
              (v) => v.video_id === videoData.video_id
            );
            if (video) {
              video.copy(videoData);
            } else {
              this.getVideos();
            }
          }
        }
      );
    }
  }

  //--------------------------------------------------------------------
  private getTextWidth(text: string, font: string) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    let result = 0;
    if (context != null) {
      context.font = font;
      const metrics = context.measureText(text);
      result = metrics.width;
    }
    return result;
  }
  //--------------------------------------------------------------------
  private getPathWidth(pathArray: FOLDER_DTO[]) {
    const margin = 2.4;
    const extra = 18;
    const font = "600 13px 'DM Sans'";

    let width = extra;
    width += this.getTextWidth(this.$t("pages.library.library"), font);
    for (let i = 0; i < pathArray.length; i++) {
      let text = this.getHeaderFolderName(pathArray[i], i, pathArray.length);
      width += this.getTextWidth(text, font);
      width += this.getTextWidth("/", font);
      width += margin * 2;
    }
    width -= this.getTextWidth("/", font);

    return width;
  }
  //#endregion

  static getRouterPath(folderId: string) {
    let path = ["/library"];
    let id = uuidToShortId(folderId) || "";
    if (id != "" && folderId != "0") {
      path.push("folder");
      path.push(id);
    }
    return path;
  }

  static isLibraryPage(route: string) {
    let path = route.split("/");
    return path.length >= 2 && path[1] == "library";
  }

  static isAllQvio(route: string) {
    let path = route.split("/");
    return path.length == 3 && path[2] == "all";
  }

  static getFolderIdFromRoute(route: string) {
    let path = route.split("/");
    let folderId = "";
    if (path.length == 4) {
      folderId = path[3];
    }

    return folderId;
  }
}
