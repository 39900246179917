import { BASE_RESPONSE } from "../api/api";

// Enum for Overlay Types (Element or Iframe)
export enum OVERLAY_TYPE {
  ELEMENT = 0,
  IFRAME = 1,
}

// Enum for Overlay Locations
export enum OVERLAY_LOCATION {
  TOP_LEFT = 0,
  TOP_RIGHT = 1,
  BOTTOM_LEFT = 2,
  BOTTOM_RIGHT = 3,
  CENTER = 4,
}

// Enum for Fitting Options
export enum OVERLAY_BACKGROUND_FITTING {
  FIT_TO_AREA = 0,
  ACTUAL_SIZE = 1,
}

// Enum for Animation Types
export enum OVERLAY_ANIMATION_TYPE {
  NONE = 0,
  FADE = 1,
  SLIDE = 2,
}

export interface PUBLIC_OVERLAY_DTO {
  id: string;
  start_time?: number;
  end_time?: number;
  whole_video: boolean;
  overlay_type: OVERLAY_TYPE;
  text_field?: string;
  embed_url?: string;
  url_field?: string;
  location: OVERLAY_LOCATION;
  background_url?: string;
  fitting?: OVERLAY_BACKGROUND_FITTING;
  background_color?: string;
  foreground_color?: string;
  border_color?: string;
  border_radius?: number;
  width?: number;
  height?: number;
  offset_x?: number;
  offset_y?: number;
  z_index?: number;
  opacity?: number;
  animation_type: OVERLAY_ANIMATION_TYPE;
  animation_duration?: number;
  is_sticky?: boolean;
}

export interface OVERLAY_DTO extends PUBLIC_OVERLAY_DTO {
  name: string;
  video_id: string;
  background_media_id?: string;
  is_active: boolean;
  created_at: string;
  last_modified: string;
  loading?: boolean;
}

export interface CREATE_OVERLAY_REQ_DTO {
  name: string;
  start_time?: number;
  end_time?: number;
  whole_video: boolean;
  overlay_type: OVERLAY_TYPE;
  text_field?: string;
  embed_url?: string;
  url_field?: string;
  location: OVERLAY_LOCATION;
  background_media_id?: string;
  fitting: OVERLAY_BACKGROUND_FITTING;
  background_color?: string;
  foreground_color?: string;
  border_color?: string;
  border_radius?: number;
  width?: number;
  height?: number;
  offset_x?: number;
  offset_y?: number;
  z_index?: number;
  opacity?: number;
  animation_type: OVERLAY_ANIMATION_TYPE;
  animation_duration?: number;
  is_sticky?: boolean;
  is_active: boolean;
}

export interface UPDATE_OVERLAY_REQ_DTO {
  name?: string;
  start_time?: number;
  end_time?: number;
  whole_video?: boolean;
  overlay_type?: OVERLAY_TYPE;
  text_field?: string;
  embed_url?: string;
  url_field?: string;
  location?: OVERLAY_LOCATION;
  background_media_id?: string;
  fitting?: OVERLAY_BACKGROUND_FITTING;
  background_color?: string;
  foreground_color?: string;
  border_color?: string;
  border_radius?: number;
  width?: number;
  height?: number;
  offset_x?: number;
  offset_y?: number;
  z_index?: number;
  opacity?: number;
  animation_type?: OVERLAY_ANIMATION_TYPE;
  animation_duration?: number;
  is_sticky?: boolean;
  is_active?: boolean;
}

export interface OVERLAY_LIST_RES_DTO extends BASE_RESPONSE {
  overlays: OVERLAY_DTO[];
}

export interface OVERLAY_RES_DTO extends BASE_RESPONSE {
  overlay: OVERLAY_DTO;
}
