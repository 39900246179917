import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { v4 as uuidv4 } from "uuid";
import {
  IBaseStyleConfig,
  IWidgetStyle,
  IInsightsWidgetConfig,
  AccountLocation,
  InsightsWidget,
  IInsightsStyleConfig,
  Language,
} from "@azure/video-indexer-widgets";
import { Subscription } from "rxjs";
import { EVENTS } from "src/app/constants/events";
import { Events } from "src/app/services/events/events.service";
import {
  PollResults,
  VideoService,
} from "src/app/services/video/video.service";
import { INSIGHTS_DTO_GET_WIDGET_RES } from "@shared/models/insights/insights";
import { VIDEO } from "src/app/models/video/video";
import { VIDEO_PUBLISH_STATE, VIDEO_STATUS } from "@shared/models/video/video";
import { MediaProgressComponent } from "../../shared/media-progress/media-progress.component";
import { T } from "src/app/services/localization/localization.service";
import { UserService } from "src/app/services/user/user.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";

@Component({
  selector: "app-video-insights",
  templateUrl: "./video-insights.component.html",
  styleUrls: ["./video-insights.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class VideoInsightsComponent implements OnInit {
  @Input() m_Edit: boolean = false;
  @Input()
  set Video(value: VIDEO | null) {
    if (!value || this.m_Video == value) {
      this.m_ErrorMessage = this.$t("shared.messages.errorLoadingVideo");
      return;
    }
    this.m_Video = value;
    this.intializeInsights(this.m_Video);
  }
  @ViewChild(MediaProgressComponent) m_VideoProgressComponent:
    | MediaProgressComponent
    | undefined;

  public $t = T.translate;
  public m_TimedOut: boolean = false;
  public m_Loading: boolean = true;
  public m_ErrorMessage?: string | null;
  //--------------------------------------------------------------------
  private m_Video: VIDEO | null = null;
  private m_InsightsIframe: any;
  private m_UID: string = "";
  private m_Widget: InsightsWidget | null = null;

  private m_TimeUpdateEvent: Subscription | null = null;
  private m_ForceRenderSub: Subscription | null = null;
  //Polling events
  private m_OnIndexReadySub: Subscription | null = null;
  private m_OnVideoProgressSub: Subscription | null = null;
  private m_OnVideoTimeoutSub: Subscription | null = null;
  private m_PollID: string | null = null;
  //--------------------------------------------------------------------
  constructor(
    private m_VideoService: VideoService,
    private m_Events: Events,
    private m_UserService: UserService,
    private m_NotificationService: HIANotificationService
  ) {
    this.m_UID = uuidv4();
  }
  //--------------------------------------------------------------------
  ngOnInit() {
    this.m_ForceRenderSub = this.m_Events.subscribe(EVENTS.PAGE_SHOW, () => {
      this.forceRender();
    });

    this.m_Events.subscribe(EVENTS.INSIGHT_DESTROY, () => {
      this.destroyIframe();
    });

    this.m_Events.subscribe(EVENTS.INSIGHT_RENDER, (video: VIDEO) => {
      this.intializeInsights(video);
    });
  }
  //--------------------------------------------------------------------
  ngOnDestroy() {
    this.dispose();
  }
  //--------------------------------------------------------------------
  dispose() {
    this.m_TimeUpdateEvent?.unsubscribe();
    this.m_ForceRenderSub?.unsubscribe();
    this.unsubscribeFromVideoStatusPolling();
  }
  //--------------------------------------------------------------------
  //#region Public Methods
  forceRender() {
    this.m_Widget?.render();
  }

  //Resets timeout and refreshes the polling
  async onRefreshClicked() {
    if (this.m_Video == null) return;
    this.m_TimedOut = false;
    if (!this.m_VideoService.pollingVideo(this.m_Video.video_id)) {
      this.m_PollID = await this.m_VideoService.startVideoStatusPolling(
        this.m_Video.video_id
      );
    }
    this.intializeInsights(this.m_Video);
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Render Helpers
  //Helper to get the insights container id
  getInsightsContainerId() {
    return "insights-container-" + this.m_UID;
  }

  //Helper to get the loading message when the insights are processing
  shouldShowProgress() {
    return (
      this.m_Video?.video_index_status == VIDEO_STATUS.PENDING ||
      this.m_Video?.video_index_status == VIDEO_STATUS.PROCESSING ||
      this.m_Video?.video_index_status == VIDEO_STATUS.ERROR ||
      this.m_TimedOut
    );
  }

  shouldShowLoading() {
    return this.m_Loading;
  }

  //Helper to get the error message when the insights fail to process or time out
  shouldShowError() {
    return (
      this.m_Video?.video_index_status == VIDEO_STATUS.ERROR || this.m_TimedOut
    );
  }

  //Helper to get the loading message when the insights are processing
  getLoadingMessage() {
    if (this.m_TimedOut) return this.$t("shared.messages.invalidContactAuthor");

    switch (this.m_Video?.video_index_status) {
      case VIDEO_STATUS.PENDING:
      case VIDEO_STATUS.PROCESSING:
        return this.$t("components.insights.processing");
      case VIDEO_STATUS.ERROR:
        return this.$t("shared.messages.failed");
      default:
        return this.$t("shared.messages.noInsights");
    }
  }
  destroyIframe() {
    if (!this.m_Widget) return;
    this.dispose();
    this.m_Widget = null;
    this.m_InsightsIframe.onload = null;
    this.m_InsightsIframe.onerror = null;
    this.m_InsightsIframe.remove();
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  /**
   * Initialize the insights widget, fetching the data needed from the backend to display the widget
   * @param videoID
   */
  private async intializeInsights(video: VIDEO) {
    this.m_ErrorMessage = null;
    if (video == null) return;

    this.destroyIframe();

    if (video.video_index_status == VIDEO_STATUS.ERROR) {
      this.m_ErrorMessage =
        this.m_UserService.ActiveUserInfo?.id == video.video_user_id
          ? this.$t("shared.messages.errorIndexingVideo")
          : this.$t("shared.messages.errorVideoNoFilters");
      return;
    }

    if (video.video_index_status != VIDEO_STATUS.READY) {
      this.subscribeToVideoStatusPolling();
      return;
    }

    let videoID = video.video_id;
    let insightsWidgetConfig: INSIGHTS_DTO_GET_WIDGET_RES;
    this.m_Loading = true;
    try {
      insightsWidgetConfig = await this.m_VideoService.getVideoInsightsWidget(
        videoID,
        this.m_Edit
      );
    } catch (error) {
      this.m_Loading = false;
      this.m_ErrorMessage =
        this.m_UserService.ActiveUserInfo?.id == video.video_user_id
          ? this.$t("shared.messages.errorIndexingVideo")
          : this.$t("shared.messages.errorVideoNoFilters");
      console.error(error);
      return;
    }

    //get main-background-color from css computed var
    let bgcolor = getComputedStyle(document.body).getPropertyValue(
      "--main-background-color"
    );

    // Change two colors of the base style config
    const insightsStyleConfig: IInsightsStyleConfig = {
      //Get from css computed var
      bgSecondary: bgcolor,
      emotionJoy: "#12866e",
    };

    // Select dark mode theme and send new style
    const style: IWidgetStyle = {
      customStyle: insightsStyleConfig,
    };

    //Get language from storage
    const getLanguage = async (): Promise<Language | undefined> => {
      if (!this.m_Video) return;

      let currentCaptionLang = undefined;

      const currentCaption = await this.m_VideoService.getShowingCaption(
        this.m_Video.video_id
      );
      const defaultCaption = await this.m_VideoService.getDefaultCaption(
        this.m_Video.video_id
      );

      if (currentCaption) {
        currentCaptionLang = currentCaption.language;
      }

      if (!currentCaption) {
        if (this.m_Video.source_language != null) {
          currentCaptionLang = this.m_Video.source_language;
        } else {
          if (!defaultCaption) return;
          currentCaptionLang = defaultCaption.language;
        }
      }

      currentCaptionLang = await this.m_VideoService.getInsightSupportLanguage(
        currentCaptionLang
      );
      if (!currentCaptionLang) {
        this.m_NotificationService.showError(
          this.$t("shared.messages.languageFailed")
        );
      }

      return currentCaptionLang ? currentCaptionLang : undefined;
    };

    let widgetConfig: IInsightsWidgetConfig = {
      accountId: insightsWidgetConfig.acc,
      videoId: insightsWidgetConfig.id,
      accessToken: insightsWidgetConfig.token,
      location: insightsWidgetConfig.location as AccountLocation,
      style: style,
      preset: "all",
      language: await getLanguage(),
    };

    this.m_Widget = new InsightsWidget(
      this.getInsightsContainerId(),
      { width: 100, height: 100 },
      widgetConfig
    );

    this.m_Widget.render();

    //Get the iframe element, which is a child of the insights container
    this.m_InsightsIframe = document.getElementById(
      this.getInsightsContainerId()
    )?.firstChild;

    //Listen to iframe load event
    this.m_InsightsIframe.onload = () => {
      //Workaround to block unwanted events from Insight Widget
      setTimeout(() => {
        this.m_Loading = false;
        this.m_Events.publish(EVENTS.INSIGHT_LOADED);
      }, 1500);
    };
    //Listen to iframe on load error
    this.m_InsightsIframe.onerror = () => {
      this.m_Loading = false;
      this.m_ErrorMessage = this.$t("shared.messages.somethingWentWrong");
    };

    this.hookUpEvents();
  }

  private hookUpEvents() {
    this.m_TimeUpdateEvent = this.m_Events.subscribe(
      EVENTS.PLAYER_TIME_UPDATE,
      (data: { time: number }) => {
        if (this.m_InsightsIframe) {
          try {
            this.m_InsightsIframe.contentWindow.postMessage(
              { currentTime: data.time },
              "https://www.videoindexer.ai"
            );
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  }

  /**
   * Subscribe to video status polling events
   */
  private subscribeToVideoStatusPolling() {
    this.m_OnVideoProgressSub = this.m_VideoService.OnVideoProgress.subscribe(
      (results: PollResults) => {
        this.handleIndexProgress(results);
      }
    );

    this.m_OnVideoTimeoutSub = this.m_VideoService.OnVideoTimeout.subscribe(
      (results: PollResults) => {
        this.handleTimeOut(results);
      }
    );

    this.m_OnIndexReadySub = this.m_VideoService.OnIndexReady.subscribe(
      (results: PollResults) => {
        this.handleIndexReady(results);
      }
    );
  }

  /**
   * Unsubscribe from video status polling events
   */
  private unsubscribeFromVideoStatusPolling() {
    this.m_OnVideoProgressSub?.unsubscribe();
    this.m_OnVideoTimeoutSub?.unsubscribe();
    this.m_OnIndexReadySub?.unsubscribe();

    if (this.m_PollID)
      this.m_VideoService.stopVideoStatusPolling(this.m_PollID);
  }

  /**
   *  Handles the index ready event, cleaning up subscribers and initializing the insights widget
   * @param results
   * @returns if the video id is not the same as the current video, return
   */
  private handleIndexReady(results: PollResults) {
    if (results.video_id != this.m_Video?.video_id) return;

    this.unsubscribeFromVideoStatusPolling();
    this.m_Video.video_index_status = VIDEO_STATUS.READY;
    this.intializeInsights(this.m_Video);
  }

  /**
   * Handles the timeout event, cleaning up subscribers and showing the timeout message
   * @param results
   * @returns if the video id is not the same as the current video, return
   */
  private handleTimeOut(results: PollResults) {
    if (results.video_id != this.m_Video?.video_id) return;

    this.unsubscribeFromVideoStatusPolling();
    this.m_TimedOut = true;
  }

  /**
   * Handles the index progress event, updating the progress bar
   * @param results
   * @returns if the video id is not the same as the current video, return
   */
  private handleIndexProgress(results: PollResults) {
    if (results.video_id != this.m_Video?.video_id) return;

    let progress = results.progress || 0;
    this.m_VideoProgressComponent?.setProgress(progress);
  }
  //#endregion
}
