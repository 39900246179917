import { Subject } from "rxjs";

export enum PlaybackType {
  URL,
  STREAM,
}

export class AudioPlayer {
  private audioContext: AudioContext | null = null;
  private bufferQueue: ArrayBuffer[] = [];
  private lastBufferEndTime = 0;
  private readonly MIN_BUFFER_DURATION = 5; // seconds
  private accumulatedDuration = 0; // accumulated buffered audio duration in seconds
  private isProcessing = false; // flag to prevent race conditions
  private m_StreamAudio: boolean = false;
  private m_TotalBuffers: number = 0;
  private m_TotalBuffersPlayed: number = 0;
  private m_SourceBuffers: AudioBufferSourceNode[] = [];
  private m_GainNode: GainNode;
  private m_PlaybackType: PlaybackType = PlaybackType.STREAM;
  private m_BufferStates: Map<AudioBufferSourceNode, boolean> = new Map();

  public OnAudioPlay: Subject<void> = new Subject<void>();
  public OnAudioStop: Subject<void> = new Subject<void>();

  set StreamAudio(value: boolean) {
    this.m_StreamAudio = value;
  }

  constructor() {
    this.audioContext = new AudioContext();
    this.m_GainNode = this.audioContext.createGain();
  }

  dispose() {
    this.audioContext?.close();
  }

  //Resumes the audio context if it is suspended, needed to play audio
  public resumeContext() {
    if (this.audioContext?.state === "suspended") {
      this.audioContext?.resume();
    }
  }

  public reset() {
    this.bufferQueue = [];
    this.lastBufferEndTime = 0;
    this.accumulatedDuration = 0;
    this.isProcessing = false;
    this.m_TotalBuffers = 0;
    this.m_TotalBuffersPlayed = 0;
    this.m_SourceBuffers = [];
    this.m_BufferStates.clear(); // Clear all buffer states
    this.m_PlaybackType = PlaybackType.STREAM;
  }

  public stop() {
    this.OnAudioStop.next();
    this.stopAllBuffers();
    this.reset();
  }

  public get volume(): number {
    return this.m_GainNode?.gain.value ?? 0;
  }

  public set volume(volume: number) {
    if (this.m_GainNode != null) {
      this.m_GainNode.gain.value = volume;
    }
  }

  //Pushes the base64 chunk to the buffer queue to be played
  public async onChunkReceived(base64Chunk: string) {
    const arrayBuffer = this.base64ToArrayBuffer(base64Chunk);
    this.bufferQueue.push(arrayBuffer);
    this.m_TotalBuffers++;
    try {
      const audioBuffer = await this.audioContext!.decodeAudioData(
        this.cloneArrayBuffer(arrayBuffer)
      ); // We'll decode without playing to get the duration
      this.accumulatedDuration += audioBuffer.duration;
    } catch (err) {
      console.warn("Failed to decode audio data:", err);
      //Remove the buffer from the queue by searching for the index of the buffer
      const index = this.bufferQueue.indexOf(arrayBuffer);
      if (index > -1) {
        this.bufferQueue.splice(index, 1);
      }
      this.m_TotalBuffers--;
    }

    if (
      !this.isProcessing &&
      this.accumulatedDuration >= this.MIN_BUFFER_DURATION &&
      this.m_StreamAudio
    ) {
      this.processBufferQueue();
    }
  }

  //Plays the buffer queue
  public async processBufferQueue() {
    if (this.isProcessing) return; // prevent concurrent processing

    this.isProcessing = true;

    if (this.audioContext?.state === "suspended") {
      await this.audioContext?.resume();
    }

    while (this.bufferQueue.length > 0) {
      let buffer = null;
      //If we are streaming, grab a single buffer from the queue and process it
      if (this.m_StreamAudio) {
        buffer = this.bufferQueue.shift()!;
      } else {
        //If we are not streaming, concatenate all buffers and process them in one shot
        buffer = this.concatenateBuffers(this.bufferQueue);
        this.bufferQueue = [];
      }

      if (buffer === null) return;
      try {
        const audioBuffer = await this.audioContext!.decodeAudioData(buffer);

        // Schedule the audio buffer for playback
        const bufferSource = this.audioContext!.createBufferSource();
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(this.m_GainNode);
        bufferSource.onended = () => {
          this.onBufferPlayEnd();
        };
        this.m_GainNode.connect(this.audioContext!.destination);
        this.m_SourceBuffers.push(bufferSource);

        // Add to buffer states before starting
        this.m_BufferStates.set(bufferSource, false);

        if (
          this.lastBufferEndTime === 0 ||
          this.audioContext!.currentTime > this.lastBufferEndTime
        ) {
          bufferSource.start(this.audioContext!.currentTime);
          this.m_BufferStates.set(bufferSource, true); // Mark as started
          this.lastBufferEndTime =
            this.audioContext!.currentTime + audioBuffer.duration;
        } else {
          bufferSource.start(this.lastBufferEndTime);
          this.m_BufferStates.set(bufferSource, true); // Mark as started
          this.lastBufferEndTime += audioBuffer.duration;
        }

        // Decrease the accumulatedDuration with the duration of the processed buffer
        this.accumulatedDuration -= audioBuffer.duration;
        this.OnAudioPlay.next();
      } catch (err) {
        console.error("Failed to decode audio data:", err);
      }
    }

    this.isProcessing = false;
  }

  playAudioFromUrl(url: string) {
    this.m_PlaybackType = PlaybackType.URL;
    this.resumeContext();
    this.m_TotalBuffers = 1;
    this.m_TotalBuffersPlayed = 0;
    this.m_SourceBuffers = [];
    this.m_GainNode.connect(this.audioContext!.destination);
    const bufferSource = this.audioContext!.createBufferSource();
    bufferSource.connect(this.m_GainNode);
    bufferSource.onended = () => {
      this.onBufferPlayEnd();
    };
    this.m_SourceBuffers.push(bufferSource);

    // Add to buffer states before starting
    this.m_BufferStates.set(bufferSource, false);

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        this.audioContext!.decodeAudioData(arrayBuffer, (audioBuffer) => {
          bufferSource.buffer = audioBuffer;
          bufferSource.start(0);
          this.m_BufferStates.set(bufferSource, true); // Mark as started
          this.OnAudioPlay.next();
        });
      });
  }

  //#region Private Methods
  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private cloneArrayBuffer(buffer: ArrayBuffer): ArrayBuffer {
    return buffer.slice(0);
  }

  private concatenateBuffers(buffers: ArrayBuffer[]): ArrayBuffer {
    let totalLength = buffers.reduce(
      (acc, buffer) => acc + buffer.byteLength,
      0
    );

    let result = new Uint8Array(totalLength);
    let offset = 0;

    for (let buffer of buffers) {
      result.set(new Uint8Array(buffer), offset);
      offset += buffer.byteLength;
    }

    return result.buffer;
  }

  private onBufferPlayEnd() {
    const bufferSource = this.m_SourceBuffers.shift();
    if (bufferSource) {
      this.m_BufferStates.delete(bufferSource);
    }

    this.m_TotalBuffersPlayed++;
    if (
      (this.m_TotalBuffers > 0 &&
        this.m_TotalBuffersPlayed >= this.m_TotalBuffers) ||
      !this.m_StreamAudio
    ) {
      this.OnAudioStop.next();
      this.reset();
    }
  }

  private stopAllBuffers() {
    for (let buffer of this.m_SourceBuffers) {
      buffer.onended = null;
      if (this.m_BufferStates.get(buffer)) {
        // Only stop if it has been started
        try {
          buffer.stop();
        } catch (err) {
          console.warn("Error stopping buffer:", err);
        }
      }
      this.m_BufferStates.delete(buffer); // Remove from map to clean up
    }
  }

  //#endregion
}
