import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { PreloadType } from "theoplayer";
import { Subscription } from "rxjs";
import { EVENTS } from "src/app/constants/events";
import { ConfigService } from "src/app/services/config/config.service";
import { Events } from "src/app/services/events/events.service";
import { UtteranceService } from "src/app/services/utterance/utterance.service";
import {
  CaptionData,
  LanguageData,
  VideoService,
} from "src/app/services/video/video.service";

import * as THEOplayer from "theoplayer";
import { LANGUAGES } from "src/app/constants/languages";
import { T } from "src/app/services/localization/localization.service";
import {
  CAPTIONS,
  ChapterMap,
  QvioViewMode,
  VIDEO,
} from "src/app/models/video/video";
import { StorageService } from "src/app/services/storage/storage.service";
import { Router, NavigationEnd, Scroll } from "@angular/router";
import {
  generateChaptersVTT,
  getChapterTimes,
  getLanguageName,
  isTextTrackMetadata,
  parseTimestampsFromString,
} from "src/app/utility/video-utils";
import { Language } from "@azure/video-indexer-widgets";
import { v4 as uuidv4 } from "uuid";
import { AdminService } from "src/app/services/admin/admin.service";
import { UserService } from "src/app/services/user/user.service";
import { UiService } from "src/app/services/ui/ui.service";
import {
  VideoOverlayMenuConfig,
  VideoOverlayPosition,
} from "../video-overlay-menu/video-overlay-menu.component";
import {
  addButtonToPlayer,
  HIATheoButtonConfig,
} from "src/app/utility/theo-utils";
import { timeStringToSeconds } from "src/app/utility/time-utils";
import { VideoSegmentComponent } from "../video-segment/video-segment.component";

export interface VideoSettingsData {
  id: string;
  duration: number;
  language: LanguageData | null;
}

export interface PlayerConfig {
  TOKEN: string;
  ENABLE_MUTED_AUTOPLAY: boolean;
  PRELOAD_DIRECTIVE: PreloadType;
  RESUME_PADDING: number;
}

export interface PlayerEvent {
  event: string;
  data: any;
}

export interface SegmentEvent {
  startTime: number;
  endTime: number;
}

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class VideoPlayerComponent implements OnInit {
  private readonly SEEK_TIME = 10.0;

  @Input() m_SegmentStartTime: number = 0;
  @Input() m_SegmentEndTime: number = 0;
  @Input() m_QvioViewMode: QvioViewMode = QvioViewMode.WATCH;
  @Input() m_OverridePreload: PreloadType | null = null;
  @Input() m_IsActive: boolean = false;
  @Input() m_DisableControls: boolean = false;
  @Input() m_ShowSegment: boolean = false;
  @Input() m_IsSegmentWholeVideo: boolean = false;
  @Input() m_IsEmbedQnaPage: boolean = false;
  @Output() OnPlayerEvent = new EventEmitter<PlayerEvent>();

  //Component References
  @ViewChild("videoContainer") m_VideoContainer: ElementRef | undefined;
  @ViewChild("videoSegment") m_VideoSegment: VideoSegmentComponent | undefined;

  get VideoId() {
    return this.m_VideoData?.video_id;
  }

  get VideoData() {
    return this.m_VideoData;
  }

  get CurrentTime() {
    return this.m_VideoPlayer?.currentTime;
  }

  get Duration() {
    return this.m_VideoPlayer?.duration;
  }

  public $t = T.translate;
  public m_VideoStarted: boolean = false;
  public m_LanguageList: LanguageData[] | undefined;
  public m_ShowingCaption?: CaptionData | null;
  public m_OpenOverlayMenu: boolean = false;
  public m_CCEnabled: boolean = false;
  public m_HasMultiLanguage: boolean = false;
  public m_ShowContainer: boolean = true;
  public m_RenderContainer: boolean = false;

  private m_VideoPlayer: THEOplayer.Player | null = null;
  private m_VideoData: VIDEO | null = null;
  private m_VideoSrc: string = "";
  private m_TextTracks?: Array<any> | null;
  private m_ChapterTimes?: number[];
  private m_Segment?: { start: number; end: number } | null;
  private m_StartTime?: number;
  private m_UseCachedTime: boolean = true;
  private m_MsgEvent: EventListener | null = null;
  private m_WindowResizeEvent: EventListener | null = null;
  private m_ErrorHandlerEvent: THEOplayer.EventListener<THEOplayer.ErrorEvent> | null =
    null;
  private m_VideoPlayEvent: THEOplayer.EventListener<THEOplayer.PlayEvent> | null =
    null;
  private m_VideoPauseEvent: THEOplayer.EventListener<THEOplayer.PauseEvent> | null =
    null;
  private m_VideoSeekStartEvent: THEOplayer.EventListener<THEOplayer.SeekingEvent> | null =
    null;
  private m_VideoSeekEndEvent: THEOplayer.EventListener<THEOplayer.SeekedEvent> | null =
    null;
  private m_VideoEndedEvent: THEOplayer.EventListener<THEOplayer.EndedEvent> | null =
    null;
  private m_VideoTimeUpdateEvent: THEOplayer.EventListener<THEOplayer.TimeUpdateEvent> | null =
    null;
  private m_CaptionsChangedEvent: THEOplayer.EventListener<THEOplayer.TrackChangeEvent> | null =
    null;
  private m_CaptionTrackAddedEvent: THEOplayer.EventListener<THEOplayer.AddTrackEvent> | null =
    null;
  private m_VisibilityChangeEvent: EventListener | null = null;
  private m_PresentationModeChangeEvent: THEOplayer.EventListener<THEOplayer.PresentationModeChangeEvent> | null =
    null;
  private m_VolumeChangeEvent: THEOplayer.EventListener<THEOplayer.VolumeChangeEvent> | null =
    null;
  private m_TouchEndEvent: EventListener | null = null;
  private m_DblClickEvent: EventListener | null = null;
  private m_SettingsClickedEvent: EventListener | null = null;
  private m_DubClickedEvent: EventListener | null = null;
  private m_UpdateThumbnailEvent: Subscription | null = null;
  private m_RouterEvent: Subscription | null = null;
  private m_InsightsLoadedEvent: Subscription | null = null;
  private m_AttachmentShowEvent: Subscription | null = null;
  private m_UpdateCCStateEvent: Subscription | null = null;
  private m_KeyDownEvent: any;
  private m_Paused: boolean = false;
  private m_OnTTSStart: Subscription | undefined;
  private m_RefreshingTracks: boolean = false;
  private m_TimeToRefresh: number = 0;
  private m_IsFullscreen: boolean = false;
  private m_LastVolume: number = 1;
  private m_AudioIsFaded: boolean = false;
  private m_AutoPlay: boolean = false;
  private m_TrackAddedFromEvent: boolean = false;
  private m_InsightLoaded: boolean = false;
  private m_DefaultTrack: any = null;
  private m_VideoInterrupted: boolean = false;
  private m_AttemptCount: number = 0;
  private m_PlayerConfig: PlayerConfig = {
    TOKEN: "",
    ENABLE_MUTED_AUTOPLAY: true,
    PRELOAD_DIRECTIVE: "auto",
    RESUME_PADDING: 3,
  };
  private m_CCMenuConfig: VideoOverlayMenuConfig = {
    options: [],
    title: this.$t("components.videoPlayer.subtitles"),
  };
  private m_ChaptersMenuConfig: VideoOverlayMenuConfig = {
    options: [],
    title: this.$t("components.videoPlayer.chapters"),
    position: VideoOverlayPosition.BottomLeft,
  };
  private m_ActiveMenuConfig: VideoOverlayMenuConfig | null = null;
  private m_PlayerUID: string = uuidv4();
  private m_ControlsEnabled: boolean = true;

  @ViewChild("timelineElement") timelineElement!: ElementRef;

  get Paused() {
    return this.m_Paused;
  }

  get Player() {
    return this.m_VideoPlayer;
  }

  get Intialized() {
    return this.m_VideoPlayer != null;
  }

  get IsFullscreen() {
    return this.m_IsFullscreen;
  }

  get ActiveMenuConfig() {
    return this.m_ActiveMenuConfig;
  }

  isMobile() {
    return this.m_UIService.isMobile();
  }

  //--------------------------------------------------------------------
  constructor(
    private m_Events: Events,
    private m_VideoService: VideoService,
    private m_UtteranceService: UtteranceService,
    private m_ConfigService: ConfigService,
    private m_StorageService: StorageService,
    private m_Router: Router,
    private m_AdminService: AdminService,
    private m_UserService: UserService,
    private m_UIService: UiService,
    private m_Cdr: ChangeDetectorRef
  ) {
    this.m_ConfigService.get("PLAYER_CONFIG").then((config) => {
      let playerConfig: PlayerConfig = JSON.parse(config);
      if (playerConfig == null) return;
      this.m_PlayerConfig = playerConfig;
    });
  }
  //--------------------------------------------------------------------
  ngOnInit() {
    //Subscribe to window resize event
    this.m_WindowResizeEvent = () => this.onWindowResize();
    this.m_VisibilityChangeEvent = () => this.onVisibilityChange();
    this.m_MsgEvent = (event: any) => this.onMessage(event);

    window.addEventListener("resize", this.m_WindowResizeEvent);
    document.addEventListener("visibilitychange", this.m_VisibilityChangeEvent);

    //Pause player on Attachment Open
    this.m_AttachmentShowEvent = this.m_Events.subscribe(
      EVENTS.ATTACHMENT_SHOW,
      () => {
        if (!this.m_Paused) {
          this.pause(false, true);
        }
      }
    );

    //Update captions state after close Attachment view modal
    this.m_UpdateCCStateEvent = this.m_Events.subscribe(
      EVENTS.UPDATE_CC_STATE,
      (tracks: THEOplayer.TextTrack[]) => {
        this.updateCaptionsFromEvt(tracks);
      }
    );

    //Update thumbnail after edit
    this.m_UpdateThumbnailEvent = this.m_Events.subscribe(
      EVENTS.UPDATE_THUMBNAIL,
      (data: { video_id: string; thumb_url: string }) => {
        if (
          this.m_VideoData?.video_id === data.video_id &&
          this.m_VideoPlayer
        ) {
          this.m_VideoPlayer.poster = "";
          this.m_VideoPlayer.poster = this.VideoData?.video_thumb_url ?? "";
        }
      }
    );

    //Subscribe to utterance service events
    this.m_OnTTSStart = this.m_UtteranceService.m_OnAudioPlay.subscribe(() => {
      this.pause();
    });

    //Check route changes
    this.m_RouterEvent = this.m_Router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!["/edit"].includes(this.m_Router.url)) {
          this.unsubscribeFromCaptionEvents();
        }
      } else if (event instanceof Scroll) {
        //Listen for scroll events, which is triggered when the user
        //clicks on a timestamp in description
        let hash = window.location.hash;
        //Check if the hash is a timestamp
        let timestamp = hash.substring(1);
        //Remove the hash from the URL
        if (this.m_VideoPlayer != null && timestamp != "") {
          window.location.hash = "";
          this.m_VideoPlayer.currentTime = parseFloat(timestamp);
        }
      }
    });
  }
  //--------------------------------------------------------------------
  reset() {
    if (this.m_VideoPlayer == null) return;
    this.unsubscribeFromVideoPlayerEvents();
    this.m_VideoPlayer.source = {
      sources: [],
    };
    this.m_VideoPlayer.poster = "";
  }
  //--------------------------------------------------------------------
  onWindowResize() {
    if (this.m_VideoPlayer != null) {
      //TODO: Why empty???
    }
  }
  //--------------------------------------------------------------------
  onVisibilityChange() {
    if (document.visibilityState === "visible") {
      setTimeout(() => {
        this.onWindowResize();
      }, 100);
    }
  }
  //--------------------------------------------------------------------
  /**
   * Takes a chapters list and adds it as a metadata track to the video player
   * @param chapters
   * @returns
   */
  setChapters(chapters: ChapterMap) {
    if (this.m_VideoPlayer == null) return;

    let vttURL = generateChaptersVTT(chapters, this.m_VideoPlayer.duration);
    this.updateChapterMenuConfig(chapters);
    if (vttURL) {
      let track = {
        kind: "chapters",
        label: "chapters",
        src: vttURL,
        default: true,
        srcLang: "en",
      };

      this.m_ChapterTimes = getChapterTimes(chapters);

      this.addTextTrack(track);

      if (this.m_ChapterTimes != null && this.m_ChapterTimes.length > 0) {
        this.buildPreviousChapterButton();
        this.buildChapterMenuButton();
        this.buildNextChapterButton();
      } else {
        this.removeControlBarButton("PreviousChapterButton");
        this.removeControlBarButton("NextChapterButton");
        this.removeControlBarButton("ChapterMenuButton");
      }
    }
  }
  //--------------------------------------------------------------------
  resumeVideo() {
    if (this.m_VideoInterrupted) this.play();
  }

  private getControlBar() {
    let elements =
      this.m_VideoContainer?.nativeElement.getElementsByClassName(
        "vjs-control-bar"
      );
    if (elements != null && elements.length >= 2) {
      return elements[1];
    } else {
      return null;
    }
  }

  private getVideoContainer() {
    let elements =
      this.m_VideoContainer?.nativeElement.getElementsByClassName("vjs-tech");

    if (elements != null && elements.length >= 1) {
      return elements[0];
    } else {
      return null;
    }
  }

  enableControls() {
    this.m_ControlsEnabled = true;
    this.getControlBar()?.setAttribute("style", "display: flex;");
    let videoContainer = this.getVideoContainer();
    if (videoContainer != null) videoContainer.style.pointerEvents = "all";
  }

  disableControls() {
    this.m_ControlsEnabled = false;
    this.getControlBar()?.setAttribute("style", "display: none;");
    let videoContainer = this.getVideoContainer();
    if (videoContainer != null) videoContainer.style.pointerEvents = "none";
  }

  //--------------------------------------------------------------------
  //Helper method to fetch the list of languages for the dropdown
  private async fetchLanguages() {
    if (!this.m_VideoData?.can_multilang) {
      this.organizeCaptions();
      return;
    } else {
      this.m_HasMultiLanguage = true;
    }
    let currentCaptions: CaptionData[];
    if (this.VideoId) {
      currentCaptions = await this.m_VideoService.getVideoCaptions(
        this.VideoId
      );
    }

    this.m_LanguageList = LANGUAGES.filter((languageCode, index, self) => {
      let languageCodeStr = languageCode.toLowerCase();

      if (
        languageCodeStr === "" ||
        languageCodeStr === "multi" ||
        languageCodeStr === "auto"
      )
        return false; // Remove empty strings

      if (
        currentCaptions.length > 0 &&
        languageCodeStr == currentCaptions[0].language
      ) {
        return false;
      }
      const languageLabel = getLanguageName(languageCodeStr);
      return (
        self.findIndex((lang) => getLanguageName(lang) === languageLabel) ===
        index
      ); // Remove duplicates based on label
    })
      .map((languageCode) => {
        const languageCodeStr = languageCode.toLowerCase();
        const languageValue = languageCodeStr as Language;
        return {
          value: languageValue,
          label: getLanguageName(languageCodeStr),
          default: false,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    this.sortLanguagelist();
    //Define the options for the closed captions menu
    this.updateCCMenuConfig();
  }

  //--------------------------------------------------------------------
  //Get default language and moves it to the top
  sortLanguagelist() {
    if (!this.m_LanguageList) return;
    var defaultLang = this.m_LanguageList.find(
      (item) => item.value == this.m_VideoData?.source_language?.toLowerCase()
    );

    this.m_LanguageList = this.m_LanguageList.filter(
      (item) => item.value !== this.m_VideoData?.source_language?.toLowerCase()
    );
    if (defaultLang) this.m_LanguageList.unshift(defaultLang);
  }

  //--------------------------------------------------------------------
  //If language setted is the same from storage should block it
  async sameLanguage(language: string) {
    if (!this.VideoId || !this.m_VideoPlayer) return;
    let tracks = this.m_VideoPlayer.textTracks;
    return !!tracks.find(
      (track) => track.language.toLowerCase() === language.toLowerCase()
    );
  }
  //--------------------------------------------------------------------
  async onMessage(evt: any) {
    var origin = evt.origin || evt.originalEvent.origin;

    if (origin !== "https://www.videoindexer.ai") return;

    //Validate that the event comes from the videoindexer domain.
    if (evt.data.time !== undefined && this.m_VideoPlayer != null) {
      //Call your player's "jumpTo" implementation.
      this.m_VideoPlayer.currentTime = evt.data.time;
      this.m_VideoPlayer.play();
      //Confirm the arrival to us.
      if ("postMessage" in window) {
        evt.source.postMessage({ confirm: true, time: evt.data.time }, origin);
      }
    } else if (evt.data.refreshTracks !== undefined) {
      //Fired after a user edits the captions
      if (this.m_VideoData && this.m_VideoData.video_id)
        //Tell the backend to update the captions stored
        this.m_VideoService.updateCaptions(this.m_VideoData.video_id);
      this.addCaptionsTrack();
    } else if (evt.data.language !== undefined && this.m_InsightLoaded) {
      const isSameLanguage = await this.sameLanguage(evt.data.language.value);
      if (!isSameLanguage) {
        //Update captions for requested language
        let language: LanguageData = {
          label: evt.data.language.name,
          value: evt.data.language.value,
        };
        this.m_TrackAddedFromEvent = true;
        this.addCaptionsTrack(language);
        this.m_Events.publish(EVENTS.INSIGHT_LANGUAGE_CHANGED, {
          video_id: this.VideoId,
          language: language,
        });
      }
    }
  }
  //--------------------------------------------------------------------
  updateCaptionsFromEvt(tracks: THEOplayer.TextTrack[]) {
    if (!this.m_VideoPlayer) return;

    let tracksFormatted = this.m_VideoPlayer.textTracks.filter((v) => {
      if (isTextTrackMetadata(v.kind)) return false;
      return true;
    });

    tracksFormatted.forEach((track, i) => {
      if (tracks[i] == null || tracks[i].language == null) {
        track.mode = "disabled";
      }

      if (track.language.toLowerCase() === tracks[i].language.toLowerCase()) {
        track.mode = tracks[i].mode;
      }
    });
  }
  //--------------------------------------------------------------------
  addInsightMessages() {
    if (!this.m_MsgEvent) return;
    window.addEventListener("message", this.m_MsgEvent);
    this.m_InsightsLoadedEvent = this.m_Events.subscribe(
      EVENTS.INSIGHT_LOADED,
      () => {
        this.m_InsightLoaded = true;
      }
    );
  }
  //--------------------------------------------------------------------
  removeInsightMessages() {
    if (!this.m_MsgEvent) return;
    this.m_InsightsLoadedEvent?.unsubscribe();
    window.removeEventListener("message", this.m_MsgEvent);
    this.m_InsightLoaded = false;
  }
  //--------------------------------------------------------------------
  setAutoPlay(status: boolean) {
    this.m_AutoPlay = status;
    if (this.m_VideoPlayer != null) this.m_VideoPlayer.autoplay = status;
  }
  //--------------------------------------------------------------------
  async pause(fadeOutAudio?: boolean, interrupted?: boolean) {
    if (interrupted !== undefined) {
      this.m_VideoInterrupted = interrupted ?? false;
    }
    if (fadeOutAudio) await this.fadeOutAudio();
    this.m_VideoPlayer?.pause();
    this.m_Paused = true;
  }
  //--------------------------------------------------------------------
  play() {
    this.m_VideoPlayer?.play();
    this.m_Paused = false;
    this.m_VideoInterrupted = false;
  }
  //--------------------------------------------------------------------
  async organizeCaptions() {
    if (!this.m_VideoPlayer || !this.VideoId) return;

    const textTracks = this.m_VideoPlayer.textTracks.filter(
      (_text) => !isTextTrackMetadata(_text.kind)
    );

    if (this.m_TextTracks != null && this.m_DefaultTrack) {
      this.m_DefaultTrack.mode = "disabled";
      this.m_DefaultTrack = null;
    }

    //Caption selected by Insight Widget
    if (this.m_ShowingCaption == null) {
      let showingCaption = await this.m_VideoService.getShowingCaption(
        this.VideoId
      );

      if (!showingCaption) {
        let Captions = await this.m_VideoService.getVideoCaptions(this.VideoId);
        this.m_ShowingCaption = Captions[0];
      } else {
        this.m_ShowingCaption = showingCaption;
      }
    }

    if (textTracks) {
      textTracks.forEach(async (track) => {
        if (this.m_ShowingCaption) {
          if (
            track.language.toLowerCase() ===
              this.m_ShowingCaption.language.toLowerCase() &&
            this.m_CCEnabled
          ) {
            track.mode = "showing";
          } else {
            track.mode = "disabled";
          }
        } else {
          if (this.m_CCEnabled && !this.m_ShowingCaption) {
            if (track.src === "") {
              track.mode = "showing";
            } else {
              if (!this.VideoId) return;
              const defaultCaption =
                await this.m_VideoService.getDefaultCaption(this.VideoId);
              if (!defaultCaption) {
                track.mode = "showing";
              } else {
                track.mode = "disabled";
              }
            }
          } else {
            track.mode = "disabled";
          }
        }
      });
    }

    setTimeout(async () => {
      await this.saveTrackStorage();
    }, 300);

    //Listen for captions refresh events
    this.m_CaptionsChangedEvent = (event: THEOplayer.TrackChangeEvent) => {
      this.onTrackChange(event);
    };

    this.m_VideoPlayer?.textTracks.addEventListener(
      "change",
      this.m_CaptionsChangedEvent
    );

    this.updateCCMenuConfig();
  }
  //--------------------------------------------------------------------
  async onVideoPlayerReady(onReadyCallback: Function) {
    if (this.m_RefreshingTracks) {
      this.onCaptionsRefresh();
      return;
    }

    onReadyCallback();

    let vjsTech =
      this.m_VideoContainer?.nativeElement.querySelector(".vjs-tech");
    if (vjsTech != null) {
      let timeout: any;
      let lastTap = 0;

      let touchHandler = (evt: any) => {
        let currentTime = new Date().getTime();
        let tapLength = currentTime - lastTap;
        clearTimeout(timeout);
        if (tapLength < 500 && tapLength > 0) {
          lastTap = 0;
          evt.preventDefault();

          let left = evt.target.getBoundingClientRect().left;
          let position = evt.changedTouches[0].clientX - left;
          let relativePosition = position / evt.target.offsetWidth - 0.5;
          this.onPlayerDoubleTouch(relativePosition);
        } else {
          timeout = setTimeout(() => {
            lastTap = 0;
            clearTimeout(timeout);

            this.onPlayerTouch();
          }, 500);
          lastTap = currentTime;
        }
      };

      this.m_TouchEndEvent = vjsTech.addEventListener("touchend", touchHandler);

      this.m_DblClickEvent = vjsTech.addEventListener(
        "dblclick",
        (evt: any) => {
          if (this.m_VideoPlayer != null) {
            let relativePosition = evt.offsetX / evt.target.offsetWidth - 0.5;
            let timeToAdd = Math.sign(relativePosition) * this.SEEK_TIME;
            this.m_VideoPlayer.currentTime += timeToAdd;
          }
        }
      );

      let settingsButton = document.querySelector(
        ".theo-settings-control-button"
      );
      if (settingsButton != null) {
        this.m_SettingsClickedEvent = (evt: Event) => {
          if (this.m_VideoPlayer != null) {
            this.hideCaptionsContainer();
          }
        };
        settingsButton.addEventListener("click", this.m_SettingsClickedEvent);
        settingsButton.addEventListener(
          "touchend",
          this.m_SettingsClickedEvent
        );
      }

      let dubButton = document.querySelector(
        ".theo-audio-track-control-button"
      );
      if (dubButton != null) {
        this.m_DubClickedEvent = (evt: Event) => {
          if (this.m_VideoPlayer != null) {
            this.hideCaptionsContainer();
          }
        };
        dubButton.addEventListener("click", this.m_DubClickedEvent);
        dubButton.addEventListener("touchend", this.m_DubClickedEvent);
      }

      if (this.m_KeyDownEvent == null) {
        this.m_KeyDownEvent = (evt: any) => {
          let tagName = evt.target.tagName.toLowerCase();
          if (tagName == "input" || tagName == "textarea") {
            return;
          }

          if (this.m_VideoPlayer != null) {
            if (evt.key == "ArrowLeft") {
              this.m_VideoPlayer.currentTime -= this.SEEK_TIME;
            } else if (evt.key == "ArrowRight") {
              this.m_VideoPlayer.currentTime += this.SEEK_TIME;
            }
          }
        };
        document.addEventListener("keydown", this.m_KeyDownEvent);
      }

      //Organize captions
      setTimeout(() => {
        this.organizeCaptions();
      }, 300);
    }

    //Run all settings
    if (this.m_UseCachedTime) {
      this.getVideoSettings();
    }

    if (this.m_VideoPlayer != null) {
      if (this.m_Segment != null) {
        this.m_VideoPlayer.clip.startTime = this.m_Segment.start;
        this.m_VideoPlayer.clip.endTime = this.m_Segment.end;
      }
      if (this.m_StartTime != null) {
        this.m_VideoPlayer.currentTime = this.m_StartTime;
      }
    }

    //if (!this.m_Paused) this.play();

    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1);
    });

    let videoElement = this.getTheoVideoElement();
    if (videoElement != null) {
      //Build markers
      videoElement.onerror = () => {
        console.error(`Error with media: ${videoElement?.error?.code}`);

        if (
          videoElement?.error?.code == MediaError.MEDIA_ERR_NETWORK ||
          videoElement?.error?.code == MediaError.MEDIA_ERR_DECODE
        ) {
          this.errorHandler({
            error: "MEDIA_ERR_NETWORK",
            errorObject: {
              code: THEOplayer.ErrorCode.MEDIA_LOAD_ERROR,
              category: THEOplayer.ErrorCategory.MEDIA,
              cause: undefined,
              name: "MEDIA_ERR_NETWORK",
              message: "MEDIA_ERR_NETWORK",
            },
            type: "error",
            date: new Date(),
          });
        }
      };
    }
  }
  //--------------------------------------------------------------------
  onPlayerTouch() {
    if (this.m_ControlsEnabled) {
      if (this.m_Paused) this.play();
      else this.pause();
      if (this.m_OpenOverlayMenu) this.m_OpenOverlayMenu = false;
    }
  }
  //--------------------------------------------------------------------
  onPlayerDoubleTouch(relativePosition: number) {
    let timeToAdd = Math.sign(relativePosition) * this.SEEK_TIME;
    if (this.m_VideoPlayer != null) {
      this.m_VideoPlayer.currentTime += timeToAdd;
    }
  }
  //--------------------------------------------------------------------
  addCCButtonToPlayer() {
    if (!this.m_VideoPlayer) return;
    if (this.getControlBarButton("HIASubtitlesButton") != null) return;

    let ccBtnConfg: HIATheoButtonConfig = {
      name: "HIASubtitlesButton",
      customClass: `closed-captions-button vjs-button cc-button-${this.m_PlayerUID}`,
      tooltip: this.$t("components.videoPlayer.subtitles"),
      handler: () => {
        this.m_OpenOverlayMenu = !this.m_OpenOverlayMenu;
        this.m_ActiveMenuConfig = this.m_OpenOverlayMenu
          ? this.m_CCMenuConfig
          : null;
        let settingsButton = document.querySelector(
          ".theo-settings-control-button"
        ) as HTMLElement;

        let settingsMenu = document.querySelector(
          ".theo-settings-control-menu"
        );
        if (
          this.m_OpenOverlayMenu &&
          settingsButton &&
          !settingsMenu?.classList.contains("vjs-hidden")
        ) {
          settingsButton.click();
          this.m_OpenOverlayMenu = true;
          this.m_ActiveMenuConfig = this.m_CCMenuConfig;
        }

        let dubButton = document.querySelector(
          ".theo-audio-track-control-button"
        ) as HTMLElement;

        let dubMenu = document.querySelector(".theo-menu-animatable.theo-menu");
        if (
          this.m_OpenOverlayMenu &&
          dubButton &&
          !dubMenu?.classList.contains("vjs-hidden")
        ) {
          dubButton.click();
          this.m_OpenOverlayMenu = true;
          this.m_ActiveMenuConfig = this.m_CCMenuConfig;
        }
      },
    };

    addButtonToPlayer(this.m_VideoPlayer, ccBtnConfg);
    //Creates a second button for mobile view
    let ccMobileBtnConfg: HIATheoButtonConfig = ccBtnConfg;
    ccMobileBtnConfg.name = "SubtitlesButtonMobile";
    ccMobileBtnConfg.customClass = `closed-captions-button closed-captions-button-mobile vjs-button mobile-cc-${this.m_PlayerUID}`;
    addButtonToPlayer(this.m_VideoPlayer, ccMobileBtnConfg);
    //Adds mobile button to top bar
    var topBar = document.querySelector(
      ".theo-top-controlbar.theo-secondary-color.vjs-control-bar"
    );
    let buttonElement = document.querySelector(
      `.mobile-cc-${this.m_PlayerUID}`
    );
    if (topBar && buttonElement) {
      buttonElement.classList.add("mobile");
      topBar.appendChild(buttonElement);
    }

    //Removes default button
    var defaultButton = document.querySelector(".vjs-icon-subtitles");
    defaultButton?.parentNode?.removeChild(defaultButton);
  }
  //--------------------------------------------------------------------
  onCaptionsChanged(language: LanguageData | null) {
    if (!language) {
      //Toggles the "None" button to the menu CC menu
      this.toggleCaptions(true);
      this.updateCCMenuConfig();
      if (this.m_CCMenuConfig.options[0].text == "None")
        this.m_CCMenuConfig.options.splice(0, 1);
      return;
    } else if (
      this.m_CCMenuConfig.options[0].text != "None" &&
      this.m_ShowingCaption?.mode == "disabled"
    ) {
      var emptyOption = {
        icon: "language",
        text: "None",
        handler: () => this.onCaptionsChanged(null),
        selected: false,
      };
      this.m_CCMenuConfig.options.unshift(emptyOption);
    }
    if (
      this.m_ShowingCaption &&
      this.m_ShowingCaption.language === language.value &&
      this.m_ShowingCaption.mode === "showing"
    ) {
      //If the selected language is the same as the showing caption, hide the captions
      this.toggleCaptions();
      this.updateCCMenuConfig();
      return;
    }

    this.addCaptionsTrack(language);
    this.setCCValue(true);
    // this.onCaptionsRefresh();
    this.hideCaptionsContainer();
    if (this.m_ShowingCaption) {
      let currentcaption: LanguageData = {
        label: this.m_ShowingCaption.label,
        value: this.m_ShowingCaption.language,
        default: false,
      };
      this.m_LanguageList?.push(currentcaption);
      this.m_LanguageList?.sort((a, b) => a.label.localeCompare(b.label));
    }

    this.m_ShowingCaption = {
      mode: "showing",
      label: language.label,
      language: language.value,
      default: false,
    };

    this.m_LanguageList = this.m_LanguageList?.filter(
      (lang) => lang.value != this.m_ShowingCaption?.language
    );

    this.sortLanguagelist();

    //Update CC menu config to show the selected language
    this.updateCCMenuConfig();
  }
  //--------------------------------------------------------------------
  setCurrentTime(newTime: number) {
    if (this.m_VideoPlayer) {
      this.m_VideoPlayer.currentTime = newTime;
    }
  }
  //--------------------------------------------------------------------
  toggleCaptions(setValue = false) {
    if (this.m_VideoPlayer == null) return;
    if (setValue) {
      for (let i = 0; i < this.m_VideoPlayer.textTracks.length; i++) {
        this.m_VideoPlayer.textTracks[i].mode = "disabled";
      }
      this.setCCValue(false);
      if (this.m_ShowingCaption) this.m_ShowingCaption.mode = "disabled";

      this.hideCaptionsContainer();
      return;
    }

    let showingTrack = this.m_VideoPlayer?.textTracks.find(
      (track) => track.mode == "showing"
    );
    if (showingTrack) {
      for (let i = 0; i < this.m_VideoPlayer.textTracks.length; i++) {
        this.m_VideoPlayer.textTracks[i].mode = "disabled";
      }
      this.setCCValue(false);
    } else {
      for (let i = 0; i < this.m_VideoPlayer.textTracks.length; i++) {
        this.m_VideoPlayer.textTracks[i].mode = "showing";
      }
      this.setCCValue(true);
    }

    if (this.m_ShowingCaption)
      this.m_ShowingCaption.mode = showingTrack ? "disabled" : "showing";

    this.hideCaptionsContainer();
  }

  //--------------------------------------------------------------------
  hideCaptionsContainer() {
    this.m_OpenOverlayMenu = false;
  }
  //--------------------------------------------------------------------
  /**
   * Builds the video player and appends it to the video-container div
   * @param srcURL
   */
  async buildVideoPlayer(
    video: VIDEO,
    textTracks?: Array<any> | null,
    showingCaption?: CaptionData | null,
    segment?: { start: number; end: number } | null,
    startTime?: number,
    useCachedTime: boolean = true,
    onReady?: Function
  ) {
    this.m_VideoData = video;
    this.m_TextTracks = textTracks;
    this.m_ShowingCaption = showingCaption;
    this.m_Segment = segment;
    this.m_StartTime = startTime;
    this.m_UseCachedTime = useCachedTime;
    let srcURL = video.video_url;

    if (onReady == null) {
      onReady = () => {};
    }

    let newTrack: any = [];
    newTrack.push({
      kind: "metadata",
      label: "thumbnails",
      src: video.timeline_vtt_url ?? "",
      default: true,
    });

    let captions: CaptionData[] = [];
    await this.fetchLanguages();

    if (textTracks == null && this.VideoId) {
      captions = await this.m_VideoService.getVideoCaptions(this.VideoId);
    }

    if (srcURL == null || srcURL == "") return;

    //If the player hasn't been created yet, create it
    if (this.m_VideoPlayer == null) {
      this.m_AttemptCount++;
      let token = this.m_PlayerConfig.TOKEN;

      let config: THEOplayer.PlayerConfiguration = {
        license: token,
        libraryLocation: "assets/theoplayer",
        isEmbeddable: true,
      };

      //Check if muted autoplay is enabled in the player config
      let enabledMutedAutoplay = this.m_PlayerConfig.ENABLE_MUTED_AUTOPLAY;
      if (enabledMutedAutoplay) {
        config.mutedAutoplay = "all" as THEOplayer.MutedAutoplayConfiguration;
      }

      if (!this.m_AutoPlay) this.m_Paused = true;

      this.m_RenderContainer = true;

      this.m_Cdr.detectChanges();

      //Create the player
      this.m_VideoPlayer = new THEOplayer.Player(
        this.m_VideoContainer?.nativeElement,
        config
      );

      this.setupVideoPlayerEvents();

      //Set the poster image if available
      if (this.m_VideoData.video_thumb_url) {
        this.m_VideoPlayer.poster = this.m_VideoData.video_thumb_url;
      }

      //Set additional player settings
      let preloadDirective =
        this.m_OverridePreload ?? this.m_PlayerConfig.PRELOAD_DIRECTIVE;

      this.m_VideoPlayer.preload = preloadDirective;
      this.m_VideoPlayer.autoplay = this.m_AutoPlay;
      this.m_VideoPlayer.addEventListener(
        "loadedmetadata",
        (event: THEOplayer.LoadedMetadataEvent) => {
          if (this.m_VideoSegment) {
            if (this.m_VideoSegment.componentContainer)
              this.m_VideoSegment.initializeSegments();
            this.m_VideoSegment.updateRangeMarkers();
          }
          if (event.readyState == 1) this.onVideoPlayerReady(onReady!);
          //TODO: Handle error
        }
      );
      this.addCCButtonToPlayer();
    }

    //Search the description for chapter timestamps
    let description = video.video_description;
    if (description && description.length > 0) {
      let chapters: ChapterMap = parseTimestampsFromString(description);
      if (chapters && Object.keys(chapters).length > 0) {
        let vttURL = generateChaptersVTT(chapters, video.video_duration ?? 0);
        this.updateChapterMenuConfig(chapters);

        if (vttURL) {
          newTrack.push({
            kind: "chapters",
            label: "chapters",
            src: vttURL,
            default: true,
            srcLang: "en",
          });

          this.m_ChapterTimes = getChapterTimes(chapters);

          this.buildPreviousChapterButton();
          this.buildChapterMenuButton();
          this.buildNextChapterButton();
        }
      }
    }

    //Set caption if has setting language on storage
    if (captions.length) {
      for (let i = 0; i < captions.length; i++) {
        if (!this.VideoId) return;

        const defaultCaption = await this.m_VideoService.getDefaultCaption(
          this.VideoId
        );

        if (
          defaultCaption &&
          defaultCaption.language.toLowerCase() ===
            captions[i].language.toLowerCase() &&
          defaultCaption.default
        ) {
          continue;
        }

        let captionsObj: CAPTIONS | null = null;
        try {
          captionsObj = await this.fetchCaptions(captions[i].language);
        } catch (error) {
          console.log(error);
        }

        if (captionsObj == null) continue;

        let track = captionsObj.toTheoTrack();
        track.default = !defaultCaption;

        let tracks = newTrack.filter((_track: any) => {
          !isTextTrackMetadata(_track.kind);
        });
        const isOnTrack = !!tracks.find(
          (_track: any) =>
            _track.srclang.toLowerCase() === captions[i].language.toLowerCase()
        );
        const isSameTrackDefault = !!captions.find(
          (_track) =>
            _track.default &&
            _track.language.toLowerCase() === captions[i].language.toLowerCase()
        );
        if (isOnTrack || isSameTrackDefault) return;

        newTrack.push(track);
      }
    } else {
      //Use the source language if no captions are available
      let language = this.m_VideoData.source_language;
      if (language) {
        let srcCaptions: CAPTIONS | null = null;
        try {
          srcCaptions = await this.fetchCaptions(language);
        } catch (error) {
          console.log(error);
        }

        if (srcCaptions != null) {
          let trackDescription = srcCaptions.toTheoTrack();
          trackDescription.default = true;
          newTrack.push(trackDescription);
        }
      }
    }

    //Set the video source and text tracks
    this.m_VideoSrc = srcURL;
    this.m_VideoPlayer.source = {
      sources: [
        {
          src: srcURL,
          type: "application/x-mpegurl",
        },
      ],
      textTracks: textTracks ?? newTrack,
      vr: {
        360: video.is_360_video,
      },
    };

    //Get the cc setting from storage and set it
    let showCC = await this.m_StorageService.get("show_cc");
    if (showCC == null || showCC == false) {
      this.disableTextTracks();
    }
    this.m_CCEnabled = showCC ?? false;
  }

  //--------------------------------------------------------------------
  errorHandler(event: THEOplayer.ErrorEvent) {
    //Throw error on 3 attempts
    if (this.m_AttemptCount === 3) {
      this.m_AttemptCount = 1;
      this.publishEvent(
        {
          event: EVENTS.VIDEO_ERROR,
          data: { error: event.errorObject.message },
        },
        false
      );
      return;
    }

    if (this.m_AttemptCount < 3 && event.errorObject.code) {
      this.dispose();
      this.m_ShowContainer = false;

      //Wait dispose
      setTimeout(() => {
        if (!this.m_VideoData) return;

        this.m_ShowContainer = true;

        setTimeout(() => {
          if (!this.m_VideoData) return;

          this.buildVideoPlayer(
            this.m_VideoData,
            this.m_TextTracks,
            this.m_ShowingCaption,
            this.m_Segment,
            this.m_VideoPlayer?.currentTime,
            this.m_UseCachedTime
          );
        }, 100);
      }, 500);
    }
  }
  //--------------------------------------------------------------------
  async getVideoSettings() {
    if (!this.VideoId) return;
    const settings = await this.m_VideoService.handleVideoSettingsGet(
      this.VideoId
    );
    if (settings && this.m_VideoPlayer) {
      if (
        this.m_QvioViewMode == QvioViewMode.WATCH &&
        this.m_StartTime == null
      ) {
        let startTimeFromVideoData = this.getStartTimeFromVideoData();
        if (startTimeFromVideoData != null) {
          //Set current time of video data and previous RESUME_PADDING seconds
          this.m_VideoPlayer.currentTime =
            startTimeFromVideoData - this.m_PlayerConfig.RESUME_PADDING;
        } else if (settings.duration) {
          //Set current time of storage and previous RESUME_PADDING seconds
          this.m_VideoPlayer.currentTime =
            settings.duration - this.m_PlayerConfig.RESUME_PADDING;
        }
      }
    }
  }
  //--------------------------------------------------------------------
  async addCurrentTimeStorage() {
    if (!this.m_VideoPlayer || !this.VideoId) return;
    const TOTAL_DURATION = this.m_VideoPlayer.duration;
    const CURRENT_DURATION = this.m_VideoPlayer.currentTime;
    const MARGIN_TO_STORAGE = 5;

    //Check if video is currently 5 seconds after started or 5 seconds to finish
    if (
      CURRENT_DURATION > TOTAL_DURATION - MARGIN_TO_STORAGE ||
      CURRENT_DURATION < MARGIN_TO_STORAGE
    ) {
      return this.m_VideoService.handleVideoSettingsValue(
        this.VideoId,
        "duration",
        0
      );
    }

    //Set duration on storage
    return await this.m_VideoService.handleVideoSettingsValue(
      this.VideoId,
      "duration",
      CURRENT_DURATION
    );
  }
  //--------------------------------------------------------------------
  publishTracksState() {
    if (!this.m_VideoPlayer) return;
    let tracks = this.m_VideoPlayer.textTracks.filter(
      (track: any) => !isTextTrackMetadata(track.kind)
    );
    if (tracks.length) {
      this.publishEvent({ event: EVENTS.UPDATE_CC_STATE, data: tracks }, true);
    }
  }
  //--------------------------------------------------------------------
  updateRangeMarkers(newStartTime: number, newEndTime: number) {
    if (!this.m_VideoSegment) return;
    this.m_VideoSegment.updateNewRangeMarkers(newStartTime, newEndTime);
  }
  //--------------------------------------------------------------------
  createRangeMarkers(
    startTime: number,
    endTime: number,
    onUpdateMarker: Function
  ) {
    if (!this.m_VideoSegment) return;
    this.m_VideoSegment.createRangeMarkers(
      startTime,
      endTime,
      (start: number, end: number) => {
        const timeEvent: SegmentEvent = {
          startTime: start,
          endTime: end,
        };

        onUpdateMarker(start, end);
      }
    );
  }
  //--------------------------------------------------------------------
  ngOnDestroy() {
    this.dispose();
    this.m_RenderContainer = false;
    this.m_VideoStarted = false;
    if (this.m_WindowResizeEvent != null)
      window.removeEventListener("resize", this.m_WindowResizeEvent);
    if (this.m_VisibilityChangeEvent != null)
      document.removeEventListener(
        "visibilitychange",
        this.m_VisibilityChangeEvent
      );

    this.m_OnTTSStart?.unsubscribe();
    this.m_UpdateThumbnailEvent?.unsubscribe();
    this.m_RouterEvent?.unsubscribe();
    this.m_AttachmentShowEvent?.unsubscribe();
    this.m_UpdateCCStateEvent?.unsubscribe();
  }
  //--------------------------------------------------------------------
  dispose() {
    let videoElement = this.getTheoVideoElement();
    if (videoElement != null) {
      videoElement.onerror = null;
    }

    if (this.m_VideoPlayer != null) {
      this.publishTracksState();
      this.unsubscribeFromVideoPlayerEvents();

      this.m_VideoPlayer.destroy();
      this.m_VideoPlayer = null;
    }
  }
  //--------------------------------------------------------------------
  seekVideoTime(timeInSeconds: number) {
    if (this.m_VideoPlayer != null) {
      this.m_VideoPlayer.currentTime = timeInSeconds;
      this.play();
    }
  }

  //--------------------------------------------------------------------
  //#region Render helpers
  getButtonName(name: string) {
    return `${name}_${this.m_VideoData?.video_id ?? ""}`;
  }
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods
  private setupVideoPlayerEvents() {
    this.m_VideoPlayEvent = (event: THEOplayer.PlayEvent) => {
      this.onVideoPlayEvent(event);
    };

    this.m_VideoPlayer?.addEventListener("play", this.m_VideoPlayEvent);

    //Error handler event
    this.m_ErrorHandlerEvent = (event: THEOplayer.ErrorEvent) => {
      console.error("Media error: " + event);

      this.errorHandler(event);
    };

    this.m_VideoPlayer?.addEventListener("error", this.m_ErrorHandlerEvent);

    this.m_VideoPauseEvent = (event: THEOplayer.PauseEvent) => {
      if (this.m_VideoPlayer == null) return;
      this.m_Paused = true;
      //Publish time update event for other components to listen to
      this.publishEvent(
        {
          event: EVENTS.PLAYER_PAUSE,
          data: { time: event.currentTime },
        },
        false
      );
    };

    this.m_VideoPlayer?.addEventListener("pause", this.m_VideoPauseEvent);

    this.m_VideoSeekStartEvent = (event: THEOplayer.SeekingEvent) => {
      if (this.m_VideoPlayer == null) return;
      //Publish time update event for other components to listen to
      this.publishEvent(
        {
          event: EVENTS.PLAYER_SEEK_START,
          data: { time: event.currentTime },
        },
        false
      );
    };

    this.m_VideoPlayer?.addEventListener("seeking", this.m_VideoSeekStartEvent);

    this.m_VideoSeekEndEvent = (event: THEOplayer.SeekedEvent) => {
      if (this.m_VideoPlayer == null) return;
      //Publish time update event for other components to listen to
      this.publishEvent(
        {
          event: EVENTS.PLAYER_SEEK_END,
          data: { time: event.currentTime },
        },
        false
      );
    };

    this.m_VideoPlayer?.addEventListener("seeked", this.m_VideoSeekEndEvent);

    this.m_VideoEndedEvent = (event: THEOplayer.EndedEvent) => {
      if (this.m_VideoPlayer == null) return;
      //Publish time update event for other components to listen to
      this.publishEvent(
        {
          event: EVENTS.PLAYER_ENDED,
          data: { time: event.currentTime },
        },
        false
      );
    };

    this.m_VideoPlayer?.addEventListener("ended", this.m_VideoEndedEvent);

    this.m_VideoTimeUpdateEvent = (event: THEOplayer.TimeUpdateEvent) => {
      if (this.m_VideoPlayer == null) return;
      //Publish time update event for other components to listen to
      this.publishEvent(
        {
          event: EVENTS.PLAYER_TIME_UPDATE,
          data: { time: event.currentTime },
        },
        false
      );
    };

    this.m_VideoPlayer?.addEventListener(
      "timeupdate",
      this.m_VideoTimeUpdateEvent
    );

    //Listen for presentation mode change events
    this.m_PresentationModeChangeEvent = (
      presChange: THEOplayer.PresentationModeChangeEvent
    ) => {
      this.onPresentationModeChange();
    };

    (this.m_VideoPlayer?.presentation as any).addEventListener(
      "presentationmodechange",
      this.m_PresentationModeChangeEvent
    );

    this.m_CaptionTrackAddedEvent = (event: THEOplayer.AddTrackEvent) => {
      this.onTrackAdded(event);
    };

    this.m_VideoPlayer?.textTracks.addEventListener(
      "addtrack",
      this.m_CaptionTrackAddedEvent
    );

    //Listen for volume change events
    this.m_VolumeChangeEvent = (event: THEOplayer.VolumeChangeEvent) => {
      this.onVolumeChange(event);
    };

    this.m_VideoPlayer?.addEventListener(
      "volumechange",
      this.m_VolumeChangeEvent
    );
  }

  private buildPreviousChapterButton() {
    if (this.getControlBarButton("PreviousChapterButton") != null) return;

    addButtonToPlayer(this.m_VideoPlayer, {
      name: "PreviousChapterButton",
      customClass: "previous-chapter-button vjs-control vjs-button",
      tooltip: this.$t("components.videoPlayer.previousChapter"),
      handler: () => {
        this.goToPreviousChapter();
      },
      customIcon: "play-skip-back",
      customOrder: -100,
    });
  }

  private buildNextChapterButton() {
    if (this.getControlBarButton("NextChapterButton") != null) return;

    addButtonToPlayer(this.m_VideoPlayer, {
      name: "NextChapterButton",
      customClass: "next-chapter-button vjs-control vjs-button",
      tooltip: this.$t("components.videoPlayer.nextChapter"),
      handler: () => {
        this.goToNextChapter();
      },
      customIcon: "play-skip-forward",
      customOrder: -100,
    });
  }

  private buildChapterMenuButton() {
    if (this.getControlBarButton("ChapterMenuButton") != null) return;

    addButtonToPlayer(this.m_VideoPlayer, {
      name: "ChapterMenuButton",
      customClass: "chapter-menu-button vjs-control vjs-button",
      tooltip: this.$t("components.videoPlayer.chapters"),
      handler: () => {
        this.m_OpenOverlayMenu = !this.m_OpenOverlayMenu;
        this.m_ActiveMenuConfig = this.m_ChaptersMenuConfig;
      },
      customIcon: "book-outline",
      customOrder: -100,
    });
  }

  private getControlBarButton(componentName: string) {
    let controlBar = (this.m_VideoPlayer as any)["ui"].getChild("controlBar");
    return controlBar?.getChild(componentName);
  }

  private removeControlBarButton(componentName: string) {
    let controlBar = (this.m_VideoPlayer as any)["ui"].getChild("controlBar");
    return controlBar?.removeChild(componentName);
  }

  private goToPreviousChapter() {
    if (this.m_VideoPlayer != null) {
      if (this.m_ChapterTimes != null) {
        for (let time of this.m_ChapterTimes.slice().reverse()) {
          if (time < this.m_VideoPlayer.currentTime) {
            this.m_VideoPlayer.currentTime = time;
            break;
          }
        }
      }
    }
  }

  private goToNextChapter() {
    if (this.m_VideoPlayer != null) {
      if (this.m_ChapterTimes != null) {
        for (let time of this.m_ChapterTimes) {
          if (time > this.m_VideoPlayer.currentTime) {
            this.m_VideoPlayer.currentTime = time;
            break;
          }
        }
      }
    }
  }

  //Called when we need to add an updated captions track to the video player
  //Fetches the captions from the video service and then adds them to the player
  private async addCaptionsTrack(language?: LanguageData) {
    if (this.m_VideoData == null || this.m_VideoPlayer == null) return;

    //Get captions
    try {
      if (!this.VideoId) return;
      this.m_RefreshingTracks = true;

      //Fetch the updated captions for the local session
      let captions = await this.fetchCaptions(language?.value);
      //Get caption storaged selected by Insight Widget
      let defaultCaption = await this.m_VideoService.getDefaultCaption(
        this.VideoId
      );

      if (captions == null) return;
      let newTrack = captions.toTheoTrack();
      if (defaultCaption === null) newTrack.default = true;

      let chaptersTrack = this.m_VideoPlayer.textTracks.find(
        (track) => track?.kind == "chapters"
      );

      this.m_TimeToRefresh = this.m_VideoPlayer.currentTime;
      //Currently we have to reset the source to add a new track
      //TODO ask THEO if we can add and remove tracks without resetting the source
      this.m_VideoPlayer.source = {
        sources: [
          {
            src: this.m_VideoSrc,
            type: "application/x-mpegurl",
          },
        ],
        textTracks: [
          newTrack,
          chaptersTrack as THEOplayer.TextTrackDescription,
        ],
      };
    } catch (error) {
      //Failed to get and set captions
      console.log(error);
      this.m_RefreshingTracks = false;
    }
  }

  //Called when the captions are refreshed via an edit,
  //this force enables our new track and then seeks to the time the video was at before the refresh
  private onCaptionsRefresh() {
    this.m_RefreshingTracks = false;

    setTimeout(async () => {
      if (this.m_VideoPlayer == null || !this.VideoId) return;
      //Remove any existing caption tracks
      let tracks = this.m_VideoPlayer.textTracks;
      for (let i = 0; i < tracks.length; i++) {
        if (!isTextTrackMetadata(tracks[i].kind)) {
          let track = tracks[i];
          track.mode = "disabled";
        }
      }
      this.m_VideoPlayer.textTracks[0].mode = "showing";

      let wasPaused = this.m_Paused;
      //If the video was paused, play it and then pause it again to force the captions to show
      this.play();
      if (wasPaused) this.pause();
      let playStartedEvt = (evt: THEOplayer.PlayEvent) => {
        if (this.m_VideoPlayer == null) return;
        this.m_VideoPlayer.removeEventListener("play", playStartedEvt);
        this.m_VideoPlayer.currentTime = this.m_TimeToRefresh;
      };
      this.m_VideoPlayer.addEventListener("play", playStartedEvt);

      //Added a new track
      if (this.m_TrackAddedFromEvent) {
        this.m_TrackAddedFromEvent = false;
      }
    }, 100);
  }

  /**
   * Adds a text track to the video player while maintaining the existing tracks
   * @param track
   * @returns
   */
  private addTextTrack(track: THEOplayer.TextTrackDescription) {
    if (this.m_VideoPlayer == null) return;
    this.m_RefreshingTracks = true;

    try {
      let tracks = this.m_VideoPlayer.textTracks;
      let newTracks = [];

      //Add all the existing tracks except the one we are adding
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].label != track.label) {
          newTracks.push(tracks[i]);
        }
      }

      //Add the new track
      newTracks.push(track);

      //Set the new tracks
      this.m_TimeToRefresh = this.m_VideoPlayer.currentTime;
      //Currently we have to reset the source to add a new track
      //TODO ask THEO if we can add and remove tracks without resetting the source
      this.m_VideoPlayer.source = {
        sources: [
          {
            src: this.m_VideoSrc,
            type: "application/x-mpegurl",
          },
        ],
        textTracks: newTracks,
      };
    } catch (error) {
      console.log(error);
      this.m_RefreshingTracks = false;
    }
  }

  //Called when the presentation mode changes (fullscreen, inline, etc)
  private onPresentationModeChange() {
    if (this.m_VideoPlayer == null) return;
    let isFullscreen =
      this.m_VideoPlayer.presentation.currentMode == "fullscreen";

    this.m_IsFullscreen = isFullscreen;
    this.m_OpenOverlayMenu = false;
    this.publishEvent(
      { event: EVENTS.PLAYER_FULLSCREEN, data: isFullscreen },
      true
    );
  }

  //Disables all text tracks
  private disableTextTracks() {
    if (this.m_VideoPlayer == null) return;
    let tracks = this.m_VideoPlayer.textTracks;
    for (let i = 0; i < tracks.length; i++) {
      if (!isTextTrackMetadata(tracks[i].kind)) {
        let track = tracks[i];
        track.mode = "disabled";
      }
    }
  }

  /**
   * Saves the current state of the captions tracks to the storage service
   * @returns
   */
  private async saveTrackStorage() {
    if (!this.m_VideoPlayer || !this.VideoId) return;

    let tracks = this.m_VideoPlayer.textTracks
      .filter((v) => {
        if (isTextTrackMetadata(v.kind)) return false;
        return true;
      })
      .map((track) => {
        if (!this.VideoId) return;
        return {
          default: track.src === "",
          language: track.language,
          label: track.label,
          mode: track.mode,
        };
      });

    //Check if tracks has any caption showing
    const hasCCEnabled = tracks.find(
      (track) => track && track.mode === "showing"
    );
    this.setCCValue(!!hasCCEnabled);

    //Update - Add states of tracks on storage
    await this.m_VideoService.setCaptionValue(this.VideoId, tracks);
  }

  //Called when the captions track changes, updates the storage service with the current state
  private async onTrackChange(event: THEOplayer.TrackChangeEvent) {
    if (!this.m_VideoPlayer || this.m_TrackAddedFromEvent) return;

    if (!isTextTrackMetadata(event.track.kind)) {
      this.saveTrackStorage();
    }
  }

  //Set CC value
  private setCCValue(value: boolean) {
    this.m_CCEnabled = value;
    this.m_StorageService.set("show_cc", this.m_CCEnabled);
  }

  //Called when a new captions track is added, disables the track if captions are disabled
  private async onTrackAdded(event: THEOplayer.AddTrackEvent) {
    if (!this.VideoId || isTextTrackMetadata(event.track.kind)) return;
    let track: any = event.track;

    //Remove 'captions' from label
    if (track.label.includes("Captions")) {
      track.label = track.label.replace(" Captions", "");
    }

    //Remove default track of attachment video
    if (this.m_TextTracks != null && track.src === "") {
      this.m_DefaultTrack = track;
      let tracks: any = this.m_VideoPlayer?.textTracks;
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].uid === this.m_DefaultTrack.uid) {
          tracks.splice(i, 1);
        }
      }
    }
  }

  private async fadeOutAudio(): Promise<void> {
    //If we are already fading out audio, return
    if (this.m_AudioIsFaded || this.m_VideoPlayer?.volume == 0) return;
    this.m_AudioIsFaded = true;
    let currVolume = this.m_VideoPlayer?.volume ?? 1;
    this.m_LastVolume = currVolume;
    //Fade out the audio on an interval and return when it is done
    return new Promise((resolve) => {
      let fadeOutInterval = setInterval(() => {
        currVolume -= 0.1;
        if (currVolume <= 0) {
          if (this.m_VideoPlayer != null) this.m_VideoPlayer.volume = 0;
          clearInterval(fadeOutInterval);
          resolve();
        } else {
          if (this.m_VideoPlayer != null)
            this.m_VideoPlayer.volume = currVolume;
        }
      }, 100);
    });
  }

  private onVolumeChange(event: THEOplayer.VolumeChangeEvent) {
    if (this.m_AudioIsFaded) return;
    this.m_LastVolume = event.volume;
  }

  private onVideoPlayEvent(event: THEOplayer.PlayEvent) {
    if (this.m_VideoPlayer == null) return;
    this.m_VideoStarted = true;
    this.m_Paused = false;
    this.m_OpenOverlayMenu = false;
    //Publish time update event for other components to listen to
    this.publishEvent(
      { event: EVENTS.PLAYER_PLAY, data: { time: event.currentTime } },
      true
    );
    if (this.m_AudioIsFaded) {
      this.m_VideoPlayer.volume = this.m_LastVolume;
      this.m_AudioIsFaded = false;
    }
  }

  /**
   * Publishes an event to the player event emitter and optionally to the global event emitter
   * @param event
   * @param globally
   */
  private publishEvent(event: PlayerEvent, globally: boolean = false) {
    this.OnPlayerEvent.emit(event);
    if (globally) this.m_Events.publish(event.event, event.data);
  }

  /**
   * Returns video start time from VideoData if it's valid, otherwise returns null
   */
  private getStartTimeFromVideoData(): number | null {
    let startTime = this.VideoData?.current_position;
    let duration = this.VideoData?.video_duration ?? 0;
    if (
      startTime != null &&
      !isNaN(startTime) &&
      startTime > 0 &&
      startTime < duration
    ) {
      return startTime;
    } else {
      return null;
    }
  }

  //Helper function that only unsubscribes from caption events
  private unsubscribeFromCaptionEvents() {
    if (this.m_CaptionsChangedEvent != null) {
      this.m_VideoPlayer?.textTracks.removeEventListener(
        "change",
        this.m_CaptionsChangedEvent
      );
    }

    if (this.m_CaptionTrackAddedEvent != null) {
      this.m_VideoPlayer?.textTracks.removeEventListener(
        "addtrack",
        this.m_CaptionTrackAddedEvent
      );
    }
  }

  //Unsubscribes from all video player events set up in setupVideoPlayerEvents
  private unsubscribeFromVideoPlayerEvents() {
    this.unsubscribeFromCaptionEvents();

    if (this.m_VideoPlayEvent != null) {
      this.m_VideoPlayer?.removeEventListener("play", this.m_VideoPlayEvent);
    }
    if (this.m_VideoPauseEvent != null) {
      this.m_VideoPlayer?.removeEventListener("pause", this.m_VideoPauseEvent);
    }
    if (this.m_VideoSeekStartEvent != null) {
      this.m_VideoPlayer?.removeEventListener(
        "seeking",
        this.m_VideoSeekStartEvent
      );
    }
    if (this.m_VideoSeekEndEvent != null) {
      this.m_VideoPlayer?.removeEventListener(
        "seeked",
        this.m_VideoSeekEndEvent
      );
    }
    if (this.m_VideoEndedEvent != null) {
      this.m_VideoPlayer?.removeEventListener("ended", this.m_VideoEndedEvent);
    }
    if (this.m_VideoTimeUpdateEvent != null) {
      this.m_VideoPlayer?.removeEventListener(
        "timeupdate",
        this.m_VideoTimeUpdateEvent
      );
    }
    if (this.m_PresentationModeChangeEvent != null) {
      (this.m_VideoPlayer?.presentation as any).removeEventListener(
        "presentationmodechange",
        this.m_PresentationModeChangeEvent
      );
    }
    if (this.m_VolumeChangeEvent != null) {
      this.m_VideoPlayer?.removeEventListener(
        "volumechange",
        this.m_VolumeChangeEvent
      );
    }
    if (this.m_TouchEndEvent != null) {
      let vjsTech =
        this.m_VideoContainer?.nativeElement.querySelector(".vjs-tech");
      if (vjsTech != null) {
        vjsTech.removeEventListener("touchend", this.m_TouchEndEvent);
      }
    }
    if (this.m_DblClickEvent != null) {
      let vjsTech =
        this.m_VideoContainer?.nativeElement.querySelector(".vjs-tech");
      if (vjsTech != null) {
        vjsTech.removeEventListener("dblclick", this.m_DblClickEvent);
      }
    }
    if (this.m_SettingsClickedEvent != null) {
      let settingsButton = document.querySelector(
        ".theo-settings-control-button"
      );
      if (settingsButton != null) {
        settingsButton.removeEventListener(
          "click",
          this.m_SettingsClickedEvent
        );

        settingsButton.removeEventListener(
          "touchend",
          this.m_SettingsClickedEvent
        );
      }
    }

    if (this.m_DubClickedEvent != null) {
      let dubButton = document.querySelector(
        ".theo-audio-track-control-button"
      );
      if (dubButton != null) {
        dubButton.removeEventListener("click", this.m_DubClickedEvent);

        dubButton.removeEventListener("touchend", this.m_DubClickedEvent);
      }
    }
    if (this.m_KeyDownEvent != null) {
      document.removeEventListener("keydown", this.m_KeyDownEvent);
      this.m_KeyDownEvent = null;
    }
    if (this.m_ErrorHandlerEvent != null) {
      this.m_VideoPlayer?.removeEventListener(
        "error",
        this.m_ErrorHandlerEvent
      );
    }
  }

  private async fetchCaptions(
    lang: string = "en-US"
  ): Promise<CAPTIONS | null> {
    if (!this.m_VideoData) return null;
    let captionDTO: CAPTIONS | null = null;

    if (this.m_QvioViewMode == QvioViewMode.ADMIN) {
      captionDTO = await this.m_AdminService.getCaptions(
        this.m_VideoData.video_id,
        lang
      );
    } else {
      captionDTO = await this.m_VideoService.getCaptions(
        this.m_VideoData.video_id,
        lang
      );
    }

    return captionDTO;
  }

  //Helper function that configures the CC menu options
  private updateCCMenuConfig() {
    this.m_CCMenuConfig.options = [];

    if (this.m_LanguageList) {
      this.m_CCMenuConfig.options = this.m_LanguageList.map((language) => {
        return {
          text: language.label,
          handler: () => this.onCaptionsChanged(language),
          selected: language.value == this.m_ShowingCaption?.language,
        };
      });
    }

    //Add the default track to the menu if it doesn't exist
    if (this.m_DefaultTrack) {
      this.m_CCMenuConfig.options = this.m_CCMenuConfig.options.filter(
        (language) => language.text != this.m_DefaultTrack.label
      );

      this.m_CCMenuConfig.options.unshift({
        text: this.m_DefaultTrack.label,
        handler: () => this.onCaptionsChanged(this.m_DefaultTrack),
        selected: this.m_DefaultTrack.src === "",
      });
    }

    //Add showing caption to the menu if it doesn't exist
    if (this.m_ShowingCaption) {
      let currentcaption: LanguageData = {
        label: this.m_ShowingCaption.label,
        value: this.m_ShowingCaption.language,
        default: false,
      };
      this.m_CCMenuConfig.options = this.m_CCMenuConfig.options.filter(
        (language) => language.text != currentcaption.label
      );
      this.m_CCMenuConfig.options.unshift({
        icon: "language",
        text: currentcaption.label,
        handler: () => this.onCaptionsChanged(currentcaption),
        selected:
          currentcaption.value == this.m_ShowingCaption?.language &&
          this.m_ShowingCaption.mode === "showing",
      });

      if (this.m_ShowingCaption?.mode == "showing") {
        var emptyOption = {
          icon: "language",
          text: "None",
          handler: () => this.onCaptionsChanged(null),
          selected: false,
        };
        this.m_CCMenuConfig.options.unshift(emptyOption);
      }
    } else {
      if (this.m_CCMenuConfig.options[0].text == "None")
        this.m_CCMenuConfig.options = this.m_CCMenuConfig.options.filter(
          (item) => {
            item.text != "None";
          }
        );
    }
  }

  /**
   * Updates the chapter menu options based on the chapters in the video description
   * @param chapters
   */
  private updateChapterMenuConfig(chapters: ChapterMap) {
    this.m_ChaptersMenuConfig.options = [];
    this.m_ChaptersMenuConfig.options = Object.keys(chapters).map(
      (timestamp) => {
        let title = chapters[timestamp];
        return {
          text: timestamp + " " + title,
          handler: () => {
            if (this.m_VideoPlayer) {
              this.m_VideoPlayer.currentTime = timeStringToSeconds(timestamp);
            }
            this.m_OpenOverlayMenu = false;
          },
        };
      }
    );
  }

  private getTheoVideoElement(): HTMLVideoElement | undefined {
    let videoElements =
      this.m_VideoPlayer?.element.getElementsByTagName("video");

    if (videoElements != null) {
      return Array.from(videoElements).find((ve) => ve.src != "");
    } else {
      return;
    }
  }
  //#endregion
}
