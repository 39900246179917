import { Component, OnInit } from "@angular/core";
import { USER_DTO_UPDATE_REQ } from "@shared/models/user/user";
import { UserService } from "src/app/services/user/user.service";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
  standalone: false,
})
export class NotificationsComponent implements OnInit {
  public m_ReceiveUnanswered?: boolean;
  public m_Saving: boolean = false;
  public $t = T.translate;

  constructor(
    private m_UserService: UserService,
    private m_NotificationService: HIANotificationService
  ) {}

  ngOnInit() {
    this.m_ReceiveUnanswered =
      this.m_UserService.ActiveUserSettings?.user_settings.receive_unanswered_email_notification;
  }

  async saveChanges() {
    if (this.m_UserService.ActiveUserInfo?.id != null) {
      this.m_Saving = true;
      let userInfo: USER_DTO_UPDATE_REQ = {
        id: this.m_UserService.ActiveUserInfo?.id,
        receive_unanswered_email_notification: this.m_ReceiveUnanswered,
      };

      try {
        await this.m_UserService.setUserInfo(userInfo);
        this.m_NotificationService.showSuccess(
          this.$t("pages.editProfile.profileUpdated"),
          1000
        );
      } catch (e) {
        console.log(e);
        this.m_NotificationService.showError(
          this.$t("pages.editProfile.profileUpdateFailed"),
          1000
        );
      } finally {
        this.m_Saving = false;
      }
    }
  }

  handleReceiveUnansweredChange(value: any) {
    if (value.target.value) this.m_ReceiveUnanswered = value.target?.checked;
  }
}
