import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ConfigService } from "src/app/services/config/config.service";
import { T } from "src/app/services/localization/localization.service";

@Component({
  selector: "app-terms-modal",
  templateUrl: "./terms-modal.component.html",
  styleUrls: ["./terms-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class TermsModalComponent implements OnInit {
  public $t = T.translate;
  public m_Terms: string[] = [];
  public m_IsOpen: boolean = false;
  public m_TermsOfServiceUrl: SafeUrl | null = null;
  public m_Loading: boolean = false;

  @ViewChild("termsRoot", { static: false }) m_TermsRoot: ElementRef | null =
    null;
  @Output() public OnDismiss: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private m_ConfigService: ConfigService,
    private sanatizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.m_Loading = true;
    this.getTermsOfServiceUrl();
  }

  async show() {
    this.m_IsOpen = true;
  }

  onContinue() {
    this.m_IsOpen = false;
    this.OnDismiss.emit();
  }

  onDidShow() {
    //Set root element height to parent height
    if (this.m_TermsRoot) {
      this.m_TermsRoot.nativeElement.style.height = `${this.m_TermsRoot.nativeElement.parentElement.clientHeight}px`;
    }
  }

  onTermsLoaded() {
    this.m_Loading = false;
  }

  private loadTerms() {
    this.m_Terms = [
      this.$t("terms-of-service.part-1"),
      this.$t("terms-of-service.part-2"),
      this.$t("terms-of-service.part-3"),
      this.$t("terms-of-service.part-4"),
      this.$t("terms-of-service.part-5"),
      this.$t("terms-of-service.part-6"),
      this.$t("terms-of-service.part-7"),
      this.$t("terms-of-service.part-8"),
      this.$t("terms-of-service.part-9"),
      this.$t("terms-of-service.part-10"),
      this.$t("terms-of-service.part-11"),
      this.$t("terms-of-service.part-12"),
    ];
  }

  private async getTermsOfServiceUrl() {
    this.m_TermsOfServiceUrl = this.sanatizer.bypassSecurityTrustResourceUrl(
      (await this.m_ConfigService.get("EULA_URL")) +
        "#toolbar=0&navpanes=0&scrollbar=0&zoom=125"
    );
  }
}
