import { Injectable } from "@angular/core";
import { TextToSpeechService } from "../text-to-speech/text-to-speech.service";
import { AudioPlayer } from "src/app/utility/audio-player";
import { Subject, Subscription } from "rxjs";
import { isSafari } from "src/app/utility/utils";
import { Platform } from "@ionic/angular";
import { removeLinks } from "@shared/utils/utils";
import { VOICE } from "src/app/models/voice/voice";

export enum UtteranceState {
  STOPPED,
  PLAYING,
  LOADING,
  FADING,
}

export interface SpeakOptions {
  streamAudioPlayback: boolean;
  streamTTS: boolean;
  voice?: VOICE;
  localize?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class UtteranceService {
  private m_AudioPlayer: AudioPlayer = new AudioPlayer();
  private m_OnAudioChunkPlayed: Subscription | undefined;
  private m_OnAudioStopped: Subscription | undefined;
  private m_State: UtteranceState = UtteranceState.STOPPED;
  private m_LastVolume: number = 1;
  private m_Lock: boolean = false;

  private fadeOutAbortController: AbortController | null = null;

  public m_OnAudioPlay = new Subject<void>();
  public m_OnAudioStop = new Subject<void>();

  get State(): UtteranceState {
    return this.m_State;
  }

  constructor(
    private m_TTSService: TextToSpeechService,
    private m_Platform: Platform
  ) {
    document.body.addEventListener("click", () =>
      this.m_AudioPlayer.resumeContext()
    );
  }

  /**
   * Converts the text input to speech data via the TTS service
   * @param textInput  The text to convert to speech
   * @param streamAudio Whether or not to stream the audio data to the audio player
   * @param onAudioChunkPlayed The function to call when an audio chunk is played
   * @param onEnd The function to call when the text to speech process ends
   * @param onError The function to call when an error occurs
   */
  async speak(
    text: string,
    speakOptions: SpeakOptions,
    onAudioChunkPlayed: () => void,
    onEnd: () => void,
    onError: (error: any) => void,
    forceRegen: boolean = false
  ) {
    // Abort any ongoing fade-out before acquiring the lock
    if (this.fadeOutAbortController) {
      this.fadeOutAbortController.abort();
      this.fadeOutAbortController = null; // Clean up the abort controller
    }

    await this.acquireLock();

    try {
      this.m_AudioPlayer.stop(); // Stop any ongoing playback

      if (this.m_Platform.is("ios") || isSafari() || !speakOptions.streamTTS) {
        speakOptions.streamAudioPlayback = false;
      }

      this.m_AudioPlayer.StreamAudio = speakOptions.streamAudioPlayback;
      this.m_State = UtteranceState.LOADING;
      const sanatizedText = removeLinks(text);

      this.m_TTSService.textToSpeech(
        sanatizedText,
        () => {
          this.onTTSStart(onAudioChunkPlayed, onEnd);
        },
        () => {
          this.onTTSEnd(speakOptions.streamTTS);
        },
        (error: any) => {
          this.onTTSError(error, onError);
        },
        this.onAudioDataReceived.bind(this),
        speakOptions.streamTTS,
        speakOptions.voice,
        speakOptions.localize,
        forceRegen
      );
    } finally {
      this.releaseLock();
    }
  }

  //Stops the current utterance playback
  async stop(fadeOutAudio = false) {
    if (
      this.m_State !== UtteranceState.PLAYING &&
      this.m_State !== UtteranceState.LOADING
    )
      return;

    this.m_State = UtteranceState.FADING; // Prevent other operations
    await this.acquireLock();

    try {
      if (fadeOutAudio) await this.fadeOutAudio();

      this.m_TTSService.stopTextToSpeech();
      this.m_AudioPlayer.stop();
      this.unsubscribeAudioPlayerEvents();
      this.m_State = UtteranceState.STOPPED;
    } finally {
      this.releaseLock();
    }
  }

  //#region Private Methods
  private onTTSStart(onAudioChunkPlayed: () => void, onEnd: () => void) {
    this.m_AudioPlayer.volume =
      this.m_State == UtteranceState.FADING ? this.m_LastVolume : 1.0;

    this.m_State = UtteranceState.PLAYING;
    // Hookup the audio player events for this request
    this.hookupAudioPlayerEvents(onAudioChunkPlayed, onEnd);
    this.m_OnAudioPlay.next();
  }

  private onTTSEnd(streaming: boolean) {
    if (streaming) {
      // Process the buffer queue one late time in case there is any data left
      this.m_AudioPlayer.processBufferQueue();
    }
  }

  private onAudioDataReceived(data: string) {
    //Check if data is url
    if (data.startsWith("http")) {
      //Url received, pass it to the audio player
      this.m_AudioPlayer.playAudioFromUrl(data);
    } else {
      //Audio chunk received, pass it to recieved function
      this.onAudioChunkReceived(data);
    }
  }

  private onAudioChunkReceived(base64Chunk: string) {
    // Pass the audio chunk to the audio player,
    // will be played if streaming is enabled, otherwise will be queued
    this.m_AudioPlayer.onChunkReceived(base64Chunk);
  }

  private onTTSError(error: any, errorCB: (error: any) => void) {
    this.stop();
    errorCB(error);
  }

  /**
   * Hooks up the audio player events for the current request
   * @param onAudioChunkPlayed
   * @param onEnd
   */
  private hookupAudioPlayerEvents(
    onAudioChunkPlayed: () => void,
    onEnd: () => void
  ) {
    this.unsubscribeAudioPlayerEvents();

    this.m_OnAudioChunkPlayed = this.m_AudioPlayer.OnAudioPlay.subscribe(() => {
      onAudioChunkPlayed();
    });

    this.m_OnAudioStopped = this.m_AudioPlayer.OnAudioStop.subscribe(() => {
      this.m_State = UtteranceState.STOPPED;
      this.unsubscribeAudioPlayerEvents();
      onEnd();
      this.m_OnAudioStop.next();
    });
  }

  private async fadeOutAudio(): Promise<void> {
    if (this.fadeOutAbortController) {
      this.fadeOutAbortController.abort(); // Cancel previous fade-out
    }

    this.fadeOutAbortController = new AbortController();
    const signal = this.fadeOutAbortController.signal;

    this.m_State = UtteranceState.FADING;
    let currVolume = this.m_AudioPlayer?.volume ?? 1;
    this.m_LastVolume = currVolume;

    return new Promise((resolve, reject) => {
      const fadeOutInterval = setInterval(() => {
        if (signal.aborted) {
          clearInterval(fadeOutInterval);
          resolve();
          return;
        }

        currVolume -= 0.05;
        if (currVolume <= 0) {
          clearInterval(fadeOutInterval);
          if (this.m_AudioPlayer) this.m_AudioPlayer.volume = 0;
          resolve();
        } else if (this.m_AudioPlayer) {
          this.m_AudioPlayer.volume = currVolume;
        }
      }, 50);
    });
  }

  //Helper to unsubscribe from the audio player events
  private unsubscribeAudioPlayerEvents() {
    this.m_OnAudioChunkPlayed?.unsubscribe();
    this.m_OnAudioChunkPlayed = undefined;
    this.m_OnAudioStopped?.unsubscribe();
    this.m_OnAudioStopped = undefined;
  }

  private async acquireLock() {
    while (this.m_Lock) {
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
    this.m_Lock = true;
  }

  private releaseLock() {
    this.m_Lock = false;
  }
  //#endregion
}
