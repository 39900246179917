import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MEDIA_STATUS, MEDIA_TYPE } from "@shared/models/media/media";
import { Media } from "src/app/models/media/media";
import { T } from "src/app/services/localization/localization.service";
import { MediaService } from "src/app/services/media/media.service";

@Component({
  selector: "app-media-card",
  templateUrl: "./media-card.component.html",
  styleUrls: ["./media-card.component.scss"],
})
export class MediaCardComponent implements OnInit {
  @Input() public m_Media: Media | null = null;
  @Input() public m_Selected: boolean = false;

  @Output() public click: EventEmitter<Media> = new EventEmitter();
  @Output() public stateChange: EventEmitter<Media> = new EventEmitter();

  public $t = T.translate;
  public m_TimedOut: boolean = false;

  private m_Progress: number = -1;
  private m_MaxAttempts: number = 120;
  private m_PollingTime = 5000;
  private m_PollingInterval: any;

  constructor(private m_MediaService: MediaService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (
      this.m_Media?.type == MEDIA_TYPE.VIDEO &&
      (this.m_Media?.status == MEDIA_STATUS.PROCESSING ||
        this.m_Media?.status == MEDIA_STATUS.IMPORTING ||
        (this.m_Media?.status == MEDIA_STATUS.READY &&
          (this.m_Media?.thumbnail_url == null ||
            this.m_Media?.thumbnail_url == "" ||
            this.m_Media?.thumbnail_url == undefined)))
    )
      this.pollForMediaStatus();
  }

  ngOnDestroy() {
    if (this.m_PollingInterval) clearInterval(this.m_PollingInterval);
  }

  public async onProgress(progress: number) {
    this.m_Progress = progress;
  }

  public async updateMedia() {
    if (!this.m_Media) return;

    this.m_Media = await this.m_MediaService.getAMedia(
      this.m_Media.video_id,
      this.m_Media.id,
      true
    );

    this.stateChange.emit(this.m_Media);

    if (
      (this.m_Media.status == MEDIA_STATUS.PROCESSING ||
        this.m_Media.status == MEDIA_STATUS.IMPORTING) &&
      this.m_PollingInterval == null
    ) {
      this.pollForMediaStatus();
    } else if (
      this.m_Media.status == MEDIA_STATUS.PROCESSING &&
      this.m_Media.type == MEDIA_TYPE.VIDEO
    ) {
      let progress = await this.m_MediaService.getVideoProcessingProgress(
        this.m_Media.video_id,
        this.m_Media.id
      );

      if (!isNaN(progress)) this.m_Progress = progress;
      else this.m_Progress = -1;
    } else {
      this.m_Progress = -1;
    }
  }

  public getProgress() {
    return this.m_Progress;
  }

  //#region Event Handlers
  onMediaClicked() {
    if (!this.m_Media) return;

    this.click.emit(this.m_Media);
  }
  //#endregion
  //#region Render Helpers
  public getDragData() {
    return () => {
      return this.m_Media;
    };
  }

  public shouldShowProgress() {
    return (
      this.m_Progress > -1 ||
      (this.m_Media?.status != null &&
        this.m_Media?.status <= MEDIA_STATUS.PROCESSING) ||
      this.isVideoProcessing() ||
      this.m_Media?.status == MEDIA_STATUS.IMPORTING
    );
  }

  public getProgressTxt() {
    let retMsg = "";
    if (
      this.m_Media?.status == MEDIA_STATUS.PENDING &&
      this.m_Progress === -1
    ) {
      return this.$t("shared.messages.startingUpload");
    } else if (
      this.m_Media?.status == MEDIA_STATUS.PENDING &&
      this.m_Progress > -1
    ) {
      retMsg = this.$t("shared.messages.uploading") + " ";
    } else if (
      this.m_Media?.status == MEDIA_STATUS.PROCESSING ||
      this.m_Media?.status == MEDIA_STATUS.UPLOADED ||
      this.isVideoProcessing()
    ) {
      retMsg = this.$t("shared.messages.processing") + " ";
    } else if (this.m_Media?.status == MEDIA_STATUS.IMPORTING) {
      retMsg = this.$t("shared.messages.importing") + " ";
    }

    if (this.m_TimedOut) retMsg = this.$t("shared.messages.timeoutRefresh");

    return `${retMsg}`;
  }

  public shouldShowImage() {
    return this.m_Media && this.m_Media.type == MEDIA_TYPE.IMAGE;
  }

  public shouldShowVideo() {
    return this.m_Media && this.m_Media.type == MEDIA_TYPE.VIDEO;
  }

  public getDisplayUrl() {
    if (this.shouldShowImage()) {
      return this.m_Media?.url;
    } else if (this.shouldShowVideo()) {
      switch (this.m_Media?.status) {
        case MEDIA_STATUS.PENDING:
        case MEDIA_STATUS.IMPORTING:
        case MEDIA_STATUS.PROCESSING:
          return "";
        case MEDIA_STATUS.READY:
          return this.m_Media?.thumbnail_url;
        case MEDIA_STATUS.ERROR:
          return "assets/icon/video/video-error.svg";
        default:
          return "assets/icon/video/video-error.svg";
      }
    } else {
      return "";
    }
  }
  //#endregion
  //#region Private Helpers
  //Helper function to poll for the media status if the media is still processing or importing
  private pollForMediaStatus() {
    if (
      this.m_Media?.status !== MEDIA_STATUS.PROCESSING &&
      this.m_Media?.status !== MEDIA_STATUS.IMPORTING
    ) {
      return;
    }

    let attempts = 0;

    const poll = async () => {
      attempts++;

      const isTimedOut = attempts > this.m_MaxAttempts;
      const isProcessing =
        this.m_Media?.status === MEDIA_STATUS.PROCESSING ||
        this.m_Media?.status === MEDIA_STATUS.IMPORTING;

      if (isTimedOut || !isProcessing) {
        clearInterval(this.m_PollingInterval);
        if (isTimedOut) {
          this.m_Progress = 100;
          this.m_TimedOut = true;
        }
        return;
      }

      await this.updateMedia();
    };

    this.m_PollingInterval = setInterval(poll, this.m_PollingTime);
  }

  //Helper function to determine if the video is still in a processing state
  private isVideoProcessing() {
    return (
      this.m_Media?.status != null &&
      this.m_Media?.status <= MEDIA_STATUS.READY &&
      this.m_Media.type == MEDIA_TYPE.VIDEO &&
      (this.m_Media?.thumbnail_url == null ||
        this.m_Media?.thumbnail_url == "" ||
        this.m_Media?.thumbnail_url == undefined)
    );
  }
  //#endregion
}
