import { BaseApiService } from "../base-api-service";
import { UserService } from "src/app/services/user/user.service";
import { GET_ALL_USERS_DTO_RES } from "@shared/models/user/user";
import { RECORDS_DTO_PAGINATED_GET_RES } from "@shared/models/record/record";
import {
  VIDEO_DTO_GET_RES,
  VIDEO_DTO_GET_USER_RES,
  VIDEO_PUBLISH_STATE,
} from "@shared/models/video/video";
import { MEDIA_DTO_GET_MEDIA_RES } from "@shared/models/media/media";
import { BASE_RESPONSE } from "@shared/models/api/api";
import { ANALYTICS_GET_ALL_USERS_DTO_RES } from "@shared/models/analytics/analytics-responses";
import { Events } from "src/app/services/events/events.service";

export class AdminApi extends BaseApiService {
  //User Routes
  private static readonly createUserEndpoint = {
    method: "POST",
    path: "/api/admin/create-user",
  };

  private static readonly getTierListEndpoint = {
    method: "GET",
    path: "/api/admin/tier-list",
  };

  private static readonly getUserListEndpoint = {
    method: "GET",
    path: "/api/admin/user-list",
  };

  private static readonly getUserAnalyticsListEndpoint = {
    method: "GET",
    path: "/api/admin/analytics/users",
  };

  private static readonly getVideoEndpoint = {
    method: "GET",
    path: "/api/admin/user/{{userID}}/videos/{{videoID}}",
  };

  private static readonly getRecordsEndpoint = {
    method: "GET",
    path: "/api/admin/user/{{userID}}/videos/{{videoID}}/records",
  };

  private static readonly videoSuggestRecordEndpoint = {
    method: "GET",
    path: "/api/admin/video/{{videoID}}/records/suggested",
  };

  private static readonly videoGetCaptionsEndpoint = {
    method: "GET",
    path: "/api/admin/video/{{videoID}}/captions",
  };

  private static readonly videoGetByUserIdEndpoint = {
    method: "GET",
    path: "/api/admin/user/{{userID}}/videos",
  };

  private static readonly mediaGetAMediaEndpoint = {
    method: "GET",
    path: "/api/admin/video/{{videoID}}/media/{{mediaID}}",
  };

  private static readonly extendSubscription = {
    method: "PATCH",
    path: "/api/admin/extend-subscription",
  };

  private static readonly getVideoQueriesEndpoint = {
    method: "GET",
    path: "/api/admin/video/{{videoID}}/queries",
  };

  private static readonly patchFallbackThreshold = {
    method: "PATCH",
    path: "/api/admin/video/{{videoID}}/fallback-threshold",
  };

  private static readonly searchVideosEndpoint = {
    method: "GET",
    path: "/api/admin/video/search",
  };

  private m_SessionId: string = "";

  get SessionId() {
    return this.m_SessionId;
  }

  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }

  //#region User Routes
  //--------------------------------------------------------------------
  /**
   * Used to update the last seen time of the user
   * @param sessionId Session Id from Stytch
   * @returns
   */
  async extendSubscription(
    userId: string,
    end_date: string
  ): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: AdminApi.extendSubscription.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({
        user_id: userId,
        end_date: end_date,
      }),
    };

    const response: BASE_RESPONSE = await this.makeRequest(
      AdminApi.extendSubscription.path,
      apiRequest,
      "Failed to extend subscription"
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Used to update the last seen time of the user
   * @param sessionId Session Id from Stytch
   * @returns
   */
  async createUser(
    email: string,
    tierId: string,
    end_date: string
  ): Promise<any> {
    let apiRequest = {
      method: AdminApi.createUserEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({
        email: email,
        tierId: tierId,
        end_date: end_date,
      }),
    };

    const response: any = await this.makeRequest(
      AdminApi.createUserEndpoint.path,
      apiRequest,
      "Login Failed"
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Used to get any user video data
   * @param videoId
   * @returns
   */
  async getVideo(userID: string, videoId: string): Promise<VIDEO_DTO_GET_RES> {
    let apiRequest = {
      method: AdminApi.getVideoEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: any = await this.makeRequest(
      AdminApi.getVideoEndpoint.path
        .replace("{{videoID}}", videoId)
        .replace("{{userID}}", userID),
      apiRequest,
      "Video fecth failed"
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get all videos by user id, returned videos depend on the user id and caller's session id
   * @param userId
   * @returns
   */
  async getVideosByUserId(
    userId: string,
    page_number: number,
    page_size: number,
    visibility?: VIDEO_PUBLISH_STATE,
    searchTerm?: string,
    fields?: string,
    orderBy?: string,
    orderDirection?: string
  ): Promise<VIDEO_DTO_GET_USER_RES> {
    let apiRequest = {
      method: AdminApi.videoGetByUserIdEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    let url = AdminApi.videoGetByUserIdEndpoint.path.replace(
      "{{userID}}",
      userId
    );
    let params: Map<string, string> = new Map<string, string>();
    params.set("pageNumber", page_number.toString());
    params.set("pageSize", page_size.toString());

    if (visibility || visibility === 0) {
      params.set("visibility", visibility.toString());
    }
    if (fields && fields.length > 0) {
      params.set("fields", fields);
    }
    if (searchTerm && searchTerm.length > 0) {
      params.set("searchTerm", searchTerm);
    }
    if (orderBy && orderBy.length > 0) {
      params.set("orderBy", orderBy);
    }
    if (orderDirection && orderDirection.length > 0) {
      params.set("orderDirection", orderDirection);
    }
    url = this.addParams(url, params);

    const response: VIDEO_DTO_GET_USER_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Videos Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Used to get any user video suggestions
   * @param videoId
   * @returns
   */
  async getSuggestions(
    videoID: string,
    pageNumber?: number,
    pageSize?: number,
    pageNumberSeed?: number
  ): Promise<RECORDS_DTO_PAGINATED_GET_RES> {
    let apiRequest = {
      method: AdminApi.videoSuggestRecordEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url = AdminApi.videoSuggestRecordEndpoint.path.replace(
      "{{videoID}}",
      videoID
    );
    let params: Map<string, string> = new Map<string, string>();
    if (pageNumber != null && pageSize != null) {
      params.set("pageNumber", pageNumber.toString());
      params.set("pageSize", pageSize.toString());
    }
    if (pageNumberSeed != null) params.set("seed", pageNumberSeed.toString());

    url = this.addParams(url, params);

    const response: RECORDS_DTO_PAGINATED_GET_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Failed to get records",
        3
      );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Used to get any user video data
   * @param videoId
   * @returns
   */
  async getRecords(
    videoID: string,
    includeTrainingPhrases?: boolean,
    pageNumber?: number,
    pageSize?: number,
    searchTerm?: string
  ): Promise<RECORDS_DTO_PAGINATED_GET_RES> {
    let apiRequest = {
      method: AdminApi.getRecordsEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url = AdminApi.getRecordsEndpoint.path.replace("{{videoID}}", videoID);
    let params: Map<string, string> = new Map<string, string>();
    if (pageNumber != null && pageSize != null) {
      params.set("pageNumber", pageNumber.toString());
      params.set("pageSize", pageSize.toString());
    }
    if (searchTerm != null) {
      params.set("searchTerm", searchTerm);
    }
    if (includeTrainingPhrases != null) {
      params.set("includeTrainingPhrases", includeTrainingPhrases.toString());
    }
    url = this.addParams(url, params);

    const response: RECORDS_DTO_PAGINATED_GET_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Failed to get records",
        3
      );

    return response;
  }

  //--------------------------------------------------------------------
  /**
   * Get user info
   * @param userId
   * @returns
   */
  async getUserTierList() {
    let apiRequest = {
      method: AdminApi.getTierListEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      AdminApi.getTierListEndpoint.path,
      apiRequest,
      "Get User Info failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get user list
   * @param userId
   * @returns
   */
  async getUserList(
    pageSize: number,
    pageNumber: number,
    searchTerm?: string,
    orderBy?: string,
    orderDirection?: string
  ): Promise<GET_ALL_USERS_DTO_RES> {
    let url = AdminApi.getUserListEndpoint.path;
    let params: Map<string, string> = new Map<string, string>();
    params.set("pageSize", pageSize.toString());
    params.set("pageNumber", pageNumber.toString());
    if (searchTerm) {
      params.set("searchTerm", searchTerm);
    }
    if (orderBy) {
      params.set("orderBy", orderBy);
    }
    if (orderDirection) {
      params.set("orderDirection", orderDirection);
    }
    url = this.addParams(url, params);

    let apiRequest = {
      method: AdminApi.getUserListEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get User Info failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get user list
   * @param userId
   * @returns
   */
  async getUserAnalyticsList(
    pageSize: number,
    pageNumber: number,
    searchTerm?: string,
    orderBy?: string,
    orderDirection?: string
  ): Promise<ANALYTICS_GET_ALL_USERS_DTO_RES> {
    let url = AdminApi.getUserAnalyticsListEndpoint.path;
    const params = new Map<string, string>();
    params.set("pageSize", pageSize.toString());
    params.set("pageNumber", pageNumber.toString());
    if (searchTerm) {
      params.set("searchTerm", searchTerm);
    }
    if (orderBy) {
      params.set("orderBy", orderBy);
    }
    if (orderDirection) {
      params.set("orderDirection", orderDirection);
    }

    url = this.addParams(url, params);

    let apiRequest = {
      method: AdminApi.getUserAnalyticsListEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get User Info failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Gets media for a video
   */
  async getAMedia(videoID: string, mediaID: string) {
    let url = AdminApi.mediaGetAMediaEndpoint.path
      .replace("{{videoID}}", videoID)
      .replace("{{mediaID}}", mediaID);

    let apiRequest = {
      method: AdminApi.mediaGetAMediaEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: MEDIA_DTO_GET_MEDIA_RES = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Media Failed",
      3,
      200
    );

    return response;
  }

  //--------------------------------------------------------------------
  /**
   * Gets captions for a video
   */
  async getCaptions(video_id: string, language = "en-US") {
    let apiRequest = {
      method: AdminApi.videoGetCaptionsEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url =
      AdminApi.videoGetCaptionsEndpoint.path.replace("{{videoID}}", video_id) +
      "?language=" +
      language;

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Video Captions Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get video queries
   */
  async getVideoQueries(videoID: string, pageSize: number, pageNumber: number) {
    let apiRequest = {
      method: AdminApi.getVideoQueriesEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let params: Map<string, string> = new Map<string, string>();
    params.set("pageSize", pageSize.toString());
    params.set("pageNumber", pageNumber.toString());

    let url = AdminApi.getVideoQueriesEndpoint.path.replace(
      "{{videoID}}",
      videoID
    );

    url = this.addParams(url, params);

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Get Video Queries Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Patch fallback threshold
   */
  async patchFallbackThreshold(videoID: string, threshold: number) {
    let apiRequest = {
      method: AdminApi.patchFallbackThreshold.method,
      headers: {
        sid: this.m_UserService.SessionId,
        "Content-Type": "application/json",
      },
    };

    let url = AdminApi.patchFallbackThreshold.path.replace(
      "{{videoID}}",
      videoID
    );

    url = this.addParams(url, new Map([["threshold", threshold.toString()]]));

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Patch Fallback Threshold Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Get video queries
   */
  async searchVideos(
    pageSize: number,
    pageNumber: number,
    searchTerm?: string
  ) {
    let url = AdminApi.searchVideosEndpoint.path;
    let params: Map<string, string> = new Map<string, string>();
    params.set("pageSize", pageSize.toString());
    params.set("pageNumber", pageNumber.toString());
    if (searchTerm) {
      params.set("searchTerm", searchTerm);
    }
    url = this.addParams(url, params);

    let apiRequest = {
      method: AdminApi.searchVideosEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response = await this.makeRequestWithRetry(
      url,
      apiRequest,
      "Search Videos failed",
      3,
      200
    );

    return response;
  }
  //#endregion
}
