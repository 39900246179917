import { Injectable } from "@angular/core";
import { UserApi } from "src/app/api/user/user-api";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { VIDEO, VIDEO_LIST } from "src/app/models/video/video";
import { ConfigService } from "../config/config.service";
import {
  LIMITS_DTO,
  USER_DTO,
  USER_DTO_GET_SETTINGS_RES,
  USER_DTO_UPDATE_REQ,
} from "@shared/models/user/user";
import { VIDEO_PUBLISH_STATE } from "@shared/models/video/video";
import { UserDataCache } from "../caching/user-data.service";
import { USER_LIMITS } from "src/app/models/user/user";
import { Subject } from "rxjs";
import { T } from "../localization/localization.service";
import { LIST_USER_PLAYLISTS_DTO_RES } from "@shared/models/playlist/playlist";
import { Events } from "../events/events.service";
import { InspectletService } from "../inspectlet/inspectlet.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private m_UserAPI: UserApi;
  private m_ActiveUserInfo: USER_DTO | null = null;
  private m_ActiveUserLimits: USER_LIMITS | null = null;
  private m_ActiveUserSettings: USER_DTO_GET_SETTINGS_RES | null = null;
  private m_LastSeenObserver: Subject<void> | null = null;
  private m_UserDataCache = new UserDataCache(60 * 60 * 1000); // 1 hour expiry in milliseconds
  public $t = T.translate;

  get LastSeenObserver() {
    return this.m_LastSeenObserver;
  }

  get SessionId() {
    return this.m_UserAPI.SessionId;
  }

  get ActiveUserInfo() {
    return this.m_ActiveUserInfo;
  }

  get ActiveUserLimits() {
    return this.m_ActiveUserLimits?.subInfo;
  }

  get ActiveUserSettings() {
    return this.m_ActiveUserSettings;
  }

  get HasSubscription() {
    return this.m_ActiveUserLimits?.hasActiveSubscription ?? false;
  }

  get CanUseFreeTrial() {
    return this.m_ActiveUserLimits?.canUseFreeTrial ?? true;
  }

  get isFreeTrial() {
    return this.m_ActiveUserLimits?.is_trial ?? true;
  }

  get UserProfilePicture() {
    if (
      this.m_ActiveUserInfo == null ||
      this.m_ActiveUserInfo.profile_picture_url == null ||
      this.m_ActiveUserInfo.profile_picture_url == ""
    )
      return "assets/icon/user/default-avatar.svg";
    return this.m_ActiveUserInfo.profile_picture_url;
  }

  get IsAdmin() {
    if (this.m_ActiveUserInfo == null) return false;
    return this.m_ActiveUserLimits?.subInfo?.tierName?.toLowerCase() == "admin";
  }

  constructor(
    eventsService: Events,
    configService: ConfigService,
    private m_InspectletService: InspectletService
  ) {
    this.m_UserAPI = new UserApi(
      eventsService,
      configService.get("SERVER_URL")
    );
  }

  //#region Public Methods
  /**
   * Used to update the last seen time of the user
   * @param sessionId Session Id from Stytch
   * @returns Results of the request, null if failed
   */
  async lastSeen(sessionId: string, userId: string) {
    this.m_LastSeenObserver = new Subject<void>();

    let res = await this.m_UserAPI.lastSeen(sessionId);
    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to update last seen");
    }

    //Fetch the user info
    let userInfo = await this.getUserInfo(userId);
    if (userInfo) this.m_ActiveUserInfo = userInfo;

    // Tag the session with metadata
    this.m_InspectletService.tagSessionMetadata({
      email: this.m_ActiveUserInfo?.email ?? "",
      username: this.m_ActiveUserInfo?.username ?? "",
    });

    try {
      let userLimits = await this.getUserLimits(userId);
      if (userLimits) this.m_ActiveUserLimits = userLimits;
    } catch (error) {
      this.onLastSeenComplete();
      return res;
    }

    let userSettings = await this.getSettings(userId);
    if (userSettings) this.m_ActiveUserSettings = userSettings;

    this.onLastSeenComplete();
    return res;
  }
  //--------------------------------------------------------------------
  /**
   *  Gets all videos for a given user id
   * @param userId
   * @returns  The videos for the given user id, null if failed
   */
  async getVideosByUserId(
    userId: string,
    page_number: number,
    page_size: number,
    visibility?: VIDEO_PUBLISH_STATE,
    searchTerm?: string,
    fields?: string,
    orderBy?: string,
    orderDirection?: "ASC" | "DESC",
    folder_id?: string
  ): Promise<VIDEO_LIST> {
    let res = await this.m_UserAPI.getVideosByUserId(
      userId,
      page_number,
      page_size,
      folder_id, //folder_id
      visibility,
      searchTerm,
      fields,
      orderBy,
      orderDirection
    );

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to get videos");
    }

    let paginatedVideos = res.videos;
    let userVideos: VIDEO[] = [];

    if (paginatedVideos != null && paginatedVideos.videos.length > 0) {
      //loop over videos and convert them to VIDEO objects
      for (let i = 0; i < paginatedVideos.videos.length; i++) {
        userVideos[i] = new VIDEO(paginatedVideos.videos[i]);
      }
    }

    let responseList: VIDEO_LIST = {
      data: userVideos,
      total: res.videos?.totalItems ?? 0,
      totalPages: res.videos?.totalPages ?? 0,
    };

    return responseList;
  }
  //--------------------------------------------------------------------
  /**
   *  Gets user info a given user id
   * @param userId The id of the user to fetch
   * @returns  The user info, null if failed
   */
  async getUserInfo(userId: string, ignoreCache = false): Promise<USER_DTO> {
    if (!ignoreCache) {
      const cachedUser = await this.m_UserDataCache.getCachedData(userId);
      if (cachedUser) {
        return cachedUser;
      }
    }

    // Fetch from the network
    const fetchPromise = this.m_UserAPI.getUserInfo(userId).then((res) => {
      if (res.status !== 200) {
        throw ErrorFactory.error(res.status, "Failed to get user info");
      }

      return res.user;
    });

    this.m_UserDataCache.setCachedData(userId, fetchPromise);
    return fetchPromise;
  }

  //--------------------------------------------------------------------
  /**
   *  Gets cached user info a given user id
   * @param userId The id of the user to fetch
   * @returns  The user info, null if failed
   */
  async getUserCachedData(userId: string): Promise<USER_DTO | null> {
    const cachedUser = await this.m_UserDataCache.getCachedData(userId);
    if (cachedUser) {
      return cachedUser;
    } else return null;
  }
  //--------------------------------------------------------------------
  async getUserInfoByUsername(
    username: string,
    ignoreCache = false
  ): Promise<USER_DTO> {
    if (!ignoreCache) {
      const cachedUser = await this.m_UserDataCache.getUserByUsername(username);
      if (cachedUser) {
        return cachedUser;
      }
    }

    // Fetch from the network
    const fetchPromise = this.m_UserAPI
      .getUserInfoByUsername(username)
      .then((res) => {
        if (res.status !== 200) {
          throw ErrorFactory.error(res.status, "Failed to get user info");
        }
        return res.user;
      });
    this.m_UserDataCache.setCachedData(username, fetchPromise);
    return fetchPromise;
  }
  //--------------------------------------------------------------------
  /**
   * Fetches user data for a list of user ids and stores it in the cache
   * @param userIds ids of the users to prefetch
   * @returns The user data for the given user ids
   */
  async prefetchUsersData(userIds: string[]) {
    let users = [];
    for (const userId of userIds) {
      try {
        users.push(await this.getUserInfo(userId)); // Fetches and caches if missing
      } catch (error) {
        console.error(`Error prefetching user ${userId}`, error);
      }
    }

    return users;
  }
  //--------------------------------------------------------------------
  /**
   *  Sets user info a given user id
   * @param userInfo
   */
  async setUserInfo(
    userInfo: USER_DTO_UPDATE_REQ,
    picture?: File | null,
    banner?: File
  ): Promise<void> {
    let res = await this.m_UserAPI.setUserInfo(userInfo, picture, banner);

    if (res.status !== 200) {
      if (res.error != "" && res.error != null)
        throw ErrorFactory.error(res.status, res.error);
      else throw ErrorFactory.error(res.status, "Failed to set user info");
    }

    if (userInfo.id == this.m_ActiveUserInfo?.id) {
      //Refresh the user info to get the updated info
      this.m_ActiveUserInfo = await this.getUserInfo(userInfo.id, true);
      this.m_ActiveUserSettings = await this.getSettings(userInfo.id);
    }

    this.m_UserDataCache.updateCachedData(userInfo.id, userInfo);
  }
  //--------------------------------------------------------------------
  /**
   *  Gets user info a given user id
   * @param userId
   * @returns  The user info, null if failed
   */
  async getUserLimits(userId: string): Promise<USER_LIMITS> {
    let res = await this.m_UserAPI.getUserLimits(userId);

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to get user limits");
    }

    return new USER_LIMITS(res);
  }
  //--------------------------------------------------------------------
  /**
   * Gets a user's settings
   * @param userId
   */
  async getSettings(userId: string): Promise<USER_DTO_GET_SETTINGS_RES> {
    let res = await this.m_UserAPI.getSettings(userId);

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to get user settings");
    }

    return res;
  }
  //--------------------------------------------------------------------
  async acceptEULA(): Promise<void> {
    let res = await this.m_UserAPI.acceptEULA();

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to accept EULA");
    }
  }
  //--------------------------------------------------------------------
  async createCheckoutSession(priceID: string): Promise<string> {
    let res = await this.m_UserAPI.createCheckoutSession(priceID);

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to create checkout session");
    }

    return res.url;
  }
  //--------------------------------------------------------------------
  async createPortalLink(): Promise<string> {
    let res = await this.m_UserAPI.createPortalLink();

    if (res.status !== 200) {
      throw ErrorFactory.error(
        res.status,
        "Failed to create a manage subscriptiom link"
      );
    }

    return res.url;
  }
  //--------------------------------------------------------------------
  async getUserPlaylists(
    userId: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<LIST_USER_PLAYLISTS_DTO_RES> {
    let res = await this.m_UserAPI.getUserPlaylists(
      userId,
      pageSize,
      pageNumber
    );

    if (res.status !== 200) {
      throw ErrorFactory.error(res.status, "Failed to get playlist");
    }

    return res;
  }

  //--------------------------------------------------------------------
  public clearUserInfo() {
    this.m_ActiveUserInfo = null;
    this.m_ActiveUserLimits = null;
    this.m_ActiveUserSettings = null;
    this.m_LastSeenObserver = null;
  }
  //--------------------------------------------------------------------
  public getErrorType(errorMessage: string) {
    switch (errorMessage) {
      case "Invalid data":
        return "invalidData";
      case "Email cannot be updated":
        return "emailError";
      case "User does not have permission to update this user":
        return "userPermission";
      case "User name cannot be longer than 50 characters":
        return "userCharacters";
      case "User name already taken":
        return "userTaken";
      case "Invalid phone number format. Expected format: +{intl_code}{phone_number}":
        return "invalidPhoneFormat";
      case "Headline cannot be longer than 100 characters":
        return "headlineCharacters";
      default:
        return "unknownError";
    }
  }
  public errorNotification(message?: string) {
    if (message != "" && message != null) {
      let errortext = this.getErrorType(message);
      return this.$t("pages.editProfile.errors." + errortext);
    } else {
      return this.$t("pages.editProfile.errors.profileUpdateFailed");
    }
  }

  private onLastSeenComplete() {
    this.m_LastSeenObserver?.next();
    this.m_LastSeenObserver?.complete();
    this.m_LastSeenObserver = null;
  }

  public updateUserVideoLimits(addedValue: number) {
    // Adds minutes to the user limits usage
    if (this.m_ActiveUserLimits?.subInfo.currentLength == null) return;
    let newUsage =
      this.m_ActiveUserLimits.subInfo.currentLength +
      Math.ceil(addedValue / 60);

    this.m_ActiveUserLimits.subInfo.currentLength = newUsage;
  }
  public async updateUserQnALimits(addedValue: number) {
    // Adds records to current records count
    if (this.m_ActiveUserLimits?.subInfo.currentRecordNumber == null) return;

    if (addedValue == -1 && this.m_ActiveUserInfo?.id) {
      let limits = await this.getUserLimits(this.m_ActiveUserInfo?.id);
      this.m_ActiveUserLimits = limits;
      return;
    }
    let newUsage =
      this.m_ActiveUserLimits?.subInfo.currentRecordNumber + addedValue;

    this.m_ActiveUserLimits.subInfo.currentRecordNumber = newUsage;
  }

  //#endregion
}
