import { UserService } from "../../services/user/user.service";
import { BaseApiService } from "../base-api-service";
import { Events } from "src/app/services/events/events.service";
import {
  CREATE_OVERLAY_REQ_DTO,
  OVERLAY_LIST_RES_DTO,
  OVERLAY_RES_DTO,
  UPDATE_OVERLAY_REQ_DTO,
} from "@shared/models/overlays/overlay";
import { BASE_RESPONSE } from "@shared/models/api/api";

export class OverlayApi extends BaseApiService {
  //Video Routes
  private static readonly overlayCreateEndpoint = {
    method: "POST",
    path: "/api/video/{{videoId}}/overlays",
  };

  private static readonly overlayListGetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/overlays",
  };

  private static readonly overlayGetEndpoint = {
    method: "GET",
    path: "/api/overlay/{{overlayId}}",
  };

  private static readonly overlayDeleteEndpoint = {
    method: "DELETE",
    path: "/api/overlay/{{overlayId}}",
  };

  private static readonly overlayUpdateEndpoint = {
    method: "PATCH",
    path: "/api/overlay/{{overlayId}}",
  };

  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }

  //#region Video Routes
  //--------------------------------------------------------------------
  async getOverlaysList(videoId: string): Promise<OVERLAY_LIST_RES_DTO> {
    let apiRequest = {
      method: OverlayApi.overlayListGetEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: OVERLAY_LIST_RES_DTO = await this.makeRequestWithRetry(
      OverlayApi.overlayListGetEndpoint.path.replace("{{videoID}}", videoId) +
        "?fields=id&fields=name&fields=start_time&fields=end_time&fields=whole_video&fields=is_active&fields=background_url&fields=background_color",
      apiRequest,
      "Get Overlays Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getOverlayByID(overlayId: string): Promise<OVERLAY_RES_DTO> {
    let apiRequest = {
      method: OverlayApi.overlayGetEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: OVERLAY_RES_DTO = await this.makeRequestWithRetry(
      OverlayApi.overlayGetEndpoint.path.replace("{{overlayId}}", overlayId),
      apiRequest,
      "Get Overlay Failed",
      3,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async createOverlay(
    videoId: string,
    data: CREATE_OVERLAY_REQ_DTO
  ): Promise<OVERLAY_RES_DTO> {
    let apiRequest = {
      method: OverlayApi.overlayCreateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response: OVERLAY_RES_DTO = await this.makeRequestWithRetry(
      OverlayApi.overlayCreateEndpoint.path.replace("{{videoId}}", videoId),
      apiRequest,
      "Create Overlay Failed",
      1,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async updateOverlay(
    overlayId: string,
    data: UPDATE_OVERLAY_REQ_DTO
  ): Promise<OVERLAY_RES_DTO> {
    let apiRequest = {
      method: OverlayApi.overlayUpdateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response: OVERLAY_RES_DTO = await this.makeRequestWithRetry(
      OverlayApi.overlayUpdateEndpoint.path.replace("{{overlayId}}", overlayId),
      apiRequest,
      "Create Overlay Failed",
      1,
      200
    );

    return response;
  }

  //--------------------------------------------------------------------
  async deleteOverlay(overlayId: string): Promise<BASE_RESPONSE> {
    let apiRequest = {
      method: OverlayApi.overlayDeleteEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: BASE_RESPONSE = await this.makeRequestWithRetry(
      OverlayApi.overlayDeleteEndpoint.path.replace("{{overlayId}}", overlayId),
      apiRequest,
      "Delete Overlay Failed",
      3,
      200
    );

    return response;
  }

  //#endregion Video Routes
}
