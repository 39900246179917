import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { EVENTS } from "src/app/constants/events";

import { VIDEO } from "src/app/models/video/video";
import { HIA_MSG } from "src/app/services/chat/chat.service";
import { Events } from "src/app/services/events/events.service";
import { T } from "src/app/services/localization/localization.service";
import { VideoPublishComponent } from "../video-publish/video-publish.component";
import { RecordEditorComponent } from "../../record-management/record-editor/record-editor/record-editor.component";
import { PLAYLIST_WITH_VIDEOS } from "../../playlist/playlist-watch/playlist-watch.component";
import { SelectEvent } from "@progress/kendo-angular-layout/tabstrip/events";
import { ConfigService } from "src/app/services/config/config.service";
import { VideoOverlayListComponent } from "../../overlays/video-overlay-list/video-overlay-list.component";

@Component({
  selector: "app-video-properties",
  templateUrl: "./video-properties.component.html",
  styleUrls: ["./video-properties.component.scss"],
  encapsulation: ViewEncapsulation.None, //Needed in order to override Kendo styles
})
export class VideoPropertiesComponent implements OnInit {
  @ViewChild("m_RootContainer", { read: ViewContainerRef, static: false })
  m_RootContainer: ViewContainerRef | undefined;
  @ViewChild(VideoPublishComponent, { static: false }) m_VideoPublishComponent:
    | VideoPublishComponent
    | undefined;
  @ViewChild(RecordEditorComponent, { static: false }) m_RecordEditorComponent:
    | RecordEditorComponent
    | undefined;
  @ViewChildren(VideoOverlayListComponent)
  overlayListComponents!: QueryList<VideoOverlayListComponent>;

  @Input() public m_Edit: boolean | null = null;
  @Input() public m_QnAFullscreen: boolean | null = null;
  @Input() public videoTemplate: TemplateRef<any> | undefined;

  @Output() onOverlayPreview = new EventEmitter<number>();
  @Output() onOverlayModalOpen = new EventEmitter<number>();

  get UnsavedChanges() {
    let unsavedChanges = false;
    if (this.m_VideoPublishComponent) {
      unsavedChanges = this.m_VideoPublishComponent.UnsavedChanges;
    }
    if (this.m_RecordEditorComponent && unsavedChanges === false) {
      unsavedChanges = this.m_RecordEditorComponent.UnsavedChanges;
    }
    return unsavedChanges;
  }

  get UnsavedChangesText() {
    let unsavedChangesText = "";
    if (
      this.m_VideoPublishComponent &&
      this.m_VideoPublishComponent.UnsavedChanges
    ) {
      unsavedChangesText = this.$t("shared.messages.unsavedProperties");
    }
    if (
      this.m_RecordEditorComponent &&
      unsavedChangesText === "" &&
      this.m_RecordEditorComponent.UnsavedChanges
    ) {
      unsavedChangesText = this.$t("shared.messages.unsavedQuestions");
    }
    return unsavedChangesText;
  }
  get PlaylistAutoScroll() {
    return this.m_PlaylistAutoScroll;
  }
  //--------------------------------------------------------------------
  public m_Video: VIDEO | null = null;
  public m_PlaylistData?: PLAYLIST_WITH_VIDEOS;
  public m_Closed: boolean = false;
  public $t = T.translate;

  private m_CurrentTab = "";
  private m_PlaylistAutoScroll = false;
  private m_OverlaysEnabled?: boolean = false;
  private overlayListInitialized = false;
  //--------------------------------------------------------------------
  constructor(
    private m_Events: Events,
    private m_ConfigService: ConfigService
  ) {}
  //--------------------------------------------------------------------
  async ngOnInit() {
    try {
      let overlayConfig = JSON.parse(
        await this.m_ConfigService.get("OVERLAY_CONFIG")
      );
      this.m_OverlaysEnabled = overlayConfig?.enabled;
    } catch (err) {
      this.m_OverlaysEnabled = false;
    }
  }

  ngAfterViewInit() {
    this.overlayListComponents.changes.subscribe(() => {
      this.initializeOverlayList();
    });
  }

  initializeOverlayList() {
    if (!this.overlayListInitialized && this.overlayListComponents.first) {
      this.overlayListComponents.first.onOverlayPreview.subscribe(
        (timeInSeconds: number) => {
          this.onOverlayPreview.emit(timeInSeconds);
        }
      );

      this.overlayListComponents.first.onOverlayModalOpen.subscribe(
        () => {
          this.onOverlayModalOpen.emit();
        }
      );

      this.overlayListInitialized = true;
    }
  }
  //--------------------------------------------------------------------
  //#region HTML Handlers
  onShowAttachment(msg: HIA_MSG) {
    this.m_Events.publish(EVENTS.ATTACHMENT_SHOW, msg);
  }
  //#endregion
  //#region Public Methods
  /**
   * Public method to initialize the properties component and subcomponents
   * @param video
   */
  async intializeProperties(
    video: VIDEO,
    queuedQuestions: string[] = [],
    queuedSearch: string = "",
    playlistData?: PLAYLIST_WITH_VIDEOS,
    playlistAutoScroll = false
  ) {
    this.m_PlaylistAutoScroll = playlistAutoScroll;
    if (this.m_RecordEditorComponent) {
      this.m_RecordEditorComponent.addQuestions(queuedQuestions, true);
      this.m_RecordEditorComponent.m_Search = queuedSearch;
    }
    this.m_Video = video;
    this.m_PlaylistData = playlistData;

    if (playlistData != null) {
      this.m_CurrentTab = "Playlist";
    }
  }

  reset() {
    this.m_Video = null;
  }
  //#endregion
  //#region Render Helpers
  getVideoId() {
    return this.m_Video?.video_id;
  }

  getReadOnly() {
    return this.m_Video?.read_only;
  }

  getIsMobile() {
    return window.innerWidth <= 768;
  }

  getButtonName(name: string) {
    return name + "_" + (this.m_Video?.video_id ?? "");
  }

  onChangeTab(event: SelectEvent) {
    const selectedTab = event.title;

    setTimeout(() => {
      this.m_CurrentTab = selectedTab;
    }, 1);

    if (selectedTab === "Overlays" && this.m_OverlaysEnabled) {
      setTimeout(() => this.initializeOverlayList(), 0); // Ensure the component has time to initialize
    }
  }

  overlaysEnabled(): boolean {
    return this.m_OverlaysEnabled || false;
  }
  //#endregion

  isInPlaylist() {
    return this.m_CurrentTab == "Playlist";
  }

  isSelected(tabName: string) {
    let initialSelectedTabName = this.getInitialSelectedTabName();

    if (this.m_CurrentTab == "") {
      return initialSelectedTabName == tabName;
    } else if (
      this.m_CurrentTab == "Video" &&
      initialSelectedTabName != "Video"
    ) {
      return initialSelectedTabName == tabName;
    } else {
      return this.m_CurrentTab == tabName;
    }
  }

  private getInitialSelectedTabName(): string {
    if (!this.m_Edit) {
      return this.m_PlaylistData != null ? "Playlist" : "Filters";
    } else {
      if (!this.m_QnAFullscreen && this.getIsMobile()) {
        return "Video";
      } else {
        return "Q&A";
      }
    }
  }
}
