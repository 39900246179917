export const EVENTS = {
  PLAYER_TIME_UPDATE: "event.playerTimeUpdate",
  PLAYER_PLAY: "event.playerPlay",
  PLAYER_PAUSE: "event.playerPause",
  PLAYER_ENDED: "event.playerEnded",
  PLAYER_SEEK_START: "event.playerSeekStart",
  PLAYER_SEEK_END: "event.playerSeekEnd",
  PLAYER_FULLSCREEN: "event.playerFullscreen",
  PLAYER_360: "event.player360",
  PAGE_SHOW: "event.pageShow",
  VIDEO_LOADED: "event.videoLoaded",
  VIDEO_UPDATED: "event.videoUpdated",
  VIDEO_DELETED: "event.videoDelete",
  PLAYLIST_DELETED: "event.playlistDeleted",
  MEDIA_SHOW: "event.mediaShow",
  ATTACHMENT_SHOW: "event.attachmentShow",
  ATTACHMENT_HIDE: "event.attachmentHide",
  QNA_MODAL_SHOW: "event.qnaModalShow",
  MEDIA_DELETE_CONFIRM_SHOW: "event.mediaDeleteConfirmShow",
  MEDIA_DELETE_CONFIRMED: "event.mediaDeleteConfirmed",
  RECORD_REFRESH_QUESTIONS: "event.recordRefreshQuestions",
  RECORD_DELETE: "event.recordDelete",
  RECORD_ADD_ATTACHMENT_CLICKED: "event.recordAddAttachmentClicked",
  RECORD_SET_ATTACHMENT: "event.recordSetAttachment",
  RECORD_REMOVE_ATTACHMENT: "event.recordRemoveAttachment",
  RECORD_UPDATE_ID: "event.recordUpdateId",
  RECORD_UPDATED: "event.recordUpdated",
  RECORD_ERROR: "event.recordError",
  RECORD_DUPLICATED: "event.recordDuplicatedModal",
  RECORD_DUPLICATED_CONFIRMED: "event.recordDuplicatedModalConfirmed",
  RECORD_GENERATED_CONFIRMED: "event.recordGeneratedConfirmed",
  SHOW_PRICING_MODAL: "event.showPricingModal",
  ALERT: "event.alert",
  UPDATE_CC_STATE: "event.updateCCState",
  INSIGHT_LOADED: "event.InsightLoaded",
  INSIGHT_DESTROY: "event.InsightDestroy",
  INSIGHT_RENDER: "event.InsightRender",
  INSIGHT_LANGUAGE_CHANGED: "event.InsightLanguageChanged",
  SHOW_TERMS_MODAL: "event.showTermsModal",
  TERMS_MODAL_DISMISSED: "event.termsModalDismissed",
  UPDATE_LIMITS: "event.uploadLimits",
  REUPLOAD_VIDEO: "event.reuploadVideo",
  SHOW_FOLDERS_MODAL: "event.showFoldersModal",
  FOLDERS_UPDATED: "event.foldersUpdated",
  UPDATE_THUMBNAIL: "event.updateThumbnail",
  OPEN_FEEDBACK: "event.openFeedback",
  PROFILE_UPDATED: "event.profileUpdated",
  OPEN_SHARE_MODAL: "event.openShareModal",
  VIDEO_MOVED: "event.videoMoved",
  EXPAND_SIDEBAR: "event.expandSidebar",
  VIDEO_STATUS_UPDATED: "event.videoStatusUpdated",
  OPEN_EXPAND_SUB_MODAL: "event.openExpandSubModal",
  OPEN_PLAYLIST_MODAL: "event.openPlaylistModal",
  PLAYLIST_REFRESH: "event.playlistRefresh",
  SESSION_EXPIRED: "event.sessionExpired",
  OVERLAY_UPDATED: "event.overlayUpdated",
  OVERLAY_DELETED: "event.overlayDeleted",
  OVERLAY_WATCH: "event.overlayDeleted",
  ADMIN_SCROLL_BOTTOM: "event.adminScrollBottom",
};
