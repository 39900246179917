import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { uuidToShortId } from "@shared/utils/utils";
import { VIDEO } from "src/app/models/video/video";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";

@Component({
  selector: "app-embed-modal",
  templateUrl: "./embed-modal.component.html",
  styleUrls: ["./embed-modal.component.scss"],
  standalone: false,
})
export class EmbedModalComponent implements OnInit {
  @Input() set IsOpen(value: boolean) {
    if (this.m_IsOpen == value) return;
    this.m_IsOpen = value;
  }
  @Input() set VideoData(value: VIDEO | null) {
    if (!value || this.m_VideoData == value) return;
    this.m_VideoData = value;
    this.m_ShortId = uuidToShortId(this.m_VideoData.video_id);
  }
  @ViewChild("m_EmbedModalRootContainer", {
    read: ViewContainerRef,
    static: false,
  })
  m_EmbedModalRootContainer: ViewContainerRef | undefined;

  public $t = T.translate;
  public m_IsOpen: boolean = false;

  private m_VideoData: VIDEO | null = null;
  private m_ShortId: string = "";

  constructor(
    private m_ModalController: ModalController,
    private m_NotificationService: HIANotificationService
  ) {}

  ngOnInit() {}

  //#region Public Methods
  show() {
    this.m_IsOpen = true;
  }

  close() {
    this.m_IsOpen = false;
  }
  //#endregion
  //#region HTML Handlers
  onEmbedLinkCloseClicked() {
    this.m_ModalController.dismiss();
  }

  copyEmbedLinkToClipboard() {
    navigator.clipboard.writeText(this.getEmbedShareableLink());
    this.m_NotificationService.showSuccess(
      this.$t("shared.messages.linkCopied"),
      2000
    );
  }
  //#endregion

  //#region HTML Render Helpers
  getEmbedShareableLink(): string {
    let link = "<iframe ";
    link += 'title="{{VIDEO_NAME}}" ';
    link += 'src="{{VIDEO_LINK}}" ';
    link += 'allow="microphone;autoplay;fullscreen" ';
    link += 'allowtransparency="true" ';
    link += 'width="1280" height="720" ';
    link += "></iframe>";

    link = link.replace("{{VIDEO_NAME}}", this.m_VideoData?.video_name ?? "");

    let videoLink = window.location.origin;
    videoLink += "/embed?v=";
    videoLink += this.m_ShortId;
    videoLink += "&autoplay=true";
    link = link.replace("{{VIDEO_LINK}}", videoLink);

    return link;
  }
  //#endregion
}
