import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";

import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { SessionService } from "src/app/services/session/session.service";
import { OverlaysService } from "src/app/services/overlays/overlays.service";
import { OVERLAY_DTO, OVERLAY_LOCATION } from "@shared/models/overlays/overlay";
import { QvioViewMode, VIDEO } from "src/app/models/video/video";
import { AppearanceProperty } from "../appearance/appearance.component";
import { VideoWidgetComponent } from "../../video/video-widget/video-widget.component";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { OVERLAY } from "src/app/models/overlays/overlay";
import { AlertModalComponent } from "../../modals/alert-modal/alert-modal.component";
import { IonModal } from "@ionic/angular";

@Component({
  selector: "app-overlay-modal",
  templateUrl: "./overlay-modal.component.html",
  styleUrls: ["./overlay-modal.component.scss"],
  encapsulation: ViewEncapsulation.None, //Needed in order to override Kendo styles
})
export class OverlayModalComponent implements OnInit {
  @ViewChild("m_RootContainer", { read: ViewContainerRef, static: false })
  m_RootContainer: ViewContainerRef | undefined;
  @Input() m_Video: VIDEO | null = null;

  @ViewChild(VideoWidgetComponent) m_VideoWidgetComponent:
    | VideoWidgetComponent
    | undefined;

  @ViewChild("m_VideoContainer") m_VideoContainer: ElementRef | undefined;

  @ViewChild("dismissWarning", { read: AlertModalComponent }) m_DismissWarning:
    | AlertModalComponent
    | undefined;

  @ViewChild("m_OverlayModal", { read: IonModal }) m_OverlayModal:
    | IonModal
    | undefined;

  @Input() set Video(value: VIDEO | null) {
    if (!value || this.m_Video == value) return;
    this.m_Video = value;
  }

  public $t = T.translate;

  public m_IsOpen: boolean = false;
  public m_Loading: boolean = false;
  public m_VideoId: string = "";
  public m_DisableSaving: boolean = false;
  public m_OverlayId: string = "";
  public m_OverlayData: OVERLAY_DTO | null = null;
  public m_OverlayList: OVERLAY_DTO[] = [];
  public m_VisibleOverlaysIds: string[] = [];
  public m_BackgroundFile: string | null = null;
  public m_QvioViewMode: QvioViewMode = QvioViewMode.EDIT;

  private m_ShowAllOthers: boolean = false;
  private m_WindowResizeEvent: EventListener | null = null;
  private m_HasUnsavedChanges: boolean = false;
  private m_ActiveState: boolean = false;

  get IsOpen() {
    return this.m_IsOpen;
  }

  get UserId() {
    return this.m_AuthService.UserId;
  }

  get CurrentTime() {
    return this.m_VideoWidgetComponent?.CurrentVideoTime;
  }

  constructor(
    private m_OverlaysService: OverlaysService,
    private m_AuthService: SessionService,
    private m_NotificationService: HIANotificationService,
    private m_Events: Events
  ) {}

  //#region Html Handlers
  ngOnInit() {
    if (this.m_WindowResizeEvent != null)
      window.addEventListener("resize", this.m_WindowResizeEvent);
    window.dispatchEvent(new Event("resize"));

    this.m_WindowResizeEvent = () => this.resizePage();
  }

  ngOnDestroy() {
    if (this.m_WindowResizeEvent != null)
      window.removeEventListener("resize", this.m_WindowResizeEvent);
  }

  async show(overlayId: string, overlayList: OVERLAY_DTO[]) {
    this.m_IsOpen = true;
    this.m_OverlayId = overlayId;
    this.m_OverlayList = overlayList;
    await this.getOverlayData();
    setTimeout(() => {
      this.initVideo();
    }, 1000);
  }

  onCancelClicked() {
    this.m_OverlayModal?.dismiss();
  }

  onWillPresent() {
    this.m_IsOpen = true;
  }

  async onWillDismiss() {
    this.m_OverlayId = "";
    this.m_VideoId = "";
    this.m_IsOpen = false;
    this.m_HasUnsavedChanges = false;
  }

  canDismiss = async (): Promise<boolean> => {
    if (!this.m_HasUnsavedChanges) {
      return true;
    }
    if (this.m_DismissWarning) {
      this.m_DismissWarning.m_MainText = this.$t(
        "shared.messages.unsavedChanges"
      );
      this.m_DismissWarning.m_Description = this.$t(
        "shared.messages.saveChanges"
      );

      this.m_DismissWarning.m_ShowIcon = true;
      this.m_DismissWarning.m_CustomClass = "delete-modal";
    }

    let result = await this.m_DismissWarning?.show();
    if (result?.ok) {
      return true;
    }
    return false;
  };

  //#endregion
  //#region Private Helpers

  async initVideo() {
    if (!this.m_Video || !this.m_VideoWidgetComponent) return;

    //Copy video data so that it wont pass along to edit page
    const videoData = JSON.parse(JSON.stringify(this.m_Video));
    
    await this.m_VideoWidgetComponent?.initVideo(videoData);
    this.updateOverlayVideoPlayer();
    this.resizePage();
  }

  async getOverlayData() {
    if (!this.m_OverlayId) return;
    this.m_Loading = true;

    try {
      this.m_OverlayData = await this.m_OverlaysService.getOverlayByID(
        this.m_OverlayId
      );
      this.m_VisibleOverlaysIds = [this.m_OverlayData.id];
      this.m_ActiveState = this.m_OverlayData.is_active;
    } catch (error: any) {
      console.error(error);
      this.m_NotificationService.showError(error.message, 10000);
      this.onCancelClicked();
    } finally {
      this.m_Loading = false;
    }
  }

  async saveOverlayData() {
    if (!this.m_OverlayData) return;
    this.m_Loading = true;

    const {
      video_id,
      created_at,
      last_modified,
      background_url,
      is_active,
      ...overlayData
    } = this.m_OverlayData;

    if (this.m_BackgroundFile) {
      overlayData.background_media_id = this.m_BackgroundFile; // Add media_id property
    }

    try {
      const response = await this.m_OverlaysService.updateOverlay(
        this.m_OverlayId,
        overlayData
      );

      if (response) {
        this.m_NotificationService.showSuccess(
          "Overlay updated succesfully",
          10000
        );
        this.updateOverlayEvent();
        this.m_HasUnsavedChanges = false;
        this.onCancelClicked();
      }
    } catch (error: any) {
      console.error(error);
      this.m_NotificationService.showError(error.message, 10000);
    } finally {
      this.m_Loading = false;
    }
  }

  //--------------------------------------------------------------------
  onEditComplete(event: Event) {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
    }
    const newName = (event.target as HTMLElement).textContent;
    if (newName && newName != this.m_OverlayData?.name) {
      if (this.m_OverlayData) {
        this.m_OverlayData.name = newName;
        this.m_HasUnsavedChanges = true;
      }
    }
  }

  //--------------------------------------------------------------------
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;

    if (!clipboardData) {
      return;
    }

    const items = clipboardData.items;
    this.m_HasUnsavedChanges = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        // Prevent the default paste behavior if an image is detected
        event.preventDefault();
        break;
      }
    }
  }

  //--------------------------------------------------------------------
  onAppearanceChanged(event: { property: AppearanceProperty; value: any }) {
    if (this.m_OverlayData) {
      switch (event.property) {
        case "backgroundColor":
          this.m_OverlayData.background_color = event.value;
          break;
        case "borderColor":
          this.m_OverlayData.border_color = event.value;
          break;
        case "foregroundColor":
          this.m_OverlayData.foreground_color = event.value;
          break;
        case "borderRadius":
          this.m_OverlayData.border_radius = event.value;
          break;
        case "showAllOthers":
          this.m_ShowAllOthers = event.value;

          if (this.m_ShowAllOthers) {
            if (this.m_OverlayList.length > 1) {
              this.m_VisibleOverlaysIds = this.m_VisibleOverlaysIds.concat(
                this.m_OverlayList
                  .filter((o: OVERLAY_DTO) => o.id !== this.m_OverlayId)
                  .map((o: OVERLAY_DTO) => o.id)
              );
            }
          } else {
            this.m_VisibleOverlaysIds = [this.m_OverlayData.id];
          }
          break;
      }
      this.updateOverlayVideoPlayer();
    }
  }
  //--------------------------------------------------------------------
  onBackgroundChanged(event: { property: string; value: any }) {
    if (this.m_OverlayData) {
      switch (event.property) {
        case "m_BackgroundImage":
          this.m_OverlayData.background_url = event.value;
          break;
        case "m_MediaId":
          this.m_BackgroundFile = event.value;
          break;
        case "m_Size":
          this.m_OverlayData.fitting = event.value;
          break;
      }
      this.updateOverlayVideoPlayer();
    }
  }
  //--------------------------------------------------------------------
  onDimensionChanged(event: { property: string; value: number }) {
    if (!this.m_OverlayData) {
      return;
    }

    switch (event.property) {
      case "m_Width":
        this.m_OverlayData.width = event.value;
        break;
      case "m_Height":
        this.m_OverlayData.height = event.value;
        break;
      case "m_OffsetX":
        this.m_OverlayData.offset_x = event.value;
        break;
      case "m_OffsetY":
        this.m_OverlayData.offset_y = event.value;
        break;
    }

    this.updateOverlayVideoPlayer();
  }

  //--------------------------------------------------------------------
  onTimeChanged(event: { property: string; value: number | null }) {
    if (!this.m_OverlayData) {
      return;
    }

    switch (event.property) {
      case "m_StartTime":
        if (!event.value) this.m_OverlayData.start_time = 0;
        else {
          this.m_OverlayData.start_time = event.value;
          this.m_DisableSaving = false;
        }
        break;
      case "m_EndTime":
        if (!event.value) this.m_OverlayData.end_time = 0;
        else {
          this.m_OverlayData.end_time = event.value;
          this.m_DisableSaving = false;
        }
        break;
    }

    this.updateOverlayVideoPlayer();
  }

  onWholeVideoChanged(event: { value: boolean }) {
    if (!this.m_OverlayData) {
      return;
    }
    this.m_OverlayData.whole_video = event.value;
    this.updateOverlayVideoPlayer();
  }

  onTypeChanged(event: { value: number }) {
    if (!this.m_OverlayData) {
      return;
    }
    this.m_OverlayData.overlay_type = event.value;
    this.updateOverlayVideoPlayer();
  }

  onLocationChanged(event: { value: OVERLAY_LOCATION }) {
    if (!this.m_OverlayData) {
      return;
    }

    this.m_OverlayData.location = event.value;

    // Reset the offset values when changing location
    this.m_OverlayData.offset_x = 0;
    this.m_OverlayData.offset_y = 0;

    this.updateOverlayVideoPlayer();
  }

  onTextChanged(event: { property: string; value: string }) {
    if (!this.m_OverlayData) {
      return;
    }

    switch (event.property) {
      case "m_TextField":
        this.m_OverlayData.text_field = event.value;
        break;
      case "m_UrlField":
        this.m_OverlayData.url_field = event.value;
        break;
      case "m_EmbedUrl":
        this.m_OverlayData.embed_url = event.value;
        break;
    }

    this.updateOverlayVideoPlayer();
  }

  updateOverlayEvent() {
    if (!this.m_OverlayData) return;
    this.m_OverlayData.is_active = this.m_ActiveState;
    const { video_id, created_at, last_modified, ...overlayData } =
      this.m_OverlayData;
    this.m_Events.publish(EVENTS.OVERLAY_UPDATED, overlayData);
  }

  //#endregion

  //#region Render Helpers
  updateOverlayVideoPlayer() {
    if (!this.m_OverlayData) return;
    const overlay = this.m_OverlayData;
    overlay.is_active = true;

    if (this.m_VideoWidgetComponent?.VideoData?.overlays) {
      const foundOverlay =
        this.m_VideoWidgetComponent?.VideoData?.overlays.find(
          (o) => o.id === this.m_OverlayData?.id
        );
      if (foundOverlay) {
        this.m_VideoWidgetComponent?.updateOverlayData(new OVERLAY(overlay));
        this.m_HasUnsavedChanges = true;
      } else {
        this.m_VideoWidgetComponent?.createOverlay(new OVERLAY(overlay));
      }
    }
  }

  initializeOverlayVideoPlayer() {
    if (!this.m_OverlayData) return;
    const overlay = this.m_OverlayData;
    overlay.is_active = true;

    if (this.m_VideoWidgetComponent?.VideoData?.overlays) {
      const foundOverlay =
        this.m_VideoWidgetComponent?.VideoData?.overlays.find(
          (o) => o.id === this.m_OverlayData?.id
        );
      if (foundOverlay) {
        this.m_VideoWidgetComponent?.updateOverlayData(new OVERLAY(overlay));
      } else {
        this.m_VideoWidgetComponent?.createOverlay(new OVERLAY(overlay));
      }
    }
  }

  disableSaving(event: { value: boolean }) {
    this.m_DisableSaving = event.value;
  }

  private async resizePage() {
    let containerSize =
      this.m_VideoContainer?.nativeElement.getBoundingClientRect();
    if (containerSize == null) return;
    let suggestedHeight = containerSize.height;
    this.m_VideoWidgetComponent?.resizeWidget({
      width: containerSize.width,
      height: suggestedHeight,
    });
  }

  //#endregion
}
