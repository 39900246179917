import { Component, OnInit } from "@angular/core";
import { USER_DTO, USER_DTO_UPDATE_REQ } from "@shared/models/user/user";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { SessionService } from "src/app/services/session/session.service";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
  standalone: false,
})
export class AccountSettingsComponent implements OnInit {
  public $t = T.translate;
  public m_UserInfo: USER_DTO;
  public m_Saving: Boolean = false;
  public m_NewPassword: string = "";
  public m_NewPasswordCopy: string = "";
  public m_CurrentPassword: string = "";
  public m_UsernameInput: string = "";
  regex = /^[a-zA-Z0-9_-]+$/;

  constructor(
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_NotificationService: HIANotificationService
  ) {
    this.m_UserInfo = {
      id: this.m_AuthService.UserId,
      username: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      privacy: null,
    };
  }

  ngOnInit() {
    this.fetchUserInfo();
  }

  resetInfo() {
    if (this.m_UserInfo.username)
      this.m_UsernameInput = this.m_UserInfo.username;
    this.m_UserInfo = {
      id: this.m_AuthService.UserId,
      username: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      privacy: null,
    };
  }

  ionViewWillLeave() {
    this.m_UsernameInput = this.m_UsernameInput.replace(/[^a-zA-Z0-9_]/g, "");
    this.resetInfo();
  }

  async fetchUserInfo() {
    let userInfo = await this.m_UserService.ActiveUserInfo;
    if (userInfo == null) {
      userInfo = await this.m_UserService.getUserInfo(this.m_UserInfo.id);
    }
    this.m_UserInfo = userInfo;
    if (this.m_UserInfo.username)
      this.m_UsernameInput = this.m_UserInfo.username;
  }

  async saveChanges() {
    this.m_Saving = true;

    if (!this.regex.test(this.m_UsernameInput)) {
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.accountSettings.usernameError"),
        1000
      );
      this.m_Saving = false;
      return;
    }

    let userInfo: USER_DTO_UPDATE_REQ = {
      id: this.m_UserInfo.id,
      username: this.m_UsernameInput,
      password: this.m_UserInfo.password,
      privacy: this.m_UserInfo.privacy,
    };

    try {
      await this.m_UserService.setUserInfo(userInfo);
      this.m_NotificationService.showSuccess(
        this.$t("pages.editProfile.profileUpdated"),
        1000
      );
    } catch (err: any) {
      let errortext = this.m_UserService.errorNotification(err.message);
      this.m_NotificationService.showError(errortext, 3000);
    } finally {
      this.m_Saving = false;
    }
  }

  async savePassword() {
    this.m_Saving = true;

    let userInfo: USER_DTO_UPDATE_REQ = {
      id: this.m_UserInfo.id,
      password: this.m_UserInfo.password,
    };

    if (this.m_UserInfo.password == this.m_CurrentPassword) {
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.accountSettings.wrongPassword"),
        1000
      );
      return;
    }
    if (this.m_NewPassword != this.m_NewPasswordCopy) {
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.accountSettings.newPasswordError"),
        1000
      );
      return;
    }
    if (this.m_NewPassword == this.m_CurrentPassword) {
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.accountSettings.NewPasswordSameAsOld"),
        1000
      );
      return;
    }
    try {
      await this.m_UserService.setUserInfo(userInfo);
      this.m_NotificationService.showSuccess(
        this.$t("pages.editProfile.profileUpdated"),
        1000
      );
    } catch (e) {
      console.log(e);
      this.m_NotificationService.showError(
        this.$t("pages.editProfile.profileUpdateFailed"),
        1000
      );
    } finally {
      this.m_Saving = false;
    }
  }

  enableButtonPassword() {
    if (this.m_CurrentPassword == "") return false;
    if (this.m_NewPassword != "") {
      if (this.m_UserInfo.password == this.m_CurrentPassword) return true;
      else return false;
    }
    return false;
  }

  handleEmailChange(value: any) {
    if (value) this.m_UserInfo.email = value;
  }

  handleUsernameChange(value: any) {
    value = value.replace(" ", "_");
    const sanitizedValue = value.replace(/[^a-zA-Z0-9_-]/g, "");
    if (value && this.regex.test(value)) {
      this.m_UsernameInput = sanitizedValue;
    } else {
      return;
    }
  }

  usernameError() {
    if (!this.m_UsernameInput) return true;
    if (this.regex.test(this.m_UsernameInput)) return false;
    else return true;
  }

  handleCurrentPasswordChange(value: any) {
    if (value) this.m_CurrentPassword = value;
  }

  handleNewPasswordChange(value: any) {
    if (value) this.m_NewPassword = value;
  }

  handleNewPasswordCopyChange(value: any) {
    if (value) this.m_NewPasswordCopy = value;
  }
}
