import {
  OVERLAY_ANIMATION_TYPE,
  OVERLAY_BACKGROUND_FITTING,
  OVERLAY_DTO,
  OVERLAY_LOCATION,
  OVERLAY_TYPE,
  PUBLIC_OVERLAY_DTO,
} from "@shared/models/overlays/overlay";

export class OVERLAY {
  id: string;
  name: string;
  start_time?: number;
  end_time?: number;
  whole_video: boolean;
  overlay_type: OVERLAY_TYPE;
  text_field?: string;
  embed_url?: string;
  url_field?: string;
  location: OVERLAY_LOCATION;
  background_url?: string;
  fitting?: OVERLAY_BACKGROUND_FITTING;
  background_color?: string;
  foreground_color?: string;
  border_color?: string;
  border_radius?: number;
  width?: number;
  height?: number;
  offset_x?: number;
  offset_y?: number;
  z_index?: number;
  opacity?: number;
  animation_type: OVERLAY_ANIMATION_TYPE;
  animation_duration?: number;
  is_sticky?: boolean;
  is_active?: boolean;

  constructor(dto?: OVERLAY_DTO | PUBLIC_OVERLAY_DTO) {
    if (dto == null) {
      this.id = "";
      this.name = "";
      this.whole_video = false;
      this.overlay_type = OVERLAY_TYPE.ELEMENT;
      this.location = OVERLAY_LOCATION.BOTTOM_LEFT;
      this.animation_type = OVERLAY_ANIMATION_TYPE.FADE;
    } else {
      let overlayDTO = dto as OVERLAY_DTO;
      this.id = overlayDTO.id;
      this.name = overlayDTO.name;
      this.start_time = overlayDTO.start_time;
      this.end_time = overlayDTO.end_time;
      this.whole_video = overlayDTO.whole_video;
      this.overlay_type = overlayDTO.overlay_type;
      this.text_field = overlayDTO.text_field;
      this.embed_url = overlayDTO.embed_url;
      this.url_field = overlayDTO.url_field;
      this.location = overlayDTO.location;
      this.background_url = overlayDTO.background_url;
      this.fitting = overlayDTO.fitting;
      this.background_color = overlayDTO.background_color;
      this.foreground_color = overlayDTO.foreground_color;
      this.border_color = overlayDTO.border_color;
      this.border_radius = overlayDTO.border_radius;
      this.width = overlayDTO.width;
      this.height = overlayDTO.height;
      this.offset_x = overlayDTO.offset_x;
      this.offset_y = overlayDTO.offset_y;
      this.z_index = overlayDTO.z_index;
      this.opacity = overlayDTO.opacity;
      this.animation_type = overlayDTO.animation_type;
      this.animation_duration = overlayDTO.animation_duration;
      this.is_sticky = overlayDTO.is_sticky;
      this.is_active = overlayDTO.is_active;
    }
  }

  copy(overlayToCopy: OVERLAY): void {
    this.id = overlayToCopy.id;
    this.name = overlayToCopy.name;
    this.start_time = overlayToCopy.start_time;
    this.end_time = overlayToCopy.end_time;
    this.whole_video = overlayToCopy.whole_video;
    this.overlay_type = overlayToCopy.overlay_type;
    this.text_field = overlayToCopy.text_field;
    this.embed_url = overlayToCopy.embed_url;
    this.url_field = overlayToCopy.url_field;
    this.location = overlayToCopy.location;
    this.background_url = overlayToCopy.background_url;
    this.fitting = overlayToCopy.fitting;
    this.background_color = overlayToCopy.background_color;
    this.foreground_color = overlayToCopy.foreground_color;
    this.border_color = overlayToCopy.border_color;
    this.border_radius = overlayToCopy.border_radius;
    this.width = overlayToCopy.width;
    this.height = overlayToCopy.height;
    this.offset_x = overlayToCopy.offset_x;
    this.offset_y = overlayToCopy.offset_y;
    this.z_index = overlayToCopy.z_index;
    this.opacity = overlayToCopy.opacity;
    this.animation_type = overlayToCopy.animation_type;
    this.animation_duration = overlayToCopy.animation_duration;
    this.is_sticky = overlayToCopy.is_sticky;
  }
}
