//#region DO_NOT_EDIT
/*
  USED BY BUILD SCRIPT, DO NOT CHANGE THESE VALUES!!!
*/
const MAJOR = "1";
const MINOR = "7";
const POINT = "7";
const REVISION = "4803";
const HASH = "1700a46d7d0dd3acea332f5dfabf512131d85176";
//#endregion DO_NOT_EDIT

export class VERSION {
  public static SIMPLE = MAJOR + "." + MINOR;
  public static DEFAULT = MAJOR + "." + MINOR + "." + POINT + "." + REVISION;
  public static FULL =
    MAJOR + "." + MINOR + "." + POINT + "." + REVISION + "-" + HASH;
}
