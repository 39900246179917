//#region DO_NOT_EDIT
/*
  USED BY BUILD SCRIPT, DO NOT CHANGE THESE VALUES!!!
*/
const MAJOR = "1";
const MINOR = "7";
const POINT = "8";
const REVISION = "4895";
const HASH = "66cbc2b7bbb565d7c0c0cf5d8ae26fe527d1fb2d";
//#endregion DO_NOT_EDIT

export class VERSION {
  public static SIMPLE = MAJOR + "." + MINOR;
  public static DEFAULT = MAJOR + "." + MINOR + "." + POINT + "." + REVISION;
  public static FULL =
    MAJOR + "." + MINOR + "." + POINT + "." + REVISION + "-" + HASH;
}
