import { BaseApiService } from "../base-api-service";

import {
  RECORDS_DTO_GET_RES,
  RECORD_DTO_QUERY_RES,
  RECORD_DTO_UPDATE_REQ,
  RECORD_DTO_UPDATE_RES,
  RECORD_DTO_DELETE_RES,
  RECORD_DTO_UPDATE_MEDIA_REQ,
  RECORD_DTO_ADVANCED_QUERY_RES,
  RECORDS_DTO_PAGINATED_GET_RES,
  GENERATE_QNA_PAIRS_RES,
  RECORD_DTO_CREATE_REQ,
} from "@shared/models/record/record";
import { UserService } from "../../services/user/user.service";
import { RECORD } from "src/app/models/record/record";
import { Events } from "src/app/services/events/events.service";

export class RecordApi extends BaseApiService {
  //Video Record Routes
  private static readonly videoQueryEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/query",
  };

  private static readonly videoQueryAdvancedEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/advanced-query",
  };

  private static readonly videoRecordsGetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/records",
  };

  private static readonly videoRecordsByMediaIdGetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/media/{{mediaID}}/referencing-records",
  };

  private static readonly videoRandomRecordGetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/records/random",
  };

  private static readonly videoSuggestRecordGetEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/records/suggested",
  };

  private static readonly videoCreateRecordEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/records",
  };

  private static readonly recordBulkCreateEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/records/bulk",
  };

  private static readonly recordExportCSVEndpoint = {
    method: "GET",
    path: "/api/video/{{videoID}}/records/export",
  };

  private static readonly recordImportJSONEndpoint = {
    method: "POST",
    path: "/api/video/{{videoID}}/records/import",
  };

  private static readonly recordDeleteAll = {
    method: "DELETE",
    path: "/api/video/{{videoID}}/records",
  };

  //Record Routes
  private static readonly recordGetEndpoint = {
    method: "GET",
    path: "/api/record/{{recordID}}",
  };

  private static readonly recordUpdateEndpoint = {
    method: "PUT",
    path: "/api/record/{{recordID}}",
  };

  private static readonly recordsUpdateEndpoint = {
    method: "PUT",
    path: "/api/record",
  };

  private static readonly recordDeleteEndpoint = {
    method: "DELETE",
    path: "/api/record/{{recordID}}",
  };

  private static readonly updateRecordMediaEndpoint = {
    method: "PUT",
    path: "/api/record/{{recordID}}/media",
  };

  private static readonly bulkDeleteEndpoint = {
    method: "DELETE",
    path: "/api/record/bulk-delete",
  };

  private static readonly recordsCompareEndpoint = {
    method: "POST",
    path: "/api/record/compare",
  };

  private static readonly recordsGenerateQnAPrompt = {
    method: "POST",
    path: "/api/record/generate-qna-pairs",
  };

  private static readonly recordsGetStateEndpoint = {
    method: "POST",
    path: "/api/record/state",
  };

  //--------------------------------------------------------------------
  constructor(
    eventsService: Events,
    private m_UserService: UserService,
    p_ApiUrl: Promise<string>
  ) {
    super(eventsService, p_ApiUrl);
  }
  //--------------------------------------------------------------------

  //#region Public Methods
  async createRecord(
    videoID: string,
    data: RECORD_DTO_CREATE_REQ
  ): Promise<RECORD_DTO_UPDATE_RES> {
    let apiRequest = {
      method: RecordApi.videoCreateRecordEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response: RECORD_DTO_UPDATE_RES = await this.makeRequestWithRetry(
      RecordApi.videoCreateRecordEndpoint.path.replace("{{videoID}}", videoID),
      apiRequest,
      "Failed to create record",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Deletes a record from the database
   * @param recordID
   */
  async deleteRecord(recordID: string): Promise<RECORD_DTO_DELETE_RES> {
    let apiRequest = {
      method: RecordApi.recordDeleteEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: RECORD_DTO_DELETE_RES = await this.makeRequestWithRetry(
      RecordApi.recordDeleteEndpoint.path.replace("{{recordID}}", recordID),
      apiRequest,
      "Failed to delete record",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  async deleteAllRecords(videoID: string) {
    let apiRequest = {
      method: RecordApi.recordDeleteAll.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
    };

    const response: RECORD_DTO_DELETE_RES = await this.makeRequestWithRetry(
      RecordApi.recordDeleteAll.path.replace("{{videoID}}", videoID),
      apiRequest,
      "Failed to delete all records",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  async bulkDeleteRecords(recordIDs: string[]): Promise<RECORD_DTO_DELETE_RES> {
    let apiRequest = {
      method: RecordApi.bulkDeleteEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ record_ids: recordIDs }),
    };

    const response: RECORD_DTO_DELETE_RES = await this.makeRequestWithRetry(
      RecordApi.bulkDeleteEndpoint.path,
      apiRequest,
      "Failed to bulk delete records",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   *  Updates a record in the database and returns the updated record DTO
   * @param record
   * @returns
   */
  async updateRecord(record: RECORD): Promise<RECORD_DTO_UPDATE_RES> {
    let data: RECORD_DTO_UPDATE_REQ = {
      question: record.question,
      answer: record.answer,
      is_pinned: record.is_pinned,
      is_suggestible: record.is_suggestible,
      web_url: record.web_url,
      media_id: record.media_id,
      start_time: record.video_start_time,
      end_time: record.video_end_time,
    };

    let apiRequest = {
      method: RecordApi.recordUpdateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response: RECORD_DTO_UPDATE_RES = await this.makeRequestWithRetry(
      RecordApi.recordUpdateEndpoint.path.replace(
        "{{recordID}}",
        record.record_id
      ),
      apiRequest,
      "Failed to update record",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   *  Updates multiple records in the database
   * @param record
   * @returns
   */
  async updateRecords(records: RECORD_DTO_UPDATE_REQ[]) {
    let data: RECORD_DTO_UPDATE_REQ[] = records.map((r) => {
      return {
        record_id: r.record_id,
        question: r.question,
        answer: r.answer,
        media_id: r.media_id,
        is_pinned: r.is_pinned,
        is_suggestible: r.is_suggestible,
        web_url: r.web_url,
        video_start_time: r.start_time,
        video_end_time: r.end_time,
      };
    });

    let apiRequest = {
      method: RecordApi.recordsUpdateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(data),
    };

    const response = await this.makeRequestWithRetry(
      RecordApi.recordsUpdateEndpoint.path,
      apiRequest,
      "Failed to update multiple records",
      1
    );

    return response;
  }

  //--------------------------------------------------------------------
  /**
   *  Compare multiple records in the database
   * @param record
   * @returns
   */
  async compareRecords(records: Array<string>) {
    let apiRequest = {
      method: RecordApi.recordsCompareEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ recordIds: records }),
    };

    const response = await this.makeRequestWithRetry(
      RecordApi.recordsCompareEndpoint.path,
      apiRequest,
      "Failed to update multiple records",
      1
    );
    return response;
  }

  async getRecord(recordID: string) {}

  //--------------------------------------------------------------------
  async getRandomRecords(videoID: string): Promise<RECORDS_DTO_GET_RES> {
    let apiRequest = {
      method: RecordApi.videoRandomRecordGetEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    const response: RECORDS_DTO_GET_RES = await this.makeRequestWithRetry(
      RecordApi.videoRandomRecordGetEndpoint.path.replace(
        "{{videoID}}",
        videoID
      ),
      apiRequest,
      "Failed to get records",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getSuggestions(
    videoID: string,
    pageNumber?: number,
    pageSize?: number,
    pageNumberSeed?: number
  ): Promise<RECORDS_DTO_PAGINATED_GET_RES> {
    let apiRequest = {
      method: RecordApi.videoSuggestRecordGetEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url = RecordApi.videoSuggestRecordGetEndpoint.path.replace(
      "{{videoID}}",
      videoID
    );
    let params = new Map<string, string>();

    if (pageNumber != null && pageSize != null) {
      params.set("pageNumber", pageNumber.toString());
      params.set("pageSize", pageSize.toString());
    }
    if (pageNumberSeed != null) params.set("seed", pageNumberSeed.toString());

    url = this.addParams(url, params);

    const response: RECORDS_DTO_PAGINATED_GET_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Failed to get records",
        3
      );

    return response;
  }
  //--------------------------------------------------------------------
  async getRecords(
    videoID: string,
    pageNumber?: number,
    pageSize?: number,
    searchTerm?: string
  ): Promise<RECORDS_DTO_PAGINATED_GET_RES> {
    let apiRequest = {
      method: RecordApi.videoRecordsGetEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let url = RecordApi.videoRecordsGetEndpoint.path.replace(
      "{{videoID}}",
      videoID
    );
    let params = new Map<string, string>();

    if (pageNumber != null && pageSize != null) {
      params.set("pageNumber", pageNumber.toString());
      params.set("pageSize", pageSize.toString());
    }
    if (searchTerm != null) {
      params.set("searchTerm", searchTerm);
    }
    url = this.addParams(url, params);

    const response: RECORDS_DTO_PAGINATED_GET_RES =
      await this.makeRequestWithRetry(
        url,
        apiRequest,
        "Failed to get records",
        3
      );

    return response;
  }
  //--------------------------------------------------------------------
  async getRecordsByMediaId(
    videoID: string,
    mediaID: string
  ): Promise<RECORDS_DTO_GET_RES> {
    let apiRequest = {
      method: RecordApi.videoRecordsByMediaIdGetEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
    };

    let path = RecordApi.videoRecordsByMediaIdGetEndpoint.path;
    path = path.replace("{{videoID}}", videoID);
    path = path.replace("{{mediaID}}", mediaID);

    const response: RECORDS_DTO_GET_RES = await this.makeRequestWithRetry(
      path,
      apiRequest,
      "Failed to get records by mediaId",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Query the records for a given video
   * @param videoID
   * @param query
   * @returns
   */
  async query(videoID: string, query: string): Promise<RECORD_DTO_QUERY_RES> {
    let apiRequest = {
      method: RecordApi.videoQueryEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ query: query }),
    };

    const response: RECORD_DTO_QUERY_RES = await this.makeRequestWithRetry(
      RecordApi.videoQueryEndpoint.path.replace("{{videoID}}", videoID),
      apiRequest,
      "Failed to get records",
      3
    );

    return response;
  }
  //--------------------------------------------------------------------
  async advancedQuery(
    videoID: string,
    query: string
  ): Promise<RECORD_DTO_ADVANCED_QUERY_RES> {
    let apiRequest = {
      method: RecordApi.videoQueryAdvancedEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ query: query }),
    };

    const response: RECORD_DTO_ADVANCED_QUERY_RES =
      await this.makeRequestWithRetry(
        RecordApi.videoQueryAdvancedEndpoint.path.replace(
          "{{videoID}}",
          videoID
        ),
        apiRequest,
        "Failed to get records",
        3
      );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Bulk Create Records from a provided CSV file
   * @param videoID
   * @param csvFile
   * @returns
   */
  async bulkCreateRecords(
    videoID: string,
    csvFile: File
  ): Promise<RECORDS_DTO_GET_RES> {
    const formData = new FormData();
    formData.append("file", csvFile);
    let apiRequest = {
      method: RecordApi.recordBulkCreateEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
      body: formData,
    };

    const response: RECORDS_DTO_GET_RES = await this.makeRequestWithRetry(
      RecordApi.recordBulkCreateEndpoint.path.replace("{{videoID}}", videoID),
      apiRequest,
      "Failed to post CSV",
      1
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Imports a JSON file into the record database for a given video id
   * @param videoID The video id to import the records to
   * @param file The JSON file to import
   * @returns The result of the import, null if failed
   */
  async importJSON(videoID: string, jsonFile: File) {
    const formData = new FormData();
    formData.append("file", jsonFile);
    let apiRequest = {
      method: RecordApi.recordImportJSONEndpoint.method,
      headers: {
        sid: this.m_UserService.SessionId,
      },
      body: formData,
    };

    const response: RECORDS_DTO_GET_RES = await this.makeRequestWithRetry(
      RecordApi.recordImportJSONEndpoint.path.replace("{{videoID}}", videoID),
      apiRequest,
      "Failed to post JSON",
      1
    );

    return response;
  }
  //--------------------------------------------------------------------
  /**
   * Exports the records for a given video id as a CSV file
   * @param videoID The video id to export the records from
   * @returns The CSV file
   */
  async exportCSV(videoID: string): Promise<Blob> {
    const response: any = await this.makeRequestForFile(
      RecordApi.recordExportCSVEndpoint.path.replace("{{videoID}}", videoID),
      RecordApi.recordExportCSVEndpoint.method,
      this.m_UserService.SessionId
    );

    return response;
  }
  //--------------------------------------------------------------------
  async updateRecordMedia(
    recordId: string,
    updateReq: RECORD_DTO_UPDATE_MEDIA_REQ
  ) {
    let apiRequest = {
      method: RecordApi.updateRecordMediaEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify(updateReq),
    };

    const response: RECORD_DTO_UPDATE_RES = await this.makeRequestWithRetry(
      RecordApi.updateRecordMediaEndpoint.path.replace(
        "{{recordID}}",
        recordId
      ),
      apiRequest,
      "Failed to update record media",
      3
    );

    return response;
  }

  //--------------------------------------------------------------------
  async generateQnAPairsByPrompt(text: string) {
    let apiRequest = {
      method: RecordApi.recordsGenerateQnAPrompt.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ text: text, numberOfPairs: 5 }),
    };

    const response: GENERATE_QNA_PAIRS_RES = await this.makeRequestWithRetry(
      RecordApi.recordsGenerateQnAPrompt.path,
      apiRequest,
      "Generate QnA Pairs Failed",
      1,
      200
    );

    return response;
  }
  //--------------------------------------------------------------------
  async getRecordsState(recordIDs: string[]) {
    let apiRequest = {
      method: RecordApi.recordsGetStateEndpoint.method,
      headers: {
        "Content-Type": "application/json",
        sid: this.m_UserService.SessionId,
      },
      body: JSON.stringify({ recordIds: recordIDs }),
    };

    const response = await this.makeRequestWithRetry(
      RecordApi.recordsGetStateEndpoint.path,
      apiRequest,
      "Failed to get records state",
      1
    );

    return response;
  }
  //#endregion
}
