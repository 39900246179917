import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { IonInput, IonTextarea, ModalController } from "@ionic/angular";
import { ActionService } from "src/app/services/action/action.service";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { UserService } from "src/app/services/user/user.service";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";

@Component({
  selector: "app-feedback-modal",
  templateUrl: "./feedback-modal.component.html",
  styleUrls: ["./feedback-modal.component.scss"],
  standalone: false,
})
export class FeedbackModalComponent implements OnInit {
  @Input() set IsOpen(value: boolean) {
    this.m_IsOpen = value;
    if (value) {
      if (this.m_MessageInput) {
        setTimeout(() => {
          this.m_MessageInput?.setFocus();
        }, 100);
      }
    }
  }
  @ViewChild("m_Root", { read: ViewContainerRef, static: false })
  m_RootContainer: ViewContainerRef | undefined;
  @ViewChild("m_MessageInput", { static: false }) m_MessageInput:
    | IonTextarea
    | undefined;
  @ViewChild("dismissWarning", { read: AlertModalComponent }) m_DismissWarning:
    | AlertModalComponent
    | undefined;

  public m_ValidData: boolean = false;
  public $t = T.translate;

  private m_IsOpen: boolean = false;
  get IsOpen(): boolean {
    return this.m_IsOpen;
  }

  constructor(
    private m_NotificationService: HIANotificationService,
    private m_ActionService: ActionService,
    private m_UserService: UserService,
    private m_ModalController: ModalController,
    private m_Events: Events
  ) {}

  ngOnInit() {
    //Open feedback modal event
    this.m_Events.subscribe(EVENTS.OPEN_FEEDBACK, () => {
      this.m_IsOpen = true;
    });
  }

  //#region Html Handlers
  onMessageChanged() {
    this.validatePayload();
  }

  onDismiss() {
    this.m_IsOpen = false;
  }

  //Function guard passed to modal to prevent closing if there are unsaved changes
  canDismiss = async (): Promise<boolean> => {
    if (
      this.m_MessageInput?.value == null ||
      this.m_MessageInput?.value == ""
    ) {
      return true;
    }
    let result = await this.m_DismissWarning?.show();
    if (result?.ok) {
      this.resetForm();
      return true;
    }
    return false;
  };

  onCloseClicked() {
    this.m_ModalController.dismiss();
  }

  async onSubmitClicked() {
    if (this.m_UserService.ActiveUserInfo == null) return;
    let message = this.m_MessageInput?.value;
    let email = this.m_UserService.ActiveUserInfo.email;
    let subject = "User Feedback";
    let name = this.m_UserService.ActiveUserInfo.username;
    if (
      this.m_UserService.ActiveUserInfo.first_name != null &&
      this.m_UserService.ActiveUserInfo.first_name != "" &&
      this.m_UserService.ActiveUserInfo.last_name != null &&
      this.m_UserService.ActiveUserInfo.last_name != ""
    ) {
      name =
        this.m_UserService.ActiveUserInfo.first_name +
        " " +
        this.m_UserService.ActiveUserInfo.last_name;
    }

    if (message == null || email == null) return;
    if (name == null || name == "") name = email;

    message += "\n\n" + this.getDeviceInfo();

    try {
      await this.m_ActionService.sendContactMessage(
        name,
        email,
        message,
        subject
      );
      this.resetForm();
      this.m_IsOpen = false;
      this.m_NotificationService.showSuccess(
        this.$t("components.modals.feedback.success"),
        3000
      );
    } catch (error) {
      this.m_NotificationService.showError(
        this.$t("components.modals.feedback.failed"),
        3000
      );
    }
  }
  //#endregion
  //#region Private Helpers
  private resetForm() {
    if (this.m_MessageInput) {
      this.m_MessageInput.value = "";
    }
  }

  private validatePayload() {
    let message = this.m_MessageInput?.value;

    if (message == null) {
      this.m_ValidData = false;
      return false;
    }

    this.m_ValidData = message.length > 0;
    return this.m_ValidData;
  }

  private getDeviceInfo(): string {
    let deviceInfo = "[DEVICE INFO]\n";
    deviceInfo += "Device: " + navigator.userAgent + "\n";
    deviceInfo += "Platform: " + navigator.platform + "\n";
    deviceInfo += "Language: " + navigator.language + "\n";
    deviceInfo += "Cookies: " + navigator.cookieEnabled + "\n";
    deviceInfo += "Screen: " + screen.width + "x" + screen.height + "\n";
    deviceInfo +=
      "App Viewport: " + window.innerWidth + "x" + window.innerHeight + "\n";
    deviceInfo += "Color Depth: " + screen.colorDepth + "\n";
    deviceInfo += "Pixel Depth: " + screen.pixelDepth + "\n";
    deviceInfo += "Local Time: " + new Date().toLocaleString() + "\n";
    //Add connection info if available
    if ((navigator as any).connection != null) {
      deviceInfo +=
        "Connection Type: " + (navigator as any).connection.type + "\n";
      deviceInfo +=
        "Connection Effective Type: " +
        (navigator as any).connection.effectiveType +
        "\n";
      deviceInfo +=
        "Connection Downlink: " +
        (navigator as any).connection.downlink +
        "Mbps \n";
      deviceInfo +=
        "Connection Downlink Max: " +
        (navigator as any).connection.downlinkMax +
        "Mbps \n";
      deviceInfo +=
        "Connection Rtt: " + (navigator as any).connection.rtt + "\n";
      deviceInfo +=
        "Connection Save Data: " +
        (navigator as any).connection.saveData +
        "\n";
    }

    return deviceInfo;
  }
  //#endregion
}
