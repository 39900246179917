import { Injectable } from "@angular/core";
import { UserService } from "src/app/services/user/user.service";
import { ErrorFactory } from "src/app/errors/custom-errors";
import { ConfigService } from "../config/config.service";
import { Events } from "../events/events.service";
import { OverlayApi } from "src/app/api/overlay/overlay-api";
import {
  CREATE_OVERLAY_REQ_DTO,
  OVERLAY_DTO,
  UPDATE_OVERLAY_REQ_DTO,
} from "@shared/models/overlays/overlay";

@Injectable({
  providedIn: "root",
})
export class OverlaysService {
  private m_OverlayApi: OverlayApi;

  //--------------------------------------------------------------------
  constructor(
    eventsService: Events,
    userService: UserService,
    configService: ConfigService
  ) {
    this.m_OverlayApi = new OverlayApi(
      eventsService,
      userService,
      configService.get("SERVER_URL")
    );
  }
  //--------------------------------------------------------------------
  //#region Public Methods
  /**
   * Gets a video overlays
   * @param videoID
   * @returns Video data response
   * @throws Error if video id is invalid
   * @throws CustomError if video failed to fetch
   */
  async getOverlays(videoID: string): Promise<OVERLAY_DTO[]> {
    if (videoID == null || videoID == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let response = await this.m_OverlayApi.getOverlaysList(videoID);

    if (response.status == 403) {
      throw ErrorFactory.error(response.status, "User does not own video");
    } else if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        response.error || "Failed to get overlays"
      );
    }

    return response.overlays;
  }

  //--------------------------------------------------------------------
  async getOverlayByID(overlayId: string): Promise<OVERLAY_DTO> {
    if (overlayId == null || overlayId == "")
      throw ErrorFactory.error(400, "Invalid video id");

    const response = await this.m_OverlayApi.getOverlayByID(overlayId);

    if (response.status == 403) {
      throw ErrorFactory.error(response.status, "User does not own video");
    } else if (response.status != 200) {
      throw ErrorFactory.error(
        response.status,
        response.error || "Failed to get overlay"
      );
    }

    return response.overlay;
  }
  //--------------------------------------------------------------------
  async createOverlay(
    videoId: string,
    overlayData: CREATE_OVERLAY_REQ_DTO
  ): Promise<OVERLAY_DTO> {
    if (videoId == null || videoId == "")
      throw ErrorFactory.error(400, "Invalid video id");

    const response = await this.m_OverlayApi.createOverlay(
      videoId,
      overlayData
    );

    if (response.status == 403) {
      throw ErrorFactory.error(response.status, "User does not own video");
    } else if (response.status != 201) {
      throw ErrorFactory.error(
        response.status,
        response.error || "Failed to create overlay"
      );
    }

    return response.overlay;
  }

  //--------------------------------------------------------------------
  async deleteOverlay(overlayId: string): Promise<boolean> {
    if (overlayId == null || overlayId == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res = await this.m_OverlayApi.deleteOverlay(overlayId);

    if (res.status != 204) {
      throw ErrorFactory.error(
        res.status,
        res.error || "Failed to delete overlay"
      );
    }

    return true;
  }

  //--------------------------------------------------------------------
  async updateOverlay(
    overlayId: string,
    overlayData: UPDATE_OVERLAY_REQ_DTO
  ): Promise<boolean> {
    if (overlayId == null || overlayId == "")
      throw ErrorFactory.error(400, "Invalid video id");

    let res = await this.m_OverlayApi.updateOverlay(overlayId, overlayData);

    if (res.status != 200) {
      throw ErrorFactory.error(
        res.status,
        res.error || "Failed to update overlay"
      );
    }

    return true;
  }

  //--------------------------------------------------------------------
  //#endregion
  //--------------------------------------------------------------------
  //#region Private Methods

  //#endregion
}
