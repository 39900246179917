import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { VIDEO } from "src/app/models/video/video";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { VideoService } from "src/app/services/video/video.service";
import { OverlaysService } from "src/app/services/overlays/overlays.service";
import {
  CREATE_OVERLAY_REQ_DTO,
  OVERLAY_ANIMATION_TYPE,
  OVERLAY_BACKGROUND_FITTING,
  OVERLAY_DTO,
  OVERLAY_LOCATION,
  OVERLAY_TYPE,
  UPDATE_OVERLAY_REQ_DTO,
} from "@shared/models/overlays/overlay";
import { OverlayModalComponent } from "../overlay-modal/overlay-modal.component";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { Subscription } from "rxjs";

@Component({
  selector: "app-video-overlay-list",
  templateUrl: "./video-overlay-list.component.html",
  styleUrls: ["./video-overlay-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class VideoOverlayListComponent implements OnInit {
  @Input() set Video(value: VIDEO | null) {
    if (!value || this.m_Video == value) return;
    this.m_Video = value;
    this.initializeContent();
  }
  @Input() public videoAspectRatio: number = 1;
  @Input() public containerAspectRatio: number = 1;

  @ViewChild(OverlayModalComponent)
  m_OverlayModal: OverlayModalComponent | undefined;

  @Output() onOverlayPreview = new EventEmitter<number>();
  @Output() onOverlayModalOpen = new EventEmitter<void>();
  get VideoReadOnly() {
    return this.m_Video?.read_only ?? false;
  }

  get ActiveVideo() {
    return this.m_Video;
  }

  public $t = T.translate;
  public m_Loading: boolean = true;
  public m_OverlayList: OVERLAY_DTO[] = [];
  public m_OverlayWholeVideoList: OVERLAY_DTO[] = [];
  public m_OverlaySegmentList: OVERLAY_DTO[] = [];
  //--------------------------------------------------------------------
  public m_Video: VIDEO | null = null;
  private m_OverlayUpdatedSub: Subscription | null = null;
  private m_IsEditing: boolean = false;

  constructor(
    private m_VideoService: VideoService,
    private m_OverlaysService: OverlaysService,
    private m_NotificationService: HIANotificationService,
    private cdr: ChangeDetectorRef,
    private m_Events: Events
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.m_OverlayUpdatedSub?.unsubscribe();
  }

  //#region HTML Handlers
  async addNewOverlay() {
    let aspectRatioWidthModifier = Math.max(
      this.containerAspectRatio / this.videoAspectRatio,
      1
    );

    let aspectRatioHeightModifier = Math.max(
      this.videoAspectRatio / this.containerAspectRatio,
      1
    );

    let width = Math.round(25 * aspectRatioWidthModifier);
    let height = Math.round(25 * aspectRatioHeightModifier);

    if (!this.m_Video) return;
    const createOverlay: CREATE_OVERLAY_REQ_DTO = {
      name: "New Overlay",
      start_time: 0,
      end_time: 0,
      whole_video: false,
      overlay_type: OVERLAY_TYPE.ELEMENT,
      location: OVERLAY_LOCATION.CENTER,
      fitting: OVERLAY_BACKGROUND_FITTING.FIT_TO_AREA,
      background_color: "#000000",
      border_color: "#FF0000",
      border_radius: 0,
      width,
      height,
      offset_x: 0,
      offset_y: 0,
      z_index: 1,
      opacity: 1,
      animation_type: OVERLAY_ANIMATION_TYPE.FADE,
      is_active: false,
      is_sticky: false,
    };
    this.m_Loading = true;
    try {
      const newOverlay = await this.m_OverlaysService.createOverlay(
        this.m_Video.video_id,
        createOverlay
      );

      this.m_OverlayList.push(newOverlay);
      // Adds the new overlay to the list
      this.processOverlayLists();

      // Trigger change detection manually
      this.cdr.detectChanges();

      this.m_Events.publish(EVENTS.OVERLAY_UPDATED, newOverlay);
    } catch (error: any) {
      console.error(error);
      this.m_NotificationService.showError(error.message, 10000);
    } finally {
      this.m_Loading = false;
    }
  }

  async deleteOverlay(overlayId: string) {
    if (!this.m_Video || this.VideoReadOnly) return;
    try {
      const response = await this.m_OverlaysService.deleteOverlay(overlayId);
      if (response) {
        // Filters the overlay list to remove the one with the specified ID
        this.m_OverlayList = this.m_OverlayList.filter(
          (overlay) => overlay.id !== overlayId
        );

        this.processOverlayLists();

        this.m_Events.publish(EVENTS.OVERLAY_DELETED, overlayId);
      }
    } catch (error: any) {
      console.error(error);
      this.m_NotificationService.showError(error.message, 10000);
    }
  }

  async toggleOverlayActiveState(overlayId: string) {
    if (!this.m_Video || this.VideoReadOnly || this.m_IsEditing) return;

    const overlay = this.m_OverlayList.find(
      (overlay) => overlay.id === overlayId
    );
    if (overlay) {
      overlay.is_active = !overlay.is_active;
      overlay.loading = true;

      const {
        video_id,
        created_at,
        last_modified,
        background_url,
        loading,
        ...overlayData
      } = overlay;

      try {
        await this.m_OverlaysService.updateOverlay(overlay.id, overlayData);
        this.m_Events.publish(EVENTS.OVERLAY_UPDATED, overlay);
      } catch (error: any) {
        console.error(error);
        this.m_NotificationService.showError(error.message, 10000);
        overlay.is_active = !overlay.is_active;
      } finally {
        overlay.loading = false;
      }
    }
  }

  async updateStartTime(event: any, overlayId: string) {
    if (this.VideoReadOnly) return;
    const overlay = this.m_OverlayList.find(
      (overlay) => overlay.id === overlayId
    );
    const inputValue = event.target.value;

    if (!this.validateTimeFormat(inputValue)) {
      this.m_NotificationService.showError(
        "Invalid time format. Time must be in 00:00 or 00:00:00 format.",
        10000
      );
      return;
    }

    const timeInMiliseconds = this.timeStringToMilliseconds(inputValue);
    if (timeInMiliseconds < 0) {
      this.m_NotificationService.showError(
        "Start time cannot be less than 0.",
        10000
      );
      return;
    }
    const endTime = overlay?.end_time || 0;
    if (timeInMiliseconds > endTime) {
      this.m_NotificationService.showError(
        "Start time needs to be higher than End time.",
        10000
      );
      return;
    }

    if (overlay) {
      overlay.start_time = timeInMiliseconds;
      // Check if it should be a whole video overlay
      await this.updateWholeVideoState(overlay);
      this.reorganizeOverlays();

      this.m_Events.publish(EVENTS.OVERLAY_UPDATED, overlay);
    }
  }

  async updateEndTime(event: any, overlayId: string) {
    if (!this.m_Video?.video_duration || this.VideoReadOnly) return;
    const overlay = this.m_OverlayList.find(
      (overlay) => overlay.id === overlayId
    );
    const inputValue = event.target.value;

    if (!this.validateTimeFormat(inputValue)) {
      this.m_NotificationService.showError(
        "Invalid time format. Time must be in 00:00 or 00:00:00 format.",
        10000
      );
      return;
    }

    let timeInMiliseconds = this.timeStringToMilliseconds(inputValue);

    const startTime = overlay?.start_time || 0;
    if (timeInMiliseconds < startTime) {
      this.m_NotificationService.showError(
        "End time cannot be lower than Start time",
        10000
      );
      return;
    }

    if (
      this.m_Video &&
      timeInMiliseconds > this.m_Video?.video_duration * 1000
    ) {
      this.m_NotificationService.showError(
        "End time cannot exceed video duration.",
        10000
      );
      timeInMiliseconds = this.m_Video?.video_duration * 1000;
    }

    if (overlay) {
      overlay.end_time = timeInMiliseconds;
      // Check if it should be a whole video overlay
      await this.updateWholeVideoState(overlay);
      this.reorganizeOverlays();

      this.m_Events.publish(EVENTS.OVERLAY_UPDATED, overlay);
    }
  }

  openOverlayModal(overlayId: string) {
    if (this.VideoReadOnly) return;
    this.onOverlayModalOpen.emit();
    this.m_OverlayModal?.show(overlayId, this.m_OverlayList);
  }

  goToOverlayTime(overlay: OVERLAY_DTO) {
    const startTime = overlay.start_time || 0;
    const timeInSeconds = Math.floor(startTime / 1000);

    this.onOverlayPreview.emit(timeInSeconds);
  }

  toggleIsEditing() {
    this.m_IsEditing = true;
  }
  //#endregion
  //#region Render Helpers

  getTimeDisplay(timeInSeconds?: number) {
    if (timeInSeconds == null) return "--:--";
    if (!timeInSeconds) return "00:00";
    const totalSeconds = Math.floor(timeInSeconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }

  getStartTimeDisplay(overlay?: OVERLAY_DTO) {
    if (overlay?.whole_video) return "00:00";
    return this.getTimeDisplay(overlay?.start_time);
  }

  getEndTimeDisplay(overlay?: OVERLAY_DTO) {
    if (overlay?.whole_video) {
      if (!this.m_Video?.video_duration) return "--:--";
      const videoEndTime = Math.ceil(this.m_Video?.video_duration * 1000);
      return this.getTimeDisplay(videoEndTime);
    }
    return this.getTimeDisplay(overlay?.end_time);
  }

  shouldDisableInputs(overlay?: OVERLAY_DTO) {
    return this.VideoReadOnly || overlay?.loading;
  }

  //#endregion
  //#region Private Methods

  private timeStringToMilliseconds(timeString: string): number {
    const [minutes, seconds] = timeString.split(":").map(Number);
    return (minutes * 60 + seconds) * 1000;
  }

  private async initializeContent() {
    await this.getOverlaysList();
    this.m_OverlayUpdatedSub = this.m_Events.subscribe(
      EVENTS.OVERLAY_UPDATED,
      (data) => {
        this.updateOverlays(data);
      }
    );
  }

  private async getOverlaysList() {
    if (!this.m_Video) return;

    try {
      this.m_Loading = true;

      this.m_OverlayList = await this.m_OverlaysService.getOverlays(
        this.m_Video.video_id
      );

      this.processOverlayLists();
    } catch (error: any) {
      console.error(error);
      this.m_NotificationService.showError(error.message, 10000);
    } finally {
      this.m_Loading = false;
    }
  }

  private processOverlayLists() {
    if (!this.m_OverlayList) return;

    this.m_OverlayWholeVideoList = this.m_OverlayList.filter(
      (item) => item.whole_video
    );

    this.m_OverlaySegmentList = this.m_OverlayList.filter(
      (item) => !item.whole_video
    );
  }

  private validateTimeFormat(time: string): boolean {
    // Regular expression to match 00:00 or 00:00:00 format
    const regex = /^(?:\d{1,2}:\d{2}|\d{1,2}:\d{2}:\d{2})$/;
    return regex.test(time);
  }

  private reorganizeOverlays() {
    // Sort overlays by start_time in ascending order
    this.m_OverlayList.sort(
      (a, b) => (a.start_time ?? 0) - (b.start_time ?? 0)
    );
    this.m_OverlaySegmentList.sort(
      (a, b) => (a.start_time ?? 0) - (b.start_time ?? 0)
    );
    this.m_OverlayWholeVideoList.sort(
      (a, b) => (a.start_time ?? 0) - (b.start_time ?? 0)
    );
  }

  private async updateWholeVideoState(overlay: OVERLAY_DTO) {
    this.m_IsEditing = false;
    if (!this.m_Video?.video_duration) return;
    const videoDuration = this.m_Video?.video_duration * 1000 || 0;
    const endTime = overlay.end_time || 0;

    if (overlay.start_time === 0 && this.m_Video && endTime >= videoDuration) {
      overlay.whole_video = true;
      overlay.end_time = videoDuration;
    } else {
      overlay.whole_video = false;
    }
    overlay.loading = true;
    try {
      const overlayData: UPDATE_OVERLAY_REQ_DTO = {
        start_time: overlay.start_time,
        end_time: overlay.end_time,
        whole_video: overlay.whole_video,
      };
      await this.m_OverlaysService.updateOverlay(overlay.id, overlayData);

      //Remove the overlay from the list to clear the Ion-Inputs, this is to rpevent the user from keeping time durations higher than the video duration
      this.m_OverlaySegmentList = this.m_OverlaySegmentList.filter(
        (o) => o.id != overlay.id
      );

      this.m_OverlayWholeVideoList = this.m_OverlayWholeVideoList.filter(
        (o) => o.id != overlay.id
      );

      //Trigger the rendering funtions to update the list
      this.m_OverlayWholeVideoList = [...this.m_OverlayWholeVideoList];
      this.m_OverlaySegmentList = [...this.m_OverlaySegmentList];
      this.cdr.detectChanges();

      if (overlay.whole_video) {
        this.m_OverlayWholeVideoList.push(overlay);
      } else {
        this.m_OverlaySegmentList.push(overlay);
      }
    } catch (error: any) {
      console.error(error);
      this.m_NotificationService.showError(error.message, 10000);
    }

    overlay.loading = false;
  }

  private updateOverlays(overlayData: OVERLAY_DTO) {
    const overlayInList = this.m_OverlayList.find(
      (overlay) => overlay.id === overlayData.id
    );
    if (overlayInList) {
      Object.assign(overlayInList, overlayData);
    }

    this.processOverlayLists();
    this.reorganizeOverlays();
  }
  //#endregion
}
