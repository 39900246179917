import { Component, Input } from "@angular/core";

export enum VideoOverlayPosition {
  TopLeft = "top-left",
  TopRight = "top-right",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
}

export interface VideoOverlayMenuOption {
  icon?: string;
  text: string;
  handler: () => void;
  customClass?: string;
  selected?: boolean;
}

export interface VideoOverlayMenuConfig {
  options: VideoOverlayMenuOption[];
  title: string;
  position?: VideoOverlayPosition;
}

@Component({
  selector: "app-video-overlay-menu",
  templateUrl: "./video-overlay-menu.component.html",
  styleUrls: ["./video-overlay-menu.component.scss"],
  standalone: false,
})
export class VideoOverlayMenuComponent {
  @Input() set Config(config: VideoOverlayMenuConfig) {
    if (!config) {
      return;
    }

    this.m_Config = config;
    this.m_ClassList = [];
    this.m_ClassList.push(config.position ?? VideoOverlayPosition.BottomRight);
  }
  @Input() set Visible(visible: boolean) {
    this.m_Visible = visible;
  }

  public m_ClassList: string[] = [];
  public m_Visible: boolean = false;

  private m_Config: VideoOverlayMenuConfig | null = null;

  get Options() {
    return this.m_Config?.options;
  }

  get Title() {
    return this.m_Config?.title;
  }

  constructor() {}

  onOptionClick(option: VideoOverlayMenuOption) {
    option.handler();
  }
}
