import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { VIDEO } from "src/app/models/video/video";
import { T } from "src/app/services/localization/localization.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { uuidToShortId } from "@shared/utils/utils";
import { EmbedModalComponent } from "../embed-modal/embed-modal.component";
import { CarouselResponsiveOptions } from "primeng/carousel";
import { VIDEO_PUBLISH_STATE } from "@shared/models/video/video";
import { SafeUrl } from "@angular/platform-browser";
import { secondsToTimeDisplay } from "src/app/utility/time-utils";
import {
  hasMoreThanAHour,
  timeStringToSeconds,
} from "src/app/utility/time-utils";
import { ContextMenuComponent } from "../../shared/context-menu/context-menu.component";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user/user.service";
import { PLAYLIST_DTO } from "@shared/models/playlist/playlist";
import { VideoService } from "src/app/services/video/video.service";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";

@Component({
  selector: "app-share-modal",
  templateUrl: "./share-modal.component.html",
  styleUrls: ["./share-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class ShareModalComponent implements OnInit {
  @Input() set IsOpen(value: boolean) {
    if (this.m_IsOpen == value) return;
    this.m_IsOpen = value;
  }
  @Input() set VideoData(value: VIDEO | null) {
    if (!value || this.m_VideoData == value) return;
    this.m_VideoData = value;
    this.updateSharableLink();
    this.fetchUniqueCodes();
  }
  @Input() set PlaylistData(value: PLAYLIST_DTO | null) {
    if (!value || this.m_PlaylistData == value) return;
    this.m_PlaylistData = value;
    this.updateSharableLink();
  }
  @Input() set PlaylistVideoID(value: string | null) {
    if (!value || this.m_PlaylistVideoID == value) return;
    this.m_PlaylistVideoID = value;
    this.updateSharableLink();
  }
  @ViewChild("m_ShareModalRootContainer", {
    read: ViewContainerRef,
    static: false,
  })
  m_RootContainer: ViewContainerRef | undefined;
  @ViewChild(EmbedModalComponent) m_EmbedModal: EmbedModalComponent | undefined;
  @ViewChild("contextMenu", { read: ContextMenuComponent }) m_CtxMenu:
    | ContextMenuComponent
    | undefined;

  public $t = T.translate;
  public m_IsOpen: boolean = false;
  public m_ResponsiveOptions: CarouselResponsiveOptions[] = [
    {
      breakpoint: "2300px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "400px",
      numVisible: 2,
      numScroll: 1,
    },
  ];

  private m_VideoData: VIDEO | null = null;
  private m_PlaylistData: PLAYLIST_DTO | null = null;
  private m_PlaylistVideoID: string | null = null;
  private m_ShortId: string = "";

  public m_SharableLink: string = "";
  public m_QRCodeDownloadLink: SafeUrl = "";
  public m_QRCodeName: string = "QRCodeShare";
  public m_ShowQRCodeButton: boolean = false;
  public m_StartTimeEnabled: boolean = false;
  public m_StartTime: number = 0;
  public m_StartTimeFormatted: string = "00:00";
  public m_IsStartTimeValid?: boolean = true;
  public m_HasHoursDuration: boolean = false;
  public m_IsCopied: boolean = false;
  public m_EmbededOption: number = 2;
  public m_StartVideoAtCurrentTime: boolean = false;
  public m_UniqueCodesLoading: boolean = false;
  public m_UniqueCodes: string[] = [];
  public m_CodesCountToGenerate: number = 0;
  public m_CodesCreating: boolean = false;
  public readonly CODE_MIN: number = 0;
  public readonly CODE_MAX: number = 10000;

  get activeTab() {
    return this.m_ShareTabs[this.m_CurrentTabIndex];
  }

  //Share states
  public m_ShareTabs = [
    {
      name: "Social",
      value: "social",
    },
    {
      name: "Embed",
      value: "embed",
    },
    {
      name: "QR Code",
      value: "qrcode",
    },
    {
      name: "Unique Codes",
      value: "unique-codes",
    },
  ];

  public m_CurrentTabIndex: number = 0;

  //Share Buttons Data
  public m_ShareButtons = [
    // {
    //   name: "embed",
    //   icon: "embed",
    //   color: "#000000",
    //   url: "",
    //   action: "embed",
    // },
    // {
    //   name: "QR Code",
    //   icon: "qrcode",
    //   color: "#000000",
    //   url: "",
    //   action: "qrcode",
    // },
    {
      name: "linkedin",
      icon: "linkedin",
      color: "#0077b5",
      url: "https://www.linkedin.com/shareArticle?url=",
      action: "share",
    },
    {
      name: "X",
      icon: "twitter-x",
      color: "#000000",
      url: "https://twitter.com/intent/tweet?url=",
      action: "share",
    },
    {
      name: "facebook",
      icon: "facebook",
      color: "#3b5998",
      url: "https://www.facebook.com/sharer/sharer.php?u=",
      action: "share",
    },
    {
      name: "reddit",
      icon: "reddit",
      color: "#ff4500",
      url: "https://reddit.com/submit?url=",
      action: "share",
    },
    {
      name: "email",
      icon: "envelope-fill",
      color: "",
      url: "mailto:?body=",
      action: "share",
    },
  ];

  get SharableLink() {
    return this.m_SharableLink;
  }

  get VideoData() {
    return this.m_VideoData;
  }

  constructor(
    private m_NotificationService: HIANotificationService,
    private m_UserService: UserService,
    private m_VideoService: VideoService,
    private m_Router: Router
  ) {}

  ngOnInit() {}

  //#region Public Methods
  onUpgradeClicked() {
    this.m_EmbedModal?.close();
    this.close();
    setTimeout(() => {
      this.m_Router.navigate(["/pricing"]);
    }, 100);
  }

  show(startTime: number = 0, videoData: VIDEO | null = null) {
    this.m_IsOpen = true;
    this.m_StartTime = Math.round(startTime);

    if (this.m_PlaylistData && !videoData) {
      this.m_ShareTabs = this.m_ShareTabs.filter(
        (tab) => tab.value !== "embed"
      );
    }

    if (this.m_VideoData?.video_duration != null) {
      this.m_HasHoursDuration = hasMoreThanAHour(
        this.m_VideoData?.video_duration
      );
    }

    this.m_StartTimeFormatted = secondsToTimeDisplay(
      this.m_StartTime || 0,
      this.m_HasHoursDuration
    );

    if (videoData !== null) {
      this.m_VideoData = videoData;
    }

    this.updateSharableLink();
  }

  close() {
    this.m_IsOpen = false;
    this.m_ShowQRCodeButton = false;
    this.m_IsCopied = false;
    this.m_StartVideoAtCurrentTime = false;
    this.m_EmbededOption = 2;
  }

  openEmbedModal() {
    this.close();
    this.m_EmbedModal?.show();
  }

  copyEmbedLinkToClipboard() {
    if (!this.isEmbedEnabled()) return;
    navigator.clipboard.writeText(this.getEmbedShareableLink());
    this.m_NotificationService.showSuccess(
      this.$t("shared.messages.linkCopied"),
      2000
    );
  }

  onEmbededTypeChange(event: any) {
    this.m_EmbededOption = event.detail.value;
  }

  public createUniqueCode(): void {
    if (!this.m_VideoData?.video_id) return;
    if (this.m_CodesCountToGenerate <= 0) return;
    if (this.m_CodesCountToGenerate > 10000) {
      this.m_CodesCountToGenerate = 10000;
    }

    this.m_CodesCreating = true;
    this.m_VideoService
      .createUniqueCodes(this.m_VideoData.video_id, this.m_CodesCountToGenerate)
      .then((newCodes: { id: string }[]) => {
        this.m_NotificationService.showSuccess("Unique codes created");
        this.m_UniqueCodes = [
          ...this.m_UniqueCodes,
          ...newCodes.map((code) => code.id),
        ];
        this.m_CodesCountToGenerate = 0;
      })
      .catch((error) => {
        console.error("Failed to create unique codes", error);
        this.m_NotificationService.showError("Failed to create unique codes");
      })
      .finally(() => {
        this.m_CodesCreating = false;
      });
  }

  public downloadCSV(): void {
    if (!this.m_SharableLink) return;
    const header = "url";
    const separator = this.m_SharableLink.includes("?") ? "&" : "?";
    const rows = this.m_UniqueCodes.map(
      (code) => `${this.m_SharableLink}${separator}trackId=${code}`
    );
    const csvContent = header + "\n" + rows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "unique_codes.csv";
    a.click();

    URL.revokeObjectURL(url);
  }

  public clipCodeCount(): void {
    if (this.m_CodesCountToGenerate < this.CODE_MIN) {
      this.m_CodesCountToGenerate = this.CODE_MIN;
    } else if (this.m_CodesCountToGenerate > this.CODE_MAX) {
      this.m_CodesCountToGenerate = this.CODE_MAX;
    }
  }

  //#endregion

  //#region Private Methods
  private updateSharableLink(event?: any) {
    if (this.m_PlaylistData && !this.VideoData) {
      this.m_ShareTabs.slice(1, 1);
      this.m_ShortId = uuidToShortId(this.m_PlaylistData.id);
      const videoLink = this.m_PlaylistVideoID
        ? "/watch?v=" + uuidToShortId(this.m_PlaylistVideoID)
        : "";
      const playlistLink = this.m_PlaylistVideoID
        ? "&p=" + this.m_ShortId
        : "/playlists?v=" + this.m_ShortId;
      this.m_SharableLink = window.location.origin + videoLink + playlistLink;
    } else if (this.m_VideoData?.video_id != null) {
      this.m_ShortId = uuidToShortId(this.m_VideoData.video_id);
      this.m_SharableLink =
        window.location.origin +
        "/watch?v=" +
        this.m_ShortId +
        this.getStartTimeParam();
    }
  }

  private getStartTimeParam() {
    let result = "";

    if (
      this.m_StartVideoAtCurrentTime &&
      !isNaN(this.m_StartTime) &&
      this.isStartTimeValid() &&
      this.m_IsStartTimeValid
    ) {
      let startTimeText = Math.round(this.m_StartTime).toString();
      result = "&startTime=" + startTimeText;
    }

    return result;
  }

  private getEmbedShareableLink(): string {
    if (!this.m_VideoData) return "";

    this.m_ShortId = uuidToShortId(this.m_VideoData.video_id);
    let link = "<iframe ";
    link += 'title="{{VIDEO_NAME}}" ';
    link += 'src="{{VIDEO_LINK}}" ';
    link += 'allow="microphone;autoplay;fullscreen" ';
    link += 'allowtransparency="true" ';
    link += 'width="{{WIDTH}}" height="{{HEIGHT}}" ';
    link += "></iframe>";

    link = link.replace("{{VIDEO_NAME}}", this.m_VideoData?.video_name ?? "");

    //Width
    link = link.replace(
      "{{WIDTH}}",
      this.m_EmbededOption === 1 ? "100%" : "1280"
    );

    //Height
    link = link.replace(
      "{{HEIGHT}}",
      this.m_EmbededOption === 1 ? "100%" : "720"
    );

    let videoLink = window.location.origin;
    videoLink += "/embed?v=";
    videoLink += this.m_ShortId;

    //Start time
    if (this.m_StartVideoAtCurrentTime) {
      videoLink += this.getStartTimeParam();
    }

    videoLink += "&autoplay=true";

    link = link.replace("{{VIDEO_LINK}}", videoLink);

    return link;
  }

  private fetchUniqueCodes(): void {
    if (!this.m_VideoData?.video_id) return;
    this.m_UniqueCodesLoading = true;
    this.m_VideoService
      .getUniqueCodes(this.m_VideoData.video_id)
      .then((codes: { id: string }[]) => {
        this.m_UniqueCodes = codes.map((code) => code.id);
        this.m_UniqueCodesLoading = false;
      })
      .catch((error) => {
        if (!this.m_IsOpen) return;
        console.error("Failed to fetch unique codes", error);
        this.m_NotificationService.showError("Failed to fetch unique codes");
        this.m_UniqueCodesLoading = false;
      });
  }
  //#endregion

  //#region HTML Handlers
  onShareCloseClicked() {
    this.close();
  }

  onChangeURL(url: SafeUrl) {
    this.m_QRCodeDownloadLink = url;
  }

  async copyLinkToClipboard() {
    await navigator.clipboard.writeText(this.m_SharableLink).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
    this.m_IsCopied = true;

    // Enable button after 5s
    setTimeout(() => {
      this.m_IsCopied = false;
    }, 5000);
    // this.m_NotificationService.showSuccess(
    //   this.$t("shared.messages.linkCopied"),
    //   2000,
    //   this.m_RootContainer
    // );
  }

  async onShareButtonClicked(buttonData: any) {
    let url = buttonData.url;
    if (buttonData.action == "share") {
      url += encodeURIComponent(this.m_SharableLink);

      if (buttonData.name == "email") {
        url +=
          "&subject=" +
          encodeURIComponent(
            this.$t("components.modals.share.shareEmailSubject")
          );
      }
    } else if (buttonData.action == "embed") {
      this.openEmbedModal();
      return;
    } else if (buttonData.action == "qrcode") {
      this.m_ShowQRCodeButton = true;
      return;
    }
    window.open(url, "_blank");
  }

  onStartTimeStatusChanged() {
    this.updateSharableLink();
    this.m_IsCopied = false;
  }

  onSetStartTimeInput(event: any) {
    let currentTime = event.target?.value;
    if (currentTime == null) return;

    let totalTime = timeStringToSeconds(currentTime);
    this.m_StartTime = totalTime;

    if (currentTime.includes(":")) {
      this.m_IsStartTimeValid = true;
    } else {
      this.m_IsStartTimeValid = false;
    }

    this.updateSharableLink();
  }
  //#endregion

  //#region HTML Render Helpers
  hideCheckbox() {
    return this.m_StartTime === 0;
  }

  getButtonName(name: string) {
    return name + "_" + this.m_VideoData?.video_id;
  }

  getShareIcon(shareName: string) {
    return "assets/icon/share/" + shareName + ".svg";
  }

  getVideoTitle() {
    return this.m_VideoData?.video_name;
  }

  isEmbedEnabled() {
    return this.m_UserService.ActiveUserLimits?.enableEmbedLinkSharing ?? false;
  }

  getInputTooltip() {
    let tooltipText = "";
    if (this.m_HasHoursDuration)
      tooltipText = this.$t("components.qnaEditor.timeHoursFormat");
    else tooltipText = this.$t("components.qnaEditor.timeFormat");

    if (!this.isStartTimeValid())
      tooltipText = `${tooltipText}\n\n${this.$t(
        "components.qnaEditor.validStartTimeFormat"
      )}`;

    return tooltipText;
  }

  isStartTimeValid() {
    let duration = this.m_VideoData?.video_duration;

    if (this.m_StartTime == null || duration == null) {
      return false;
    }

    return this.m_StartTime >= 0 && this.m_StartTime <= duration;
  }

  showWarning() {
    return (
      this.m_VideoData?.video_publish_state === VIDEO_PUBLISH_STATE.PRIVATE
    );
  }

  hideQRCodeDownload() {
    this.m_ShowQRCodeButton = false;
  }

  setCurrentTab(index: number) {
    this.m_CurrentTabIndex = index;
  }

  onShareOptionsClicked(event: MouseEvent) {
    event.preventDefault();
    this.m_CtxMenu?.open(event);
  }

  getMessage(social: any) {
    if (social.name === "X") return "tweet";
    if (social.name === "email") return "message";
    return "post";
  }
  //#endregion
}
