import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { IonTextarea } from "@ionic/angular";
import {
  ATTACHMENT_MIME_TYPE,
  ATTACHMENT_TYPE,
  RECORD,
} from "src/app/models/record/record";
import { T } from "src/app/services/localization/localization.service";
import { RecordService } from "src/app/services/record/record.service";
import {
  ContextMenuComponent,
  ContextMenuOption,
} from "../../shared/context-menu/context-menu.component";
import { DropTargetEvent } from "@progress/kendo-angular-utils";
import { Media } from "src/app/models/media/media";
import { MediaService } from "src/app/services/media/media.service";
import { MEDIA_TYPE } from "@shared/models/media/media";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import {
  ChatService,
  HIA_ATTACHMENT_CONTENT,
  HIA_MSG,
} from "src/app/services/chat/chat.service";
import {
  UtteranceService,
  UtteranceState,
} from "src/app/services/utterance/utterance.service";
import { AlertModalComponent } from "../../modals/alert-modal/alert-modal.component";
import { StorageService } from "src/app/services/storage/storage.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { allowIframe, validateLink } from "src/app/utility/utils";
import { NotificationRef } from "@progress/kendo-angular-notification";
import { VoiceService } from "src/app/services/voice/voice.service";
import { UtilityService } from "src/app/services/utility/utility.service";
import { UserService } from "src/app/services/user/user.service";
import { AdminService } from "src/app/services/admin/admin.service";
import { ValidationError } from "src/app/errors/custom-errors";

@Component({
  selector: "app-record",
  templateUrl: "./record.component.html",
  styleUrls: ["./record.component.scss"],
  standalone: false,
})
export class RecordComponent implements OnInit {
  @Input()
  set Record(value: RECORD | null) {
    if (value == null) return;
    if (this.m_Record?.record_id != value.record_id) {
      this.initWithRecord(value);
    }
  }

  @Input() m_VideoId: string = "";
  @Input() m_Index: number = 0;
  @Input() m_Search: string = "";
  @Input() m_ReadOnly: boolean = false;
  @Input() m_IsAdmin: boolean = false;

  @ViewChild("rootElem", { read: ElementRef }) m_RootElem:
    | ElementRef
    | undefined;

  @ViewChild("rootElem", { read: ViewContainerRef }) m_RootContainer:
    | ViewContainerRef
    | undefined;

  @ViewChild("contextMenu", { read: ContextMenuComponent }) m_CtxMenu:
    | ContextMenuComponent
    | undefined;

  @ViewChild("deleteWarning", { read: AlertModalComponent })
  m_DeleteWarning: AlertModalComponent | undefined;

  @ViewChild("m_QuestionContainer")
  m_QuestionContainer: ElementRef | null = null;

  @ViewChild("m_AnswerContainer")
  m_AnswerContainer: ElementRef | null = null;

  @Output() error = new EventEmitter<string>();

  public m_DragOver: boolean = false;

  get Record(): RECORD | null {
    return this.m_Record;
  }

  get UnsavedChanges(): boolean {
    return this.m_Record?.unsaved || false;
  }

  set UnsavedChanges(value: boolean) {
    if (this.m_Record == null) return;
    this.m_Record.unsaved = value;
  }

  get IsPinned(): boolean {
    return this.m_Record?.is_pinned || false;
  }

  set IsPinned(value: boolean) {
    if (this.m_Record == null) return;
    this.m_Record.is_pinned = value;
  }

  get IsSuggestible(): boolean {
    return this.m_Record?.is_suggestible || false;
  }

  set IsSuggestible(value: boolean) {
    if (this.m_Record == null) return;
    this.m_Record.is_suggestible = value;
  }

  //---------------------------------------------------------------------------
  @ViewChild("m_QuestionInput") m_QuestionInput: IonTextarea | undefined;
  @ViewChild("m_AnswerInput") m_AnswerInput: IonTextarea | undefined;
  //---------------------------------------------------------------------------
  public m_InvalidData: boolean = false;
  public m_Question: string = "";
  public m_Answer: string = "";
  public m_MediaAttachment: Media | null = null;
  public m_AttachmentType: ATTACHMENT_TYPE = ATTACHMENT_TYPE.NONE;
  public m_EditingQuestion: boolean = false;
  public m_EditingAnswer: boolean = false;
  public m_Saving: boolean = false;
  public m_FetchingAttachment: boolean = true;
  public m_ErrorSaving: boolean = false;
  public $t = T.translate;
  //---------------------------------------------------------------------------
  private m_Record: RECORD | null = null;
  private m_ContextOptions: ContextMenuOption[] = [];
  private m_Id: string = "";
  private m_TTSState: UtteranceState = UtteranceState.STOPPED;
  private m_ErrorNotification: NotificationRef | null = null;
  private m_SaveTimeout: any;
  public m_IsOnUnsavedList: boolean = false;
  //---------------------------------------------------------------------------
  constructor(
    private m_RecordService: RecordService,
    private m_MediaService: MediaService,
    private m_Events: Events,
    private m_ChatService: ChatService,
    private m_UtteranceService: UtteranceService,
    private m_Storage: StorageService,
    private m_NotificationService: HIANotificationService,
    private m_VoiceService: VoiceService,
    private m_UserService: UserService,
    private m_AdminService: AdminService
  ) {}
  //---------------------------------------------------------------------------
  async ngOnInit() {}

  ngOnDestroy() {
    this.reset();
  }

  reset() {
    if (this.m_SaveTimeout) clearTimeout(this.m_SaveTimeout);

    this.m_Record = null;
    this.m_Question = "";
    this.m_Answer = "";
    this.m_MediaAttachment = null;
    this.m_AttachmentType = ATTACHMENT_TYPE.NONE;
    this.m_EditingQuestion = false;
    this.m_EditingAnswer = false;
    this.m_Saving = false;
    this.m_FetchingAttachment = true;
    this.m_ErrorSaving = false;
    this.m_InvalidData = false;
    this.m_TTSState = UtteranceState.STOPPED;
    this.m_IsOnUnsavedList = false;
    this.m_ContextOptions = [];
    this.m_Id = "";
  }
  //---------------------------------------------------------------------------
  //#region Public Methods
  /**
   * Initialize the record component with a record model
   * @param record
   */
  public async initWithRecord(record: RECORD) {
    this.reset();
    this.m_Record = record;
    this.m_Id = this.m_Record?.record_id + "-" + Date.now().toString();
    //Save the initial id of the record at the start of init
    let initID = this.m_Record?.record_id;

    this.m_Question = record?.question || "";
    this.m_Answer = record?.answer || "";
    let restored = await this.restoreRecordChanges();

    if (!restored) {
      //Fetch the attachment if we have one
      await this.fetchAttachment(initID);
    }

    //Check if the record has been changed since the init started
    if (initID != this.m_Record?.record_id) return;

    this.shouldRenderSaveBtn();
  }

  public setMediaAttachment(attachment: Media, triggerEvent = true) {
    this.clearAttachments();
    this.m_MediaAttachment = attachment;
    if (this.m_Record) this.m_Record.media_id = attachment.id;
    this.UnsavedChanges = true;
    this.updateValidState();

    this.setAttachmentType();
    this.initContextMenu();

    if (triggerEvent) {
      this.m_Events.publish(EVENTS.RECORD_SET_ATTACHMENT, {
        id: this.m_Record?.record_id,
        attachment: attachment,
      });
    }

    this.cacheRecordChanges({});

    if (!this.m_InvalidData) {
      this.m_Saving = true;
      this.handleSave();
    }
  }

  public setVideoSegment(startTime: number, endTime: number) {
    if (this.m_Record != null) {
      this.clearAttachments();
      this.m_AttachmentType = ATTACHMENT_TYPE.VIDEO_SEGMENT;
      this.m_Record.video_start_time = startTime == 0 ? 0.1 : startTime;
      this.m_Record.video_end_time = endTime;
      this.UnsavedChanges = true;
      this.m_Saving = true;
      this.handleSave();
      this.updateValidState();
      this.initContextMenu();
      this.cacheRecordChanges({});
    }
  }

  private setRecordErrorMessage(message: string) {
    this.UnsavedChanges = false;
    this.m_InvalidData = true;
    if (this.m_ErrorNotification != null) this.m_ErrorNotification.hide();

    this.m_NotificationService.showError(message, undefined);
  }

  public async setWebUrlAttachment(url: string) {
    if (this.m_Record == null) return;

    this.clearAttachments();
    this.m_Record.web_url = url;
    this.UnsavedChanges = true;
    this.m_FetchingAttachment = true;

    //let isAllowedToIframe = await allowIframe(url);
    let isAllowedToIframe = true; //allowIframe seems to break with some embed URLs, needs more testing

    if (!isAllowedToIframe) {
      this.setRecordErrorMessage(
        this.$t("shared.messages.urlNotAllowIframing")
      );
    } else if (this.m_ErrorNotification != null) {
      this.m_ErrorNotification.hide();
      this.m_InvalidData = this.m_Question == "";
    } else {
      this.m_InvalidData = this.m_Question == "";
    }

    this.m_FetchingAttachment = false;
    this.setAttachmentType();
    this.initContextMenu();
    this.cacheRecordChanges({});
    this.m_Saving = true;
    this.handleSave();
  }

  public async updateRecord(record: RECORD) {
    this.m_Record = record;
    this.m_Question = record?.question || "";
    this.m_Answer = record?.answer || "";
    if (this.m_MediaAttachment == null && this.m_Record.media_id != null)
      await this.fetchAttachment();
  }

  public focusQuestion() {
    this.handleQuestionEdit(true);
  }
  //#endregion
  //#region HTML Event Handlers
  onDataChanged() {
    if (this.m_Record == null) return;
    if (this.m_Record.web_url != null && !validateLink(this.m_Record.web_url)) {
      this.m_InvalidData = true;
      return;
    }

    //Sanitize Inputs and prevent HTML tags on inputs
    this.m_Question = this.m_Question.replace(/<\/?[^>]+(>|$)/g, "");
    this.m_Answer = this.m_Answer.replace(/<\/?[^>]+(>|$)/g, "");

    if (this.m_SaveTimeout) clearTimeout(this.m_SaveTimeout);

    //Check if we have unsaved changes
    if (this.m_Question != "") {
      this.UnsavedChanges = true;
      this.m_InvalidData = false;
    } else {
      this.UnsavedChanges = false;
      this.m_InvalidData = true;
    }

    this.cacheRecordChanges({
      question: this.m_Question,
      answer: this.m_Answer,
    });
  }

  handleQuestionEdit(edit: boolean, event?: any) {
    if (event) this.handleSave();

    if (this.m_ReadOnly) {
      this.m_NotificationService.showError(
        this.$t("shared.messages.readOnly"),
        5000
      );
      return;
    }

    if (this.m_Saving) return;

    setTimeout(() => {
      this.m_EditingQuestion = edit;
      if (edit && this.m_QuestionInput != null) {
        this.focusTextArea(this.m_QuestionInput, this.m_Question.length);
      } else {
        this.m_Question = this.m_Question.trim();
        if (this.m_Record) this.m_Record.question = this.m_Question;
      }
    }, 75);
  }

  async handleAnswerEdit(edit: boolean, event?: any) {
    if (event) this.handleSave();

    if (this.m_ReadOnly) {
      this.m_NotificationService.showError(
        this.$t("shared.messages.readOnly"),
        5000
      );
      return;
    }

    if (this.m_Saving) return;

    setTimeout(() => {
      this.m_EditingAnswer = edit;
      if (edit && this.m_AnswerInput != null) {
        this.focusTextArea(this.m_AnswerInput, this.m_Answer.length);
      } else {
        this.m_Answer = this.m_Answer.trim();
        if (this.m_Record) this.m_Record.answer = this.m_Answer;
      }
    }, 75);
  }

  async onDeleteClicked() {
    let skipConfirm =
      (await this.m_Storage.get("record-delete-skip-confirm")) || [];

    if (typeof skipConfirm == "boolean") {
      await this.m_Storage.set("record-delete-skip-confirm", []);
    }

    if (skipConfirm.includes(this.m_Record?.video_id)) {
      this.deleteRecord();
    } else {
      if (this.m_DeleteWarning) {
        this.m_DeleteWarning.m_MainText = this.$t(
          "shared.messages.deleteRecord"
        );
        this.m_DeleteWarning.m_Description = this.$t(
          "shared.messages.deleteRecord_description"
        );
        this.m_DeleteWarning.m_NeverShowText = this.$t(
          "shared.messages.dontShowAgain"
        );
        this.m_DeleteWarning.m_ShowIcon = true;
        this.m_DeleteWarning.m_CustomClass = "delete-modal";
        this.m_DeleteWarning.m_CustomButtons = [
          {
            label: this.$t("shared.messages.deleteRecord").replace("?", ""),
            close: true,
            color: "#dd2222cc",
            callback: async () => {
              if (this.m_DeleteWarning?.m_NeverShow) {
                skipConfirm.push(this.m_Record?.video_id);
                await this.m_Storage.set(
                  "record-delete-skip-confirm",
                  skipConfirm
                );
              }
              this.deleteRecord();
            },
          },
        ];
      }

      await this.m_DeleteWarning?.show();
    }
  }

  handleSave() {
    if (this.m_SaveTimeout) clearTimeout(this.m_SaveTimeout);
    this.m_SaveTimeout = setTimeout(() => {
      this.onSaveClicked();
    }, 2000);
  }

  async onSaveClicked() {
    if (this.UnsavedChanges) {
      this.m_CtxMenu?.onContextMenuDismissed();
      await this.submitRecordChanges();
    } else {
      this.m_Saving = false;
    }
  }

  onAddAttachmentClicked(type: ATTACHMENT_TYPE) {
    this.m_Events.publish(EVENTS.RECORD_ADD_ATTACHMENT_CLICKED, {
      id: this.m_Record?.record_id,
      type: type,
    });
  }

  async shouldRenderSaveBtn() {
    let key = "record_unsaved_ids_" + this.m_VideoId;
    let unsavedIdsStr = (await this.m_Storage.get(key)) ?? "[]";
    let unsavedIds = [];

    try {
      unsavedIds = JSON.parse(unsavedIdsStr);
    } catch (exc) {}
    const isUnsaved = !!unsavedIds.find(
      (id: string) => id === this.Record?.record_id
    );

    this.m_IsOnUnsavedList = isUnsaved;
  }

  onOpenContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.m_CtxMenu?.open(event);
  }

  onViewAttachment(event: MouseEvent) {
    event.preventDefault();
    this.viewAttachment();
  }

  handleDragEnter(dragEvent: DropTargetEvent) {
    if (dragEvent.dragData == null) return;
    this.m_DragOver = true;
    this.m_RootElem?.nativeElement?.classList.add("drag-over");
  }

  handleDragLeave() {
    this.m_DragOver = false;
    this.m_RootElem?.nativeElement?.classList.remove("drag-over");
  }

  handleDrop(dragEvent: DropTargetEvent) {
    this.m_RootElem?.nativeElement?.classList.remove("drag-over");
    this.m_DragOver = false;
    if (this.m_Saving) return;
    let dragData = dragEvent.dragData as Media;
    if (dragData == null) return;
    this.setMediaAttachment(dragData);
  }

  public async onTTSBtnClicked(
    event?: MouseEvent,
    forceRegen: boolean = false
  ) {
    //if (this.m_Message == null || this.m_Message.text == undefined) return;
    if (event) event.preventDefault();
    if (!this.m_UserService.IsAdmin) forceRegen = false;
    if (this.m_TTSState == UtteranceState.PLAYING) {
      await this.m_UtteranceService.stop();
    } else {
      this.m_TTSState = UtteranceState.LOADING;
      await this.m_UtteranceService.speak(
        this.m_Answer,
        {
          streamTTS: false,
          streamAudioPlayback: false,
          voice: this.m_VoiceService.CurrentVoice ?? undefined,
        },
        this.onTTSStart.bind(this),
        this.onTTSEnd.bind(this),
        this.onTTSEnd.bind(this),
        forceRegen
      );
    }
  }
  //#endregion
  //#region Render Helpers
  getFormattedLabel(label: string, isAnswer: boolean) {
    if (label == "") {
      return isAnswer
        ? this.$t("components.qnaEditor.noAnswer")
        : this.$t("components.qnaEditor.noQuestion");
    } else return label;
  }

  getContextMenuOpts() {
    return this.m_ContextOptions;
  }

  shouldShowSaveBtn() {
    return this.m_ErrorSaving || this.m_IsOnUnsavedList;
  }

  removeAttachment(triggerEvent = true) {
    this.m_MediaAttachment = null;
    if (this.m_Record) {
      this.m_AttachmentType = ATTACHMENT_TYPE.NONE;
      this.m_Record.web_url = null;
      this.m_Record.video_start_time = null;
      this.m_Record.video_end_time = null;
      this.m_Record.media_id = null;
    }
    if (this.m_ErrorNotification != null) this.m_ErrorNotification.hide();
    this.initContextMenu();
    this.onDataChanged();
    this.m_Saving = true;
    this.handleSave();

    if (triggerEvent) {
      this.m_Events.publish(EVENTS.RECORD_REMOVE_ATTACHMENT, {
        id: this.m_Record?.record_id,
      });
    }
  }

  getAttachmentIcon() {
    switch (this.m_AttachmentType) {
      case ATTACHMENT_TYPE.WEBURL:
        return "globe-outline";
      case ATTACHMENT_TYPE.VIDEO_SEGMENT:
        return "film-outline";
      case ATTACHMENT_TYPE.IMAGE:
        return "image";
      case ATTACHMENT_TYPE.VIDEO:
        return "videocam";
      default:
        return "attach";
    }
  }

  public getTTSIcon() {
    return this.m_TTSState == UtteranceState.PLAYING ? "stop-circle" : "play";
  }

  public shouldRenderTTSBtn() {
    return !this.shouldRenderTTSLoading();
  }

  public shouldRenderTTSLoading() {
    return this.m_TTSState == UtteranceState.LOADING;
  }

  public shouldDisableTTSBtn() {
    return (
      this.m_TTSState == UtteranceState.LOADING ||
      this.m_Saving ||
      this.m_InvalidData ||
      this.m_Answer == ""
    );
  }

  public onPinRecordClicked() {
    this.IsPinned = !this.m_Record?.is_pinned;
    this.UnsavedChanges = true;
    this.cacheRecordChanges({ is_pinned: this.IsPinned });
    //A Record can only be either pinned or unseen
    if (this.IsPinned) {
      this.IsSuggestible = true;
      this.cacheRecordChanges({ is_suggestible: true });
    }
    this.handleSave();
  }

  public clearStatesValues() {
    this.UnsavedChanges = false;
    this.m_InvalidData = false;
    this.m_ErrorSaving = false;
  }

  public onUnseenIconClicked() {
    this.IsSuggestible = !this.m_Record?.is_suggestible;
    this.UnsavedChanges = true;
    this.cacheRecordChanges({ is_suggestible: this.IsSuggestible });

    //A Record can only be either pinned or unseen
    if (!this.IsSuggestible) {
      this.IsPinned = false;
      this.cacheRecordChanges({ is_pinned: false });
    }
    this.handleSave();
  }
  //#endregion
  //#region Private Helpers
  //Helper to submit changes to the text options
  private async submitRecordChanges() {
    if (!this.UnsavedChanges || this.m_Record == null) return;
    if (this.m_Question == "") {
      this.m_InvalidData = true;
      return;
    }
    this.m_Saving = true;

    let updatedRecordDTO = null;
    let isNew = false;
    let newRecordData: RECORD = new RECORD();
    try {
      newRecordData.copy(this.m_Record);
      newRecordData.question = this.m_Question;
      newRecordData.answer = this.m_Answer;
      newRecordData.is_pinned = this.IsPinned;
      newRecordData.is_suggestible = this.IsSuggestible;
      newRecordData.web_url = this.m_Record.web_url;
      newRecordData.media_id = this.m_Record.media_id;
      newRecordData.video_start_time = this.m_Record.video_start_time;
      newRecordData.video_end_time = this.m_Record.video_end_time;

      if (
        this.m_Record.record_id == "" ||
        this.m_Record.record_id.startsWith("unsaved")
      ) {
        isNew = true;
        //Create the record
        updatedRecordDTO = await this.m_RecordService.createRecord(
          this.m_VideoId,
          newRecordData
        );
        if (updatedRecordDTO) {
          this.m_UserService.updateUserQnALimits(1);
          this.m_Events.publish(EVENTS.UPDATE_LIMITS);
        }
      } else {
        //Update the record
        updatedRecordDTO = await this.m_RecordService.updateRecord(
          newRecordData
        );
      }
    } catch (error) {
      this.m_ErrorSaving = true;
      console.error(error);
    }
    this.m_Saving = false;

    if (updatedRecordDTO == null || updatedRecordDTO.record == null) {
      //Update failed
      this.UnsavedChanges = true;
      this.m_InvalidData = true;
      this.m_ErrorSaving = true;
    } else {
      //Update succeeded, update the record and emit the new id if it's new
      this.clearStatesValues();
      let oldId = this.m_Record.record_id;

      await this.deleteCachedRecordChanges();

      this.m_Record = new RECORD(updatedRecordDTO.record);
      if (isNew) {
        this.m_Events.publish(EVENTS.RECORD_UPDATE_ID, {
          oldId: oldId,
          newId: this.m_Record.record_id,
        });
      }
      this.m_Events.publish(EVENTS.RECORD_UPDATED, {
        id: this.m_Record?.record_id,
      });
    }
  }

  //Helper to submit changes to the attachments
  /*
  private async submitMediaChanges() {
    if (
      this.m_Record == null ||
      this.m_Record.record_id == "" ||
      this.m_Record.record_id.startsWith("unsaved")
    )
      return;
    if (
      this.m_Record.media_id != null &&
      this.m_Record.media_id == this.m_MediaAttachment?.id
    )
      return;

    this.UnsavedChanges = true;
    this.m_Saving = true;

    //Set the record's media
    try {
      let updatedRecordDTO = await this.m_RecordService.updateRecordMedia(
        this.m_Record.record_id,
        this.m_MediaAttachment?.id
      );

      this.UnsavedChanges = false;
      if (updatedRecordDTO == null || updatedRecordDTO.record == null) {
        //Update failed
        this.UnsavedChanges = true;
        this.m_InvalidData = true;
      } else {
        this.m_Record = new RECORD(updatedRecordDTO.record);
      }
    } catch (error) {
      console.error(error);
      this.UnsavedChanges = true;
      this.error.emit(this.$t("shared.messages.failedToSetMedia"));
    }

    this.m_Saving = false;
  }
  */

  private async deleteRecord() {
    if (this.m_Record == null) return;
    if (this.m_Record.record_id.startsWith("unsaved")) {
      await this.deleteCachedRecordChanges();
      this.m_Events.publish(EVENTS.RECORD_DELETE, {
        id: this.m_Record.record_id,
      });
      return;
    }

    this.m_Saving = true;
    try {
      let result = await this.m_RecordService.deleteRecord(
        this.m_Record.record_id
      );

      this.deleteCachedRecordChanges();

      this.m_Events.publish(EVENTS.RECORD_DELETE, {
        id: this.m_Record.record_id,
      });
    } catch (error) {
      console.error(error);
      this.m_InvalidData = true;
    }
    this.m_Saving = false;
  }

  private isVideoSegment() {
    return (
      this.m_Record?.video_start_time != null &&
      this.m_Record?.video_end_time != null
    );
  }

  //Helper to initialize the context menu options for attachments
  private initContextMenu() {
    let webUrlOptTxt = "";
    //If we have a web url, set the text to edit, otherwise set it to add, but if we have a media attachment, set it to replace instead
    if (this.m_Record?.web_url != null)
      webUrlOptTxt = this.$t("shared.button.editWebUrl");
    else if (this.m_MediaAttachment != null)
      webUrlOptTxt = this.$t("shared.button.replaceWebUrl");
    else webUrlOptTxt = this.$t("shared.button.addWebUrl");

    this.m_ContextOptions = [
      {
        text:
          this.m_AttachmentType == ATTACHMENT_TYPE.NONE
            ? this.$t("shared.button.addImage")
            : this.$t("shared.button.replaceImage"),
        action: () => {
          this.onAddAttachmentClicked(ATTACHMENT_TYPE.IMAGE);
        },
        disabled: this.m_ReadOnly,
      },
      {
        text:
          this.m_AttachmentType == ATTACHMENT_TYPE.NONE
            ? this.$t("shared.button.addVideo")
            : this.$t("shared.button.replaceVideo"),
        action: () => {
          this.onAddAttachmentClicked(ATTACHMENT_TYPE.VIDEO);
        },
        disabled: this.m_ReadOnly,
      },
      {
        text:
          this.m_AttachmentType == ATTACHMENT_TYPE.NONE
            ? this.$t("shared.button.addVideoSegment")
            : this.$t("shared.button.replaceVideoSegment"),
        action: () => {
          this.onAddAttachmentClicked(ATTACHMENT_TYPE.VIDEO_SEGMENT);
        },
        disabled: this.m_ReadOnly,
      },
      {
        text: webUrlOptTxt,
        action: () => {
          this.onAddAttachmentClicked(ATTACHMENT_TYPE.WEBURL);
        },
        disabled: this.m_ReadOnly,
      },
    ];

    if (
      this.m_MediaAttachment != null ||
      this.m_Record?.web_url != null ||
      this.isVideoSegment()
    ) {
      //Insert view attachment option at start of array
      this.m_ContextOptions.unshift({
        text: this.$t("shared.button.viewAttachment"),
        action: () => {
          this.viewAttachment();
        },
      });

      //Insert remove attachment option at end of array
      this.m_ContextOptions.push({
        text: this.$t("shared.button.removeAttachment"),
        action: () => {
          this.UnsavedChanges = true;
          this.removeAttachment();
        },
        disabled: this.m_ReadOnly,
      });
    }
    if (this.m_UserService.IsAdmin) {
      //Insert regenerate Audio
      this.m_ContextOptions.push({
        text: this.$t("shared.button.regenerateAudio"),
        action: () => {
          this.onTTSBtnClicked(undefined, true);
        },
        disabled: false,
      });
    }
  }

  //Helper to fetch a media attachment for this record if it has one
  private async fetchAttachment(fetchId: string | null = null) {
    this.m_FetchingAttachment = true;
    if (this.m_Record?.media_id) {
      try {
        let attachment: Media | null = null;
        if (this.m_IsAdmin) {
          attachment = await this.m_AdminService.getAMedia(
            this.m_VideoId,
            this.m_Record.media_id
          );
        } else {
          attachment = await this.m_MediaService.getAMedia(
            this.m_VideoId,
            this.m_Record.media_id
          );
        }

        if (fetchId != null && fetchId != this.m_Record?.record_id) return; //Check if the fetch id is the same as the current id
        this.m_MediaAttachment = attachment;
      } catch (error) {
        console.warn(error);
        //Check if error is type of ValidationError class
        if (error instanceof ValidationError) {
          this.m_MediaAttachment = null;
          this.m_Record.media_id = null;
        }
      }
    }

    this.setAttachmentType();
    this.initContextMenu();
    this.m_FetchingAttachment = false;
  }

  public async openSegmentEditor() {
    let segment = this.isVideoSegment()
      ? {
          start: this.m_Record!.video_start_time!,
          end: this.m_Record!.video_end_time!,
        }
      : {
          start: 0.0,
          end: 0.0,
        };

    let attachmentContents: HIA_ATTACHMENT_CONTENT = {
      src: "",
      text: this.m_Answer,
      main_video_id: this.m_VideoId,
      segment: segment,
    };
    let msg: HIA_MSG = {
      msgUID: "",
      text: this.m_Answer,
      author: this.m_ChatService.bot,
      attachments: [
        {
          content: attachmentContents,
          contentType: ATTACHMENT_MIME_TYPE.VIDEO,
        },
      ],
      onSetSegment: (interval) =>
        this.setVideoSegment(interval.start, interval.end),
    };

    this.m_Events.publish(EVENTS.ATTACHMENT_SHOW, msg);
  }

  private async viewAttachment() {
    if (this.m_AttachmentType == ATTACHMENT_TYPE.IMAGE)
      this.m_Events.publish(EVENTS.MEDIA_SHOW, this.m_MediaAttachment);
    else if (this.m_AttachmentType == ATTACHMENT_TYPE.VIDEO) {
      let attachmentContents: HIA_ATTACHMENT_CONTENT = {
        src: this.m_MediaAttachment?.url || "",
        text: this.m_Answer,
        video_id: this.Record?.media_id ?? undefined,
        main_video_id: this.m_VideoId,
      };
      let msg: HIA_MSG = {
        msgUID: "",
        text: this.m_Answer,
        author: this.m_ChatService.bot,
        attachments: [
          {
            content: attachmentContents,
            contentType: ATTACHMENT_MIME_TYPE.VIDEO,
          },
        ],
      };

      this.m_Events.publish(EVENTS.ATTACHMENT_SHOW, msg);
    } else if (this.m_AttachmentType == ATTACHMENT_TYPE.WEBURL) {
      let attachmentContents: HIA_ATTACHMENT_CONTENT = {
        src: this.m_Record?.web_url || "",
        text: this.m_Answer,
        main_video_id: this.m_VideoId,
      };
      let msg: HIA_MSG = {
        msgUID: "",
        text: this.m_Answer,
        author: this.m_ChatService.bot,
        attachments: [
          {
            content: attachmentContents,
            contentType: ATTACHMENT_MIME_TYPE.WEBURL,
          },
        ],
      };

      this.m_Events.publish(EVENTS.ATTACHMENT_SHOW, msg);
    } else if (this.m_AttachmentType == ATTACHMENT_TYPE.VIDEO_SEGMENT) {
      let segment = this.isVideoSegment()
        ? {
            start: this.m_Record!.video_start_time!,
            end: this.m_Record!.video_end_time!,
          }
        : undefined;

      let attachmentContents: HIA_ATTACHMENT_CONTENT = {
        src: "",
        text: this.m_Answer,
        main_video_id: this.m_VideoId,
        segment: segment,
      };
      let msg: HIA_MSG = {
        msgUID: "",
        text: this.m_Answer,
        author: this.m_ChatService.bot,
        attachments: [
          {
            content: attachmentContents,
            contentType: ATTACHMENT_MIME_TYPE.VIDEO,
          },
        ],
      };

      this.m_Events.publish(EVENTS.ATTACHMENT_SHOW, msg);
    }
  }

  private onTTSStart() {
    this.m_TTSState = UtteranceState.PLAYING;
  }

  private onTTSEnd() {
    this.m_TTSState = UtteranceState.STOPPED;
  }

  private async focusTextArea(input: IonTextarea, caratPos: number = 0) {
    input.setFocus();
    let inputEle = await input.getInputElement();
    inputEle?.setSelectionRange(caratPos, caratPos);
  }

  private setAttachmentType() {
    if (
      this.m_MediaAttachment == null &&
      this.m_Record?.web_url == null &&
      !this.isVideoSegment()
    ) {
      this.m_AttachmentType = ATTACHMENT_TYPE.NONE;
      return;
    }

    if (this.m_MediaAttachment != null) {
      if (this.m_MediaAttachment.type == MEDIA_TYPE.IMAGE)
        this.m_AttachmentType = ATTACHMENT_TYPE.IMAGE;
      else if (this.m_MediaAttachment.type == MEDIA_TYPE.VIDEO)
        this.m_AttachmentType = ATTACHMENT_TYPE.VIDEO;
    } else if (this.isVideoSegment()) {
      this.m_AttachmentType = ATTACHMENT_TYPE.VIDEO_SEGMENT;
    } else {
      this.m_AttachmentType = ATTACHMENT_TYPE.WEBURL;
    }
  }

  private async updateValidState() {
    this.m_InvalidData = this.m_Question == "";
  }

  private clearAttachments() {
    this.m_MediaAttachment = null;
    this.m_AttachmentType = ATTACHMENT_TYPE.NONE;
    if (this.m_Record != null) {
      this.m_Record.video_start_time = null;
      this.m_Record.video_end_time = null;
      this.m_Record.web_url = null;
      this.m_Record.media_id = null;
    }
  }

  private async hasRecordChanges(): Promise<boolean> {
    if (this.m_Record?.record_id == null) return false;

    let key =
      "record_unsaved_changes_" +
      this.m_Record.video_id +
      "_" +
      this.m_Record.record_id;
    let unsavedChangesStr = await this.m_Storage.get(key);
    return unsavedChangesStr != null;
  }

  private cacheRecordChanges(changes: any) {
    if (this.m_Record?.record_id == null) return;

    let object: any = {
      question: this.m_Question,
      answer: this.m_Answer,
      media_id: this.m_Record.media_id,
      assetPath: this.m_Record.assetPath,
      assetType: this.m_Record.assetType,
      video_start_time: this.m_Record.video_start_time,
      video_end_time: this.m_Record.video_end_time,
      web_url: this.m_Record.web_url,
      is_pinned: this.IsPinned,
      is_suggestible: this.IsSuggestible,
      thumbnailPath: this.m_Record.thumbnailPath,
    };

    for (let key of Object.keys(changes)) {
      object[key] = changes[key];
    }

    this.m_Storage.set(
      "record_unsaved_changes_" +
        this.m_Record.video_id +
        "_" +
        this.m_Record.record_id,
      JSON.stringify(object)
    );

    this.addUnsavedId();
  }

  private async restoreRecordChanges(): Promise<boolean> {
    if (this.m_Record?.record_id == null) return false;

    let key =
      "record_unsaved_changes_" +
      this.m_Record.video_id +
      "_" +
      this.m_Record.record_id;
    let unsavedChangesStr = await this.m_Storage.get(key);
    let unsavedChanges = null;
    try {
      unsavedChanges = JSON.parse(unsavedChangesStr);
    } catch (exc) {}

    if (unsavedChanges != null) {
      this.m_Question = unsavedChanges.question;
      this.m_Answer = unsavedChanges.answer;
      this.IsPinned = unsavedChanges.is_pinned;
      this.IsSuggestible = unsavedChanges.is_suggestible;

      this.m_Record.media_id = unsavedChanges.media_id;
      this.m_Record.assetPath = unsavedChanges.assetPath;
      this.m_Record.assetType = unsavedChanges.assetType;
      this.m_Record.video_start_time = unsavedChanges.video_start_time;
      this.m_Record.video_end_time = unsavedChanges.video_end_time;
      this.m_Record.web_url = unsavedChanges.web_url;
      this.m_Record.thumbnailPath = unsavedChanges.thumbnailPath;

      await this.fetchAttachment();

      this.UnsavedChanges = true;
      return true;
    }

    return false;
  }

  public async deleteCachedRecordChanges() {
    if (this.m_Record?.record_id != null) {
      await this.m_Storage.remove(
        "record_unsaved_changes_" +
          this.m_Record.video_id +
          "_" +
          this.m_Record.record_id
      );

      await this.deleteUnsavedId();
      this.shouldRenderSaveBtn();
    }
  }

  private async addUnsavedId() {
    if (this.m_Record?.record_id == null) return;

    let key = "record_unsaved_ids_" + this.m_VideoId;
    let unsavedIdsStr = await this.m_Storage.get(key);
    let unsavedIds = null;
    try {
      unsavedIds = JSON.parse(unsavedIdsStr);
    } catch (exc) {}

    if (unsavedIds == null) unsavedIds = [];
    if (unsavedIds.indexOf(this.m_Record.record_id) < 0) {
      unsavedIds.push(this.m_Record.record_id);
      this.m_Storage.set(key, JSON.stringify(unsavedIds));
    }
  }

  private async deleteUnsavedId() {
    if (this.m_Record?.record_id == null) return;

    let recordId = this.m_Record.record_id;
    let key = "record_unsaved_ids_" + this.m_VideoId;
    let unsavedIdsStr = await this.m_Storage.get(key);
    let unsavedIds = [];
    try {
      unsavedIds = JSON.parse(unsavedIdsStr);
    } catch (exc) {}

    if (unsavedIds != null) {
      unsavedIds = unsavedIds.filter((item: string) => {
        return item !== recordId;
      });
      this.m_Storage.set(key, JSON.stringify(unsavedIds));
    }
  }
  //#endregion
}
