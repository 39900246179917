import {
  AnonymousCredential,
  BlockBlobClient,
  newPipeline,
} from "@azure/storage-blob";
import { ErrorFactory } from "../errors/custom-errors";

/**
 *  Uploads a file to an azure blob url
 * @param file
 * @param url
 * @param onProgress
 * @returns true if successful
 * @throws Error if failed
 */
export async function uploadToBlobURLSync(
  file: File,
  url: string,
  onProgress: (event: number) => void
): Promise<boolean> {
  let fileSize = file.size;
  const pipeline = newPipeline(new AnonymousCredential());
  let blockBlobClient = new BlockBlobClient(url, pipeline);

  let maxBlockSize = 4 * 1024 * 1024;
  let minNumberofBlocks = 5;
  let blockSize = Math.min(fileSize / minNumberofBlocks, maxBlockSize);

  let res = await blockBlobClient.uploadData(file, {
    blockSize,
    maxSingleShotSize: blockSize,
    concurrency: 20, // 20 concurrency
    onProgress: (ev) => {
      let progress = Math.round((ev.loadedBytes / fileSize) * 100);
      onProgress(progress);
    },
    blobHTTPHeaders: {
      blobContentType: file.type || "application/octet-stream",
    },
  });

  if (res._response.status >= 200 && res._response.status < 300) {
    return true;
  } else {
    throw ErrorFactory.error(res._response.status, "Failed to upload blob");
  }
}
