import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { USER_DTO, USER_DTO_UPDATE_REQ } from "@shared/models/user/user";
import { EVENTS } from "src/app/constants/events";
import { Events } from "src/app/services/events/events.service";
import { T } from "src/app/services/localization/localization.service";
import { SessionService } from "src/app/services/session/session.service";

import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  standalone: false,
})
export class AvatarComponent {
  @ViewChild("imgUpload", { static: false }) m_ImgUpload!: ElementRef;

  public m_User: USER_DTO | null = null;
  public m_Initials: string = "";
  public m_ShowUploadMessage: boolean = false;
  public m_ImageSrc?: string | ArrayBuffer;
  public m_Loading: boolean = false;
  public m_Initialized: boolean = false;
  public m_BackgroundColor: string = "#FFF";
  public m_FontColor: string = "#000";

  private m_ProfileImage?: string;
  private m_ImageFile?: File;

  public $t = T.translate;

  @Input() set User(user: USER_DTO) {
    if (!user) {
      this.m_User = this.m_UserService.ActiveUserInfo;
      return;
    }
    this.m_User = user;
  }

  @Input() EnableUpload: boolean = false;
  @Input() Size: number | null = null;
  @Input() UserId: string | null = null;
  @Input() RedirectProfile: boolean = false;

  constructor(
    private m_UserService: UserService,
    private m_AuthService: SessionService,
    private m_Events: Events,
    private m_Router: Router
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.initialize();
    }, 1000);

    this.m_Events.subscribe(EVENTS.PROFILE_UPDATED, () => {
      this.initialize();
    });
  }

  get UserPropId() {
    return this.UserId;
  }

  private async initialize() {
    this.m_Initialized = false;
    //If user id not setted - Force again
    if (!this.UserId) {
      setTimeout(() => {
        this.m_Loading = true;
        this.m_Initialized = false;
        return this.initialize();
      }, 2500);
    }

    //Get user informations by User ID
    if (this.UserId != null) {
      try {
        await this.getUserInformations(this.UserId, true);

        if (
          !this.m_User?.profile_picture_url &&
          !this.m_UserService.UserProfilePicture
        ) {
          this.handleColors();
          this.generateInitials();
        }
      } finally {
        this.m_Loading = false;
        this.m_Initialized = true;
      }
    }
  }

  openPublicProfile() {
    if (this.RedirectProfile && this.m_User) {
      this.m_Router.navigate([`/u/${this.m_User.username}`]);
    }
  }

  generateInitials() {
    if (this.m_User && this.m_User.profile_picture_url == null) {
      if (this.m_User.first_name || this.m_User.last_name) {
        this.m_Initials = `${this.m_User.first_name?.charAt(0) || ""}${
          this.m_User.last_name?.charAt(0) || ""
        }`;
      } else {
        this.m_Initials = `${this.m_User.username?.charAt(0) || ""}`;
      }
    }

    return this.m_Initials;
  }

  async getUserInformations(userId: string, setProfileImg = false) {
    if (!userId && !this.UserId) return;
    try {
      this.m_Loading = true;
      const user = await this.m_UserService.getUserInfo(userId, false);
      this.m_User = user;
      if (setProfileImg) {
        if (user.profile_picture_url) {
          this.m_ProfileImage = user.profile_picture_url;
        } else {
          this.m_ProfileImage = this.m_UserService.UserProfilePicture;
        }
      }
    } finally {
      this.m_Loading = false;
    }
  }

  handleColors() {
    if (!this.m_User) return;
    const formatToHex = (c: any) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    let name =
      this.m_User.first_name ||
      this.m_User.last_name ||
      this.m_User.username ||
      "";

    let r = 0;
    let g = 0;
    let b = 0;

    //Generate hex by initials
    for (let i = 0; i < name.length; i++) {
      const charCode = name.charCodeAt(i);
      r = (r + charCode) % 256;
      g = (g + charCode * 2) % 256;
      b = (b + charCode * 3) % 256;
    }

    const luminosity = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
    const backgroundColor =
      "#" + formatToHex(r) + formatToHex(g) + formatToHex(b);
    const textColor = luminosity > 0.5 ? "#000000" : "#FFFFFF";

    this.m_BackgroundColor = backgroundColor;
    this.m_FontColor = textColor;
  }

  onChangeClicked() {
    this.m_ImgUpload.nativeElement.click();
  }

  onSelectPicture(event: any) {
    if (event?.target?.files != null && event?.target?.files[0] != null) {
      let file = event.target.files[0];
      this.m_ImageFile = file;
      let reader = new FileReader();
      reader.onload = (e) => {
        if (reader.result != null) {
          this.m_ImageSrc = reader.result;
        }
      };
      reader.readAsDataURL(file);
    }

    if (this.m_User) {
      this.setUserInfo(this.m_User, this.m_ImageFile);
    }
  }

  async setUserInfo(user: USER_DTO_UPDATE_REQ, picture?: File) {
    this.m_Loading = true;

    const payload = {
      id: user.id,
      username: user.username,
    };

    try {
      await this.m_UserService.setUserInfo(payload, picture);
    } catch (e) {
      console.log(e);
    } finally {
      this.m_Events.publish(EVENTS.PROFILE_UPDATED);
      this.m_Loading = false;
    }
  }

  showUploadMessage(value: boolean) {
    if (!this.EnableUpload) return;
    this.m_ShowUploadMessage = value;
  }

  getProfileImage() {
    if (this.User && !this.User.profile_picture_url) return null;
    else if (this.m_ProfileImage) return this.m_ProfileImage;
    return null;
  }

  getInitials() {
    return this.m_Initials;
  }

  getFontSize() {
    if (this.Size && this.Size > 100) {
      return "1.8em";
    } else {
      return "0.8em";
    }
  }
}
