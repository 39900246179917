import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { RecordService } from "../record/record.service";
import {
  Action,
  Attachment,
  Message,
  User,
} from "@progress/kendo-angular-conversational-ui";
import { T } from "../localization/localization.service";
import {
  ATTACHMENT_MIME_TYPE,
  ATTACHMENT_TYPE,
  PAGINATED_RECORDS,
  QueryResponse,
} from "src/app/models/record/record";
import { MEDIA_TYPE } from "@shared/models/media/media";
import { v4 as uuidv4 } from "uuid";
import { UtteranceService } from "../utterance/utterance.service";
import { AnalyticsService } from "../analytics/analytics.service";
import {
  TRACK_EVENT,
  TRACK_QUERY_DTO,
} from "src/app/models/analytics/analytics";
import { removeLinks } from "@shared/utils/utils";
import { VoiceService } from "../voice/voice.service";
import { VOICE } from "src/app/models/voice/voice";
import { ConfigService } from "../config/config.service";
import { SessionService } from "../session/session.service";

export interface HIA_MSG extends Message {
  runTTS?: boolean;
  overrideVoice?: VOICE;
  clientUID?: string;
  msgUID: string;
  response_type?: "record" | "fallback" | "insight" | "none";
  record_score?: number;
  recordId?: string;
  insightInfo?: { insight_key: string; insight_name: string };
  attachmentOnly?: boolean;
  fallbackOptions?: Suggestion[];
  onSetSegment?: (interval: { start: number; end: number }) => void;
}

export interface HIA_ATTACHMENT_CONTENT {
  src: string;
  thumbnail?: string;
  text: string;
  video_id?: string;
  main_video_id?: string;
  segment?: { start: number; end: number };
}

export interface Suggestion {
  text: string;
  attachmentType: ATTACHMENT_TYPE;
  type: "askHIA" | "askOpenAI" | "login";
}

@Injectable({
  providedIn: "root",
})
export class ChatService {
  public readonly Response: Subject<HIA_MSG> = new Subject<HIA_MSG>();

  private m_Messages: HIA_MSG[] = [];
  private $t = T.translate;
  private m_CurrMsgPlaying: HIA_MSG | null = null;

  get Messages(): HIA_MSG[] {
    return this.m_Messages;
  }

  get CurrMsgPlaying(): HIA_MSG | null {
    return this.m_CurrMsgPlaying;
  }

  //Chat participants
  public readonly bot: User = {
    id: 0,
  };
  public readonly user: User = {
    id: 1,
  };
  public readonly openAI: User = {
    id: 2,
    name: "ChatGPT",
  };

  constructor(
    private m_RecordService: RecordService,
    private m_AuthService: SessionService,
    private m_UtteranceService: UtteranceService,
    private m_Analytics: AnalyticsService,
    private m_VoiceService: VoiceService,
    private m_ConfigService: ConfigService
  ) {
    this.reset();
  }

  /**
   * Submits a question to the query api
   * @param videoId
   * @param question
   */
  async submit(
    videoId: string,
    msg: HIA_MSG,
    questionTyped: boolean = false,
    canAskChatGPT: boolean = false
  ) {
    if (!msg.text) return;

    //Inform the user that the bot is thinking
    this.Response.next(msg);
    this.Response.next({
      msgUID: uuidv4(),
      author: this.bot,
      typing: true,
    });

    let result: QueryResponse | null = null;
    try {
      result = await this.m_RecordService.query(videoId, msg.text);
    } catch (error) {
      console.error(error);
    }

    let botMessage = this.verifyResult(
      result,
      msg.text,
      videoId,
      canAskChatGPT
    );
    this.trackQueryEvent(botMessage, msg.text, videoId, questionTyped);
    if (botMessage.text != null && botMessage.text != "" && botMessage.runTTS) {
      //If the answer is not empty, run TTS on it
      let msgReplied = false;
      let sanatizedText = removeLinks(botMessage.text);
      if (sanatizedText == "") {
        //Do not run TTS on empty answer
        this.m_Messages.push(msg);
        this.sendResponse(botMessage);
        msgReplied = true;
        return;
      }
      //Run TTS on the answer, and show on start of stream
      let activeVoice = this.m_VoiceService.CurrentVoice;
      if (
        botMessage.overrideVoice &&
        (await this.m_ConfigService.get("OVERWRITE_VOICE")) === "true"
      )
        activeVoice = botMessage.overrideVoice;
      await this.m_UtteranceService.speak(
        sanatizedText,
        {
          streamTTS: false,
          streamAudioPlayback: false,
          voice: activeVoice ?? undefined,
          localize: botMessage.overrideVoice ? true : false,
        },
        () => {
          //Audio playback started, send the response
          if (!msgReplied) {
            this.m_CurrMsgPlaying = botMessage;
            //Push to the messages array for history and send the response
            this.m_Messages.push(msg);
            this.sendResponse(botMessage);
            msgReplied = true;
          }
        },
        () => {
          //Audio playback ended, clear the current message
          this.m_CurrMsgPlaying = null;
        },
        (error) => {
          console.error(error);
          //Push to the messages array for history and send the response
          this.m_Messages.push(msg);
          this.sendResponse(botMessage);
          msgReplied = true;
        }
      );
    } else {
      this.m_Messages.push(msg);
      this.sendResponse(botMessage);
    }
  }
  //--------------------------------------------------------------------
  /**
   * Submits a question to the Advanced Query API
   * @param videoId
   * @param question
   */
  async submitToOpenAI(videoId: string, msg: HIA_MSG) {
    if (!msg.text) return;

    if (!this.m_AuthService.LoggedIn) {
      let loginMessage: HIA_MSG = {
        msgUID: uuidv4(),
        text: this.$t("components.qna.loginMessage"),
        author: this.bot,
        fallbackOptions: [
          {
            text: this.$t("shared.button.login"),
            attachmentType: ATTACHMENT_TYPE.NONE,
            type: "login",
          },
        ],
      };

      this.sendResponse(loginMessage);
      return;
    }

    //Inform the user that the bot is thinking
    this.Response.next({
      msgUID: uuidv4(),
      author: this.openAI,
      typing: true,
    });

    //Submit the question to the API
    let result = null;
    try {
      result = await this.m_RecordService.advancedQuery(videoId, msg.text);
    } catch (error) {
      console.error(error);
      result = this.$t("components.qna.openAIErr");
    }

    //Construct the bot response and send it
    let botMessage: HIA_MSG = {
      msgUID: uuidv4(),
      text: result.answer,
      author: this.openAI,
    };

    let msgReplied = false;
    let activeVoice = this.m_VoiceService.CurrentVoice;
    await this.m_UtteranceService.speak(
      result.answer,
      {
        streamTTS: false,
        streamAudioPlayback: false,
        voice: activeVoice ?? undefined,
      },
      () => {
        if (!msgReplied) {
          this.m_CurrMsgPlaying = botMessage;
          //Push to the messages array for history and send the response
          this.sendResponse(botMessage);
          msgReplied = true;
        }
      },
      () => {
        //Audio playback ended, clear the current message
        this.m_CurrMsgPlaying = null;
      },
      (error) => {
        if (!msgReplied) {
          //Push to the messages array for history and send the response
          this.sendResponse(botMessage);
          msgReplied = true;
        }
      }
    );
  }
  //--------------------------------------------------------------------
  /**
   * Gets suggestions for a given video id to be displayed in chat UI
   * @param videoId
   * @returns
   */
  async getSuggestions(videoId: string): Promise<Suggestion[]> {
    let suggestions: Suggestion[] = [];
    try {
      let result = await this.m_RecordService.getRandomRecords(videoId, 10);
      result.forEach((record) => {
        if (record.question) {
          //Determine the attachment type
          let attachmentType: ATTACHMENT_TYPE = record.getAttachmentType();

          //Add the suggestion to the array
          suggestions.push({
            text: record.question,
            attachmentType: attachmentType,
            type: "askHIA",
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    return suggestions;
  }
  //--------------------------------------------------------------------
  async getSuggestedRecords(
    videoId: string,
    pageNumber?: number,
    pageSize?: number,
    pageNumberSeed?: number
  ): Promise<PAGINATED_RECORDS | null> {
    try {
      let result = await this.m_RecordService.getSuggestions(
        videoId,
        pageNumber,
        pageSize,
        pageNumberSeed
      );

      return result;
    } catch (error) {
      console.error(error);
    }

    return null;
  }
  //--------------------------------------------------------------------
  //Resets the chat to the welcome message, clears the messages
  async reset() {
    this.m_Messages = [];

    const welcomeMessage: HIA_MSG = {
      msgUID: uuidv4(),
      author: this.bot,
      text: this.$t(
        "components.qna.welcomeMessage",
        "Hello, you can ask me one of the questions suggested or type your own question below."
      ),
      runTTS: true,
    };

    this.m_Messages.push(welcomeMessage);
  }
  //--------------------------------------------------------------------
  //Helper function to assemble suggested actions for the chat UI
  //Deprecated
  private assembleSuggestedActions(
    suggestions: string[] | undefined
  ): Action[] {
    if (suggestions == null) return [];

    let suggestedActions: Action[] = [];

    suggestions.forEach((suggestion) => {
      suggestedActions.push({
        title: suggestion,
        type: "askHIA",
        value: suggestion,
      });
    });

    return suggestedActions;
  }
  //--------------------------------------------------------------------
  private async sendResponse(msg: HIA_MSG) {
    this.m_Messages.push(msg);
    this.Response.next(msg);
  }
  //--------------------------------------------------------------------
  /**
   * Helper function that verifies the result of a query and constructs the bot message response to show
   * @param result
   * @param originalQuestion
   * @returns HIA_MSG to show the user
   */
  private verifyResult(
    result: QueryResponse | null,
    originalQuestion: string,
    videoId: string,
    canAskChatGPT = false
  ): HIA_MSG {
    //Default msg properties
    let answer = this.$t("components.qna.defaultMessage");
    let suggestions: Suggestion[] = [];
    let attachments: Attachment[] = [];
    let runTTS = true;
    let response_type: "record" | "fallback" | "insight" | "none" = "none";
    let recordId = "";
    let recordScore = undefined;
    let insightInfo = { insight_key: "", insight_name: "" };
    let attachmentOnly = false;
    let overrideVoice: VOICE | undefined = undefined;
    //Result verification
    let fallback = false;
    if (result == null || result.records.length == 0) {
      answer = canAskChatGPT
        ? this.$t("components.qna.noAnswerWithOpenAI")
        : this.$t("components.qna.noAnswerMessage");
      fallback = true;
    } else if (
      (result.type == "record" || result.type == "insight") &&
      result.records.length > 0
    ) {
      //If the top result has a score above the threshold, use it as the answer
      let topResponse = result.records[0];
      response_type = result.type;

      //Check reponse type and set the answer accordingly
      if (response_type == "record" && topResponse.record) {
        let record = topResponse.record;
        recordId = record.record_id;
        recordScore = topResponse.score;
        //If the top result has an answer, use it
        if (record.answer) {
          answer = record.answer;
        } else if (record.answer == "") {
          answer = this.$t("components.qna.noAnswerProvided");
        }

        //If the top result has an override voice, set it
        if (topResponse.voice) {
          overrideVoice = new VOICE(topResponse.voice);
        }

        //If the top result has an asset, add it to the attachments
        if (record.getAttachmentType() == ATTACHMENT_TYPE.VIDEO_SEGMENT) {
          let attachmentContent: HIA_ATTACHMENT_CONTENT = {
            src: "",
            text: record.answer ?? "",
            main_video_id: videoId,
            segment: {
              start: record.video_start_time!,
              end: record.video_end_time!,
            },
          };
          attachments.push({
            content: attachmentContent,
            contentType: ATTACHMENT_MIME_TYPE.VIDEO,
          });
        } else if (record.assetPath && record.assetType != null) {
          let attachmentContent: HIA_ATTACHMENT_CONTENT = {
            src: record.assetPath,
            thumbnail: record.thumbnailPath ?? "",
            text: record.answer ?? "",
          };

          switch (record.assetType as MEDIA_TYPE) {
            case MEDIA_TYPE.IMAGE:
              attachments.push({
                content: attachmentContent,
                contentType: ATTACHMENT_MIME_TYPE.IMAGE,
              });
              if (record.answer == "") {
                answer = this.$t("components.qna.imageAttachment");
              }
              break;
            case MEDIA_TYPE.VIDEO:
              attachmentContent.video_id = record.media_id ?? undefined;
              attachmentContent.main_video_id = record.video_id;

              attachments.push({
                content: attachmentContent,
                contentType: ATTACHMENT_MIME_TYPE.VIDEO,
              });
              if (record.answer == "") {
                answer = this.$t("components.qna.videoAttachment");
              }
              break;
          }
        } else if (record.web_url != null) {
          let attachmentContent: HIA_ATTACHMENT_CONTENT = {
            src: record.web_url,
            text: record.answer ?? "",
          };
          attachments.push({
            content: attachmentContent,
            contentType: ATTACHMENT_MIME_TYPE.WEBURL,
          });
        }

        //Check if the response is attachment only
        if (attachments.length > 0 && record.answer == "") {
          attachmentOnly = true;
          runTTS = false;
        }
      } else if (response_type == "insight" && topResponse.insight) {
        console.warn("Intent response type not implemented yet");
        insightInfo = {
          insight_key: topResponse.insight.insight_key,
          insight_name: topResponse.insight.insight_name,
        };
      }
    } else if (result.type == "fallback") {
      fallback = true;
      response_type = "fallback";
      answer = canAskChatGPT
        ? this.$t("components.qna.fallbackWithOpenAI")
        : this.$t("components.qna.fallbackMessage");
      let recordsLen = result.records?.length ?? 0;
      for (let i = 0; i < recordsLen; i++) {
        //limit to 4 suggestions
        if (i > 3) break;
        let queryResultEntry = result.records[i];
        //add returned questions to suggestions
        if (queryResultEntry?.record?.question) {
          suggestions.push({
            text: queryResultEntry?.record?.question,
            attachmentType: queryResultEntry?.record?.getAttachmentType(),
            type: "askHIA",
          });
        }
      }
      recordId = result.records[0]?.record?.record_id ?? "";
      recordScore = result.records[0]?.score ?? undefined;
    }

    let botMessage: HIA_MSG = {
      msgUID: uuidv4(),
      text: answer,
      attachmentOnly: attachmentOnly,
      //suggestedActions: this.assembleSuggestedActions(suggestions),
      fallbackOptions: suggestions,
      author: this.bot,
      attachments: attachments,
      runTTS: runTTS,
      response_type: response_type,
      recordId: recordId,
      record_score: recordScore,
      insightInfo: insightInfo,
      overrideVoice: overrideVoice,
    };

    //If fallback is true, append ask chatgpt to suggestions action
    if (canAskChatGPT && fallback) {
      //botMessage.suggestedActions?.push({
      //  title: this.$t("components.qna.askOpenAI"),
      //  type: "askOpenAI",
      //  value: originalQuestion,
      //});
      botMessage.fallbackOptions?.push({
        text: this.$t("components.qna.askOpenAI"),
        attachmentType: ATTACHMENT_TYPE.NONE,
        type: "askOpenAI",
      });
    }

    return botMessage;
  }
  //--------------------------------------------------------------------
  /**
   * Tracks the query event results
   * @param botMessage The bot message that was sent
   * @param originalQuestion The original question that was asked
   * @param videoId The video id that was queried
   * @param questionTyped Whether the question was typed or selected from suggestions
   */
  private trackQueryEvent(
    botMessage: HIA_MSG,
    originalQuestion: string,
    videoId: string,
    questionTyped: boolean = false
  ) {
    let sessionId = this.m_Analytics.CurrWatchSessionId;
    if (!sessionId) return;

    let metadata: TRACK_QUERY_DTO = {
      sessionId: sessionId,
      videoId: videoId,
      question: originalQuestion,
      questionTyped: questionTyped,
      response_type: botMessage.response_type ?? "none",
      answer: botMessage.text ?? "",
      record_id: botMessage.recordId ?? "",
      record_score: botMessage.record_score ?? undefined,
      insight_key: botMessage.insightInfo?.insight_key ?? "",
      insight_name: botMessage.insightInfo?.insight_name ?? "",
    };

    this.m_Analytics.trackEvent(TRACK_EVENT.VIDEO_QUERY, metadata);
  }
}
