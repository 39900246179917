import {
  PUBLIC_VIDEO_DTO,
  VIDEO_DTO,
  VIDEO_DTO_CAPTIONS_RES,
  VIDEO_PUBLISH_STATE,
  VIDEO_STATUS,
} from "@shared/models/video/video";
import { getLanguageName } from "src/app/utility/video-utils";
import { TextTrackDescription } from "theoplayer";
import { OVERLAY } from "../overlays/overlay";

export class VIDEO {
  public video_id: string;
  public video_user_id: string;
  public video_status: VIDEO_STATUS;
  public video_name: string;
  public voice_id: string;
  public video_description?: string;
  public video_url?: string;
  public create_date?: string;
  public last_modified?: string;
  public publish_date?: string;
  public video_publish_state?: VIDEO_PUBLISH_STATE;
  public video_index_status: VIDEO_STATUS;
  public video_thumb_url?: string;
  public video_duration?: number;
  public video_views?: number;
  public total_questions?: number;
  public show_filters?: boolean;
  public can_ask_chatgpt?: boolean;
  public read_only?: boolean;
  public timeline_vtt_url?: string;
  public current_position?: number;
  public source_language?: string;
  public is_360_video?: boolean;
  public folder_id?: string;
  public can_multilang?: boolean;
  public fallback_threshold?: number;
  public overlays?: OVERLAY[];

  constructor(dto: VIDEO_DTO | PUBLIC_VIDEO_DTO) {
    let videoDTO = dto as VIDEO_DTO;
    this.video_id = videoDTO.id;
    this.video_user_id = videoDTO.user_id;
    this.video_name = videoDTO.video_name;
    this.video_description = videoDTO.video_description;
    this.video_url = videoDTO.video_url;
    this.video_thumb_url = videoDTO.video_thumb_url;
    this.video_duration = videoDTO.duration;
    this.video_views = videoDTO.views;
    this.total_questions = videoDTO.total_questions;
    this.show_filters = videoDTO.show_filters;
    this.can_ask_chatgpt = videoDTO.can_ask_chatgpt;
    this.timeline_vtt_url = videoDTO.timeline_vtt_url;
    this.voice_id = videoDTO.voice_id;
    this.current_position = videoDTO.current_position;
    this.source_language = videoDTO.source_language;
    this.create_date = videoDTO.create_date;
    this.video_status = videoDTO.video_status;
    this.last_modified = videoDTO.last_modified;
    this.publish_date = videoDTO.publish_date;
    this.video_publish_state = videoDTO.video_publish_state;
    this.video_index_status = videoDTO.video_index_status;
    this.read_only = videoDTO.read_only;
    this.folder_id = videoDTO.folder_id;
    this.is_360_video = videoDTO.is_360_video;
    this.can_multilang = videoDTO.can_multilang;
    this.fallback_threshold = videoDTO.fallback_threshold;
    this.overlays = videoDTO.overlays?.map((overlay) => {
      let newOverlay = new OVERLAY(overlay);
      return newOverlay;
    });
  }

  copy(videoToCopy: VIDEO): void {
    this.video_id = videoToCopy.video_id;
    this.video_user_id = videoToCopy.video_user_id;
    this.video_name = videoToCopy.video_name;
    this.video_description = videoToCopy.video_description;
    this.video_url = videoToCopy.video_url;
    this.video_thumb_url = videoToCopy.video_thumb_url;
    this.video_duration = videoToCopy.video_duration;
    this.video_views = videoToCopy.video_views;
    this.total_questions = videoToCopy.total_questions;
    this.show_filters = videoToCopy.show_filters;
    this.can_ask_chatgpt = videoToCopy.can_ask_chatgpt;
    this.timeline_vtt_url = videoToCopy.timeline_vtt_url;
    this.voice_id = videoToCopy.voice_id;
    this.current_position = videoToCopy.current_position;
    this.source_language = videoToCopy.source_language;
    this.create_date = videoToCopy.create_date;
    this.video_status = videoToCopy.video_status;
    this.last_modified = videoToCopy.last_modified;
    this.publish_date = videoToCopy.publish_date;
    this.video_publish_state = videoToCopy.video_publish_state;
    this.video_index_status = videoToCopy.video_index_status;
    this.read_only = videoToCopy.read_only;
    this.folder_id = videoToCopy.folder_id;
    this.is_360_video = videoToCopy.is_360_video;
    this.can_multilang = videoToCopy.can_multilang;
    this.overlays = videoToCopy.overlays?.map((overlay) => {
      let newOverlay = new OVERLAY();
      newOverlay.copy(overlay);
      return newOverlay;
    });
    this.fallback_threshold = videoToCopy.fallback_threshold;
  }
}

export interface VIDEO_LIST {
  data: VIDEO[];
  total: number;
  totalPages?: number;
}

export class CAPTIONS {
  contents: string;
  language: string;

  constructor(captionsRes: VIDEO_DTO_CAPTIONS_RES, language: string) {
    this.contents = captionsRes.captions;
    this.language = language;
  }

  // Convert to TextTrackDescription for theoplayer
  toTheoTrack(): TextTrackDescription {
    let blob = this.toBlob();
    return {
      kind: "captions",
      label: getLanguageName(this.language),
      srclang: this.language,
      src: URL.createObjectURL(blob),
      default: false,
    };
  }

  toBlob(): Blob {
    return new Blob([this.contents], { type: "text/vtt" });
  }
}

/**
 * Interface for chapter data to display in player.
 * Timestamp is the key and the value is the chapter name
 */
export interface ChapterMap {
  [key: string]: string;
}

export enum QvioViewMode {
  WATCH = 0,
  EDIT = 1,
  ADMIN = 2,
}
