import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import {
  PageChangeEvent,
  PagerSettings,
} from "@progress/kendo-angular-listview";
import { MEDIA_STATUS, MEDIA_TYPE } from "@shared/models/media/media";
import { MEDIA_LIST, Media } from "src/app/models/media/media";
import { T } from "src/app/services/localization/localization.service";
import { MediaService } from "src/app/services/media/media.service";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { MediaCardComponent } from "../media-card/media-card.component";
import { VideoService } from "src/app/services/video/video.service";
import { AlertModalComponent } from "../../modals/alert-modal/alert-modal.component";
import { Events } from "src/app/services/events/events.service";
import { EVENTS } from "src/app/constants/events";
import { ATTACHMENT_MIME_TYPE, RECORD } from "src/app/models/record/record";
import {
  ChatService,
  HIA_ATTACHMENT_CONTENT,
  HIA_MSG,
} from "src/app/services/chat/chat.service";

export enum FILTER_TYPE {
  ALL,
  IMAGES,
  VIDEOS,
}

@Component({
  selector: "app-media-gallery",
  templateUrl: "./media-gallery.component.html",
  styleUrls: ["./media-gallery.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MediaGalleryComponent implements OnInit {
  @Input() public set VideoId(value: string) {
    if (this.m_VideoId == value) return;
    this.m_SearchValue = "";
    this.m_VideoId = value;
    this.reset();
  }
  @Input() public m_Records: RECORD[] | null = null;
  @Input() public m_ShowSelect: boolean = false;
  @Input() public m_ShowFilter: boolean = true;
  @Input() public m_AllowDelete: boolean = true;
  @Input() public m_ShowImport: boolean = true;
  @Input() public set CurrFilter(value: FILTER_TYPE) {
    if (this.m_CurrFilter == value) return;
    this.m_CurrFilter = value;
    this.reset();
  }
  @ViewChild("m_RootContainer", { read: ViewContainerRef, static: false })
  m_RootContainer: ViewContainerRef | undefined;
  @ViewChild("m_DropZone") m_DropZone: any;
  @ViewChild("m_ImgUploader") m_ImgUploader: ElementRef | undefined;
  @ViewChildren(MediaCardComponent) m_MediaCards:
    | MediaCardComponent[]
    | undefined;
  @ViewChild("deleteWarning", { read: AlertModalComponent })
  m_DeleteWarning: AlertModalComponent | undefined;

  @Output() public OnCloseClicked: EventEmitter<void> = new EventEmitter();
  @Output() public OnMediaSelected: EventEmitter<Media> = new EventEmitter();
  @Output() public OnMediaDeleted: EventEmitter<Media> = new EventEmitter();

  get CurrMedia(): MEDIA_LIST {
    return this.m_CurrMedia;
  }

  private m_VideoId: string = "";
  private m_CurrMedia: MEDIA_LIST = { data: [], total: 0 };
  private m_CurrSelected: Media | null = null;
  private m_Uploading: boolean = false;
  private m_CurrFilter: FILTER_TYPE = FILTER_TYPE.IMAGES;
  private m_SearchValue: string = "";
  private m_SearchTimeout: any = null;
  private m_SearchTimeoutDelay: number = 500;

  public m_PageNumber: number = 0;
  public m_PageSize: number = 8;
  public m_Skip: number = 0;
  public m_Loading: boolean = false;
  public $t = T.translate;
  public m_SearchFilters = {
    file: false,
    image: true,
  };

  /**
   * Returns the pager settings for the media gallery
   * @returns The pager settings for the media gallery or false if there is no media to display
   */
  public get showPager(): PagerSettings | boolean {
    if (this.CurrMedia && this.CurrMedia.data?.length > 0) {
      let settings: PagerSettings = {
        pageSizeValues: false,
      };
      return settings;
    }

    return false;
  }

  constructor(
    private m_VideoService: VideoService,
    private m_MediaService: MediaService,
    private m_NotificationService: HIANotificationService,
    private m_Events: Events,
    private m_ChatService: ChatService
  ) {
    this.m_Events.subscribe(EVENTS.MEDIA_DELETE_CONFIRMED, () => {
      this.onMediaDeleteConfirmed();
    });
  }

  ngOnInit() {}

  //#region HTML Event Handlers
  public addMediaClicked() {
    this.m_ImgUploader?.nativeElement.click();
  }
  //--------------------------------------------------------------------
  public onImgClicked(img: Media) {
    this.m_CurrSelected = img;
  }
  //--------------------------------------------------------------------
  public onRightClicked(media: Media, event: MouseEvent) {
    if (media.status !== MEDIA_STATUS.READY) return;
    event.preventDefault();
    this.m_CurrSelected = media;
    this.viewAttachment();
  }
  //--------------------------------------------------------------------
  /**
   * Event handler for when the pager is changed (page size, page number, etc.)
   * @param event The event data from the kendo pager
   */
  public handlePageChange(event: PageChangeEvent): void {
    this.m_PageSize = event.take;
    this.m_Skip = event.skip;
    this.m_PageNumber = event.skip / event.take;

    if (this.m_CurrFilter == FILTER_TYPE.IMAGES) this.loadMoreImages();
    else if (this.m_CurrFilter == FILTER_TYPE.VIDEOS) this.loadMoreVideos();
  }
  //--------------------------------------------------------------------
  public onCloseClicked() {
    this.OnCloseClicked.emit();
  }
  //--------------------------------------------------------------------
  //Event handler for select button, emits selected media
  public onSelectClicked() {
    if (this.m_CurrSelected == null) return;

    this.OnMediaSelected.emit(this.m_CurrSelected);
    this.m_CurrSelected = null;
  }
  //--------------------------------------------------------------------
  //Event handler for media delete button, emits deleted media
  public async onDeleteClicked() {
    if (this.m_CurrSelected == null) return;

    this.m_Events.publish(EVENTS.MEDIA_DELETE_CONFIRM_SHOW, {
      videoId: this.m_VideoId,
      mediaId: this.m_CurrSelected?.id,
      unsavedRecords: this.m_Records?.filter((item) => item.unsaved),
    });
  }
  //--------------------------------------------------------------------
  //Event handler for image uploader input
  async onMediaSelected($event: any) {
    if ($event.target.files.length == 0) return;

    let files = $event.target.files;
    if (this.m_CurrFilter == FILTER_TYPE.IMAGES) this.uploadImages(files);
    else if (this.m_CurrFilter == FILTER_TYPE.VIDEOS) this.uploadVideos(files);
  }

  //--------------------------------------------------------------------
  onMediaClicked($event: any) {
    if ($event?.target?.value != null) {
      $event.target.value = null;
    }
  }
  //--------------------------------------------------------------------
  onMediaStateChanged(media: Media) {
    let index = this.m_CurrMedia.data.findIndex((m) => m.id == media.id);
    if (index == -1) return;

    this.m_CurrMedia.data[index].copy(media);
  }
  //--------------------------------------------------------------------
  onDragOver(event: any) {
    event.preventDefault();
    this.m_DropZone.nativeElement.classList.add("drag-over");
  }
  //--------------------------------------------------------------------
  onDragLeave(event: any) {
    event.preventDefault();
    this.m_DropZone.nativeElement.classList.remove("drag-over");
  }
  //--------------------------------------------------------------------
  onDrop(event: any) {
    event.preventDefault();
    this.m_DropZone.nativeElement.classList.remove("drag-over");

    //Verify a file was selected
    if (event.dataTransfer == null || event.dataTransfer.files.length == 0)
      return;

    let files: File[] = event.dataTransfer.files;
    if (this.m_CurrFilter == FILTER_TYPE.IMAGES) this.uploadImages(files);
    else if (this.m_CurrFilter == FILTER_TYPE.VIDEOS) this.uploadVideos(files);
  }
  //--------------------------------------------------------------------
  onFilterChanged(event: any) {
    this.m_SearchValue = "";

    switch (event.detail.value) {
      case "all":
        this.m_CurrFilter = FILTER_TYPE.ALL;
        break;
      case "images":
        this.m_CurrFilter = FILTER_TYPE.IMAGES;
        break;
      case "videos":
        this.m_CurrFilter = FILTER_TYPE.VIDEOS;
        break;
    }

    this.reset();
  }
  //--------------------------------------------------------------------
  onSearchValueChanged(value: any) {
    let searchValue = value;

    if (this.m_SearchTimeout) clearTimeout(this.m_SearchTimeout);

    this.m_SearchTimeout = setTimeout(() => {
      this.m_SearchValue = searchValue;
      this.reset();
    }, this.m_SearchTimeoutDelay);
  }
  //--------------------------------------------------------------------
  onSearchFilterChanged(value: any, filter: string) {
    let searchValue = value.detail.checked;
    let filterName = filter;

    if (filterName === "File") {
      this.m_SearchFilters.file = searchValue;
      if (searchValue) {
        this.m_SearchFilters.image = false;
      }
    } else if (filterName === "Image") {
      this.m_SearchFilters.image = searchValue;
      if (searchValue) {
        this.m_SearchFilters.file = false;
      }
    }
  }
  //#endregion
  //#region Render Helpers
  public validateSelect(): boolean {
    return this.m_CurrSelected != null;
  }
  //--------------------------------------------------------------------
  public shouldShowSelect(media: Media): boolean {
    return this.m_CurrSelected == media;
  }
  //--------------------------------------------------------------------
  public shouldRenderMedia(): boolean {
    return this.m_CurrMedia.data.length > 0 || this.m_Loading;
  }
  //--------------------------------------------------------------------
  public shouldDisableSearch(): boolean {
    return (
      this.m_Loading ||
      this.m_Uploading ||
      (this.m_CurrMedia.total == 0 && this.m_SearchValue == "")
    );
  }
  //--------------------------------------------------------------------
  public shouldRenderSearch(): boolean {
    return this.m_CurrFilter == FILTER_TYPE.IMAGES;
  }
  //--------------------------------------------------------------------
  public getAcceptedFileTypes(): string {
    if (this.m_CurrFilter == FILTER_TYPE.IMAGES) return "image/jpeg, image/png";
    else if (this.m_CurrFilter == FILTER_TYPE.VIDEOS) return "video/mp4";
    else return "image/jpeg, image/png, video/mp4";
  }
  //--------------------------------------------------------------------
  public getCurrFilterValue(): string {
    switch (this.m_CurrFilter) {
      case FILTER_TYPE.ALL:
        return "all";
      case FILTER_TYPE.IMAGES:
        return "images";
      case FILTER_TYPE.VIDEOS:
        return "videos";
    }
  }
  //#endregion
  //#region Private Methods
  private async reset() {
    this.CurrMedia.data = [];
    this.m_PageNumber = 0;
    this.m_Skip = 0;

    if (this.m_VideoId.length == 0) return;

    if (this.m_CurrFilter == FILTER_TYPE.IMAGES) {
      await this.loadMoreImages();
    } else if (this.m_CurrFilter == FILTER_TYPE.VIDEOS) {
      await this.loadMoreVideos();
    }
  }

  //--------------------------------------------------------------------

  //--------------------------------------------------------------------
  //Helper function used to load more images based on pagination
  private async loadMoreImages() {
    this.m_Loading = true;
    try {
      let newImages = await this.m_MediaService.getMedia(
        this.m_VideoId,
        MEDIA_TYPE.IMAGE,
        this.m_PageNumber,
        this.m_PageSize,
        this.m_SearchValue,
        !this.m_SearchFilters.file
      );

      this.m_CurrMedia = newImages;
    } catch (error) {
      console.log(error);
    }
    this.m_Loading = false;
  }
  //--------------------------------------------------------------------
  //Helper function used to load more videos based on pagination
  private async loadMoreVideos() {
    this.m_Loading = true;
    try {
      let newVideos = await this.m_MediaService.getMedia(
        this.m_VideoId,
        MEDIA_TYPE.VIDEO,
        this.m_PageNumber,
        this.m_PageSize
      );

      this.m_CurrMedia = newVideos;
    } catch (error) {
      console.log(error);
    }
    this.m_Loading = false;
  }
  //--------------------------------------------------------------------
  //Helper function used to upload images selected to a video
  private async uploadImages(files: File[]) {
    this.m_Loading = true;
    this.m_Uploading = true;

    this.m_NotificationService.showInfo(
      this.$t("shared.messages.startingUpload"),
      1000
    );

    let promises: Promise<any>[] = [];
    for (let file of files) {
      //Verify file is image
      if (file.type != "image/jpeg" && file.type != "image/png") {
        //Show error if file is not a supported image type
        this.m_NotificationService.showError(
          `${this.$t("shared.messages.invalidFileType")}: ${file.name}`,
          5000
        );
        this.m_Loading = false;
        continue;
      }

      try {
        //Generate a temp id from upload time
        let id = `${Date.now()}`;
        let media = new Media({
          id: id,
          name: file.name,
          url: URL.createObjectURL(file),
          type: MEDIA_TYPE.IMAGE,
          video_id: "",
          status: MEDIA_STATUS.PENDING,
        });
        //Add the media to the front of the list
        this.prependMedia(media);

        let promise = this.m_MediaService.uploadImage(
          this.m_VideoId,
          file,
          (progress) => {
            let numProgress = Math.round(
              (progress.loaded / progress.total) * 100
            );
            this.updateMediaProgress(id, numProgress);
          }
        );

        promises.push(promise);
      } catch (error) {
        //Error uploading image
        this.m_NotificationService.showError(
          `${this.$t("shared.messages.failedToUpload")}  ${file.name}`,
          5000
        );
      }
    }

    await Promise.all(promises).catch((error) => {
      //Error uploading image
      this.m_NotificationService.showError(
        `${this.$t("shared.messages.failedToUpload")}`,
        5000
      );
    });
    await this.loadMoreImages();
    this.m_Loading = false;
    this.m_Uploading = false;
  }
  //--------------------------------------------------------------------
  private async uploadVideos(files: File[]) {
    this.m_Loading = true;
    this.m_Uploading = true;

    this.m_NotificationService.showInfo(
      this.$t("shared.messages.startingUpload"),
      1000
    );

    let promises: Promise<any>[] = [];
    for (let file of files) {
      promises.push(this.uploadVideo(file));
    }

    await Promise.all(promises).catch((error) => {
      //Error uploading image
      this.m_NotificationService.showError(
        `${this.$t("shared.messages.failedToUpload")}`,
        5000
      );
    });

    await this.loadMoreVideos();
    this.m_Loading = false;
    this.m_Uploading = false;
  }
  //--------------------------------------------------------------------
  private async uploadVideo(file: File) {
    if (!file.type.includes("video")) {
      //Show error if file is not a supported image type
      this.m_NotificationService.showError(
        `${this.$t("shared.messages.invalidFileType")}: ${file.name}`,
        5000
      );
      return;
    }

    try {
      //Create the asset first
      let res = await this.m_MediaService.createVideoMedia(
        this.m_VideoId,
        file
      );
      let media = new Media(res.media);

      //Upload the video to the asset blob URL
      this.m_MediaService
        .uploadVideoMedia(
          this.m_VideoId,
          media.id,
          media.url,
          file,
          (progress: number) => {
            //Find the media card for the upload and update the progress
            let mediaCard = this.getMediaCard(media.id);
            mediaCard?.onProgress(progress);
          }
        )
        .then((success) => {
          let mediaCard = this.getMediaCard(media.id);
          mediaCard?.updateMedia();
        });

      //Add the media to the front of the list
      this.prependMedia(media);
    } catch (error) {
      console.error(error);
      this.m_NotificationService.showError(
        `${this.$t("shared.messages.failedToUpload")}`,
        5000
      );
    }
  }
  //--------------------------------------------------------------------
  private prependMedia(media: Media) {
    this.m_CurrMedia.data.unshift(media);
    this.m_CurrMedia.total++;

    if (this.m_CurrMedia.total > this.m_PageSize) {
      this.m_CurrMedia.data.pop();
    }
  }
  //--------------------------------------------------------------------
  private updateMediaProgress(mediaID: string, progress: number) {
    let mediaCard = this.m_MediaCards?.find(
      (card) => card.m_Media?.id == mediaID
    );
    mediaCard?.onProgress(progress);
  }
  //--------------------------------------------------------------------
  private getMediaCard(mediaID: string): MediaCardComponent | undefined {
    return this.m_MediaCards?.find((card) => card.m_Media?.id == mediaID);
  }
  //--------------------------------------------------------------------
  private async viewAttachment() {
    if (this.m_CurrSelected?.type == MEDIA_TYPE.IMAGE)
      this.m_Events.publish(EVENTS.MEDIA_SHOW, this.m_CurrSelected);
    else if (this.m_CurrSelected?.type == MEDIA_TYPE.VIDEO) {
      let attachmentContents: HIA_ATTACHMENT_CONTENT = {
        src: this.m_CurrSelected?.url || "",
        text: "",
        video_id: this.m_CurrSelected?.id ?? undefined,
        main_video_id: this.m_VideoId,
      };
      let msg: HIA_MSG = {
        msgUID: "",
        text: "",
        author: this.m_ChatService.bot,
        attachmentOnly: true,
        attachments: [
          {
            content: attachmentContents,
            contentType: ATTACHMENT_MIME_TYPE.VIDEO,
          },
        ],
      };

      this.m_Events.publish(EVENTS.ATTACHMENT_SHOW, msg);
    }
  }
  //--------------------------------------------------------------------
  private async onMediaDeleteConfirmed() {
    if (this.m_CurrSelected == null) return;

    await this.m_MediaService.deleteMedia(
      this.m_VideoId,
      this.m_CurrSelected.id
    );

    this.m_CurrMedia.data = this.m_CurrMedia.data.filter(
      (media) => media.id != this.m_CurrSelected?.id
    );

    this.OnMediaDeleted.emit(this.m_CurrSelected);

    this.m_CurrSelected = null;
  }
  //#endregion
}
