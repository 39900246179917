import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { v4 as uuidv4 } from "uuid";

import {
  ChatService,
  HIA_MSG,
  Suggestion,
} from "src/app/services/chat/chat.service";
import { T } from "src/app/services/localization/localization.service";
import { UtteranceService } from "src/app/services/utterance/utterance.service";
import { Router } from "@angular/router";
import { linkifyText } from "src/app/utility/utils";
import { SttService } from "src/app/services/stt/stt.service";
import { convertRange } from "@shared/utils/utils";
import { HIANotificationService } from "src/app/services/notification/notification.service";
import { UiService } from "src/app/services/ui/ui.service";
import {
  ATTACHMENT_TYPE,
  PAGINATED_RECORDS,
} from "src/app/models/record/record";
import { UserService } from "src/app/services/user/user.service";
import { AdminService } from "src/app/services/admin/admin.service";
import {
  TRACK_EVENT,
  TRACK_QUERY_DTO,
} from "src/app/models/analytics/analytics";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";

enum QnA_UI_STATE {
  QUESTION,
  FALLBACK,
  ANSWER,
  THINKING,
}

export const PulseConstants = {
  pulseMaxBlur: 25,
  pulseMinBlur: -1,
  pulseMaxBright: 2.0,
  pulseMinBright: 0.8,
  pulseMinSaturate: 1.0,
  pulseMaxSaturate: 2.0,
  pulseColor: "#e73939",
};

@Component({
  selector: "app-qna-question-ui",
  templateUrl: "./qna-question-ui.component.html",
  styleUrls: ["./qna-question-ui.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QnaQuestionUiComponent implements OnInit {
  @Input()
  set VideoId(value: string | null) {
    if (!value || this.m_VideoId == value) return;
    this.m_VideoId = value;
    this.clearSuggestions();

    if (this.m_VideoId.length > 0) this.updateSuggestions();
  }
  @Input()
  set CanAskChatGPT(value: boolean) {
    this.m_CanAskChatGPT = value;
  }

  @Input()
  set isAdmin(value: boolean) {
    this.m_IsAdmin = value;
  }

  @Output() showAttachment: EventEmitter<HIA_MSG> = new EventEmitter();
  @Output() resumeQvio: EventEmitter<void> = new EventEmitter();
  @ViewChild("m_Root", { static: true, read: ViewContainerRef })
  m_Root: ViewContainerRef | null = null;
  @ViewChild("m_SuggestionsListContainer", {
    static: false,
    read: ViewContainerRef,
  })
  m_SuggestionsListContainer: ViewContainerRef | null = null;

  get Suggestions() {
    return this.m_Suggestions;
  }

  get FallbackOptions() {
    return this.m_FallbackOptions;
  }

  get State() {
    return this.m_UIState;
  }

  get ActiveTextMsg() {
    return this.m_ActiveTextMsg;
  }

  get SuggestionClasses() {
    let classes = ["suggestions-list", "hia-scrollbar"];
    if (this.m_Suggestions.length > 6) {
      classes.push("suggestions-list-scrollable");
    }
    return classes;
  }

  get STTEnabled() {
    return this.m_EnableSTT;
  }

  get STTActive() {
    return this.m_STTService.isSTTActive();
  }

  public $t = T.translate;
  public m_CanAskChatGPT: boolean = false;
  public m_IsEditPage: boolean = false;
  public m_EnableSTT: boolean = false;
  public m_Loading: boolean = false;
  public m_Question: string = "";
  public m_IsAdmin: boolean = false;
  public m_ThumbsUp: boolean = false;
  public m_ThumbsDown: boolean = false;
  private m_VideoId: string = "";
  private m_ChatResponseSub: Subscription | null = null;
  private m_ClientUID: string = uuidv4();
  private m_SuggestionsPage: number = 0;
  private m_SuggestionsPageSize: number = 10;
  private m_SuggestionsPageSeed: number | undefined = undefined;
  private m_TotalSuggestions: number = 0;
  private m_Suggestions: Suggestion[] = [];
  private m_UIState: QnA_UI_STATE = QnA_UI_STATE.QUESTION;
  private m_FallbackOptions: Suggestion[] = [];
  private m_CancelQuery: boolean = false;
  private m_ActiveTextMsg: HIA_MSG | null = null;
  private m_PulseInterval: any;
  private m_PulseContext: AudioContext | null = null;
  private m_PulseSource: MediaStreamAudioSourceNode | null = null;
  private m_PulseAnalyser: AnalyserNode | null = null;
  private m_PulseStream: MediaStream | null = null;

  constructor(
    private m_ChatService: ChatService,
    private m_Router: Router,
    private m_UtteranceService: UtteranceService,
    private m_STTService: SttService,
    private m_NotificationService: HIANotificationService,
    private m_UIService: UiService,
    private m_Analytics: AnalyticsService,
    private m_UserService: UserService,
    private m_AdminService: AdminService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.m_ChatService.reset();
    this.unhookEvents();
  }

  public async intialize() {
    this.m_ChatResponseSub = this.m_ChatService.Response.subscribe(
      (response: HIA_MSG) => {
        this.onMessageReceived(response);
      }
    );

    this.m_EnableSTT = this.m_STTService.EnableSTT;
    //Check if we are on firefox or android, if so disable STT
    if (
      this.m_EnableSTT &&
      (navigator.userAgent.indexOf("Firefox") > -1 ||
        this.m_UIService.isAndroid())
    ) {
      this.m_EnableSTT = false;
    }

    //Checks if we are on Edit Page
    if (this.m_Router.url?.includes("edit")) {
      this.m_IsEditPage = true;
    }

    if (this.m_VideoId.length > 0 && this.m_Suggestions.length == 0)
      this.updateSuggestions();
  }

  public unhookEvents() {
    this.m_ChatResponseSub?.unsubscribe();
    this.endAudioPulse();
  }

  public async updateSuggestions() {
    if (this.m_VideoId == null) return;

    this.m_Loading = true;

    let suggestions: PAGINATED_RECORDS | null = null;
    if (this.m_IsAdmin) {
      suggestions = await this.m_AdminService.getSuggestions(
        this.m_VideoId,
        this.m_SuggestionsPage,
        this.m_SuggestionsPageSize,
        this.m_SuggestionsPageSeed
      );
    } else {
      suggestions = await this.m_ChatService.getSuggestedRecords(
        this.m_VideoId,
        this.m_SuggestionsPage,
        this.m_SuggestionsPageSize,
        this.m_SuggestionsPageSeed
      );
    }

    this.m_Loading = false;
    if (suggestions == null) return;

    //Add to suggestions
    let records = suggestions.records;
    this.m_SuggestionsPageSeed = suggestions.seed ?? undefined;
    this.m_TotalSuggestions = suggestions.totalItems;
    if (records && records.length > 0) {
      let newSuggestions: Suggestion[] = records.map((record) => {
        let attachmentType: ATTACHMENT_TYPE = record.getAttachmentType();
        return {
          text: record.question ?? "",
          attachmentType: attachmentType,
          type: "askHIA",
        };
      });
      this.m_Suggestions = this.m_Suggestions.concat(newSuggestions);
    }
  }

  public clearSuggestions() {
    this.m_Suggestions = [];
    this.m_SuggestionsPage = 0;
    this.m_SuggestionsPageSeed = undefined;
  }
  //#region HTML Event Handlers
  public onAskChatGPTClicked() {
    let action: Suggestion = {
      type: "askOpenAI",
      text: this.m_Question,
      attachmentType: ATTACHMENT_TYPE.NONE,
    };

    this.onFallbackOptSelected(action);
  }

  public onSuggestionClick(suggestion: Suggestion) {
    this.m_Question = suggestion.text;
    this.submitQuery(suggestion.text, false);
  }

  public onSuggestionScrollBottom() {
    this.m_SuggestionsPage++;

    if (
      this.m_SuggestionsPage * this.m_SuggestionsPageSize >=
      this.m_TotalSuggestions
    )
      return;
    this.updateSuggestions();
  }

  onSendClicked() {
    if (this.m_Question.length == 0) return;

    this.submitQuery(this.m_Question, true);
  }

  onAnswerClose() {
    this.m_UtteranceService.stop(true);
    this.m_UIState = QnA_UI_STATE.QUESTION;
    this.m_ActiveTextMsg = null;
    this.m_Question = "";
    this.m_ThumbsUp = false;
    this.m_ThumbsDown = false;
  }

  onThumbsClicked(value: boolean) {
    //Blocks the user to spam thumbs up or down
    if (this.m_ThumbsUp || this.m_ThumbsDown) return;
    this.m_ThumbsUp = value;
    this.m_ThumbsDown = !value;

    let sessionId = this.m_Analytics.CurrWatchSessionId;
    let originalQuestion = this.m_Question;

    let response_type = this.m_ActiveTextMsg?.response_type;
    let videoId = this.m_VideoId;
    let answerText = this.m_ActiveTextMsg?.text;
    let recordId = this.m_ActiveTextMsg?.recordId;
    let recordScore = this.m_ActiveTextMsg?.record_score;
    let insightKey = this.m_ActiveTextMsg?.insightInfo?.insight_key;
    let insightName = this.m_ActiveTextMsg?.insightInfo?.insight_name;
    if (!sessionId) return;

    let metadata: TRACK_QUERY_DTO = {
      sessionId: sessionId,
      videoId: videoId,
      question: originalQuestion,
      questionTyped: false,
      response_type: response_type ?? "none",
      answer: answerText ?? "",
      record_id: recordId ?? "",
      record_score: recordScore ?? undefined,
      insight_key: insightKey ?? "",
      insight_name: insightName ?? "",
      thumbsUpDown: value,
    };

    this.m_Analytics.trackEvent(TRACK_EVENT.VIDEO_FEEDBACK, metadata);
  }

  //Called when the user clicks on a suggested action
  onFallbackOptSelected(suggestion: Suggestion) {
    if (suggestion.type == "askOpenAI") {
      if (this.m_VideoId == null) return;
      if (suggestion.text == null || suggestion.text == "") return;

      //Submit the question to the OpenAI API
      this.m_ChatService.submitToOpenAI(this.m_VideoId, {
        msgUID: uuidv4(),
        clientUID: this.m_ClientUID,
        text: suggestion.text,
        author: this.m_ChatService.user,
      });
    } else if (suggestion.type == "login") {
      this.m_Router.navigate(["/login"], {
        queryParams: { redirect: this.m_Router.url },
      });
    } else if (suggestion.type == "askHIA") {
      this.m_Question = suggestion.text ?? "";
      this.submitQuery(suggestion.text, false);
    }
  }

  onThinkingCancelClicked() {
    this.m_CancelQuery = true;
    this.m_UIState = QnA_UI_STATE.QUESTION;
  }

  onResumeClicked() {
    this.resumeQvio.next();
    this.m_Question = "";
    this.m_UIState = QnA_UI_STATE.QUESTION;
    this.m_ActiveTextMsg = null;
  }

  onSTTClicked() {
    if (this.m_STTService.isSTTActive()) {
      this.endAudioPulse();
      this.m_STTService.stopSTT();
    } else {
      this.startAudioPulse();
      this.m_STTService.startSTT(
        (result: string) => {
          this.m_Question = result;
        },
        () => {
          this.endAudioPulse();
        },
        this.onSTTError.bind(this)
      );
    }
  }
  //#endregion
  //#region Render Helpers
  shouldRenderLoading() {
    return this.m_Loading;
  }

  shouldRenderWarningMessage() {
    return this.shouldRenderSuggestions() && this.m_IsEditPage;
  }

  shouldRenderSuggestions() {
    return !this.shouldRenderFallback() && !this.shouldRenderTextAnswer();
  }

  shouldRenderFallback() {
    return this.m_UIState == QnA_UI_STATE.FALLBACK;
  }

  shouldRenderThinking() {
    return this.m_UIState == QnA_UI_STATE.THINKING;
  }

  shouldRenderTextAnswer() {
    return this.m_UIState == QnA_UI_STATE.ANSWER;
  }

  getAttachmentIcon(suggestion: Suggestion) {
    if (suggestion?.attachmentType == null) return "";
    switch (suggestion.attachmentType) {
      case ATTACHMENT_TYPE.IMAGE:
        return "image";
      case ATTACHMENT_TYPE.VIDEO:
        return "videocam";
      case ATTACHMENT_TYPE.WEBURL:
        return "globe-outline";
      case ATTACHMENT_TYPE.VIDEO_SEGMENT:
        return "film-outline";
      default:
        return "";
    }
  }

  getAnswerText() {
    if (this.m_ActiveTextMsg == null) return "";
    return linkifyText(this.m_ActiveTextMsg.text ?? "");
  }

  getFallbackHeader() {
    if (this.FallbackOptions.length > 0) {
      return this.$t("components.qna.fallbackHeader");
    } else {
      return this.$t("components.qna.fallbackHeaderNoOptions");
    }
  }

  getSuggestionsContainerHeight() {
    let parentHeight =
      this.m_SuggestionsListContainer?.element.nativeElement.clientHeight;
    if (parentHeight == null || parentHeight == 0) return "200";

    return parentHeight;
  }

  shouldDisplayThumbs() {
    return this.m_UIService.WatchVideo;
  }
  //#endregion
  //#region Private Methods
  private async submitQuery(query: string, questionTyped: boolean) {
    this.m_STTService.stopSTT();
    if (this.m_VideoId == null) return;
    let newMsg: HIA_MSG = {
      msgUID: uuidv4(),
      author: this.m_ChatService.user,
      text: query,
      clientUID: this.m_ClientUID,
    };

    this.m_ChatService.submit(
      this.m_VideoId,
      newMsg,
      questionTyped,
      this.m_CanAskChatGPT
    );
  }

  private onMessageReceived(msg: HIA_MSG) {
    if (this.m_CancelQuery) {
      this.m_CancelQuery = false;
      this.m_UtteranceService.stop();
      return;
    }

    if (
      msg.author == this.m_ChatService.bot ||
      msg.author == this.m_ChatService.openAI
    ) {
      //Show text otherwise if it's from the bot and we have no suggested actions
      if (msg.fallbackOptions != null && msg.fallbackOptions.length > 0) {
        this.m_UIState = QnA_UI_STATE.FALLBACK;
        //Remove the askOpenAI action as we have a separate button for that
        this.m_FallbackOptions = msg.fallbackOptions.filter(
          (action) => action.type != "askOpenAI"
        );
      } else if (msg.typing) {
        this.m_UIState = QnA_UI_STATE.THINKING;
      } else if (msg.attachments != null && msg.attachments.length > 0) {
        this.showAttachment.emit(msg);
        this.m_UIState = QnA_UI_STATE.QUESTION;
        this.m_Question = "";
        return;
      } else {
        this.m_UIState = QnA_UI_STATE.ANSWER;
        this.m_ActiveTextMsg = msg;
        if (msg.author == this.m_ChatService.openAI) {
          this.m_ActiveTextMsg.text =
            this.m_ActiveTextMsg.text +
            "</br></br><b>" +
            this.$t("shared.messages.chatGPTWarning") +
            "</b>";
        }
      }
    }
  }

  private onSTTError(error: any) {
    console.error(error);
    this.endAudioPulse();
    this.m_NotificationService.showError(
      this.$t("shared.messages.sttError"),
      8000
    );
  }

  private async startAudioPulse() {
    try {
      this.m_PulseStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      this.m_PulseContext = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      this.m_PulseSource = this.m_PulseContext.createMediaStreamSource(
        this.m_PulseStream
      );
      this.m_PulseAnalyser = this.m_PulseContext.createAnalyser();

      this.m_PulseAnalyser.fftSize = 512;
      this.m_PulseAnalyser.minDecibels = -127;
      this.m_PulseAnalyser.maxDecibels = 0;
      this.m_PulseAnalyser.smoothingTimeConstant = 0.4;
      this.m_PulseSource.connect(this.m_PulseAnalyser);

      //Default mic button effect on start
      let dom = document.getElementById("qna-question-ui-stt");
      if (dom == null) {
        return;
      }

      dom.style.filter =
        "drop-shadow(0px 0px 10px #e73939) brightness(1.0) saturate(1)";

      // Create a new array to store the audio frequency data
      const frequencyData = new Uint8Array(
        this.m_PulseAnalyser.frequencyBinCount
      );
      // Start the pulse effect
      const pulse = () => {
        if (this.m_PulseAnalyser == null) return;
        this.m_PulseAnalyser.getByteFrequencyData(frequencyData);
        let volumeSum = 0;
        for (const volume of frequencyData) {
          volumeSum += volume;
        }

        const avgVolume = volumeSum / frequencyData.length;

        //Build filter modifiers
        let brightness = convertRange(
          avgVolume,
          0,
          50,
          PulseConstants.pulseMinBright,
          PulseConstants.pulseMaxBright
        );
        brightness = Math.min(brightness, PulseConstants.pulseMaxBright);

        let blur = convertRange(
          avgVolume,
          0,
          50,
          PulseConstants.pulseMinBlur,
          PulseConstants.pulseMaxBlur
        );
        blur = Math.min(blur, PulseConstants.pulseMaxBlur);

        let saturate = convertRange(
          avgVolume,
          0,
          50,
          PulseConstants.pulseMinSaturate,
          PulseConstants.pulseMaxSaturate
        );
        saturate = Math.min(saturate, PulseConstants.pulseMaxSaturate);

        if (dom == null) return;
        dom.style.filter = `drop-shadow(0px 0px ${blur}px ${PulseConstants.pulseColor}) brightness(${brightness}) saturate(${saturate})`;
      };

      this.m_PulseInterval = setInterval(pulse, 10);
    } catch (error) {
      console.error(error);
    }
  }
  //----------------------------------------------------------------------------------------
  private endAudioPulse() {
    let dom = document.getElementById("qna-question-ui-stt");
    if (dom == null) {
      return;
    }
    dom.style.filter = "";
    clearInterval(this.m_PulseInterval);

    //Clean up pulse logic to release mic
    this.m_PulseContext?.close();
    this.m_PulseContext = null;
    this.m_PulseAnalyser?.disconnect();
    this.m_PulseAnalyser = null;
    this.m_PulseSource?.disconnect();
    this.m_PulseSource = null;

    if (this.m_PulseStream != null) {
      this.m_PulseStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.m_PulseStream = null;
    }
  }

  //#endregion
}
